import React from "react"
import { parseText,parseSubText, actions, requests , xls , doc, pdf} from "../../../../../util/chat"
import { Dimmer,Loader, Popup  } from "semantic-ui-react"
import { ASSETS_CDN } from "../../../../../util/GlobalContants"
import moment from "moment-timezone"
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox"
import { openModal } from "../../../../modals/redux-state/modalActions";
import { connect } from "react-redux";

function Message({profileId,data, partner, requestAction, read, index, tz, scrollTo, openModal, globalNumber, upgrade_link}) {
    let icon = null
    let msgActions = null
    let action = null
    let req
    let msgCardClass = data.from === profileId ? data.type === 'request' ? 'msg-card action-card' : 'msg-card' : 'msg-card my-card'
    if(data.from === profileId && data.participants.length === 1 && ['text','file'].includes(data.type)){
        msgCardClass = 'msg-card my-card'
    }

    if(data.from !== profileId && data.spam){
        msgCardClass = 'warning-msg'
    }
    if(data.type === 'request'){
        req = Object.assign({}, requests[data.request]) 
        if(req !== undefined){
            icon = req.icon
            if(data.from === profileId){
                msgActions =  Object.assign({}, req.self_actions)
                if(!['later','pending'].includes(data.action)){
                    Object.keys(msgActions).forEach(key => {
                        msgActions[key].disabled = true
                    })
                }else if(data.reminders > 0  && msgActions.reminder !== undefined){
                    msgActions.reminder.disabled = true
                }
            }else{
                msgActions =  Object.assign({}, req.partner_actions)
                if(['later','ignore'].includes(data.action)){
                    if(msgActions.later !== undefined){
                        msgActions.later.disabled = true
                    }
                    if(msgActions.ignore !== undefined){
                        msgActions.ignore.disabled = true
                    }
                }else if(data.action  !==  'pending'){
                    Object.keys(msgActions).forEach(key => {
                        msgActions[key].disabled = true
                    })
                }
            }
        }
    }
    else if(data.type === 'action'){
        action = actions[data.action]
        if(data.linked !== undefined && data.linked.type === 'request'){
            req = requests[data.linked.text]
            icon = req.icon
            if(data.action === 'reminder' && (data.linked.action === undefined || data.linked.action === null ) ){
               msgActions =  (data.from === profileId) ? Object.assign({}, req.self_actions) : Object.assign({}, req.partner_actions)
               if(['later','ignore'].includes(data.linked.action)) {
                    if(msgActions.later !== undefined){
                        msgActions.later.disabled = true
                    }
                    if(msgActions.ignore !== undefined){
                        msgActions.ignore.disabled = true
                    }
               }
            }
            
        }
    }
    const get_url_extension = (url) => {
        return url.split(/[#?]/)[0].split('.').pop().trim();
    }
    let photo_card_class = `photo-card`
    if(data.attachments !== undefined && data.attachments.length === 1) {
        photo_card_class+= ` single` 
    }else{
        photo_card_class+= ` multiple`
        if(data.attachments !== undefined && data.attachments.length === 2) {
            photo_card_class+= ` two` 
        }
        if(data.attachments !== undefined && data.attachments.length === 4) {
            photo_card_class+= ` four` 
        }
    }
    
    

    return(
        <React.Fragment>
            { data.type === 'sys-info' ? (
                <div id={`M_${data.id}`} className="item center" onClick={()=> { if(data.linked && data.linked.id){scrollTo(data.linked.id)}}}>
                <div className="warning-msg">{parseText(data,profileId,partner.name)}</div>
                <div className="time right">{data.created_at_ago}</div>
                </div>
            ) : (
                <div id={`M_${data.id}`} className={ (data.from === profileId) ? (read && !(data.participants.length === 1 &&  ['text','file'].includes(data.type))) ? `item right read` : `item right` : `item left`  } >
                    
            {data.attachments !== undefined && data.attachments.length > 0 && (
                <SimpleReactLightbox>
                <SRLWrapper>
                <div className={photo_card_class} >
                    {data.attachments.map((a,i) => {
                        let img = a.file
                        let gallery = true
                        if(a.file === null ){
                            img = `${ASSETS_CDN}/img/attachment_pending_approval.png`
                            gallery = false
                        }
                        else if(xls.includes(get_url_extension(a.file))){
                            img = `${ASSETS_CDN}/img/xls.png`
                            gallery = false
                        }else if(doc.includes(get_url_extension(a.file))){
                            img = `${ASSETS_CDN}/img/doc.png`
                            gallery = false
                        }else if (pdf.includes(get_url_extension(a.file))){
                            img = `${ASSETS_CDN}/img/pdf.png`
                            gallery = false
                        }
                        return (<div className="photo-item" key={i}>
                                {a.approved === 0 && (
                                    <div className="middle-progress d-flex flex-end item-end">
                                        <div className="bg-yellow font-12 font-weight-normal text-center p-1 w-100">In admin review</div>
                                    </div>
                                )}
                                {a.approved === 2 && (
                                    <div className="middle-progress d-flex flex-end item-end">
                                        <div className="bg-red font-12 font-weight-normal text-center text-white p-1 w-100">Admin rejected</div>
                                    </div>
                                )}
                                <a href={a.file} target="_blank" rel="noopener noreferrer" data-attribute={gallery ? 'SRL' : ''} >
                                    <img src={img} alt="Attachment" />
                                </a>
                            </div>)
                    })}
                </div>
                </SRLWrapper>
                </SimpleReactLightbox>
            )}
            {data.text !== null ? (
                <div className={(data.type === 'action' && data.action === 'reminder' &&  data.from === profileId ) ? `${msgCardClass} reminder-card` : `${msgCardClass}`}>
                    {data.from === profileId && (data.spam || (data.participants.length === 1 &&  ['text','file'].includes(data.type))) && (
                        <Popup
                        trigger={
                            <i className="i-alert-circle"></i>
                        }
                        inverted
                        content={`This message was not sent as you need to upgrade your account`}
                        size='mini'
                        position='top center'
                        ></Popup>
                    )}
                    {data.from === profileId && data.spam && (
                        <Popup
                        trigger={
                            <i className="i-alert-circle"></i>
                        }
                        inverted
                        content={`Don't share any kind of contact informations in the chat to avoid your account get banned automatically`}
                        size='mini'
                        position='top center'
                        ></Popup>)}
                <Dimmer active={data.loading} inverted><Loader inverted content={null} /></Dimmer>
                <div className="cmt-box">
                    <div className="description">
                        {icon !== null  && (
                            <i className={`data-icon ${icon}`}></i>
                        )}
                        <div className="content">
                        <div className="text">{parseText(data,profileId,partner.name)}</div>
                            { data.type === 'action' && (data.action === 'accept' || data.action === 'add') && data.from !== profileId && (
                                <div className="mt-1"><a href={`/${profileId}/matches/${data.from}`} target="_blank" rel="noopener noreferrer" className="ui tiny button btn-white text-green ripple">View Profile</a></div>
                            )}
                        {data.type === 'request' && (
                            <small>{ data.from === profileId ? `${partner.name} requires some time to decide. Long wait? Take the number and speak.` : `${partner.name} waiting for your action.` }</small>
                        )}

                        {data.type === 'action' && data.linked !== undefined && data.linked.action !== undefined && (
                           <small>{parseSubText(data.linked,profileId,partner.name)}
                           <span className="float-right ml-2 font-11">{moment(data.linked.updated_at).tz(tz).format('DD MMM YYYY')}</span>
                           </small>
                        )}
                         {data.from === profileId && data.participants.length === 1 &&  ['text','file'].includes(data.type) && (
                             <div class="help-msg">
                                 <div className="font-13 text-dark"> <span className="mr-05" >Sorry, only premium customers can send own messages</span> 
                                 <button
                                 className="ui button btn-link p-0 mr-0 text-blue h-auto lh-100"
                                 onClick={e =>
                                    openModal("Upgrade", {
                                      customClass: "modal-md",
                                      upgrade_link: upgrade_link,
                                      globalNumber: globalNumber,
                                      profileId: profileId
                                    })
                                  }
                                 >see details.</button>
                                 </div>
                            </div>
                         )}
                        
                        {msgActions !== null  &&  data.from !== profileId && (
                            <div className="mt-1">
                                { Object.keys(msgActions).map(i => {
                                    return <button
                                        key={i}
                                        className={`ui btn-white button font-weight-normal ${msgActions[i].class||'text-gray'}`}
                                        disabled={msgActions[i].disabled}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            requestAction(index,i)
                                        }}
                                        >
                                        {msgActions[i].label}
                                        </button> 
                                })}
                                
                                {(data.action !== 'pending' ||  data.reminders > 0 ) && (
                                    <small className="mt-1">{parseSubText(data,profileId,partner.name)} at {moment(data.updated_at).tz(tz).format('DD MMM YYYY')}</small>
                                )}
                                
                            </div>
                        )}
                        </div>
                    </div>
                    {msgActions !== null  && data.from === profileId && (
                            <div className="msg-footer">
                                { Object.keys(msgActions).map(i => {
                                    return <button
                                            key={i}
                                            disabled={msgActions[i].disabled}
                                            className={`ui button btn-link font-weight-normal p-0 font-14 mr-2 ${msgActions[i].class||'text-gray'}`}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                requestAction(index,i)
                                            }}
                                        >{msgActions[i].label}
                                    </button>
                                })}
                                {(data.action !== 'pending' ||  data.reminders > 0 ) && (
                                <small className="mt-05 d-block">{parseSubText(data,profileId,partner.name)} at {moment(data.updated_at).tz(tz).format('DD MMM YYYY')}</small>
                                )}
                            </div>
                    )}
                </div>
                <div className="time right">
                {data.from === profileId && (data.spam || (data.participants.length === 1 &&  ['text','file'].includes(data.type))) && ( <span class="font-12 text-pink mr-2">Sending failed</span>)}
                {data.created_at_ago}
                {data.from === profileId && !(data.spam || (data.participants.length === 1 &&  ['text','file'].includes(data.type))) && (
                    <i className="msg-check msg-status"></i>
                )}
                </div>
            </div>
            ):(
                <div className="time right">{data.created_at_ago}
                 {data.from === profileId && (
                    <i className="msg-check msg-status"></i>
                )}
                </div>
            )}
            </div>)}
            </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        globalNumber: state.commonData.defaultCountryNumber
    };
  };

export default connect(
    mapStateToProps,
    { openModal}
  )(Message);