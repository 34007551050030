import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
function SubFooter({profileId, user}){
    return (
        <div className="settings-links">
            <NavLink to={`/${profileId}/settings`} >Settings Home</NavLink>
            <NavLink to={`/${profileId}/edit-profile`} >Edit Profile</NavLink>
            <NavLink to={`/${profileId}/partner-preferences`}>Partner Preferences</NavLink>
            <NavLink to={`/${profileId}/filterout`}>Filterout</NavLink>
            <NavLink to={`/${profileId}/photos`}>Manage Photos</NavLink>
        </div>)
}

const mapStateToProps = state => {
    return {
        user : state.user.user.data
    };
};

export default connect(
    mapStateToProps
  )(SubFooter);
