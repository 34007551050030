import React from "react";
export const errorCommaSeparated = (
  error = null,
  propertyOne = null,
  propertyTwo = null
) => {
  if (error.hasOwnProperty(propertyOne) || error.hasOwnProperty(propertyTwo)) {
    return (
      <div class="text-red font-12 mt-05">
        {error[propertyOne]} <br /> {error[propertyTwo]}
      </div>
    );
  }
};
