import React, { Fragment , useEffect, useState } from "react"
import { connect } from "react-redux"
import { closeModal } from "../redux-state/modalActions"
import { Dimmer,Loader  } from "semantic-ui-react"
import { chatAxios } from "../../../util/axios"
import swal from "sweetalert"

function ContactViewAlert(props) {
  const [loading, setLoading] = useState(false)
  const { token , closeModal, tokenUpdated, profileId, text } = props

  const viewContact = async () => {
        try {
            setLoading(true)
            const {data} = await chatAxios.get(`/view-contact`,{ params : {token : token}})
            if(data && data.result) {
                if(text){
                  await sendMessage(data.data.token,text)
                  tokenUpdated(data.data.token)
                }else{
                  tokenUpdated(data.data.token)
                  closeModal()
                }
            }
            setLoading(false)
        } catch (error) {
          
            setLoading(false)
            swal({
              title: `Your credit has been runout for this period.`,
              icon: "warning",
              text: 'Your credit has been runout for this period.'
            })
        }
  }

  const sendMessage = async (tkn,txt) => {
    try {
        setLoading(true)
        const {data} = await chatAxios.post(`/conversations`,{ 
            token : tkn,
            type: 'text' ,
            text : txt
        })
        if(data && data.result) {
            const wrapper = document.createElement('div')
            wrapper.innerHTML = `<a href="/${profileId}/messages" > Go to Chat</a>`
            swal({
                title: 'Your message has been sent',
                icon: "success",
                content: wrapper
              })
            closeModal()
        }
        setLoading(false)
    } catch (error) {
        setLoading(false)
    }
}

  return (
    <Fragment>
      <Dimmer active={loading} inverted><Loader inverted content='Loading' /></Dimmer>
      <div class="modal-header text-center header-light">
                <h3 class="m-0">Please confirm!</h3>
      </div>
      <i className="close modal-close" onClick={() => closeModal()} >×</i>
      <div className="content modal-body text-center">
        <div class="mb-2"><div class="text-black">Your contact credit will get reduce if you are share the contact detail in the message.</div></div> 
        <div class="">
            <button class="ui small button btn-green border box-shadow font-weight-normal text-white radius-2 ripple mr-2"
                onClick={() => {
                    viewContact()
                }}
            >Send anyway</button>
            <button class="ui small button btn-white border box-shadow font-weight-normal radius-2 ripple mr-05"
                onClick={() => {
                    closeModal()
                }}
            >Don't send</button>
        </div>
      </div>
    </Fragment>
  );
}

export default connect(
  undefined,
  { closeModal }
)(ContactViewAlert);
