import { GLOBAL_SPINNER } from "./types";

const initState = {
  globalSpinner: false
};

export default function(state = initState, action) {
  switch (action.type) {
    case GLOBAL_SPINNER:
      return {
        ...state,
        globalSpinner: action.payload
      };
    default:
      return state;
  }
}
