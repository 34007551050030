import React, { useState } from "react";
import { toBase64 } from "../../../../../util/toBase64";
import { gpAxios } from "../../../../../util/axios";
import { FormattedMessage } from "react-intl";

function HoroscopeChart({ profileId, singleUser, set_other_way_modal }) {
  const [album_loader, set_album_loader] = useState(false);
  const [baseImages, setBaseImages] = useState([]);

  const _handleImageChange = async e => {
    e.preventDefault();
    set_album_loader(true);

    let files = e.target.files;
    let base64Array = [];
    for (var i = 0; i < files.length; i++) {
      const photo = await toBase64(files[i]);
      base64Array.push(photo);
    }
    setBaseImages(base64Array);
    try {
      await gpAxios.post(`/profile/${profileId}/horoscope-images`, {
        horoscope_images: base64Array
      });
      set_album_loader(false);
    } catch (error) {
      set_album_loader(false);
    }
  };

  return (
    <div className="ui fluid card wd-card mb-2">
      <div className="content py-3">
        <i className="icon-xxl i-planet-green m-auto mb-2 d-block mt-3" />
        <div className="mb-2 text-center">
          <div className="text-black font-18 lh-lg mb-05"><FormattedMessage id="upload.horoscope" /></div>

          <button
            className="ui button btn-link p-0 mr-0 font-weight-normal font-12 text-blue"
            id="modal9"
            type="button"
            onClick={() => set_other_way_modal(true, "horoscope")}
          >
            <FormattedMessage id="upload.other.way.horoscope" />
          </button>
        </div>
        <div className="finle-input m-auto mb-4 text-center">
          <label
            htmlFor="embedpollfileinputt"
            className="ui button btn-white font-weight-semibold ripple"
          >
            <i className="ui upload icon" /> <FormattedMessage id="upload.horoscope.button" />
          </label>
          <input
            type="file"
            multiple
            onChange={e => _handleImageChange(e)}
            className="inputfile"
            id="embedpollfileinputt"
          />
        </div>
        <div className="chart-wrapper text-center">
          {baseImages.length > 0 &&
            baseImages.map((himage, index) => {
              return (
                <React.Fragment>
                  <div className="card" key={index}>
                    <div className="ui tiny image">
                      {album_loader && (
                        <div className="middle-progress">
                          <div className="d-flex item-center content-center h-100 flex-column h-100">
                            <div className="ui active centered inline loader mb-05" />
                            <div className="font-11 text-white mb-05">
                              Uploading…
                            </div>
                          </div>
                        </div>
                      )}

                      <img src={himage} alt={index} />
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
          {singleUser &&
            singleUser.horoscope_images.length > 0 &&
            singleUser.horoscope_images.map((chart, index) => {
              return (
                <div className="card" key={index}>
                  <div className="ui tiny image">
                    {chart.approved === 2 && (
                      <div class="img-status bg-red">Rejected</div>
                    )}
                     {chart.approved === 1 && (
                      <div class="img-status bg-green">Approved</div>
                    )}
                    {(!chart.approved ||chart.approved === 0 ) && (
                      <div class="img-status bg-yellow">In Review</div>
                    )}
                    <img src={chart.file} alt="" />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default HoroscopeChart;
