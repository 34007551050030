import React from "react";
import { Dropdown } from "semantic-ui-react";
import "react-input-range/lib/css/index.css";
import { Error } from "../../../../../../common/shared-components/Error";
import {
  arrayWithAny,
  age,
  hisHeight,
  maritalStatus
} from "../../../../../../common/forms-data/forms-data";
import { filterItems } from "../../../../../../util/filter_common_data";
import { checkAny } from "../../../../../../util/check_any";
import { errorCommaSeparated } from "../../../../../../util/errorCommaSeparate";

function BasicPrefrence({
  commonD,
  errors,
  setMinAge,
  setMaxAge,
  setMinHeight,
  setMaxHeight,
  setMaritalStatus,
  current_state,
  setCommunity,
  setReligion,
  setCaste,
  setSpeakingLanguages,
  allCommonData,
  data
}) {
  return (
    <React.Fragment>
      <div className="inline fields mb-4 ">
        <label className="w-50 lh-md">
          <span className="mr-05">Preferred Age</span>
        </label>
        <div className="w-100">
          <div className="d-flex item-center age-group">
            <span className="font-15 mr-05">From</span>
            <span className="w-xxs mr-05">
              <Dropdown
                search
                className={`wd-input ${Error(errors, "age.min")}`}
                placeholder="Age"
                fluid
                selection
                selectOnBlur={false}
                options={age}
                onChange={(e, { value }) => {
                  setMinAge(value);
                }}
                value={current_state.age.min}
              />
            </span>
            <span className="font-15 mr-05">to</span>
            <span className="w-xxs mr-05">
              <Dropdown
                search
                className={`wd-input ${Error(errors, "age.max")}`}
                placeholder="Age"
                fluid
                selection
                options={age}
                onChange={(e, { value }) => {
                  setMaxAge(value);
                }}
                value={parseInt(current_state.age.max)}
              />
            </span>
            <span className="font-15">years</span>
          </div>
          {errors && errorCommaSeparated(errors, "age.min", "age.max")}
        </div>
      </div>

      <div className="inline fields mb-4">
        <label className="w-50 lh-md">
          <span className="mr-05">Marital Status</span>
        </label>
        <div className="w-100">
          <Dropdown
            search
            className={`wd-input ${Error(errors, "time_to_call")}`}
            placeholder="Marital Status"
            fluid
            selection
            selectOnBlur={false}
            multiple
  
            options={arrayWithAny(maritalStatus)}
            onChange={(e, { value }) => {
              setMaritalStatus(checkAny(value));
            }}
            value={current_state.marital_status}
          />
        </div>
      </div>

      <div className="inline fields mb-4">
        <label className="w-50 lh-md">
          <span className="mr-05">Preferred Height</span>
        </label>
        <div className="w-100">
          <div className="d-flex item-center height-group">
            <Dropdown
              search
              className={`ui fluid selection dropdown wd-input mr-05 ${Error(
                errors,
                "height.min"
              )}`}
              placeholder="Select Height"
              fluid
              selection
              selectOnBlur={false}
              options={hisHeight}
              onChange={(e, { value }) => {
                setMinHeight(value);
              }}
              value={current_state.height.min}
            />

            <span className="font-15 mr-05">to</span>

            <Dropdown
              search
              className={`ui fluid selection dropdown wd-input  ${Error(
                errors,
                "height.max"
              )}`}
              placeholder="Select Height"
              fluid
              selection
              selectOnBlur={false}
              options={hisHeight}
              onChange={(e, { value }) => {
                setMaxHeight(value);
              }}
              value={current_state.height.max}
            />
          </div>
          {errors && errorCommaSeparated(errors, "height.min", "height.max")}
        </div>
      </div>

      <div className="inline fields mb-4">
        <label className="w-50 lh-md">
          <span className="mr-05">Community</span>
        </label>
        <div className="w-100">
          <Dropdown
            search
            className={`wd-input ${Error(errors, "community")}`}
            placeholder="Select Community"
            fluid
            selection
            selectOnBlur={false}
            multiple
            options={arrayWithAny(
              filterItems("community", allCommonData && allCommonData.data)
            )}
            onChange={(e, { value }) => setCommunity(checkAny(value))}
            value={current_state.community}
          />
        </div>
      </div>
      <div className="inline fields mb-4">
        <label className="w-50 lh-md">
          <span className="mr-05">Speaking Languages</span>
        </label>
        <div className="w-100">
          <Dropdown
            search
            className={`wd-input ${Error(errors, "spkeaing_language")}`}
            placeholder="Select Speaking Languages"
            fluid
            selection
            selectOnBlur={false}
            multiple
            options={arrayWithAny(
              filterItems("mother-tongue", allCommonData && allCommonData.data)
            )}
            onChange={(e, { value }) => setSpeakingLanguages(checkAny(value))}
            value={current_state.speaking_language}
          />
        </div>
      </div>
      <div className="inline fields mb-4">
        <label className="w-50 lh-md">
          <span className="mr-05">Religion</span>
        </label>
        <div className="w-100">
          <Dropdown
            search
            className={`wd-input ${Error(errors, "religion")}`}
            placeholder="Select Religion"
            fluid
            selection
            selectOnBlur={false}
            multiple
            options={arrayWithAny(
              filterItems("religion", allCommonData && allCommonData.data)
            )}
            onChange={(e, { value }) => setReligion(checkAny(value))}
            value={current_state.religion}
          />
        </div>
      </div>
      <div className="inline fields mb-4">
        <label className="w-50 lh-md">
          <span className="mr-05">Caste</span>
        </label>
        <div className="w-100">
          <Dropdown
            search
            className={`wd-input ${Error(errors, "caste")}`}
            placeholder="Select Caste"
            fluid
            selection
            selectOnBlur={false}
            multiple
            options={arrayWithAny(
              filterItems("caste", allCommonData && allCommonData.data, {
                community: data.data && data.data.community
              })
            )}
            onChange={(e, { value }) => setCaste(checkAny(value))}
            value={current_state.caste}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default BasicPrefrence;
