import { filterItems } from "./filter_common_data";

const getNameFromArrayObject = (key, arrayData) => {
  let name = arrayData
    .filter(data => data.key === key)
    .map(name => {
      return name.text;
    });

  return name;
};

const getNextIndexFromArrayObject = (key, arrayData) => {
  return (
    arrayData !== undefined &&
    arrayData
      .filter(data => data.key === key)
      .map((name, index) => {
        if (
          typeof filterItems("profile-steps", arrayData)[index + 1] !==
          "undefined"
        ) {
          return filterItems("profile-steps", arrayData)[index + 1]["id"];
        } else {
          return filterItems("profile-steps", arrayData)[index]["id"];
        }
      })
  );
};


const commonDataName = (id,commonData) => {
  if(Array.isArray(id)){
    return commonArrayDataName(id,commonData)
  }
  const item = commonData.find(e => e.id === id)
  return item ? item.name : null
}

const commonArrayDataName = (list,commonData)=> {
  if (!Array.isArray(list)) {
    return ''
  }
  const nameList = list.map(i => {
    const itm = commonData.find(e => e.id === i)
    return itm ? itm.name : null
  })
  return nameList.join(', ')
}

export { getNameFromArrayObject, getNextIndexFromArrayObject, commonDataName};
