import React, { useState, useEffect } from "react";
import Img from "react-image";
import { Placeholder } from "semantic-ui-react";

export default function SelectedProfile({ profileCards, profileId }) {
  const [profile, setProfile] = useState({});

  useEffect(() => {
    selectedProfile();
  });

  const selectedProfile = () => {
    const sProfile = profileCards.filter(
      profile => parseInt(profile.id) === parseInt(profileId)
    )[0];
    setProfile(sProfile);
  };

  return (
    <div className="text" onClick={selectedProfile}>
      <span className="thumb thumb-xs float-left mr-1 radius-4 o-hidden">
        {" "}

        <Img
          className="ui fluid image"
          src={
            profile && profile.photo
              ? profile.photo
              : profile && profile.gender &&
                `https://storage.googleapis.com/matrimony-assets/dashboard/img/${profile.gender}.png`
          }
          loader={
            <Placeholder style={{ height: 150, width: 150 }}>
              <Placeholder.Image />
            </Placeholder>
          }
        />
      </span>
      <div className="o-hidden">
        <div className="font-13 text-truncate font-weight-bold mb-1">
          {profile && profile.name}
        </div>
        <div className="extra content mt--1">
          <span className="d-inline-block mb-05">
            <span className="d-inline-block align-middle text-gray mr-xs font-12">
              {profile && profile.did}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}
