import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { filterItems } from "../../../../../../util/filter_common_data";
import { getNameFromArrayObject } from "../../../../../../util/getNameFromArrayObject";
import { Link } from "react-router-dom";
import MatchListingPhoto from "../../../../../../common/shared-components/photo/listing_photo";
import { Popup } from "semantic-ui-react";
import reactStringReplace  from'react-string-replace'

export default function RegularCard({
  matches,
  allCommonData,
  profileId,
  matchesSpinner,
  matchType,
  matchesCount,
  matchTypeConfig,
  singleUser,
  openModal
}) {
  const parseSubText = (str) => {
  
    return (reactStringReplace(str, /\[(.*?)\]/, (match, i) => (
      match === 'preference' ? <a href={`/${profileId}/partner-preferences`}>preference</a> : 
      match === 'all-matches' ? <a href={`/${profileId}/matches/all`}>all matches</a> :
      match === 'edit-profile' ? <a href={`/${profileId}/edit-profile`}>edit profile</a> : ''
    )))
  }

  const MatchBody = (match,allCommonData) =>{
      return (
        <Fragment>
          <div className="mp-header" >
                      <div className="mp-name">
                          <span>{match.name}</span>
                      </div>
                      <div className="mp-description">
                        { match.package_group !== null && (
                          <label className="ui label match-label premium">
                           Premium
                          </label>
                        )}
                        <span className="mp-id">{match.did}</span>
                        <span className="createdby">
                          Created by{" "}
                          {
                            getNameFromArrayObject(
                              match.created_by,
                              filterItems(
                                "relationship",
                                allCommonData && allCommonData.data
                              )
                            )[0]
                          }
                        </span>
                      </div>
                      </div>
                      <div className="mp-body">
                        { match.match_type === 'reverse-match' && (
                          <div className="mp-meta">
                          <div className="matching-score">
                              <span className="match-horoscope d-block m-auto mb-05">
                                  <i className="i-arrows d-block"></i>
                              </span>
                              
                              <div className="font-8 text-gray">Reverse Match</div>
                          </div>
                        </div>
                        )}
                        <div className="mp-info">
                          {match.about_txt !== null && (
                            <div className="font-14 text-black mb-1"><i className="icon-sm i-user-fill-female float-left mr-1" />
                                <div className="o-hidden">
                                  {match.about_txt}
                                </div>
                              </div>
                          )}
                          
                          {match.location_txt !== null && (
                            <div className="font-14 text-black mb-1"><i className="icon-sm i-map-pin float-left mr-1" />
                              <div className="o-hidden">
                              {match.location_txt}
                              </div>
                            </div>
                           )}

                          {match.occ_txt !== null ?(
                            <div className="font-14 text-black mb-1"><i className="icon-sm i-briefcase-fill-gray float-left mr-1"/>
                              <div className="o-hidden">
                              {match.occ_txt}
                              </div>
                            </div>
                          ): (
                            <div className="font-14 text-black mb-1"><i className="icon-sm i-language-bubble float-left mr-1"/>
                              <div className="o-hidden">
                              {match.community_txt}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
        </Fragment>
      )
  }

  return (
    <React.Fragment>
      {matchesSpinner && (
        <div
          className="ui fluid card wd-card segment"
          style={{ height: "230px" }}
        >
          <div className="content match-card-container">
            <div
              className="ui active transition visible inverted dimmer"
              style={{
                opacity: 1,
                backgroundColor: "#fff",
                overflow: "hidden",
                height: "100%"
              }}
            >
              <div className="content">
                <div className="ui large text loader">Loading</div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!matchesSpinner && matches && matches.length < 1 && (
        <React.Fragment>
        <div className="ui fluid card wd-card m-0 mb-2 nomatch-card">
          <div className="font-18 font-weight-normal text-center mb-05">
          {matchTypeConfig && parseSubText(matchTypeConfig.emtpy)}
          </div>
          <div className="ui horizontal divider font-weight-normal text-muted mb-4">Or</div>
          <div className="font-18 font-weight-normal text-center mb-2">Looking for more suitable matches? Check and edit your partner preference here.</div>
          <NavLink to={`/${profileId}/partner-preferences`} className="ui button font-15 btn-green font-weight-normal ripple">Adjust Partner Preference</NavLink>
        </div>
        {matchesCount && (
          <div className="ui fluid card wd-card m-0 mb-1">
            <div className="content">
                <div className="ui two column grid match-stats">
                    <div className="row pt-4 pb-1">
                        {matchesCount.matches.new > 0 && (
                          <div className="column d-flex">
                            <NavLink to={`/${profileId}/matches`} className="ui segment no-shadow border mb-2 text-center">
                                <div className="font-20 text-blue font-weight-bold mb-1">{matchesCount.matches.new}</div>
                                <div className="font-14 text-blue">New matches</div>
                            </NavLink>
                          </div>
                        )}
                        {matchesCount.matches.total > 0 && (
                          <div className="column d-flex">
                            <NavLink to={`/${profileId}/matches/all`} className="ui segment no-shadow border mb-2 text-center">
                                <div className="font-20 text-blue font-weight-bold mb-1">{matchesCount.matches.total}</div>
                                <div className="font-14 text-blue">All matches</div>
                            </NavLink>
                          </div>
                        )}
                        {matchesCount.matches.viewed > 0 && (
                          <div className="column d-flex">
                            <NavLink to={`/${profileId}/matches/viewed`} className="ui segment no-shadow border mb-2 text-center">
                                <div className="font-20 text-blue font-weight-bold mb-1">{matchesCount.matches.viewed}</div>
                                <div className="font-14 text-blue">Recently viewed by me</div>
                            </NavLink>
                          </div>
                        )}
                        {matchesCount.mutual > 0 && (
                          <div className="column d-flex">
                            <NavLink to={`/${profileId}/matches/mutual`} className="ui segment no-shadow border mb-2 text-center">
                                <div className="font-20 text-blue font-weight-bold mb-1">{matchesCount.mutual}</div>
                                <div className="font-14 text-blue">Mutual Matches</div>
                            </NavLink>
                          </div>
                        )}
                        {(matchesCount.matches.favourite + matchesCount.reverse_matches.r_favourite) > 0 && (
                          <div className="column d-flex">
                            <NavLink to={`/${profileId}/matches/shortlisted`} className="ui segment no-shadow border mb-2 text-center">
                                <div className="font-20 text-blue font-weight-bold mb-1">{matchesCount.matches.favourite + matchesCount.reverse_matches.r_favourite}</div>
                                <div className="font-14 text-blue">Shortlisted by me</div>
                            </NavLink>
                          </div>
                        )}
                        {(matchesCount.matches.hide + matchesCount.reverse_matches.r_hide) > 0 && (
                          <div className="column d-flex">
                            <NavLink to={`/${profileId}/matches/rejected`} className="ui segment no-shadow border mb-2 text-center">
                                <div className="font-20 text-blue font-weight-bold mb-1">{matchesCount.matches.hide + matchesCount.reverse_matches.r_hide}</div>
                                <div className="font-14 text-blue">Rejected by me</div>
                            </NavLink>
                          </div>
                        )}
                        {(matchesCount.matches.contact_viewed + matchesCount.reverse_matches.r_contact_viewed) > 0 && (
                          <div className="column d-flex">
                            <NavLink to={`/${profileId}/matches/contact-viewed`} className="ui segment no-shadow border mb-2 text-center">
                                <div className="font-20 text-blue font-weight-bold mb-1">{matchesCount.matches.contact_viewed + matchesCount.reverse_matches.r_contact_viewed}</div>
                                <div className="font-14 text-blue">Contact Viewed by me</div>
                            </NavLink>
                          </div>
                        )}
                    </div>
                </div>
            </div>
          </div>
        )}
     {matchesCount && singleUser && (singleUser.package === null || singleUser.package === 'free' ) && matchesCount.matches.qualified !== 0 && (
        <div className="text-center mt-5">
        <div className="font-17 font-weight-bold mb-1">{matchesCount.matches.qualified} possible matching profiles are waiting for you!</div>
        <a href={`${singleUser.upgrade_link}`} target="_blank" rel="noopener noreferrer" className="ui button btn-blue font-12 radius-2 mr-0">Upgrade* today to see more matches</a>
        <div className="font-12 text-gray mt-1">*Based on your current partner preference, vary time to time</div>
        </div>
      )}
    

    </React.Fragment> )}
      {!matchesSpinner &&
        matches &&
        matchesCount && 
        matches.length > 0 &&
        matches.map((match,index) => {
          return (
            <React.Fragment>
            {index === 2 && (matchesCount.matches.favourite + matchesCount.reverse_matches.r_favourite) >0 && matchType === 'all' && (
                <div className="ui fluid card wd-card action-card pro-tiny-card mt-0 mb-1">
                  <NavLink to={`/${profileId}/matches/shortlisted`} exact className="body">
                  <div className="left">
                      <i className="i-heart-pink-circle ml-0"></i>
                  </div> 
                  <div className="w-100">
                      <div className="font-18 text-black mb-05">Shortlisted</div>
                      <div className="font-14 text-gray">You have shortlisted {matchesCount.matches.favourite + matchesCount.reverse_matches.r_favourite} profiles</div>
                  </div>
                </NavLink>
                </div>
            )}
            {index === 2 &&  matchType === undefined && (
                <div className="ui fluid card wd-card mp-card">
                    <div className="content">
                        <div className="ui two column grid match-stats">
                            <div className="row pt-4 pb-1">
                                <div className="column d-flex">
                                <NavLink to={`/${profileId}/matches/shortlisted`} exact className="ui segment no-shadow border mb-2 text-center">
                                        <div className="font-20 text-blue font-weight-bold mb-1">{matchesCount.matches.favourite + matchesCount.reverse_matches.r_favourite}</div>
                                        <div className="font-14 text-blue">Shortlisted profiles</div>
                                </NavLink>
                                </div>
                                <div className="column d-flex">
                                <NavLink to={`/${profileId}/matches/all`} exact className="ui segment no-shadow border mb-2 text-center">
                                        <div className="font-20 text-blue font-weight-bold mb-1">{matchesCount.matches.total}</div>
                                        <div className="font-14 text-blue">All matches</div>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div key={match.id} className="ui fluid card wd-card mp-card">
              <div className="match-short-info">
                  { match.last_viewed_at !== null  && (
                      <Popup
                      content='Profile Viewed'
                      on='click'
                      pinned
                      position='left center'
                      inverted
                      trigger={<span className="match-viewed hidden-xs"><i className="i-eye-line-blue profile-viewed pointer"></i></span>}
                    />
                  )}
                    {/* <span className="match-info pointer">i</span> */}
                    {/* <Dropdown text={null} icon="icon-sm i-menu-vertical d-block" className='mini icon button bg-transparent mr-0 pr-0' fluid floating >
                      <Dropdown.Menu>
                        <Dropdown.Item>Block</Dropdown.Item>
                        <Dropdown.Item>Report profile for misuse</Dropdown.Item>
                        <Dropdown.Item>Report photo</Dropdown.Item>
                        <Dropdown.Item>View chart</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> */}
              </div>
              { match.package_group !== null && (<label className="ui label mp-label premium">Premium</label>) }
              {singleUser && singleUser.package === 'free' && match.match_type === 'match' && match.is_premium === 1 &&  (
                <div className="match-upgrade">
                  <a href={singleUser.upgrade_link} target="_blank" rel="noopener noreferrer" className="ui button btn-blue-invert font-16 ripple" id="modal8">Available on Upgrade</a>
                </div>
              )}
              {singleUser && singleUser.package === 'free' && match.match_type === 'reverse-match' && (
                <div className="match-upgrade">
                  <a href={singleUser.upgrade_link} target="_blank" rel="noopener noreferrer" className="ui button btn-blue-invert font-16 ripple" id="modal8">Available on Upgrade</a>
                </div>
              )}
              {singleUser && singleUser.package !== 'free' && !singleUser.package.config.reverse && match.match_type === 'reverse-match' && (
                <div className="match-upgrade">
                  <a href={singleUser.upgrade_link} target="_blank" rel="noopener noreferrer" className="ui button btn-blue-invert font-16 ripple" id="modal8">Available on Upgrade</a>
                </div>
              )}
              <div className="mp-container">
                <div className="mp-thumb">
                  <MatchListingPhoto
                    matchType={matchType}
                    profileId={profileId}
                    single_user={singleUser}
                    match_details={match}
                  />
                </div>
                <div 
                  className={match.photo_upload_warning ? "mp-details pointer" : "mp-details"}
                  onClick={()=>{
                    if(match.photo_upload_warning){
                      openModal('UploadPhotoWarning',{customClass: 'tiny', profileId: profileId, photo: singleUser.photo})
                    }
                  }}
                  >
                  {match.photo_upload_warning ? (
                     MatchBody(match,allCommonData)
                  ):(
                    <Link to={`/${profileId}/matches/${match.id}`}>
                        {MatchBody(match,allCommonData)}
                    </Link>
                  )}
                  
                </div>
              </div>
            </div>
            </React.Fragment>
          );
        })}
    </React.Fragment>
  );
}
