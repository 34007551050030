import React, { Fragment } from "react";
import { connect } from "react-redux";
import { closeModal } from "../redux-state/modalActions";
import { getNameFromArrayObject } from "../../../util/getNameFromArrayObject";
import { filterItems } from "../../../util/filter_common_data";

function PremimumUserModal(props) {
  const {
    closeModal,
    contact_details: { data },
    common_data,
    matched_name
  } = props;
  return (
    <Fragment>
      <div className="ui header">
        <h3 className>Contact detail for {matched_name}</h3>
      </div>
      <i onClick={() => closeModal()} className="close wd-close2">
        ×
      </i>
      <div className="content">
        <div className>
          <div className="d-flex item-center w-100 mb-3">
            <div className="thumb-md mr-2">
              <img
                className="ui image"
                alt='profile'
                src="//storage.googleapis.com/matrimony-assets/dashboard/img/default-boy.png"
              />
            </div>
            <div className>
              <div className="font-16 text-black font-weight-semibold">
                {data.name}
              </div>
              <div className="font-14 text-gray">
                <span className="d-inline-block align-middle mr-1">
                  {
                    getNameFromArrayObject(
                      data.relationship,
                      filterItems(
                        "contact-relationship",
                        common_data.data
                      )
                    )[0]
                  }
                </span>
                {getNameFromArrayObject(
                    data.call_time,
                    filterItems(
                        "call-time",
                        common_data.data
                    )
                )[0] && getNameFromArrayObject(
                    data.relationship,
                    filterItems(
                        "contact-relationship",
                        common_data.data
                    )
                )[0] && (<i className="dot dot-gray d-inline-block align-middle mr-05" />)}{" "}
                <span className="d-inline-block align-middle">{
                  getNameFromArrayObject(
                      data.call_time,
                      filterItems(
                          "call-time",
                          common_data.data
                      )
                  )[0]
                }</span>
              </div>
            </div>
          </div>
          {data.phone && (
            <div className="font-16 mb-2">
              <span className="i-sm float-left mr-1">
                <i className="icon-md i-cellphone-gray d-block m-auto" />
              </span>{" "}
              <a href className="text-black">
                {data.phone && data.phone}
              </a>
            </div>
          )}

          {data.additional_phone && (
            <div className="font-16 mb-2">
              <span className="i-sm float-left mr-1">
                <i className="icon-md i-cellphone-gray d-block m-auto" />
              </span>{" "}
              <a href className="text-black">
                {data.additional_phone && data.additional_phone}
              </a>
            </div>
          )}

          {data.email && (
            <div className="font-16 mb-2">
              <span className="i-sm float-left mr-1">
                <i className="icon-md i-mail-fill-gray d-block m-auto" />
              </span>{" "}
              <a href className="text-black">
                {data.email}
              </a>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

export default connect(
  undefined,
  { closeModal }
)(PremimumUserModal);
