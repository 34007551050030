import React, { Component } from "react";
import { connect } from "react-redux";
import { Button} from "semantic-ui-react";
import {
  singleProfile,
  defaultSingleProfile
} from "../../../../redux/actions/user/user";

import { updateLayout } from "../../../../redux/layout_controll/layout_action";
import {
  openModal,
  closeModal
} from "../../../modals/redux-state/modalActions";
import Photos from "./components/photos";
import AwaitingMatches from "./components/awaiting_matches";
import ProfileCard from "./components/profile_card";
import MatchesNotFound from "./components/matches_not_found";

import HoroscopeChart from "./components/horoscope_chart";

import {
  excludedStatus
} from "../../../../util/GlobalContants";

import {FormattedMessage, injectIntl} from "react-intl";
import { Link, NavLink } from "react-router-dom";

class Active extends Component {
  state = {
    profileId: this.props.match.params.profileId,
    other_way_modal: false,
    modal_type: null
  };

  componentDidMount() {
   
  }

  componentWillUnmount() {
    this.props.closeModal();
  }

  set_other_way_modal = (value, type) => {
    this.setState({ other_way_modal: value, modal_type: type });
  };

  render() {

    const SeeMore = () => {
        return <div className="extra content text-center bt-0 pt-0">
        <Link to={`/${this.state.profileId}/matches`} className="text-blue">
          See more
        </Link>
      </div>
    }

    const SeeMoreReverse = () => {
      return <div className="extra content text-center bt-0 pt-0">
      <Link to={`/${this.state.profileId}/reverse-matches`} className="text-blue">
        See more
        </Link>
    </div>
  }

    
    return (
            <div className="content-body">
              <div className="content-wrapper">
                <div className="wd-rail">
                  <div className="wd-middle-content">
                    <div className="ui relaxed divided items">

                      {this.props.singleUser &&
                        !excludedStatus.includes(
                          this.props.singleUser.state
                        ) && (
                          <React.Fragment>
                            <ProfileCard
                              profileId={this.state.profileId}
                              singleUser={this.props.singleUser}
                            />

                    {this.props.singleUser && this.props.singleUser.match_state === 1  &&  this.props.singleUser.match_count > 0  &&  (
                      <AwaitingMatches
                      title="Recommended Matches"
                      titleLink={`/${this.state.profileId}/matches`}
                      SubTitle={() => {
                        return <div className="font-14 text-gray px-2">Review your <NavLink 
                            className="btn-link p-0 text-blue"
                            to={`/${this.state.profileId}/partner-preferences`}
                            >partner preference</NavLink> to get more matches</div>
                    }}
                      Tip="Here are some matches we found for you by broadening your partner preference"
                      SeeMore={SeeMore}
                      apiEnd="matches-suggestion"
                      moreRoute={null}
                      profileId={this.state.profileId}
                      singleUser={this.props.singleUser}
                    />)}

                    {(this.props.singleUser && this.props.singleUser.match_state === 1  &&  this.props.singleUser.match_count === 0 ) &&  (
                      <MatchesNotFound profileId={this.state.profileId} />
                    )}
                    
                    {this.props.singleUser && this.props.singleUser.package === 'free' && (
                              <div className="ui fluid card wd-card upgrade-card mb-2">
                              <a className="body" href={this.props.singleUser.upgrade_link} target="_blank" rel="noopener noreferrer"  >
                                <div className="left">
                                  <div className="title">Upto 60% off on all plans</div>
                                  <span>The best way to find your dream partner faster is by upgrading!</span>
                                </div>
                                <div className="right">
                                  <div className="ui button btn-white ripple mr-0 ml-2 mt-05 font-weight-normal text-black">Upgrade Now</div>
                                </div>
                              </a>
                            </div>
                            )}
                            
                    {this.props.singleUser && this.props.singleUser.match_state === 1  &&  this.props.singleUser.reverse_matches_count > 0 && (
                            <AwaitingMatches
                              title="Members who looking for you"
                              profileId={this.state.profileId}
                              SeeMore={SeeMoreReverse}
                              upgradePopup={this.props.singleUser.state !== 2 ? 'PendingActivationModal' : (this.props.singleUser.package === 'free'|| !this.props.singleUser.package.config.reverse )? 'FreeProfileModal' : false}
                              apiEnd="reverse-matches-suggestion"
                              singleUser={this.props.singleUser}
                            />
                    )}

                    {this.props.singleUser && this.props.singleUser.match_state === 1  &&   this.props.singleUser.match_count === 0  && this.props.singleUser.reverse_matches_count === 0 && (
                            <AwaitingMatches
                              title="You may like this"
                              SubTitle={() => {
                                return <div className="font-14 text-gray px-2">Our recommended profiles based on your community</div>
                              }}
                              prefId={this.props.singleUser.partner_preferences && this.props.singleUser.partner_preferences[0].id}
                              profileId={this.state.profileId}
                              upgradePopup={'PreferenceAdjustModal'}
                              //apiEnd="basic-matches"
                              apiEnd="matches-suggestion"
                              singleUser={this.props.singleUser}
                            />
                    )}



                            <Photos
                              profileId={this.state.profileId}
                              singleUser={this.props.singleUser}
                              other_way_modal={this.state.other_way_modal}
                              modal_type={this.state.modal_type}
                              set_other_way_modal={this.set_other_way_modal}
                            />
                            {this.props.singleUser &&
                              this.props.singleUser.horoscope !== 2 &&
                              [1124,1121].includes(this.props.singleUser.religion) &&
                              this.props.singleUser.horoscope !== null && (
                                <HoroscopeChart
                                  profileId={this.state.profileId}
                                  singleUser={this.props.singleUser}
                                  set_other_way_modal={this.set_other_way_modal}
                                />
                              )}
                          </React.Fragment>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
    );
  }
}

const mapStateToProps = state => ({
  profileCards: state.user.profileCards,
  singleUser: state.user.singleProfileData,
  profileNotFound: state.user.profileNotFound,
  globalNumber: state.commonData.defaultCountryNumber
});

export default injectIntl(
    connect(mapStateToProps, {
    singleProfile,
    updateLayout,
    openModal,
    closeModal,
    defaultSingleProfile
})(Active));
