import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import {
  getCurrentCountry,
  setDefaultCountryNumber,
  setDefaultCountry
} from "../../redux/actions/common/common-data";
import {
  countryNumbers,
  HOMEPAGE,
  urls,
  ASSETS_CDN
} from "../../util/GlobalContants";

class HeaderLeftMenu extends React.Component {
  state = {
    defaultNumber: "+91 80753 24709"
  };

  componentDidMount() {
    // this.setState({ profileId: this.props.location.pathname.split("/")[1] });
    if (localStorage.getItem("defaultCountryIp")) {
      this.props.setDefaultCountry(
        JSON.parse(localStorage.getItem("defaultCountryIp"))
      );
    } else {
      this.props.getCurrentCountry();
    }
  }

  setCountry = number => {
    this.setState({ defaultNumber: number });
    this.props.setDefaultCountryNumber(number);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.defaultCountry && nextProps.defaultCountry.data.iso) {
      const index = countryNumbers.findIndex(x => x.code === nextProps.defaultCountry.data.iso);
      if(index >= 0){
        this.setCountry(countryNumbers[index].number);
      }else{
        this.setCountry(countryNumbers[0].number);
      }
    }
  }

  render() {
    const { menuActive, closetoggleMenu, isAuthenticated } = this.props;
    return (
      <div
        className={menuActive ? `wd-mobi-menu wd-mobi-menu-active` : `wd-mobi-menu`}
      >
        <button
          className="ui circular icon button wd-menu-close"
          onClick={closetoggleMenu}
        >
          <i className="i-close icon" />
        </button>
        <div className="p-2">
          <a href={`tel:${this.state.defaultNumber}`} className="font-14 text-black">
            {this.state.defaultNumber}
          </a>
          <div className="font-10 text-muted lh-100">24/7 Support</div>
        </div>
        {isAuthenticated && (
          <div className="my-account ml-2 mb-2">
            <div className="ui feed m-0 d-block p-1 w-100 border radius-3">
              <Link to="/myaccount" onClick={closetoggleMenu} className="event">
                <div className="label">
                  <img src={`${ASSETS_CDN}/img/male.png`} alt="" />
                </div>
                <div className="content text-dark font-weight-bold">
                  My Account
                </div>
              </Link>
            </div>
          </div>
        )}

        <div className="p-2">
          <div className="border radius-3">
            <a
              href={`tel:${this.state.defaultNumber}`}
              title
              className="ui fluid d-block text-dark border-bottom item"
            >
              <i className="icon-lg i-phone icon mr-05" />
              <div className="d-inline-block font-weight-normal">
                Help Center
                <div className="phono-no font-weight-bold font-16 mt-05">
                  {this.state.defaultNumber}
                </div>
              </div>
            </a>
            <a
              href={`https://api.whatsapp.com/send?phone=918075324709&text=Hi%20Matrimony%2c%20I%20need%20your%20help${
                this.state.profile !== null &&
                this.state.profile !== undefined &&
                this.state.profile.did !== ""
                  ? `,%20my%20id%20is%20${this.state.profile.did}`
                  : ""
              }`}
              target="_blank"
              rel="noopener noreferrer"
              className="ui basic button d-block mr-1 ml-1 mb-1 mt-1 pr-1 pl-1"
            >
              <div className="d-flex item-center">
                <i className="icon-lg d-inline-block i-whatsapp mr-1" />
                <div className="o-hidden text-left">
                  <div className="font-15 text-dark font-weight-bold">
                    {urls.whatsAppNo}
                  </div>
                  <span className="d-block text-muted font-12 mt-05">
                    Whatsapp (Message only)
                  </span>
                </div>
              </div>
            </a>
            <a
              href={urls.messangerLink}
              target="_blank"
              rel="noopener noreferrer"
              className="ui basic button d-block mr-1 ml-1 mb-1 mt-1 pr-1 pl-1"
            >
              <div className="d-flex item-center">
                <i className="icon-lg d-inline-block i-messenger mr-1" />
                <div className="o-hidden text-left">
                  <div className="font-15 text-dark font-weight-bold text-truncate lh-md">
                    {urls.messanger}
                  </div>
                  <span className="d-block text-muted font-12 mt-05">
                    Messenger
                  </span>
                </div>
              </div>
            </a>
            <a
              href={`${HOMEPAGE}/contact-us`}
              target="_blank"
              rel="noopener noreferrer"
              title
              className="ui fluid d-block text-dark item border-top font-15"
            >
              <i className="icon-lg d-inline-block align-middle i-support mr-05 pos-relative top--3" />
              Contact us
            </a>
          </div>
        </div>
        <div className="ui fluid vertical text menu mt-0 p-2">
          <div className="ui header font-15 font-weight-bold text-black mb-05">
            Company
          </div>
          <a href={`${HOMEPAGE}/about-us`} rel="noopener noreferrer" target="_blank" className="item">
            About us
          </a>

          <a
            href={`${HOMEPAGE}/terms-and-conditions`}
            target="_blank"
            rel="noopener noreferrer"
            className="item"
          >
            Terms of use
          </a>
          <a
            href={`${HOMEPAGE}/privacy-policy`}
            target="_blank"
            rel="noopener noreferrer"
            className="item"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  defaultCountry: state.commonData.defaultCountryData,
  isAuthenticated: state.user.isAuthenticated
});

export default connect(
  mapStateToProps,
  { getCurrentCountry, setDefaultCountryNumber, setDefaultCountry }
)(withRouter(HeaderLeftMenu));
