import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter, NavLink, Link } from "react-router-dom";

import { getProfiles } from "../../redux/actions/user/user";
import CardPlaceholder from "../../util/card_placeholder";
import SelectedProfile from "../../common/shared-components/SelectProfile";

import {
  getCurrentCountry,
  setDefaultCountryNumber,
  setDefaultCountry
} from "../../redux/actions/common/common-data";
import {
  ASSETS_CDN,
  countryNumbers,
  HOMEPAGE,
  urls
} from "../../util/GlobalContants";
import { FormattedMessage, injectIntl } from "react-intl";
import {openModal} from "../../components/modals/redux-state/modalActions";

class Sidebar extends Component {
  state = {
    profileId: null,
    matchesCount: {},
    profileCards: [],
    defaultNumber: "+91 80753 24709",
    profile: ""
  };

  componentDidMount() {
    this.props.getProfiles();

    this.setState({ profileId: this.props.location.pathname.split("/")[1] });
    if (localStorage.getItem("defaultCountryIp")) {
      this.props.setDefaultCountry(
        JSON.parse(localStorage.getItem("defaultCountryIp"))
      );
    } else {
      this.props.getCurrentCountry();
    }
  }

  setCountry = number => {
    this.setState({ defaultNumber: number });
    this.props.setDefaultCountryNumber(number);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.profileCards.length > 0) {
      this.setState({
        profileCards: nextProps.profileCards
      });
    }

    if (nextProps.profileCards) {
      const matches = nextProps.profileCards.filter(
        profile =>
          parseInt(profile.id) ===
          parseInt(this.props.location.pathname.split("/")[1])
      )[0];

      const sProfile = nextProps.profileCards.filter(
        profile => parseInt(profile.id) === parseInt(this.state.profileId)
      )[0];
      this.setState({ matchesCount: matches, profile: sProfile });
    }

    if (nextProps.defaultCountry && nextProps.defaultCountry.data.iso) {
      const index = countryNumbers.findIndex(x => x.code === nextProps.defaultCountry.data.iso);
      if(index >= 0){
        this.setCountry(countryNumbers[index].number);
      }else{
        this.setCountry(countryNumbers[0].number);
      }
    }
  }

  changeProfile = profileId => {
    window.location = `/${profileId}`;
  };

  render() {
    const { globalSpinner, profileCards, single_user } = this.props;
    const { profileId } = this.state;
    return (
      <aside className="app-aside bg-white">
        <div className="ui sidebar vertical menu">
          <div className="aside-header">
            <Link
              to={`/${this.state.profileId}`}
              className="aside-brand align-middle"
            >
              <img src="/assets/img/brand.png" alt="logo" />
            </Link>
          </div>
          <div className="aside-body">
            <div className="aside-profile-card">
              {globalSpinner ? (
                <CardPlaceholder />
              ) : (
                <Dropdown
                  className="ui compact selection dropdown border radius-4 pos-relative custom-card w-100"
                  icon={null}
                  trigger={
                    <React.Fragment>
                      <i className="dropdown icon top-2" />
                      <SelectedProfile
                        profileCards={this.state.profileCards}
                        profileId={this.state.profileId}
                      />
                    </React.Fragment>
                  }
                >
                  <Dropdown.Menu className="menu border">
                    {profileCards.length <= 3 &&
                      profileCards
                        .filter(cprofile => cprofile.id != this.state.profileId)
                        .map(profile => (
                          <div
                            className="item"
                            onClick={() => this.changeProfile(profile.id)}
                          >
                            <div className="text">
                              <span className="thumb thumb-xs float-left mr-1 radius-4 o-hidden">
                                {" "}
                                <img
                                  src={
                                    profile.photo
                                      ? profile.photo
                                      : `/assets/img/${
                                          profile.gender === "male"
                                            ? "male"
                                            : "female"
                                        }.png`
                                  }
                                  alt=""
                                />
                              </span>
                              <div className="o-hidden">
                                <div className="font-13 text-truncate font-weight-bold mb-1 lh-100">
                                  {profile.name}
                                </div>
                                <div className="extra content mt--1 lh-100">
                                  <span className="d-inline-block mb-05">
                                    <span className="d-inline-block align-middle text-gray mr-xs font-12">
                                      {profile.did}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    {profileCards.length > 3 &&
                      profileCards
                        .filter(cprofile => cprofile.id != this.state.profileId)
                        .splice(0, 3)
                        .map(profile => (
                          <div
                            key={profile.id}
                            className="item"
                            onClick={() => this.changeProfile(profile.id)}
                          >
                            <div className="text">
                              <span className="thumb thumb-xs float-left mr-1 radius-4 o-hidden">
                                {" "}
                                <img
                                  src={
                                    profile.photo
                                      ? profile.photo
                                      : `/assets/img/${
                                          profile.gender === "male"
                                            ? "male"
                                            : "female"
                                        }.png`
                                  }
                                  alt=""
                                />
                              </span>
                              <div className="o-hidden">
                                <div className="font-13 text-truncate font-weight-bold mb-1 lh-100">
                                  {profile.name}
                                </div>
                                <div className="extra content mt--1 lh-100">
                                  <span className="d-inline-block mb-05">
                                    <span className="d-inline-block align-middle text-gray mr-xs font-12">
                                      {profile.did}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}

                    {profileCards.length <= 3 ? (
                      <div className="item p-0">
                        <Link
                          to="/create-profile"
                          className="d-block text-pink font-14 m-auto text-center"
                        >
                          <i className="icon-14 i-pluse-circle d-inline-block align-middle mr-05 pos-relative top--1"></i>
                          Add New Profile
                        </Link>
                      </div>
                    ) : (
                      <Link
                        to="/"
                        className="text-blue text-center text-blue pt-1 pb-1 pr-2 pl-2 d-block border-top"
                      >
                        See more
                      </Link>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
            <NavLink exact className="item" to={`/${profileId}`}>
              <i className="icon-sm i-dashboard d-inline-block align-middle mr-1" />

              <FormattedMessage id="sidebar.dashboard" >{txt => txt }</FormattedMessage>
            </NavLink>

            <NavLink className="item" to={`/${profileId}/matches`}>
              <i className="icon-sm i-hearts d-inline-block align-middle mr-1" />
              {/* <span className="ui label badge-pill badge-gray m-0 font-weight-normal mt-1">
                {this.state.matchesCount ? this.state.matchesCount.matches : 0}
              </span> */}
              <FormattedMessage id="sidebar.dashboard.matches">{txt => txt }</FormattedMessage>
            </NavLink>
          {/*  <NavLink className="item" to={`/${profileId}/messages`}>
              <i className="icon-sm i-message d-inline-block align-middle mr-1" />
              <FormattedMessage id="sidebar.dashboard.messages" >{txt => txt }</FormattedMessage>
              <span className="ui mini label bg-pink pl-05 pr-05 d-inline-block radius-2 text-white ml-05 float-none">NEW</span>
              {this.props.unread > 0 && (
                <span className="ui label badge-pill badge-blue m-0 font-weight-normal mt-1">{this.props.unread}</span>
              )}
            </NavLink>
            <NavLink className="item" to={`/${profileId}/notifications`}>
              <i className="icon-sm i-bell d-inline-block align-middle mr-1" />
              <FormattedMessage id="sidebar.dashboard.notification" >{txt => txt }</FormattedMessage>{" "}
            </NavLink>*/}
            {single_user && (
                <a href={single_user && single_user.upgrade_link} target="_blank" rel="noopener noreferrer" className="item" >
                  <i className="icon-sm i-upgrade d-inline-block align-middle mr-1" />
                  <FormattedMessage id="sidebar.dashboard.upgrade_account" >{txt => txt }</FormattedMessage>
                </a>
            )}

            <div className="divider mt-1 mr-3 mb-1 ml-3" />
            <NavLink className="item" to={`/${profileId}/edit-profile`}>
              <i className="icon-sm i-edit d-inline-block align-middle mr-1" />
              <FormattedMessage id="sidebar.dashboard.edit_profile">{txt => txt }</FormattedMessage>
            </NavLink>
            {single_user && single_user.partner_preferences && (
              <NavLink className="item" to={`/${profileId}/partner-preferences`} ><i className="icon-sm i-users d-inline-block align-middle mr-1" />
              <FormattedMessage id="sidebar.dashboard.partner_preference">{txt => txt }</FormattedMessage>
            </NavLink>
            )
            }
            <NavLink className="item" to={`/${profileId}/photos`}>
              <i className="icon-sm i-photo d-inline-block align-middle mr-1" />
              <FormattedMessage id="sidebar.dashboard.manage_photos" >{txt => txt }</FormattedMessage>
            </NavLink>
            <NavLink className="item" to={`/${profileId}/settings`}>
              <i className="icon-sm i-settings d-inline-block align-middle mr-1" />
              <FormattedMessage id="sidebar.dashboard.settings" >{txt => txt }</FormattedMessage>
            </NavLink>
            <div className="divider mt-1 mr-3 mb-1 ml-3" />
            <Link
              to="/create-profile"
              className="item text-pink font-weight-bold font-15"
            >
              <i className="icon-md i-pluse-circle d-inline-block align-middle mr-1 pos-relative top--1" />
              <FormattedMessage id="sidebar.dashboard.add_profile" >{txt => txt }</FormattedMessage>
            </Link>
          </div>

          <div className="aside-sticky">
            {/* <div className="ui card border radius-4 custom-card d-block w-auto mb-1">
              <a href className="content p-1 d-block">
                <i className="icon-xl i-phone d-inline-block align-middle mr-1" />
                <div className="d-inline-block align-middle">
                  <div className="font-weight-semibold text-black font-15">
                    Call us
                  </div>
                  <div className="text-muted font-12">
                    {this.state.defaultNumber}
                  </div>
                </div>
              </a>
            </div> */}
            <div className="ui card border radius-4 custom-card d-block w-auto mb-1">
              <a
                href={`https://api.whatsapp.com/send?phone=918075324709&text=Hi%20Matrimony%2c%20I%20need%20your%20help${
                  this.state.profile !== null &&
                  this.state.profile !== undefined &&
                  this.state.profile.did !== ""
                    ? `,%20my%20id%20is%20${this.state.profile.did}`
                    : ""
                }`}
                target="_blank"
                rel="noopener noreferrer"
                className="content p-1 d-block"
              >
                <i className="icon-xl i-whatsapp d-inline-block align-middle mr-1" />
                <div className="d-inline-block align-middle">
                  <div className="font-weight-semibold text-black font-15">
                    <FormattedMessage id="sidebar.dashboard.whats_app" >{txt => txt }</FormattedMessage>
                  </div>
                  <div className="text-muted font-12">{urls.whatsAppNo}</div>
                </div>
              </a>
            </div>
            <div className="ui card border radius-4 custom-card d-block w-auto mb-3">
              <a
                href={urls.messangerLink}
                target="_blank"
                rel="noopener noreferrer"
                className="content p-1 d-block"
              >
                <i className="icon-xl i-messenger float-left d-inline-block align-middle mr-1 mt-05" />
                <div className="d-block align-middle text-truncate o-hidden">
                  <div className="font-weight-semibold text-black font-15">
                    <FormattedMessage id="sidebar.dashboard.messenger" >{txt => txt }</FormattedMessage>
                  </div>
                  <div className="text-muted font-12 text-truncate">
                    {urls.messanger}
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </aside>
    );
  }
}

const mapStateToProps = state => ({
  profileCards: state.user.profileCards,
  globalSpinner: state.globalSpinner.globalSpinner,
  single_user: state.user.singleProfileData,
  unread: state.user.unread
});

export default injectIntl(
  connect(
    mapStateToProps,
    {
      getProfiles,
      getCurrentCountry,
      setDefaultCountryNumber,
      setDefaultCountry,
      openModal
    }
  )(withRouter(Sidebar))
);


