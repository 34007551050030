import React, { Fragment, useState, useEffect } from "react";
import { chatAxios } from "../../../../../../util/axios";

import "lightgallery.js";
import "lightgallery.js/dist/css/lightgallery.min.css";
import { Button, Dimmer, Loader } from "semantic-ui-react";

export default function HoroscopeSingleView(props) {
  const { match_details,profileId } = props;
  const [spinner, set_spinner] = useState(false);
  const [images, set_images] = useState([]);

  useEffect(() => {
      const localImages = [];
      match_details.horoscope_txt.length > 0 && match_details.horoscope_images.length > 0 &&
      match_details.horoscope_images.forEach(img => {
        localImages.push({
          src: img,
          thumb: img
        });
      })
      set_images(localImages)
  }, [match_details.horoscope_images,match_details.horoscope_txt]);
  
  if(images.length){
    document
    .getElementById("horoscope_gallery")
    .addEventListener("click", function() {
      window.lightGallery(document.getElementById("horoscope_gallery"), {
        dynamic: true,
        dynamicEl: images
      });
    });
  }

  const requestHoroscope = async () => {
    set_spinner(true);
    try {
      await chatAxios.post(`/conversations`,{
        type: 'request',
        text: 'add-horoscope',
        token : match_details.chat_token
      })
      match_details.horoscope_add_state = 0
      set_spinner(false);
    } catch (error) {
      set_spinner(false);
    }
  };

  return (
    <Fragment>
      { match_details.horoscope_txt.length > 0 ? (
          <div className="ui fluid card wd-card view-card-group mt-0 mb-2">
            <div className="header">
              <div className="title text-black font-17 font-weight-semibold">
                <span>
                  <i className="i-planet-line" />
                </span>
                Horoscope
              </div>
              {match_details.horoscope_images.length > 0 && (
                <button
                  id="horoscope_gallery"
                  className="ui button btn-link text-blue font-weight-normal mr-0 ml-auto p-0"
                >
                  <i className="icon icon-md d-inline-block align-middle pos-relative top--3 i-planet-blue mr-1" />
                  View Chart
                  <i className="play icon ml-05 font-8 pos-relative top--1" />
                </button>
              )}
            </div>

            <div className="content">
            {match_details && match_details.horoscope_txt.map(item => {
                  return (
                  <div className="row mb-2">
                  <i className={`icon-md ${item.icon} d-inline-block float-left mr-2`} />
                  <div className="o-hidden">
                    <div className="font-14 text-black">
                      {item.text}
                    </div>
                  </div>
                </div>
              )}
             )}

            </div>
          </div>
        ):(
          <div className="ui fluid card horoscope-card bg-green-lt mt-0 mb-3">
            <Dimmer inverted active={spinner}>
               <Loader inverted >Please wait</Loader>
            </Dimmer>
            <div className="content text-center">
              <div className="horoscope-oval"><i className="i-planet-white" /></div>
              <div className="font-weight-semibold font-22 text-black lh-lg mb-2">Horoscope missing</div>
              <p className="font-14 text-gray">Horoscope details are missing. Request them to add their horoscope details</p>
              { match_details.horoscope_add_state === null ? (
                  <Button  onClick={requestHoroscope} className="btn-green font-weight-normal radius-2 ripple mr-0">Request horoscope to add</Button>
              ): (
                <span className="text-blue">Request sent</span>
              )}
            </div>
          </div>
        )}
    </Fragment>
  );
}
