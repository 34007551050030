import React, { useState, useEffect } from "react";
import { gpAxios } from "../../../../../util/axios";
import { connect } from "react-redux";
import { Popup } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { openModal } from "../../../../modals/redux-state/modalActions";

function AwaitingMatches(props) {
    const { title, titleLink, profileId, prefId, singleUser, SubTitle = false , SeeMore = false, apiEnd , Tip = false, upgradePopup = false} = props
    const [matches, setMatches] = useState([])

    useEffect(() => {
        gpAxios
        .get(`/profile/${profileId}/${apiEnd}`)
        .then(res => {
            setMatches(res.data.data);
        })
        .catch(error => {
            
        });

    }, [profileId,apiEnd]);


    return (
        <React.Fragment>
            { (matches && matches.length) ? (
                    <div className="ui fluid card wd-card wd-recommended-cards m-0 mb-2">
                    <div className="font-16 font-weight-bold p-2 pb-0">
                        <a href={titleLink || null} className="text-dark">
                        {title}
                        </a>{" "}
                        {Tip && (
                            <Popup
                            content={Tip}
                            trigger={<i class="stats-help">?</i>}
                          />
                        )}
                    </div>
                    { SubTitle && (
                        <SubTitle></SubTitle>
                    )}
                    <div className="content b-none">
                    <div className="ui four doubling cards flex-start">
            
                    {matches.map((i) => (
                        <React.Fragment>
                        {upgradePopup ? (
                            <div className="card b-none no-shadow">
                             <div
                                onClick={(e) =>{
                                    props.openModal(upgradePopup, { customClass: "modal-md", profileId : profileId, prefId: prefId})
                                }}
                                className="image mb-1 pointer">
                                    { i.photo && i.photo_privacy !== 0 && (
                                        <div className="request-card">
                                            <span><i className="i-lock-fill-gray"></i></span>
                                            <div className="title">Photo Protected</div>
                                        </div>
                                    )}
                             <img src={i.photo ? i.photo : i.gender ==='male' ? "//storage.googleapis.com/matrimony-assets/dashboard/img/default-boy.png" : "//storage.googleapis.com/matrimony-assets/dashboard/img/default-girl.png"} alt="" />
                             </div>
                             <div
                             onClick={(e) =>{
                                props.openModal(upgradePopup, { customClass: "modal-md", profileId : profileId, prefId: prefId})
                             }}
                             className="m-auto font-13 text-black text-center lh-md text-hover-pink pointer"
                             >
                             {i.name}
                             </div>
                         </div>
                        ) : i.photo_upload_warning ? (

                            <div className="card b-none no-shadow">
                             <div 
                                onClick={(e) =>{
                                    props.openModal("UploadPhotoWarning", { customClass: "modal-md", profileId : profileId, photo: singleUser.photo, prefId: prefId })
                                }}
                                className="image mb-1 pointer">
                                    { i.photo && i.photo_privacy !== 0 && (
                                        <div className="request-card">
                                            <span><i className="i-lock-fill-gray"></i></span>
                                            <div className="title">Photo Protected</div>
                                        </div>
                                    )}
                             <img src={i.photo ? i.photo : i.gender ==='male' ? "//storage.googleapis.com/matrimony-assets/dashboard/img/default-boy.png" : "//storage.googleapis.com/matrimony-assets/dashboard/img/default-girl.png"} alt="" />
                             </div>
                             <div
                             onClick={(e) =>{
                                props.openModal("UploadPhotoWarning", { customClass: "modal-md", profileId : profileId, photo: singleUser.photo, prefId: prefId})
                             }}
                             className="m-auto font-13 text-black text-center lh-md text-hover-pink pointer"
                             >
                             {i.name}
                             </div>
                         </div>
                        ) : (
                            <div className="card b-none no-shadow">
                             <Link to={`/${profileId}/matches/${i.id}`} className="image mb-1">
                             { i.photo && i.photo_privacy !== 0 && (
                                        <div className="request-card">
                                            <span><i className="i-lock-fill-gray"></i></span>
                                            <div className="title">Photo Protected</div>
                                        </div>
                                    )}
                             <img src={i.photo ? i.photo : i.gender ==='male' ? "//storage.googleapis.com/matrimony-assets/dashboard/img/default-boy.png" : "//storage.googleapis.com/matrimony-assets/dashboard/img/default-girl.png"} alt="" />
                             </Link>
                             <Link
                             to={`/${profileId}/matches/${i.id}`}
                             className="m-auto font-13 text-black text-center lh-md text-hover-pink"
                             >
                             {i.name}
                             </Link>
                         </div>
                        )}
                        </React.Fragment>
                    ))}
            
                    {Array( 4 - matches.length).fill(null).map((i) => (
                        <div className="card b-none no-shadow coming-soon-card">
                            <div className="image ">
                            New matches on the way
                            </div>
                        </div>
                    ))}
            
                    </div>
                    </div>
                    { SeeMore && (
                        <SeeMore></SeeMore>
                    )}
                    </div>
            ): ("")}
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {};
  };

export default connect(
    mapStateToProps,
    { openModal}
)(AwaitingMatches);
