import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { openModal } from "../../../../modals/redux-state/modalActions";
import { Link, NavLink } from "react-router-dom";
import { Button } from "semantic-ui-react";
function ProfileCardAdvanced(props) {
    const { profileId, singleUser } = props;
    return (
        <div className="ui fluid card wd-card m-0 mb-2 profile-card-big">
        <div className="content d-flex w-100">
        <div className="thumb-xxxl radius-4 pos-relative mr-1 mb-0">
        {props.croppedProfilePicUrl !== null ? (
        <React.Fragment>
                <img
                src={props.croppedProfilePicUrl}
                alt=""
                />
                {props.profilePicSpinner ? (
                <div className="middle-progress">
                    <div className="d-flex item-center content-center h-100 flex-column h-100">
                        <div className="ui active centered inline loader mb-05" />
                        <div className="font-11 text-white mb-05">Uploading…</div>
                    </div>
                </div>
                ):(
                    <div className="update-dp">
                        <label htmlFor="ProfilePhotoSmall">
                        <i className="icon-xs i-camera d-inline-block align-middle mr-05" />
                        Change Photo
                        <input
                            type="file"
                            id="ProfilePhotoSmall" 
                            size={60} 
                            accept="image/*"
                            onChange={e =>
                            props.openModal("ProfileImageCropModal", {
                                files: e.target.files,
                                profileId: profileId
                            })
                            }
                        />
                        </label>
                    </div>
                )}
        </React.Fragment>
        ):(singleUser && singleUser.photo) ? (
            <React.Fragment>
            <img
            src={singleUser.photo}
            alt=""
        />
        <div className="update-dp">
        <label htmlFor="ProfilePhotoSmall">
          <i className="icon-xs i-camera d-inline-block align-middle mr-05" />
          Change Photo
          <input
            type="file"
            id="ProfilePhotoSmall" 
            size={60} 
            accept="image/*"
            onChange={e =>
            props.openModal("ProfileImageCropModal", {
                files: e.target.files,
                profileId: profileId
            })
            }
          />
        </label>
      </div>
        </React.Fragment>
        ):(
            <React.Fragment>
            <img
             src={`//storage.googleapis.com/matrimony-assets/dashboard/img/${
                (singleUser && singleUser.gender === "male") ? "default-boy.png" : "default-girl.png"
              }`}
            alt=""
        />
        <div className="update-dp">
        <label htmlFor="ProfilePhotoSmall">
          <i className="icon-xs i-camera d-inline-block align-middle mr-05" />
          Upload Photo
          <input
            type="file"
            id="ProfilePhotoSmall" 
            size={60} 
            accept="image/*"
            onChange={e =>
            props.openModal("ProfileImageCropModal", {
                files: e.target.files,
                profileId: profileId
            })
            }
          />
        </label>
      </div>
        </React.Fragment>
        )}
    </div>
    <div className="profile-info">
      <div className="header font-20 mb-05">
        <a 
            href
            className="text-black font-weight-semibold">
          {singleUser && singleUser.name}
        </a>
      </div>
      <div className="meta font-13 text-gray mb-2">ID:  {singleUser && singleUser.did}</div>
      {singleUser && singleUser.package !== 'free' ? (
      <span class="ui tiny label custom-label label-premium label-rounded mr-1 d-inline-block align-middle">Premium</span>
      ):(<span class="ui tiny label custom-label label-basic label-rounded mr-05 d-inline-block align-middle mt-05">Free</span>)}
        {singleUser && [0,3].includes(singleUser.state) && (
          <span className="text-gray font-10 d-inline-block align-middle">
            <i className="dot dot-gray d-inline-block align-middle mr-05" />
            Not Completed
          </span>
        )}
         {singleUser && singleUser.state === 1 && (
          <span className="text-orange font-10 d-inline-block align-middle">
          <i className="dot dot-orange d-inline-block align-middle mr-05" />
          Activation pending
        </span>
          )}
           {singleUser && singleUser.state === 2 && (
             <span className="text-green font-10 d-inline-block align-middle">
             <i className="dot dot-green d-inline-block align-middle mr-05" />
             Active
           </span>
          )}

      <div className="d-inline-block w-100 mt-2">

        <button className="ui mini button btn-green right floated font-13 font-weight-normal ml-3 mt--2 hidden-xs ripple">Complete profile</button>
        <div className="o-hidden">
           <span className="right floated font-12 ml-2">{singleUser && singleUser.progress }% profile complete</span>
          <div className="o-hidden mt-1">
            <div className="ui tiny green progress wd-xs-progress mb-0 active" id="complete" data-percent={43}>
              <div className="bar" style={{transitionDuration: '300ms', width: `${singleUser && singleUser.progress}%`}} />
            </div>
          </div>
        </div>
        <button className="ui mini button btn-green font-13 mr-auto ml-auto mt-2 hidden-lg ripple">Complete profile</button>
      </div>
    </div>
  </div>
  <div className="profile-nav desk">
        <div className="ui menu">
          <a href='javascript:void(0)' className="active item">Personal Information</a>
          <NavLink className="item" to={`/${profileId}/partner-preferences`}>Partner Preferences</NavLink>
        </div>
      </div>
      <div className="profile-nav mobi">
        <div className="ui menu">
          <a href='javascript:void(0)' className="active item">Personal Information</a>
          <NavLink className="item" to={`/${profileId}/partner-preferences`} >Partner Preferences</NavLink>
        </div>
      </div>
</div>)
}

const mapStateToProps = state => {
    return {
      profilePicSpinner: state.user.profilePicSpinner,
      croppedProfilePicUrl: state.user.croppedProfilePicUrl
    };
};
export default connect(
    mapStateToProps,
    { openModal }
  )(ProfileCardAdvanced);
