import React, { Fragment } from "react";
import ActiveStatus from "./active-status";
import DeactivateStatus from "./deactivate_status";
import SuspendedStatus from "./suspended_status";
import BannedStatus from "./banned_status";
import PendingDeletionStatus from "./pending-deletion-status";

export const rootStatusCards = singleUser => {
  switch (singleUser.state) {
    case 5:
      return <DeactivateStatus singleUser={singleUser} />;
    case 6:
      return <SuspendedStatus singleUser={singleUser} />;
    case 7:
      return <BannedStatus singleUser={singleUser} />;
    case 9:
      return <PendingDeletionStatus />;
    default:
      return <Fragment />;
  }
};
