import React from "react"
import { connect } from "react-redux"
import { NavLink, withRouter } from "react-router-dom"
import {FormattedMessage,injectIntl} from "react-intl"
import {
    singleProfile,
    defaultSingleProfile
  } from "../../../../../redux/actions/user/user"

import { route_config } from "../../../../../util/GlobalContants"
import Wrapper from "../../../../../common/shared-components/wrapper/Wrapper"
import { updateLayout } from "../../../../../redux/layout_controll/layout_action"
import { getCommonData } from "../../../../../redux/actions/common/common-data"
import SubSideBar from "../components/subsidebar"
import SubFooter from "../components/subfooter"
class Settings extends React.Component {

    state = {
        profileId: this.props.match.params.profileId
    };

      
    componentDidMount() {
        this.props.defaultSingleProfile(`profile/${this.state.profileId}`);
        this.props.getCommonData();
    }
    render() {
    const profileId = this.props.match.params.profileId
    const singleUser= this.props.singleUser 
    return (
        <Wrapper
        config={route_config.draft_dashboard}
        isLoading={this.props.globalSpinner}
        singleUser={this.props.singleUser}
      >
          <div className="content-body">
                    <div className="content-wrapper">
                        <div className="wd-rail">
                            <div className="wd-container-box">
                            <SubSideBar
                            profileId={profileId}
                            />
                            <div className="box-content">
                                    <h1 className="page-title">Settings</h1>
                                    <div className="ui relaxed divided items mt-0">
                                    <div className="profile-mini-card d-flex w-100 mb-5">
                                    <div className="thumb-lg mr-2">
                                        {(singleUser && singleUser.photo) ? (
                                             <React.Fragment>
                                             <img
                                             src={singleUser.photo}
                                             alt=""
                                            />
                                         </React.Fragment>
                                        ):(
                                            <React.Fragment>
                                            <img
                                            src={`//storage.googleapis.com/matrimony-assets/dashboard/img/${ (singleUser && singleUser.gender === "male") ? "default-boy.png" : "default-girl.png"}`}
                                            alt=""
                                        />
                                        </React.Fragment>
                                        )}
                                    </div>
                                    <div>
                                        <div className="font-18 font-weight-bold lh-lg">{singleUser && singleUser.name}</div>
                                        <div className="font-13 text-gray">ID: {singleUser && singleUser.did}</div>
                                        {singleUser && [0,3].includes(singleUser.state) && (
                                        <span className="text-gray font-10 d-inline-block align-middle">
                                            <i className="dot dot-gray d-inline-block align-middle mr-05" />
                                            Not Completed
                                        </span>
                                        )}
                                        {singleUser && singleUser.state === 1 && (
                                        <span className="text-orange font-10 d-inline-block align-middle">
                                        <i className="dot dot-orange d-inline-block align-middle mr-05" />
                                        Activation pending
                                        </span>
                                        )}
                                        {singleUser && singleUser.state === 2 && (
                                            <span className="text-green font-10 d-inline-block align-middle">
                                            <i className="dot dot-green d-inline-block align-middle mr-05" />
                                            Active
                                        </span>
                                        )}
                                    </div>
                                    </div>
                                    <div className="ui fluid card wd-card profile-progress-card box-shadow mb-4">
                                    <div className="content p-3 d-flex item-center flex-column-xs">
                                        <div className="left">
                                        <div className="header font-16 text-black b-none p-0 bg-transparent mb-05">{singleUser && singleUser.progress }% profile completed</div>
                                        <div className="o-hidden">
                                            <div className="o-hidden mt-1">
                                            <div className="ui tiny green progress wd-xs-progress mb-0 active"  data-percent={singleUser && singleUser.progress}>
                                                <div className="bar" style={{transitionDuration: '300ms', width: `${singleUser && singleUser.progress}%`}} />
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                        <NavLink to={`/`} className="ui tiny button bg-white text-green border-green font-weight-normal radius-2 ripple mr-0">Complete your profile</NavLink>
                                    </div>
                                    </div>
                                    <div className="mb-5">
                                            <div className="ui relaxed divided list settings-links2 bg-white">
                                            <div className="item">
                                                <div className="left">
                                                    <span className="verify-icon"><i className="i-settings-line-blue" /></span>
                                                    <div className="pl-2">
                                                    <NavLink to={`/account`} className="font-16 text-dark font-weight-bold lh-md mb-05 d-inline-block">Account Settings</NavLink>
                                                    <div className="font-13 text-gray">

                                                    </div>
                                                    </div>
                                                </div>
                                                <div className="right">
                                                    <NavLink to={`/account`} className="ui button btn-link text-blue font-weight-normal p-0 mr-0">Edit</NavLink>
                                                </div>
                                            </div>

                                            <div className="item">
                                                <div className="left">
                                                    <span className="verify-icon"><i className="i-user-blue" /></span>
                                                    <div className="pl-2">
                                                    <NavLink to={`/${profileId}/edit-profile`} className="font-16 text-dark font-weight-bold lh-md mb-05 d-inline-block">Profile Settings</NavLink>
                                                    <div className="font-13 text-gray">

                                                    </div>
                                                    </div>
                                                </div>
                                                <div className="right">
                                                    <NavLink to={`/${profileId}/edit-profile`} className="ui button btn-link text-blue font-weight-normal p-0 mr-0">Edit</NavLink>
                                                </div>
                                            </div>

                                            <div className="item">
                                                <div className="left">
                                                    <span className="verify-icon"><i className="i-users-blue" /></span>
                                                    <div className="pl-2">
                                                    <NavLink to={`/${profileId}/partner-preferences`} className="font-16 text-dark font-weight-bold lh-md mb-05 d-inline-block">Partner Preferences</NavLink>
                                                    <div className="font-13 text-gray">

                                                    </div>
                                                    </div>
                                                </div>
                                                <div className="right">
                                                    <NavLink to={`/${profileId}/partner-preferences`} className="ui button btn-link text-blue font-weight-normal p-0 mr-0">Edit</NavLink>
                                                </div>
                                            </div>

                                            <div className="item">
                                                <div className="left">
                                                    <span className="verify-icon"><i className="i-filter-line-blue" /></span>
                                                    <div className="pl-2">
                                                    <NavLink to={`/${profileId}/filterout`} className="font-16 text-dark font-weight-bold lh-md mb-05 d-inline-block">Filterout</NavLink>
                                                    <div className="font-13 text-gray">

                                                    </div>
                                                    </div>
                                                </div>
                                                <div className="right">
                                                    <NavLink to={`/${profileId}/filterout`} className="ui button btn-link text-blue font-weight-normal p-0 mr-0">Edit</NavLink>
                                                </div>
                                            </div>

                                            <div className="item">
                                                <div className="left">
                                                    <span className="verify-icon"><i className="i-image-line-blue" /></span>
                                                    <div className="pl-2">
                                                    <NavLink to={`/${profileId}/photos`} className="font-16 text-dark font-weight-bold lh-md mb-05 d-inline-block">Manage Photos</NavLink>
                                                    <div className="font-13 text-gray">

                                                    </div>
                                                    </div>
                                                </div>
                                                <div className="right">
                                                    <NavLink to={`/${profileId}/photos`} className="ui button btn-link text-blue font-weight-normal p-0 mr-0">Edit</NavLink>
                                                </div>
                                            </div>



                                            </div>
                                    </div>
                                    <div className="mb-5">
                                    <SubFooter
                                        profileId={profileId}
                                    />
                                    </div>

                                    </div>
                            </div>
                            </div>
                        </div>
                    </div>
        </div>
      </Wrapper>
    )}
}

const mapStateToProps = state => ({
    singleUser: state.user.singleProfileData,
    commonData: state.commonData.allCommonData,
    profileNotFound: state.user.profileNotFound,
    globalSpinner: state.globalSpinner.globalSpinner,
    globalNumber: state.commonData.defaultCountryNumber
});
export default injectIntl( connect(mapStateToProps, {
    singleProfile,
    updateLayout,
    getCommonData,
    defaultSingleProfile
})(withRouter(Settings)));
