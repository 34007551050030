import React, { Fragment } from "react";
import { connect } from "react-redux";
import { closeModal } from "../redux-state/modalActions";
function CommonUpgrade(props) {
  const {  upgrade_link,closeModal } = props

  const view_plans = () => {
    window.open(upgrade_link, "_blank")
    closeModal()
  }
  return (
    <Fragment>
      <div className="upgrade-header">
        Don't delay further. Upgrade now to find the soul mate faster !!! 
      </div>
      <div className="content">
        <div className="font-14 text-black mb-2 font-weight-semibold">
          <i className="icon-sm i-crown-yellow d-inline-block align-middle mr-05 pos-relative top--2" />
          Benefits
        </div>
        <div className="ui list mb-4">
          <div className="item mb-05 lh-md">
            <i className="icon icon-sm i-check-green d-inline-block align-middle float-left mr-1 pos-relative top-1" />
            <div className="o-hidden">
              View mobile numbers to contact them directly !!!
            </div>
          </div>
          <div className="item mb-05 lh-md">
            <i className="icon icon-sm i-check-green d-inline-block align-middle float-left mr-1 pos-relative top-1" />
            <div className="o-hidden">
              Enhanced profile visibility &amp; privacy !!!
            </div>
          </div>
          <div className="item mb-05 lh-md">
            <i className="icon icon-sm i-check-green d-inline-block align-middle float-left mr-1 pos-relative top-1" />
            <div className="o-hidden">
              Make your profile stand out to your matches !!!
            </div>
          </div>
          <div className="item mb-05 lh-md">
            <i className="icon icon-sm i-check-green d-inline-block align-middle float-left mr-1 pos-relative top-1" />
            <div className="o-hidden">
              Paid tag for more responses &amp; get featured on TOP of search
              results !!!
            </div>
          </div>
        </div>
        <div className="upgrade-leads">
          <div className="right">
            <a
              onClick={view_plans}
              href
              className="ui button btn-green font-weight-normal mr-0"
            >
              View Plans
              <i className="play icon ml-05 font-8 pos-relative top--1"></i>
            </a>
            <button
              type="button"
              onClick={() => closeModal()}
              className="ui button btn-link font-weight-normal text-pink p-0 bg-white ml-2 mr-0"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default connect(
  undefined,
  { closeModal }
)(CommonUpgrade);
