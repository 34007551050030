import React from "react";
import { Link } from "react-router-dom";
import Img from "react-image";
import { Placeholder } from "semantic-ui-react";
import CompletedSteps from "../../../../../common/shared-components/CompletedSteps";

export default function NotCompletedProfileCard({ profile, commonData }) {
  return (
    <div className="ui fluid card wd-card m-0 mb-2">
      <div className="content">
        <div className="left floated ui tiny image thumb-lg radius-4 o-hidden mr-1 mb-0">
          <Link to={`/${profile.id}`}>
            <Img
              className="ui fluid image"
              src={
                profile && profile.photo
                  ? profile.photo
                  : profile.gender == "male"
                  ? "https://storage.googleapis.com/matrimony-assets/dashboard/img/d-boy.png"
                  : "https://storage.googleapis.com/matrimony-assets/dashboard/img/d-girl.png"
              }
              loader={
                <Placeholder style={{ height: 150, width: 150 }}>
                  <Placeholder.Image />
                </Placeholder>
              }
            />
          </Link>
        </div>
        <div className="o-hidden">
          <div className="header font-16 mb-05">
            <Link className="text-black font-weight-bold" to={`/${profile.id}`}>
              {profile.name}
            </Link>
          </div>
          <div className="meta font-12 text-gray mb-05">
            ID: {profile.did}
            <span class="activity">
              Last Activity: {profile.last_accessed_diff}
            </span>
          </div>
          <span
            className={`${
              profile.dstate == "INCOMPLETE" ? "text-gray" : "text-red"
            } font-10 d-inline-block align-middle text-uppercase`}
          >
            <i
              className={`dot ${
                profile.dstate == "INCOMPLETE" ? "dot-gray" : "dot-red"
              } d-inline-block align-middle pos-relative top--1 mr-05`}
            />
            {profile.dstate}
          </span>
        </div>
      </div>

      <div className="extra content">
        <div className="ui grid">
          <div className="eight wide column">
            <CompletedSteps
              csteps={profile.completed_steps}
              commonData={commonData}
            />
          </div>
          <div className="eight wide column pl-0">
            <Link
              to={`/${profile.id}`}
              className="right floated medium ui button btn-blue radius-2 ripple mt-05 font-weight-normal p-1 font-13"
            >
              Complete your profile
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
