import React from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import LanguageSwitcher from "../../common/shared-components/Language";
import { switchLanguage } from "../../redux/actions/language/language";

import {
  getCurrentCountry,
  setDefaultCountryNumber,
  setDefaultCountry
} from "../../redux/actions/common/common-data";
import {
  countryNumbers,
  HOMEPAGE,
  urls,
  ASSETS_CDN
} from "../../util/GlobalContants";

class MainResponsiveHeader extends React.Component {
  state = {
    defaultNumber: "+91 80753 24709"
  };
  componentDidMount() {
    if (localStorage.getItem("defaultCountryIp")) {
      this.props.setDefaultCountry(
        JSON.parse(localStorage.getItem("defaultCountryIp"))
      );
    } else {
      this.props.getCurrentCountry();
    }
  }
  setCountry = number => {
    this.setState({ defaultNumber: number });
    this.props.setDefaultCountryNumber(number);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.defaultCountry && nextProps.defaultCountry.data.iso) {
      const index = countryNumbers.findIndex(x => x.code === nextProps.defaultCountry.data.iso);
      if(index >= 0){
        this.setCountry(countryNumbers[index].number);
      }else{
        this.setCountry(countryNumbers[0].number);
      }
    }
  }

  render(){
   const  { active, closeResponsiveHeader, logoutUser, history, user, switchLanguage, lang} = this.props;
   return (
    <div
    className={active ? `wd-mobi-menu wd-mobi-menu-active` : `wd-mobi-menu`} >
      <button
        className="ui circular icon button wd-menu-close"
        onClick={closeResponsiveHeader}
      ><i className="i-close icon"/>
      </button>
      <div className="px-2 py-3">
      <div className="font-18 font-weight-bold mb-3">My Account</div>
      <div className="font-12 text-gray mb-05">Site Language</div>
      <LanguageSwitcher
          mobileView={true}
          switchLanguage={switchLanguage}
          current_lang={lang}
          closeResponsiveHeader={closeResponsiveHeader}
        />
      <div  className="ui fluid vertical text menu mb-3">
        <Link to={`/`} className="item font-18 text-dark font-weight-black">Manage My Profiles</Link>
        <Link to={`/myaccount`} onClick={closeResponsiveHeader} className="item font-18 text-dark font-weight-black">Account Setting</Link>
        <Link onClick={() => logoutUser(history)} className="item font-18 text-dark font-weight-black">Logout</Link>
      </div>
      <div className="ui divider"></div>
      <div className="ui fluid vertical text menu mt-5 mb-4">
    <div className="ui header font-17 font-weight-normal text-black mb-2">Help Center</div>
    <div className="ui grid d-block">
        <div className="eight wide column float-left p-1">
            <a href={`tel:${this.state.defaultNumber}`} className="bg-light p-1 text-center d-block">
                <i className="icon-lg i-phone d-block mr-auto ml-auto mb-1"></i>
                <div className="font-15 text-black">{this.state.defaultNumber}</div>
            </a>
        </div>

        <div className="eight wide column float-left p-1">
            <a 
            href={`https://api.whatsapp.com/send?phone=918075324709&text=Hi%20Matrimony%2c%20I%20need%20your%20help${
              this.state.profile !== null &&
              this.state.profile !== undefined &&
              this.state.profile.did !== ""
                ? `,%20my%20id%20is%20${this.state.profile.did}`
                : ""
            }`}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-light p-1 text-center d-block">
                <i className="icon-lg i-whatsapp d-block mr-auto ml-auto mb-1"></i>
                <div className="font-15 text-black">Whatsapp Chat</div>
            </a>
        </div>
        <div className="eight wide column float-left p-1">
            <a  href={urls.messangerLink}
              target="_blank"
              rel="noopener noreferrer" className="bg-light p-1 text-center d-block">
                <i className="icon-lg i-messenger d-block mr-auto ml-auto mb-1"></i>
                <div className="font-15 text-black">Messenger Chat</div>
            </a>
        </div>
        <div className="eight wide column float-left p-1">
            <a 
             href={`${HOMEPAGE}/contact-us`}
             target="_blank"
             rel="noopener noreferrer"
             className="bg-light p-1 text-center d-block">
                <i className="icon-lg i-support d-block mr-auto ml-auto mb-1"></i>
                <div className="font-15 text-black">Contact us</div>
            </a>
        </div>
    </div>
    </div>
    <div className="ui divider"></div>
      <div className="ui fluid vertical text menu mt-5">
            <div className="ui header font-17 font-weight-bold text-black mb-05">Company</div>
            <a href={`${HOMEPAGE}/about-us`} rel="noopener noreferrer" target="_blank" className="item font-14 font-weight-500">
            About us
            </a>
            <a href={`${HOMEPAGE}/terms-and-conditions`} rel="noopener noreferrer" target="_blank" className="item font-14 font-weight-500">
            Terms of use
            </a>
            <a href={`${HOMEPAGE}/privacy-policy`} rel="noopener noreferrer" target="_blank" className="item font-14 font-weight-500">
            Privacy Policy
            </a>
        </div>
      </div>
    </div>
  );
  }
}


const mapStateToProps = state => ({
  defaultCountry: state.commonData.defaultCountryData,
  isAuthenticated: state.user.isAuthenticated,
  lang: state.language
});

export default connect(
  mapStateToProps,
  { getCurrentCountry, setDefaultCountryNumber, setDefaultCountry, switchLanguage }
)(withRouter(MainResponsiveHeader));
