import {OPEN, CLOSE} from '../../actions/mobile/matches-more'
const INITIAL_STATE = {
  open: false
}

function handleChange(state, change) {
  return ({
    open: change
  })
}

export default function matchesMore(state = INITIAL_STATE, action) {
  switch(action.type) {
    case OPEN:
      return handleChange(state, true);
    case CLOSE:
      return handleChange(state, false);
    default:
      return state;
  }
}

