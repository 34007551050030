import React from "react";
import { connect } from "react-redux";
import { openModal } from "../modals/redux-state/modalActions";

function HowToLogin(props) {

    return (
        <React.Fragment>
            <a a href="https://sameepam.com/en/contact-us" rel="noopener noreferrer" target="_blank" className="ui fluid card demo-card-2 mb-3 d-none-xs">
                <div className="description">
                    <div className="left">
                        <span className="icon"><i 
                            className="i-help-blue"
                            onClick={(e) =>{
                                //props.openModal("HelpVideoModal", { customClass: "modal-md", video : "https://www.youtube.com/embed/5ciTzt0KHMQ"})
                            }}
                        ></i></span>
                        <div className="o-hidden text-black">
                            <div className="header">How to login</div>
                        </div>
                    </div>
                    <div className="demo-more">Learn more</div>
                </div>
            </a>

            <a href="https://youtu.be/Oy9YjMc9R14" rel="noopener noreferrer" target="_blank"  className="ui fluid card demo-card-2 mb-3 d-block-xs">
                <div className="description">
                    <div className="left">
                        <span className="icon">
                            <i 
                            className="i-help-blue"
                            onClick={(e) =>{
                                //props.openModal("HelpVideoModal", { customClass: "modal-md", video : "https://www.youtube.com/embed/5ciTzt0KHMQ"})
                            }}
                        ></i></span>
                        <div className="o-hidden text-black">
                            <div className="header">How to login</div>
                        </div>
                    </div>
                    <div className="demo-more">Learn more</div>
                </div>
            </a>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {};
  };

export default connect(
    mapStateToProps,
    { openModal}
)(HowToLogin);
