import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter, NavLink } from "react-router-dom"
import { gpAxios } from "../../../../../util/axios"
import Preference from "./preference"
import SubSideBar from "../components/subsidebar"
import SubFooter from "../components/subfooter"

import {
  singleProfile,
  defaultSingleProfile
} from "../../../../../redux/actions/user/user"

import { updateLayout } from "../../../../../redux/layout_controll/layout_action"
import {
  closeModal,
  openModal
} from "../../../../modals/redux-state/modalActions"

import { getCommonData } from "../../../../../redux/actions/common/common-data"
import {
  route_config,
  ASSETS_CDN
} from "../../../../../util/GlobalContants"

import Wrapper from "../../../../../common/shared-components/wrapper/Wrapper"
import {injectIntl} from "react-intl"
import { Dimmer,Loader  } from "semantic-ui-react"

class PartnerPreferences extends Component {
  
  state = {
    profileId: parseInt(this.props.match.params.profileId),
    loading: true,
    simplePreferences: [],
    preferencesWithCount: []
  }

  async componentDidMount() {
    this.props.defaultSingleProfile(`profile/${this.state.profileId}`)
    this.props.getCommonData()
    await this.getAllPreferences()
  }
  
  async getAllPreferences() {
      try {
          const {data} = await gpAxios.get(`profile/${this.props.match.params.profileId}/preferences`)
          this.setState({
            preferencesWithCount : data.data,
            loading:false
          })
      } catch (error) {
          this.setState({ loading:false})
      }
  }
  componentWillUnmount() {
    this.props.closeModal()
  }
  

  async UNSAFE_componentWillReceiveProps(nextProps) {
    
  }
  
  render() {
    const allCommonData = this.props.allCommonData
    const single_user = this.props.singleUser
    const profileId = this.props.match.params.profileId
    return (
      <Wrapper
        config={route_config.draft_dashboard}
        isLoading={this.props.globalSpinner}
        singleUser={this.props.singleUser}
      >
        <div className="content-body">
          <div className="content-wrapper">
              <div className="wd-rail">
                <div className="wd-container-box">
                <SubSideBar
                      profileId={profileId}
                />
                <div className="box-content">
                <div className="ui small breadcrumb setting-breadcrumb">
                    {/* <a href="" className="section text-blue">Settings</a>
                    <i className="right chevron icon divider"></i> */}
                    <div className="active section font-weight-normal text-gray">Partner Preference</div>
                </div>
                <h1 className="page-title mb-0">Partner Preference</h1>
                {single_user && (
                    <div className="font-14 text-gray mb-4">
                    You have used {single_user.partner_preferences.length} out of {single_user.package === 'free' ? 3 : 5} partner preference. Create more preference to get more matches. 
                    <NavLink to={`/${this.state.profileId}/partner-preferences/${single_user.partner_preferences[0].id}`} class="text-blue pointer">Create new preference</NavLink>
                    </div>
                )}
                { this.state.loading ? (
                      <div className="ui fluid card wd-card pp-item py-5 pb-5">
                        <div className="mb-5 mt-5">
                          <Dimmer active inverted><Loader inverted content='Loading' /></Dimmer>
                        </div>
                      </div>
                    ) : (
                      <div className="ui relaxed divided items mt-0">
                        {this.state.preferencesWithCount && this.state.preferencesWithCount.length > 0 && (
                          this.state.preferencesWithCount.map((p,i) => <Preference pref={p} i={i} profileId={this.state.profileId}/>)
                        )}
                        <div className="ui horizontal divider text-transform-none font-weight-normal text-black font-15 mt-5 mb-5">
                          Are you getting unwanted calls?
                          <NavLink to={`/${this.state.profileId}/filterout`} className="text-blue d-block-xs-only mt-xs-10">Filter out</NavLink>
                        </div>
                      </div>
                    )}
                    <div className="mb-5">
                                  <SubFooter
                                    profileId={profileId}
                                  />
                  </div>
                </div>
                
                </div>
                </div>
            </div>
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  profileCards: state.user.profileCards,
  singleUser: state.user.singleProfileData,
  profileNotFound: state.user.profileNotFound,
  globalSpinner: state.globalSpinner.globalSpinner,
  globalNumber: state.commonData.defaultCountryNumber,
  allCommonData: state.commonData.allCommonData
});

export default injectIntl(
    connect(mapStateToProps, {
    singleProfile,
    updateLayout,
    getCommonData,
    closeModal,
    openModal,
    defaultSingleProfile,
})(withRouter(PartnerPreferences)));
