import React, { Fragment } from "react";
import { connect } from "react-redux";
import { closeModal } from "../redux-state/modalActions";
function PendingCompleteModal(props) {
  const { profileId, closeModal } = props;

  return (
    <Fragment>
      <i className="close modal-close" onClick={() => closeModal()} >×</i>
      <div className="content modal-body">
        <div className="header-content text-center mt-3">
          <div className="font-15 font-weight-bold text-black mb-1">Opps!</div>
          <p className="m-auto mb-1">You have to complete your profile to see your matches</p>
        </div>
      </div>
    </Fragment>
  );
}

export default connect(
  undefined,
  { closeModal }
)(PendingCompleteModal);
