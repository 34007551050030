import React from "react";
import { profile_status_messages } from "../../../../../util/GlobalContants";

export default function BannedStatus(props) {
  return (
    <div className="ui pushable segment">
    <div className="pusher">
      <div className="full height"  >
        <div className="ui container">
          <div className="wd-middle-content container-xs mt-5">
            <div className="ui fluid card wd-card m-0 mb-2">
              <div className="content p-5 text-center">
                <div className="thumb-xxxl img-circle my-auto mb-2 mt-05">
                  <img
                    width="100%"
                    src={
                      props.singleUser && props.singleUser.photo
                        ? props.singleUser.photo
                        : `https://storage.googleapis.com/matrimony-assets/dashboard/img/${props.singleUser &&
                            props.singleUser.gender}.png`
                    }
                    alt=""
                  />
                </div>
                <div className="mb-4">
                  <div className="font-20 mb-05">
                    <a href className="text-black">
                      {props.singleUser.name}
                    </a>
                  </div>
                  <div className="font-13 text-gray">
                    {props.singleUser.did}
                  </div>
                </div>
                <div className="p-2 bg-dark-lt border-dark-lt text-left radius-2 d-flex item-center flex-column-xs">
                  <span className="mr-2 schedule-round">
                    <i className="i-ban-black" />
                  </span>
                  <div className>
                    <div className="font-16 text-black mb-05">
                      {profile_status_messages.banned.title}
                    </div>
                    <div className="font-14 text-gray2">
                      {profile_status_messages.banned.description}
                      <a
                        href="https://sameepam.com/en/terms-and-conditions"
                        className="text-blue"
                        target="_blank"
                      >
                        Terms of Use.
                      </a>
                      {profile_status_messages.banned.sub_description}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}
