import React, { Component } from "react";
import { connect } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
import { HOMEPAGE, ASSETS_CDN } from "../../util/GlobalContants";
import { logoutUser } from "../../redux/actions/user/user";
import { switchLanguage } from "../../redux/actions/language/language";
import LanguageSwitcher from "./Language";
import { FormattedMessage } from "react-intl";
class MyAccount extends Component {
  render() {
    const { switchLanguage } = this.props;
    return (
      <React.Fragment>
        {this.props.authenticated.isAuthenticated ? (
          <React.Fragment>
            <Dropdown
              className="my-account"
              icon={null}
              trigger={
                <div className="ui feed m-0">
                  <div className="event">
                    <div className="label">
                      <img src={`${ASSETS_CDN}/img/user-default.png`} />
                    </div>
                    <div className="content text-dark font-weight-bold">
                      <FormattedMessage id="header.dashboard.my_account">{txt => txt }</FormattedMessage>{" "}
                      <i className="dropdown icon" />
                    </div>
                  </div>
                </div>
              }
            >
              <Dropdown.Menu className="menu radius-0 transition">
                <LanguageSwitcher switchLanguage={switchLanguage} />
                <div className="divider" />
                <Link to="/" className="item font-13">
                  <FormattedMessage id="header.dashboard.my_account.manage_profiles">{txt => txt }</FormattedMessage>
                </Link>
                <Link to={`/myaccount`} className="item font-13">
                  <FormattedMessage id="header.dashboard.my_account.my_account_settings">{txt => txt }</FormattedMessage>
                </Link>
                <div className="divider" />

                <a
                  href="https://sameepam.com/en/contact-us"
                  target="_blank"
                  className="item font-13"
                >
                  <FormattedMessage id="header.dashboard.my_account.contact_us">{txt => txt }</FormattedMessage>
                </a>

                <Dropdown.Item
                  className="item font-13"
                  onClick={() => this.props.logoutUser(this.props.history)}
                >
                  <FormattedMessage id="header.dashboard.my_account.logout">{txt => txt }</FormattedMessage>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <a href="/" className="item font-weight-bold">
              Login
            </a>
            <a href={`${HOMEPAGE}/register`} className="item font-weight-bold">
              Register
            </a>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  authenticated: state.user
});

export default connect(
  mapStateToProps,
  { logoutUser, switchLanguage }
)(withRouter(MyAccount));
