import React from "react";
import { Redirect, Route } from "react-router";
import { connect } from "react-redux";

const RestrictedRoute = props => {
  const { component: Component, authUser, ...rest } = props;

  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

const mapStateToProps = state => ({
  mainHeader: state.layout.showMainHeader,
  singleUser: state.user.singleProfileData,
  profileNotFound: state.user.profileNotFound
});

export default connect(mapStateToProps)(RestrictedRoute);
