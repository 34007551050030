import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink,Link, withRouter } from "react-router-dom";

import { logoutUser } from "../../redux/actions/user/user";

import { ASSETS_CDN, HOMEPAGE, urls } from "../../util/GlobalContants";
import HelpCenter from "../../common/shared-components/HelpCenter";
import MyAccount from "../../common/shared-components/MyAccount";
import MainResponsiveHeader from "./main-responsive";
import HeaderLeftMenu from "./header-left-menu";
import HelpCenterMobilePopup from "./HelpCenterMobilePopup";
import LanguageSwitcherMini from "../../common/shared-components/Language/mini";
import { switchLanguage } from "../../redux/actions/language/language";

class Header extends Component {
  state = {
    wwClass: "sticky-header header-2",
    p0: "p-0",
    helpCenterDropdown: false,
    responsiveHeader: false,
    menuActive: false,
    helpCenterActive: false,
    profileId: null
  };

  componentDidMount() {
    this.setState({
      profileId: parseInt(this.props.location.pathname.split("/")[1])
    });
  }

  helpCenter = () => {
    this.setState({ helpCenterDropdown: !this.state.helpCenterDropdown });
  };

  hanldeClickOutside() {
    this.setState({ helpCenterDropdown: false });
  }

  setCountry = number => {
    this.setState({ defaultNumber: number });
    this.props.setDefaultCountryNumber(number);
  };

  toggleHeader = () => {
    this.setState({ responsiveHeader: !this.state.responsiveHeader });
  };

  closeResponsiveHeader = () => {
    this.setState({ responsiveHeader: false });
  };

  toggleMenu = () => {
    this.setState({ menuActive: !this.state.menuActive });
  };

  closetoggleMenu = () => {
    this.setState({ menuActive: false });
  };

  toggleHelpCenterPopup = () => {
    this.setState({ helpCenterActive: !this.state.helpCenterActive });
  };

  closeHelpCenterPopup = () => {
    this.setState({ helpCenterActive: false });
  };

  render() {
    const { profileCards, switchLanguage } = this.props;
    return (
      <React.Fragment>

        <MainResponsiveHeader
          active={this.state.responsiveHeader}
          closeResponsiveHeader={this.closeResponsiveHeader}
          profileCards={profileCards}
          logoutUser={this.props.logoutUser}
          history={this.props.history}
          profileId={null}
          user={this.props.user}
        />

        <HeaderLeftMenu
          menuActive={this.state.menuActive}
          closetoggleMenu={this.closetoggleMenu}
        />
        <HelpCenterMobilePopup
          helpCenterActive={this.state.helpCenterActive}
          closeHelpCenterPopup={this.closeHelpCenterPopup}
          profileId={this.state.profileId}
        />
        <div
          className={`ui fixed borderless menu m-auto ${this.state.wwClass}`}
        >
          <div className="ui container grid">
            <div className={`computer only row  ${this.state.p0}`}>
              {this.props.authenticated.isAuthenticated ? (
                <NavLink to="/" className="header item" activeClassName={null}>
                  <img
                    className="wd-brand"
                    src={`${ASSETS_CDN}/img/brand.png`}
                    alt="sameepam-logo"
                  />
                </NavLink>
              ) : (
                <a href={HOMEPAGE} className="header item">
                  <img
                    className="wd-brand"
                    src={`${ASSETS_CDN}/img/brand.png`}
                    alt="sameepam-logo"
                  />
                </a>
              )}

              <div className="right menu item-center" ref={node => (this.node = node)}>
                <HelpCenter profileId={this.state.profileId} />
                <span className="vr-divider" />
                <MyAccount profileId={null} />
              </div>
            </div>

            <div className="tablet mobile only row p-0">
              <div className="column">
                <div className="ui menu b-none no-shadow">

                {this.props.authenticated.isAuthenticated ? (
                  <React.Fragment>
                  <NavLink to={`/${this.state.profileId||''}`} className="wd-brand desktop">
                      <img
                        src={`${ASSETS_CDN}/img/brand2.png`}
                        alt="sameepam-logo"
                      />
                  </NavLink>
                  <NavLink to={`/${this.state.profileId||''}`} className="wd-brand mobi">
                      <img
                        src={`${ASSETS_CDN}/img/logo-small.png`}
                        alt="sameepam-logo"
                      />
                  </NavLink> </React.Fragment>) : (<React.Fragment>
                     <a href={`${HOMEPAGE}`} target="_blank" rel="noopener noreferrer" className="wd-brand desktop">
                     <img
                       src={`${ASSETS_CDN}/img/brand2.png`}
                       alt="sameepam-logo"
                     />
                 </a>
                 <a href={`${HOMEPAGE}`} target="_blank" rel="noopener noreferrer"  className="wd-brand mobi">
                     <img
                       src={`${ASSETS_CDN}/img/logo-small.png`}
                       alt="sameepam-logo"
                     />
                 </a>
                  </React.Fragment>)}
                  <LanguageSwitcherMini
                    mobileView={true}
                    switchLanguage={switchLanguage}
                    current_lang={this.props.lang}
                  />
                  <div className="right menu item-center">
                      <div
                        className="icon-xl mr-2"
                        onClick={this.toggleHelpCenterPopup}
                      >
                      <i className="icon-xl i-phone icon mr-0" />
                    </div>
                    {this.props.authenticated.isAuthenticated ? (
                      <div className="ui basic buttons wd-menu-button"
                      onClick={this.toggleHeader}>
                      <button className="ui icon button">
                        <span className="m-line" />
                        <span className="m-line" />
                        <span className="m-line" />
                      </button>
                    </div>
                    ) : (
                      <div className="ui basic buttons wd-menu-button"
                        onClick={this.toggleMenu}>
                        <button className="ui icon button">
                          <span className="m-line" />
                          <span className="m-line" />
                          <span className="m-line" />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  authenticated: state.user,
  profileCards: state.user.profileCards,
  lang: state.lang
});

export default connect(
  mapStateToProps,
  { logoutUser, switchLanguage }
)(withRouter(Header));
