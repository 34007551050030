import { GET_ERRORS, MAX_REACH_ERROR, MAX_REACH_ERROR_UPDATE } from "./types";

const initState = {
  formsError: null,
  maxReachLimit: null
};

export default function(state = initState, action) {
  switch (action.type) {
    case GET_ERRORS:
      return {
        ...state,
        formsError: action.payload
      };
    case MAX_REACH_ERROR:
      return {
        ...state,
        maxReachLimit: action.payload
      };

    default:
      return state;
  }
}
