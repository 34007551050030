import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { openModal } from "../../../../modals/redux-state/modalActions";
import { Link } from "react-router-dom";
function ProfileCard(props) {
    const { profileId, singleUser } = props;
    return (
        <div className="ui fluid card wd-card m-0 mb-2 profile-card-small">
        <div className="content d-flex w-100">
        <div className="thumb-xxl radius-4 pos-relative mr-1 mb-0">
        {props.croppedProfilePicUrl !== null ? (
        <React.Fragment>
                <img
                src={props.croppedProfilePicUrl}
                alt=""
                />
                {props.profilePicSpinner ? (
                <div className="middle-progress">
                    <div className="d-flex item-center content-center h-100 flex-column h-100">
                        <div className="ui active centered inline loader mb-05" />
                        <div className="font-11 text-white mb-05">Uploading…</div>
                    </div>
                </div>
                ):(
                    <div className="update-dp d-block">
                        <label htmlFor="ProfilePhotoSmall">
                        <i className="icon-xs i-camera d-inline-block align-middle mr-05" />
                        Change Photo
                        <input
                            type="file"
                            id="ProfilePhotoSmall" 
                            size={60} 
                            accept="image/*"
                            onChange={e =>
                            props.openModal("ProfileImageCropModal", {
                                files: e.target.files,
                                profileId: profileId
                            })
                            }
                        />
                        </label>
                    </div>
                )}
        </React.Fragment>
        ):(singleUser && singleUser.photo) ? (
            <React.Fragment>
            <img
            src={singleUser.photo}
            alt=""
        />
        <div className="update-dp d-block">
        <label htmlFor="ProfilePhotoSmall">
          <i className="icon-xs i-camera d-inline-block align-middle mr-05" />
          Change Photo
          <input
            type="file"
            id="ProfilePhotoSmall" 
            size={60} 
            accept="image/*"
            onChange={e =>
            props.openModal("ProfileImageCropModal", {
                files: e.target.files,
                profileId: profileId
            })
            }
          />
        </label>
      </div>
        </React.Fragment>
        ):(
            <React.Fragment>
            <img
             src={`//storage.googleapis.com/matrimony-assets/dashboard/img/${
                (singleUser&& singleUser.gender === "male") ? "default-boy.png" : "default-girl.png"
              }`}
            alt=""
        />
        <div className="update-dp d-block">
        <label htmlFor="ProfilePhotoSmall">
          <i className="icon-xs i-camera d-inline-block align-middle mr-05" />
          Upload Photo
          <input
            type="file"
            id="ProfilePhotoSmall" 
            size={60} 
            accept="image/*"
            onChange={e =>
            props.openModal("ProfileImageCropModal", {
                files: e.target.files,
                profileId: profileId
            })
            }
          />
        </label>
      </div>
        </React.Fragment>
        )}
    </div>
    
    <div className="profile-info">
      <div className="header font-20 mb-05">
        <a 
            href
            className="text-black font-weight-semibold">
          {singleUser && singleUser.name}
        </a>
      </div>
      <div className="meta font-12 text-gray mb-2">ID:  {singleUser && singleUser.did}</div>
      {singleUser && singleUser.upgrade_link && (
            <a
            href={singleUser.upgrade_link}
            target="_blank"
            rel="noopener noreferrer"
            className="right floated tiny ui button btn-blue font-weight-normal radius-2 ripple ml-1 hidden-xs"
          >
            Upgrade
          </a>
        )}
      <Link className="right floated tiny ui basic button radius-2 ripple ml-1 hidden-xs" to={`/${profileId}/edit-profile`}>
                         Edit Profile
      </Link>
      <div className="o-hidden">
      {singleUser && singleUser.package !== 'free' ? (
      <span class="ui tiny label custom-label label-premium label-rounded mr-1 d-inline-block align-middle">Premium</span>
      ):(<span class="ui tiny label custom-label label-basic label-rounded mr-05 d-inline-block align-middle mt-05">Free</span>)}
        {singleUser && singleUser.state === 0 && (
          <span className="text-gray font-10 d-inline-block align-middle mt-05">
            <i className="dot dot-gray d-inline-block align-middle mr-05" />
            Not Completed
          </span>
        )}
         {singleUser && singleUser.state === 1 && (
          <span class="text-orange font-10 d-inline-block align-middle mt-05"><i class="dot dot-orange d-inline-block align-middle mr-05"></i>Activation Pending</span>
          )}
           {singleUser && singleUser.state === 2 && (
          <span class="text-green font-10 d-inline-block align-middle mt-05"><i class="dot dot-green d-inline-block align-middle mr-05"></i>Active</span>
          )}
        
      </div>
      <div className="hidden-lg mt-2">
        {singleUser && singleUser.upgrade_link && (
            <a
            href={singleUser.upgrade_link}
            target="_blank"
            rel="noopener noreferrer"
            className="tiny ui button btn-blue font-weight-normal radius-2 ripple ml-1"
          >
            Upgrade
          </a>
        )}
        <Link className="tiny ui basic button radius-2 ripple ml-1" to={`/${profileId}/edit-profile`}>
                         Edit Profile
        </Link>
      </div>
    </div>
  </div>
</div>)
}

const mapStateToProps = state => {
    return {
      profilePicSpinner: state.user.profilePicSpinner,
      croppedProfilePicUrl: state.user.croppedProfilePicUrl
    };
};
export default connect(
    mapStateToProps,
    { openModal }
  )(ProfileCard);
