import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";

/**
 * @importActions
 */
import { getBasicProfileDataServer } from "../../../../../redux/actions/user/user";
import { getCommonData } from "../../../../../redux/actions/common/common-data";
import {
  saveStepTwo
} from "../../../../../redux/actions/step-basic-profile-data/basic-profile";

/**
 * @importComponents
 */
import BasicInfoSteps from "./childrens/basic-info-steps";
import EducationsCareerSteps from "./childrens/educations-career-steps";
import LanguagesLocationsSteps from "./childrens/languages-locations-steps";
import NumberOfMatches from "./childrens/number-of-matches";
import Steps from "../../../../../common/shared-components/StepsFollow";
import { checkIfProfileIdNotEmpty } from "../../../../../common/shared-components/CheckProfileId";
import Wrapper from "../../../../../common/shared-components/wrapper/Wrapper";
import { route_config } from "../../../../../util/GlobalContants";

class StepTwo extends Component {
  /**
   * @initalState
   */
  state = {
    databind : false,
    religion: null,
    caste: null,
    height: null,
    skin_tone: null,
    body_type: null,
    disability: null,
    diet: null,
    children_state: null,
    children : null,
    birth_country: null,
    living_country: null,
    living_location: null,
    nationality: null,
    residence_status: null,
    primary_lang: 1604,
    other_langs: [],
    education_level: null,
    education_field: [],
    employment_type: null,
    occupation: null,
    native_district: null,

  };

  componentDidMount() {
    checkIfProfileIdNotEmpty(
      this.props.history,
      this.props.match.params.profileId
    );
    this.props.getBasicProfileDataServer(
      this.props.match.params.profileId,
      this.props.history
    );

    this.props.getCommonData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const stateObj = this.state;
    if(!stateObj.databind){
      if(nextProps.data.basicProfileData && nextProps.data.basicProfileData.data){
        this.setState({
          databind : true
        })
      }
      for(var item in stateObj){
        if(item !== 'databind'){
          this.setState({
            [item] : (nextProps.data.basicProfileData && nextProps.data.basicProfileData.data && nextProps.data.basicProfileData.data[item] !== null ) ? nextProps.data.basicProfileData.data[item] : stateObj[item] 
          })
        }
      }
    }
  }

  // saveStepTwo
  handleFormSubmission = e => {
    e.preventDefault();
    this.props.saveStepTwo(
      this.props.match.params.profileId,
      this.props.history,
      this.state,
    );
  };

  render() {
    const { data } = this.props.data.basicProfileData;
    const { allCommonData } = this.props;

    return (
      <Wrapper
        config={route_config.basic_information}
        isLoading={this.props.spinner}
        singleUser={this.props.singleUser}
        whyRegister={true}
      >
          <div className="pusher">
            <div className="full height"  >
              <Steps active={2} />
              {/* / Steps End */}
              <div className="ui container">
                <div className="page-form">
                  <div class="text-center mb-4">
                    <div class="text-green3 font-24 font-weight-bold mb-1">
                      Great news!
                    </div>
                      <p class="m-auto mw-80">
                        We found some matches to {data && data.name}'s ({data && data.did}) profile. To see the matches please complete the profile.
                      </p>
                  </div>
                  <div className="wd-form-title">
                  Now let's build {data && data.name}'s Profile
                  </div>
                  {/* Inline Card 1 */}
                  <form method="post" onSubmit={this.handleFormSubmission}>
                    <div className="ui fluid card mb-4">
                      {this.props.globalSpinner && (
                        <div className="ui active transition visible inverted dimmer">
                          <div className="content">
                            <div className="ui large text loader">Loading</div>
                          </div>
                        </div>
                      )}
                      {this.props.spinner && (
                        <div className="ui active transition visible inverted dimmer">
                          <div className="content">
                            <div className="ui large text loader">Loading</div>
                          </div>
                        </div>
                      )}

                      <div className="content bt-0">
                        <div className="wd-form-divider pt-0">
                          <div className="ui header">
                            <span>Basic Information</span>
                          </div>
                        </div>
                        <div className="ui form">
                          <BasicInfoSteps
                            spinner={this.props.data.basicProfileDataSpinner}
                            setParentState={(param,value) =>
                              this.setState({ [param] : value })
                            }
                            data={this.props.data.basicProfileData}
                            errors={this.props.errors && this.props.errors}
                            state={this.state}
                            allCommonData={allCommonData}
                          />
                          <LanguagesLocationsSteps
                            commonData={this.props.commonData}
                            setParentState={(param,value) =>
                              this.setState({ [param] : value })
                            }
                            errors={this.props.errors && this.props.errors}
                            state={this.state}
                            data={this.props.data.basicProfileData}
                            allCommonData={allCommonData}
                          />
                          <EducationsCareerSteps
                            commonData={this.props.commonData}
                            data={this.props.data.basicProfileData}
                            setParentState={(param,value) =>
                              this.setState({ [param] : value })
                            }
                            errors={this.props.errors && this.props.errors}
                            state={this.state}
                            allCommonData={allCommonData}
                          />
                          <div className="inline fields">
                            <label className="w-50 lh-md" />
                            <div className="w-100">
                              <div className="two wide fields m-0 w-100 btn-group">
                                <div className="field mb-0">
                                <Button className="fluid ui button btn-green ripple">
                                    Continue
                                </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>

                  {/* Inline Card 1 End */}
                </div>
              </div>
            </div>
          </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    data: state.user,
    commonData: state.commonData,
    spinner: state.commonData.commonDataSpinner,
    globalSpinner: state.globalSpinner.globalSpinner,
    errors: state.error.formsError,
    allCommonData: state.commonData.allCommonData
  };
};

export default connect(
  mapStateToProps,
  {
    getBasicProfileDataServer,
    getCommonData,
    saveStepTwo
  }
)(StepTwo);
