import { USER } from '../../constants/ActionTypes';
import { gpAxios ,chatAxios } from '../../../util/axios';
import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';

import { toastr } from 'react-redux-toastr';
import { globalSpinner } from '../../../common/commonActions/commonSpinner';
import { setError } from '../otp-verify/verify';

import { maxReachLimit } from '../../../common/commonActions/errorAction';
import { checkLoginData } from '../check-login/check-login';
import { updateLayout } from '../../layout_controll/layout_action';
import { closeModal } from '../../../components/modals/redux-state/modalActions';
import { switchLang } from '../language/language';
import { getMatchDetails, temporarilyUnavailable } from '../matches/matches';

// Set logged in user
export const setCurrentUser = decoded => ({
  type: USER.SET_CURRENT_USER,
  payload: decoded
});


// Set logged in user
export const setToken = token => ({
  type: USER.SET_AUTH_TOKEN,
  payload: token
});

/**
 * @setting basic profile data
 * @param {*} data
 */
export const setBasicProfileData = data => ({
  type: USER.PROFILE_BASIC_DATA,
  payload: data
});

/**
 * @setBasicProfileDataSpinner: true or false
 * @param {*} value
 */
export const setBasicProfileDataSpinner = value => ({
  type: USER.PROFILE_BASIC_DATA_SPINNER,
  payload: value
});

/**
 * @phoneNumberValid: true or false
 * @param {*} value
 */
export const phoneNumberValid = value => ({
  type: USER.PHONE_NO_VALID,
  payload: value
});

/**
 * @getBasicProfileDataServer: getting data from server
 * @param {*} profileId
 */
export const getBasicProfileDataServer = (
  profileId,
  history,
  fromCheckDob = false
) => dispatch => {
  dispatch(setBasicProfileData({}));
  dispatch(setBasicProfileDataSpinner(true));
  gpAxios
    .get(`/profile/${profileId}`)
    .then(response => {
      dispatch(setBasicProfileDataSpinner(false));
      dispatch(setBasicProfileData(response.data));

      // if (response.data.data.state !== 0 && response.data.data.state !== 1) {
      //   history.push(`/${profileId}/edit-profile`);
      // }

      if (response.data.data.dob === null) {
        history.push(`/${profileId}/basic-profile`);
      }

      if (fromCheckDob === true) {
        if (response.data.data.dob !== null) {
          history.push(`/${profileId}/step-2`);
        }
      }
    })
    .catch(error => {
      dispatch(setBasicProfileDataSpinner(false));
      if (error.response && error.response.data !== undefined) {
        if (!error.response.data.result) {
          history.push('/pageNotFound');
        }
      }
    });
};

/**
 * @authentication: lets login
 * @param {*} data
 */
export const authentication = (data, history) => dispatch => {
  dispatch(globalSpinner(true));
  localStorage.removeItem('wedmToken');
  gpAxios
    .post(`/auth/login`, data)
    .then(response => {
      dispatch(globalSpinner(false));
      if (JSON.parse(localStorage.getItem('rememberMe')) === false) {
        localStorage.removeItem('mobile');
      }

      localStorage.setItem('wedmToken', JSON.stringify(response.data));
      Cookies.set('wedmToken',JSON.stringify(response.data),{sameSite: 'lax', domain: 'sameepam.com'});
      const decode = jwtDecode(response.data.token.access_token);
      dispatch(
        switchLang({
          languageId: 'tamil',
          locale: decode.data.site_lang,
          name: 'தமிழ்',
          icon: 'ta'
        })
      );

      dispatch(setCurrentUser(decode));
      dispatch(setToken(response.data.token.access_token));

      localStorage.setItem('currentLang', decode.data.site_lang);

      gpAxios.defaults.headers.common.Authorization =
        'Bearer ' + response.data.token.access_token;
      localStorage.removeItem('check_login');
      localStorage.removeItem('timer');
      localStorage.removeItem('alternativeMethodsShowOnReload');
      localStorage.removeItem('max_attempt');
      history.push('/');
    })
    .catch(err => {
      dispatch(globalSpinner(false));
      if (
        err.response &&
        err.response.data !== undefined &&
        err.response.data.error.hasOwnProperty('password')
      ) {
        dispatch(setError(err.response.data.error.password[0]));
      } else {
        if (
          err.response &&
          err.response.data !== undefined &&
          err.response.data.error
        ) {
          dispatch(setError(err.response.data.error));
        }
      }
    });
};

/**
 * @authentication: check phonenumebr validate
 * @param {*} data
 */
export const checkMobileNumber = (data, history, redirection) => dispatch => {
  localStorage.removeItem('max_attempt');
  localStorage.setItem('max_attempt', false);
  dispatch(globalSpinner(true));
  dispatch(maxReachLimit(null));
  gpAxios
    .post(`/auth/check-login`, data)
    .then(response => {
      localStorage.removeItem('mobile');
      localStorage.setItem('mobile', data.mobile);
      dispatch(globalSpinner(false));
      dispatch(phoneNumberValid(true));
      dispatch(setError(null));
      dispatch(checkLoginData(response.data));
      if (response.data.data.pass === true) {
        localStorage.setItem('sendOtp', false);
      }
      localStorage.setItem('check_login', JSON.stringify(response.data));

      if (redirection) {
        history.replace('/signin/password');
      }
    })
    .catch(err => {
      dispatch(globalSpinner(false));
      dispatch(phoneNumberValid(false));
      localStorage.setItem(
        'check_login',
        JSON.stringify({ result: true, data: { valid: true, pass: false } })
      );

      if (
        err.response &&
        err.response.data !== undefined &&
        err.response.data.hasOwnProperty('type')
      ) {
        dispatch(maxReachLimit(err.response.data.type));
        localStorage.setItem('max_attempt', true);
      }

      if (
        err.response &&
        err.response.data !== undefined &&
        err.response.data.error.hasOwnProperty('mobile')
      ) {
        dispatch(setError(err.response.data.error.mobile[0]));
      } else {
        if (
          err.response &&
          err.response.data !== undefined &&
          err.response.data.error
        ) {
          dispatch(setError(err.response.data.error));
        }
      }
    });
};

/**
 * @startTimer: true or false
 * @param {*} value
 */
export const startTimer = value => ({
  type: USER.START_TIMER,
  payload: value
});

/**
 * @sendOtp: send-otp
 * @param {*} data
 */
export const sendOtp = data => dispatch => {
  dispatch(globalSpinner(true));
  gpAxios
    .post(`/auth/send-text-otp`, data)
    .then(response => {
      dispatch(globalSpinner(false));
      dispatch(startTimer(true));
    })
    .catch(err => {
      dispatch(globalSpinner(false));

      if (
        err.response &&
        err.response.data !== undefined &&
        err.response.data.hasOwnProperty('type')
      ) {
        // dispatch(startTimer(true));
        dispatch(maxReachLimit(err.response.data.type));
      }

      if (
        err.response &&
        err.response.data !== undefined &&
        err.response.data.error.hasOwnProperty('mobile')
      ) {
        dispatch(setError(err.response.data.error.mobile[0]));
      } else {
        if (
          err.response &&
          err.response.data !== undefined &&
          err.response.data.error
        ) {
          dispatch(setError(err.response.data.error));
        }
      }
    });
};

/**
 * @sendVoiceOtp: send-voic-otp
 * @param {*} data
 */
export const sendVoiceOtp = data => dispatch => {
  dispatch(globalSpinner(true));
  gpAxios
    .post(`/auth/send-voice-otp`, data)
    .then(response => {
      dispatch(globalSpinner(false));
      dispatch(startTimer(true));
    })
    .catch(err => {
      dispatch(globalSpinner(false));

      if (
        err.response &&
        err.response.data !== undefined &&
        err.response.data.hasOwnProperty('type')
      ) {
        // dispatch(startTimer(true));
        dispatch(maxReachLimit(err.response.data.type));
      }

      if (
        err.response &&
        err.response.data !== undefined &&
        err.response.data.error.hasOwnProperty('mobile')
      ) {
        dispatch(setError(err.response.data.error.mobile[0]));
      } else {
        if (
          err.response &&
          err.response.data !== undefined &&
          err.response.data.error
        ) {
          dispatch(setError(err.response.data.error));
        }
      }
    });
};

/**
 *
 * @param {*} data
 */
export const setProfileCards = data => ({
  type: USER.PROFILE_CARDS,
  payload: data
});

/**
 * @getProfiles: your profiles
 * @param {*}
 */
export const getProfiles = () => dispatch => {
  dispatch(globalSpinner(true));
  gpAxios
    .get('/profile')
    .then(response => {
      dispatch(globalSpinner(false));
      dispatch(setProfileCards(response.data.data));
      // dispatch(getBasicProfileDataServer(response.data.data[1].id));
    })
    .catch(error => {
      dispatch(globalSpinner(false));
      // console.log(error);
    });
};

export const profilePicSpinner = isSpin => {
  return {
    type: USER.PROFILE_PIC_SPINNER,
    payload: isSpin
  };
};

export const croppedProfilePicUrl = url => {
  return {
    type: USER.CROPPED_PROFILE_PIC_URL,
    payload: url
  };
};

/**
 * @updateProfilePic: your profiles
 * @param {*}
 */
export const updateProfilePic = (profileId, data) => dispatch => {
  dispatch(profilePicSpinner(true));
  gpAxios
    .post(`/profile/${profileId}/profile-photo`, data)
    .then(response => {
      dispatch(profilePicSpinner(false));
      //dispatch(singleProfile(`profile/${profileId}`));
      //dispatch(getProfiles());
    })
    .catch(error => {
      dispatch(profilePicSpinner(false));
      console.log(error);
    });
};

/**
 * @getProfiles: your profiles
 * @param {*}
 */
export const singleProfile = (
  profile_id,
  closemodal = false,
  partner_id = null,
  globalLoading = true
) => dispatch => {
  if(globalLoading){
    dispatch(globalSpinner(true));
  }
  dispatch(temporarilyUnavailable(false));
  gpAxios
    .get(`/${profile_id}`)
    .then(response => {
      dispatch(globalSpinner(false));
      dispatch(setSingleProfile(response.data.data));
      if (response.data.data.state === 5 || response.data.data.state === 6) {
        dispatch(temporarilyUnavailable(true));
      } else {
        if (partner_id !== null) {
          dispatch(getMatchDetails(profile_id, `matches/${partner_id}`));
        }
      }
      if (closemodal) {
        dispatch(closeModal());
      }
      return;
    })
    .catch(error => {
      dispatch(temporarilyUnavailable(false));
      if (error.response.status === 401) {
        dispatch(setProfileNotFound(true));
      }
      dispatch(globalSpinner(false));
      dispatch(updateLayout(false));
    });
};

/**
 * @getProfiles: your profiles
 * @param {*}
 */
export const defaultSingleProfile = (
  profile_id,
  closemodal = false
) => dispatch => {
  dispatch(globalSpinner(true));
  dispatch(setSingleProfile(null));
  gpAxios
    .get(`/${profile_id}`)
    .then(response => {
      dispatch(globalSpinner(false));
      dispatch(setSingleProfile(response.data.data));
      dispatch(unreadMessagesCount(response.data.data.chat_token))
      if (closemodal) {
        dispatch(closeModal());
      }
      return;
    })
    .catch(error => {
      if (error.response.status === 401) {
        dispatch(setProfileNotFound(true));
      }
      dispatch(globalSpinner(false));
      dispatch(updateLayout(false));
    });
};


export const unreadMessagesCount = (
  chat_token
) => dispatch => {
  chatAxios
    .get(`/unreads?token=${chat_token}`)
    .then(response => {
      dispatch(setUnreadCount(response.data.data))
    })
    .catch(error => {
      dispatch(setUnreadCount(0))
    })
}



/**
 *
 * @param {*} data
 */
export const setUnreadCount = data => ({
  type: USER.UNREAD_MESSAGES,
  payload: data
});

/**
 *
 * @param {*} data
 */
export const setProfileNotFound = data => ({
  type: USER.PROFILE_NOT_FOUND,
  payload: data
});

/**
 *
 * @param {*} data
 */
export const setSingleProfile = data => ({
  type: USER.SINGLE_PROFILE,
  payload: data
});

/**
 *
 * @param {*} data
 */
export const updateUserSpinner = isSpine => ({
  type: USER.UPDATE_PROFILE_SPINNER,
  payload: isSpine
});


export const updateDraftDashboardProfile = (
  profile_id,
  uri,
  data
) => dispatch => {
  dispatch(updateUserSpinner(true));
  gpAxios
    .post(`/${profile_id}/${uri}`, data)
    .then(_ => {
      dispatch(singleProfile(profile_id, true));
      dispatch(updateUserSpinner(false));
      dispatch(setError(null));
      toastr.success('Success!', 'Details are saved');
    })
    .catch(error => {
      dispatch(updateUserSpinner(false));
      //dispatch(setError(error.response.data.error));
      toastr.error('Failed!', 'Details not saved');
    });
};

export const logoutUser = history => {
  localStorage.removeItem('wedmToken');
  Cookies.remove('wedmToken');
  window.location.reload();
};

export const setProfileId = id => ({
  type: USER.PROFILE_ID,
  payload: id
});
