import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";

/**
 * @ReuseableRadioBox
 */
import RadioBox from "../../../../../../common/shared-components/Radio";

/**
 * @ResusableErrorComponent
 */
import { Error } from "../../../../../../common/shared-components/Error";
import { filterItems } from "../../../../../../util/filter_common_data";
/**
 * @localData: this file contains local data
 */

import { genderPrefix } from "../../../../../../util/getGender"; 
import {
  haveChildren,
  hisHeight
} from "../../../../../../common/forms-data/forms-data";

export default class BasicInfoSteps extends Component {

  render() {
    
    const {

      data: { data },
      state,
      allCommonData,
      errors
    } = this.props;

    return (
      <React.Fragment>
        <div className="inline fields mb-4">
          <label className="w-50 lh-md">
            <span className="mr-05">Religion</span>
          </label>
          <div className="w-100">
            <Dropdown
              search
              className={`wd-input ${Error(errors, "religion")}`}
              placeholder="Select Religion"
              fluid
              selection
              clearable
              selectOnBlur={false}
              options={filterItems(
                "religion",
                allCommonData && allCommonData.data
              )}
              onChange={(e, { value }) => {
                this.props.setParentState('religion',(value && value !== "") ? value : null)
                this.props.setParentState('caste',null)
              }}
              value={state.religion}
            />
          </div>
        </div>
        { data && data.community && [1146,1231].includes(data.community) && (
            <div className="inline fields mb-4">
            <label className="w-50 lh-md">
              <span className="mr-05">Caste</span>
            </label>
            <div className="w-100">
              <Dropdown
                search
                className={`wd-input ${Error(errors, "caste")}`}
                placeholder="Select Caste"
                fluid
                selection
                clearable
                selectOnBlur={false}
                options={filterItems(
                  "caste",
                  allCommonData && allCommonData.data,
                  { community: data && data.community, religion: state.religion }
                )}
                onChange={(e, { value }) => {
                  this.props.setParentState('caste',(value && value !== "") ? value : null)
                }}
                value={state.caste}
              />
            </div>
          </div>
        ) }
        <div className="inline fields mb-4">
          <label className="w-50 lh-md">
            <span className="mr-05">
             Height
            </span>
          </label>
          <div className="w-100">
            <Dropdown
              search
              selectOnBlur={false}
              className={`wd-input ${Error(errors, "height")}`}
              placeholder="Select Height"
              fluid
              selection
              clearable
              options={hisHeight}
              onChange={(e, { value }) => {
                this.props.setParentState('height',(value && value !== "") ? value : null)
              }}
              value={state.height}
            />
          </div>
        </div>


          <div  className={`inline fields mb-4 flex-start ${Error(errors, "skin_tone")}`} >
          <label className="w-50 lh-md">
            <span className="mr-05">Skin Tone</span>
          </label>
          <div className="w-100">
            <div className={`skin-box ${Error(errors, "skin_tone")}`} >
              <RadioBox
                name="skin-tone"
                radioBoxObject={[
                  {
                    text: "Fair",
                    value: "fair",
                    class: `skintone-fair-${
                      data && data.gender === "female" ? "female" : "male"
                    }`
                  },
                  {
                    text: "Moderate Fair",
                    value: "moderate-fair",
                    class: `skintone-vfair-${
                      data && data.gender === "female" ? "female" : "male"
                    }`
                  },
                    {
                    text: "Medium",
                    value: "medium",
                    class: `skintone-wheatish-${
                      data && data.gender === "female" ? "female" : "male"
                    }`
                  },
                  {
                    text: "Dark",
                    value: "dark",
                    class: `skintone-dark-${
                      data && data.gender === "female" ? "female" : "male"
                    }`
                  }
                ]}
                className="custom-radio"
                selectedRadioValue={value => {
                  if(errors){
                    delete errors.skin_tone
                  }
                  this.props.setParentState('skin_tone',(value && value !== "") ? value : null)
                  }
                }
                setDefaultValue={state.skin_tone}
              />
            </div>
          </div>
        </div>
        <div className={`inline fields mb-4 flex-start ${Error(errors, "body_type")}`} >
          <label className="w-50 lh-md">
            <span className="mr-05">Body Type</span>
          </label>

          <div className="w-100">
            <div className={`bodytype-box ${Error(errors, "body_type")}`} >
              <RadioBox
                name="body-type"
                radioBoxObject={[
                  {
                      text: "Athletic",
                      value: "athletic",
                      class: `body-skinny-${
                      data && data.gender === "female" ? "female" : "male"
                    }`
                  },
                  {
                      text: "Slim",
                      value: "slim",
                      class: `body-slim-${
                      data && data.gender === "female" ? "female" : "male"
                    }`
                  },
                  {
                      text: "Average",
                      value: "average",
                      class: `body-average-${
                      data && data.gender === "female" ? "female" : "male"
                    }`
                  },
                  {
                      text: "Heavy",
                      value: "heavy",
                      class: `body-fat-${
                      data && data.gender === "female" ? "female" : "male"
                    }`
                  }
                ]}
                className="custom-radio"
                selectedRadioValue={value =>
                  {
                    if(errors){
                      delete errors.body_type
                    }
                    this.props.setParentState('body_type',(value && value !== "") ? value : null)
                  }
                }
                setDefaultValue={state.body_type}
              />
            </div>
          </div>
        </div>
        <div className= {`inline fields mb-4 flex-start ${Error(errors, "disability")}`}>
          <label className="w-50 lh-md">
            <span className="mr-05">Physically challenged or Disabled ?</span>
          </label>
          <div className="w-100">
            <div className="two wide fields m-0 w-full-xs">
              <RadioBox
                name="disability"
                radioBoxObject={[
                  { text: "No", value: 0, parent: "field"  },
                  {
                    text: "Yes",
                    value: 1,
                    parent: "field pr-0"
                  }
                ]}
                className="wd-radio-box w-100 pink"
                selectedRadioValue={value =>
                  {
                    if(errors){
                      delete errors.disability
                    }
                    this.props.setParentState('disability',(value !== "") ? value : null)
                  }
                }
                setDefaultValue={state.disability}
              />
            </div>
            <div class="font-12 text-gray mt-05">Physically challenged or Disabled  ?</div>
          </div>
        </div>
        <div className="inline fields mb-4">
          <label className="w-50 lh-md">
            <span className="mr-05">Eating Habit</span>
          </label>
          <div className="w-100">
            <Dropdown
              search
              className={`wd-input ${Error(
                this.props.errors,
                "diet"
              )}`}
              placeholder="Select Eating Habit"
              clearable
              fluid
              selection
              selectOnBlur={false}
              options={filterItems(
                "diet",
                allCommonData && allCommonData.data
              )}
              onChange={(e, { value }) =>
                this.props.setParentState('diet',(value && value !== "") ? value : null)
              }
              value={state.diet}
            />
          </div>
        </div>
        {data && data.marital_status && data.marital_status !== 'never-married' && (
              <div className="inline fields mb-4 flex-start">
                <label className="w-50 lh-md">
                  <span className="mr-05">
                    Have any children?
                  </span>
                </label>
                <div className="w-100">
                  <Dropdown
                    search
                    className={`wd-input ${Error(errors, "children_state")}`}
                    placeholder="Select Children"
                    fluid
                    clearable
                    selection
                    selectOnBlur={false}
                    options={haveChildren}
                    onChange={(e, { value }) => {
                      this.props.setParentState('children_state', value !== "" ? value : null )
                    }}
                    value={
                      state.children_state === 0 ? 0 : state.children_state
                    }
                  />
                  {(state.children_state === 1 || state.children_state === 2) && (
                    <div className="field pr-0">
                      <label className="font-weight-normal mt-05 mb-05">
                        No of children
                      </label>

                      <div className="count-box">
                        <RadioBox
                          name="children"
                          className={`custom-radio`}
                          radioBoxObject={[
                            { text: "1", value: 1 },
                            { text: "2", value: 2 },
                            { text: "3", value: 3 },
                            { text: "4+", value: 4 }
                          ]}
                          selectedRadioValue={value =>
                            this.props.setParentState('children', value !== "" ? value : null)
                          }
                          setDefaultValue={state.children}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
      )}
      </React.Fragment>
    );
  }
}
