import { LANGUAGE } from "../../constants/ActionTypes";
import { createReducer } from "../../../util/reducerUtil";
const counter = 0;
const initState = {
  locale: {
    languageId: "english",
    locale: localStorage.getItem("currentLang") || "en",
    name: "English",
    icon: "us"
  }
};

/**
 * @switchLanguage
 * @param {*} state
 * @param {*} payload
 */
const switchLanguage = (state, payload) => {
  return {
    ...state,
    locale: payload
  };
};

export default createReducer(initState, {
  [LANGUAGE.SWITCH_LANGUAGE]: switchLanguage
});
