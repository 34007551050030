import React from "react";
import { urls } from "../../../../../../util/GlobalContants";

function Congratulation({ did }) {
  return (
    <div className="content bt-0">
      <div className="mb-4">
        <img
          className="mw-80 m-auto d-block"
          src="/assets/img/success-bg.png"
          alt="success"
        />
        <div className="mt-2 text-center">
          <h3 className="ui header text-black font-weight-bold mb-1">
            Congratulations! Your profile {did && did.data.did} was created
            successfully!
          </h3>
        </div>
      </div>
      <div className="ui ignored warning message bg-yellow-light no-shadow mt-0 mb-4 border">
        <div className="header font-weight-normal mb-05 font-18 text-black">
          What's Next?
        </div>
        <div className="font-13 mb-2 text-black">
          Your ID: <b>{did && did.data.did}</b>
        </div>
        <div className>
          <div className="font-16 mb-1 text-blue">
            <span className="step-count">1</span>
            <div className="o-hidden">Send Photo &amp; Horoscope Chart</div>
          </div>
          <div className="ml-5 mb-4">
            <div className="mb-1">
              <a href={urls.whatsApp} className="text-black" target="_blank" >
                <i className="icon-md i-whatsapp d-inline-block mr-05 align-middle pos-relative top--1" />
                Whatsapp - {urls.whatsAppNo}
              </a>
            </div>
            <div className="mb-1">
              <a href={`mailto:${urls.email}`} className="text-black" target="_blank" >
                <i className="icon-md i-email d-inline-block mr-05 align-middle pos-relative top--1" />
                Email - {urls.email}
              </a>
            </div>
            <div className="mb-1">
              <a href={urls.messangerLink} className="text-black" target="_blank">
                <i className="icon-md i-messenger d-inline-block mr-05 align-middle pos-relative top--1" />
                Messenger - {urls.messanger}
              </a>
            </div>
          </div>
          <div className>
            <div className="font-16 mb-05 text-blue">
              <span className="step-count">2</span>
              <div className="o-hidden">Verification</div>
            </div>
            <div className="ml-5">
              <div className="text-black mb-1 ml-05">
                We will call you within next 7 working days to verify your profile.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mw-80 m-auto font-14 font-weight-bold">
            Kindly enter your partner preference to find your perfect matching.
          </div>
    </div>
  );
}

export default Congratulation;
