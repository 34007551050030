import * as constant from "../../constants/ActionTypes";
import { VERIFY } from "../../constants/ActionTypes";
import { gpAxios } from "../../../util/axios";
import jwtDecode from "jwt-decode";

/**
 * @importConstants
 */
import { GET_ERRORS } from "../../../common/commonReducers/types";
import {
  setErrors,
  maxReachLimit
} from "../../../common/commonActions/errorAction";

/**
 * @importActions
 */
import { setCurrentUser, setProfileId, singleProfile, setToken } from "../user/user";
import { RESEND_OTP_SECONDS } from "../../../util/GlobalContants";
import { closeModal } from "../../../components/modals/redux-state/modalActions";
import { setTimeout } from "timers";
import { openVerificationModal } from "../../../components/modals/draft-dashboard-forms/parter_contact_sub_modals/redux/action";
import {toastr} from "react-redux-toastr";

export const verify = decoded => ({
  type: constant.VERIFY_NUMBER,
  payload: decoded
});

export const spinner = spin => ({
  type: constant.VERIFY_SPINNER,
  payload: spin
});

export const close_modal = isClose => ({
  type: VERIFY.CLOSE_MODAL,
  payload: isClose
});

// Set error null
export const setError = err => ({
  type: GET_ERRORS,
  payload: err
});

// CHECK IF TOKEN IS VALID
export const validateTokenSpinner = spin => ({
  type: VERIFY.VALIDATE_TOKEN_SPINNER,
  payload: spin
});

// CHECK IF TOKEN IS VALID
export const validateTokenResponse = resp => ({
  type: VERIFY.VALIDATE_TOKEN,
  payload: resp
});

// Validate Token
export const validateToken = (token, history) => dispatch => {
  dispatch(validateTokenSpinner(true));
  gpAxios
    .get(`/profile/valid?token=${token}`)
    .then(response => {
      dispatch(validateTokenSpinner(false));
      if (response.data.data === false) {
        history.push("/");
        //localStorage.clear();
      } else {
        dispatch(validateTokenResponse(response.data));
        window.history.pushState("", "", `/verification?token=${token}`);
        localStorage.removeItem('wedmToken');
      }
    })
    .catch(err => {
      dispatch(validateTokenSpinner(false));

      if (
        err.response &&
        err.response.data.error.code &&
        err.response.data.error.hasOwnProperty("code")
      ) {
        dispatch(setError(err.response.data.error.code[0]));
      } else {
        if (
          err.response &&
          err.response.data !== undefined &&
          err.response.data.error
        ) {
          dispatch(setError(err.response.data.error));
        }
      }
    });
};

// Verify Number
export const verifyNumber = (tokenData, history) => dispatch => {
  dispatch(spinner(true));
  gpAxios
    .post("/auth/complete-registration", tokenData)
    .then(response => {
      dispatch(spinner(false));
      localStorage.removeItem("timer");
      localStorage.setItem("wedmToken", JSON.stringify(response.data));
      const decode = jwtDecode(
        JSON.parse(localStorage.getItem("wedmToken")).token.access_token
      );
      dispatch(setCurrentUser(decode));
      dispatch(setToken(response.data.token.access_token));
      dispatch(setProfileId(response.data.data.id));
      gpAxios.defaults.headers.common.Authorization =
        "Bearer " + response.data.token.access_token;

      if (!response.data.data.dob_exist) {
        history.push(`/${response.data.data.id}/basic-profile`);
      } else {
        history.push(`/${response.data.data.id}/step-2`);
      }
    })
    .catch(err => {
      dispatch(spinner(false));
      if (
        err.response &&
        err.response.data.error.code &&
        err.response.data.error.hasOwnProperty("code")
      ) {
        dispatch(setError(err.response.data.error.code[0]));
      } else {
        if (
          err.response &&
          err.response.data !== undefined &&
          err.response.data.error
        ) {
          dispatch(setError(err.response.data.error));
        }
      }
    });
};

// Resend OTP
export const resendOTP = (token, otpType) => dispatch => {
  dispatch(spinner(true));
  var callOrSms = "";
  if (otpType === "sms") {
    callOrSms = "/auth/resend-text-verification";
  } else {
    callOrSms = "/auth/resend-voice-verification";
  }
  gpAxios
    .post(callOrSms, token)
    .then(response => {
      dispatch(spinner(false));
    })
    .catch(err => {
      dispatch(spinner(false));
      if (
        err.response &&
        err.response.data !== undefined &&
        err.response.data.hasOwnProperty("type")
      ) {
        dispatch(maxReachLimit(err.response.data.type));
      }

      if (
        err.response &&
        err.response.data.error.code &&
        err.response.data.error.hasOwnProperty("code")
      ) {
        dispatch(setError(err.response.data.error.code[0]));
      } else {
        if (
          err.response &&
          err.response.data !== undefined &&
          err.response.data.error
        ) {
          dispatch(setError(err.response.data.error));
        }
      }
    });
};
// Resend OTP
export const changePhoneNumberOTP = (
  profile_id,
  otpType,
  numberType = null
) => dispatch => {
  dispatch(spinner(true));
  var callOrSms = "";
  if (numberType === "change") {
    if (otpType === "sms") {
      callOrSms = `/profile/${profile_id}/phone-resend-text-verification`;
    } else {
      callOrSms = `/profile/${profile_id}/phone-resend-voice-verification`;
    }
  } else {
    if (otpType === "sms") {
      callOrSms = `/profile/${profile_id}/additional-phone-resend-text-verification`;
    } else {
      callOrSms = `/profile/${profile_id}/additional-phone-resend-voice-verification`;
    }
  }

  gpAxios
    .post(callOrSms)
    .then(response => {
      dispatch(spinner(false));
    })
    .catch(err => {
      dispatch(spinner(false));
      if (
        err.response &&
        err.response.data !== undefined &&
        err.response.data.hasOwnProperty("type")
      ) {
        dispatch(maxReachLimit(err.response.data.type));
      }

      if (
        err.response &&
        err.response.data.error.code &&
        err.response.data.error.hasOwnProperty("code")
      ) {
        dispatch(setError(err.response.data.error.code[0]));
      } else {
        if (
          err.response &&
          err.response.data !== undefined &&
          err.response.data.error
        ) {
          dispatch(setError(err.response.data.error));
        }
      }
    });
};

// verifyPhoneNumberOTP
export const verifyPhoneNumberOTP = (
  profile_id,
  code,
  numberType = null
) => dispatch => {
  dispatch(spinner(true));
  let url = "";
  if (numberType === "change") {
    url = `/profile/${profile_id}/verify-contact-phone`;
  } else {
    url = `/profile/${profile_id}/verify-contact-additional-phone`;
  }
  gpAxios
    .post(url, code)
    .then(response => {
      dispatch(spinner(false));
      dispatch(openVerificationModal(false));
      dispatch(close_modal(true));
      dispatch(singleProfile(`profile/${profile_id}`));
      setTimeout(() => {
        dispatch(closeModal());
      }, 1500);
      toastr.success("Success!", "Details are saved");
    })
    .catch(err => {
      toastr.error("Failed!", "Details not saved");

      dispatch(spinner(false));
      if (
        err.response &&
        err.response.data !== undefined &&
        err.response.data.hasOwnProperty("type")
      ) {
        dispatch(maxReachLimit(err.response.data.type));
      }

      if (
        err.response &&
        err.response.data.error.code &&
        err.response.data.error.hasOwnProperty("code")
      ) {
        dispatch(setError(err.response.data.error.code[0]));
      } else {
        if (
          err.response &&
          err.response.data !== undefined &&
          err.response.data.error
        ) {
          dispatch(setError(err.response.data.error));
        }
      }
    });
};

export const changeMobileModal = modal => ({
  type: constant.CHANGE_NUMBER_MODAL,
  payload: modal
});

export const resetTimerAgain = isReset => ({
  type: constant.VERIFY.RESET_TIMER,
  payload: isReset
});

export const changeNumberSpinner = spin => ({
  type: constant.CHANGE_NUMBER_SPINNER,
  payload: spin
});

// CHECK IF TOKEN IS VALID
export const validNumber = number => ({
  type: VERIFY.VALID_NUMBER,
  payload: number
});

export const changeMobileNumber = tokenMobileNumber => dispatch => {
  dispatch(changeNumberSpinner(true));
  gpAxios
    .post("/auth/change-mobile", tokenMobileNumber)
    .then(response => {
      dispatch(changeNumberSpinner(false));
      dispatch(changeMobileModal(false));
      dispatch(setErrors(null));
      dispatch(validNumber(tokenMobileNumber.mobile));
      dispatch(validateToken(response.data.data, ""));
      dispatch(resetTimerAgain(true));
      localStorage.setItem("timer", RESEND_OTP_SECONDS);
      window.location.reload();
    })
    .catch(err => {
      dispatch(changeNumberSpinner(false));
      if (
        err.response &&
        err.response.data.error.mobile &&
        err.response.data.error.hasOwnProperty("mobile")
      ) {
        dispatch(setError(err.response.data.error.mobile[0]));
      } else {
        if (
          err.response &&
          err.response.data !== undefined &&
          err.response.data.error
        ) {
          dispatch(setError(err.response.data.error));
        }
      }
    });
};
