import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { urls } from "../../../../../util/GlobalContants";
import { openModal } from "../../../../modals/redux-state/modalActions";
import Photos from "../../dashboard/components/photos";
import HoroscopeChart from "../../dashboard/components/horoscope_chart";
import { FormattedMessage } from "react-intl";

function ActiveStatus(props) {
  return (
    <React.Fragment>
      <div className="wd-rail">
        <div className="wd-middle-content">
          <div className="ui relaxed divided items">
            <div className="mb-2">
              <div className="ui cards">
                <Link
                  to={`/${props.singleUser.id}/matches`}
                  className="ui fluid card wd-card "
                >
                  <div className="content pt-5 pb-5">
                    <div className="text-dark text-center">
                      <div className="d-block font-30 mb-05 lh-100">
                        {props.singleUser.matches === null
                          ? 0
                          : props.singleUser.matches}
                      </div>
                      <div className="d-block font-24 mb-2"><FormattedMessage id="dashboard.matches.title" /></div>

                      <div className="ui header font-20 text-green2 mt-0 mb-05 font-weight-semibold">
                        <FormattedMessage id="active.dashboard.match.activation.message.title" />
                      </div>
                      <p className="font-16 mw-80 mw-xs-100 m-auto">
                        {props.singleUser.matches === null &&
                          "We are searching our database for some great matches. Please stand by."}
                        {props.singleUser.matches === 0 &&
                          "Sorry, we couldn’t find any matches to suit your current partner preference. Wider your preference & explore new matches on your way... Go to partner preference and broaden your search."}
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            <Photos
              profileId={props.singleUser.id}
              singleUser={props.singleUser}
              other_way_modal={props.other_way_modal}
              modal_type={props.modal_type}
              set_other_way_modal={props.set_other_way_modal}
            />
            {props.singleUser &&
              props.singleUser.horoscope !== 2 &&
              props.singleUser.horoscope !== null && (
                <HoroscopeChart
                  profileId={props.singleUser.id}
                  singleUser={props.singleUser}
                  set_other_way_modal={props.set_other_way_modal}
                />
              )}

            <div className="ui fluid card wd-card mb-2">
              <div className="content py-3">
                <div className="text-center mb-2 mt-2">
                  <div className="font-16 mb-1">
                   <FormattedMessage id="upload.photo.missed.messsage" />
                  </div>
                  <div className="uploading-options">
                    <div className="item">
                      <a
                        href={`https://api.whatsapp.com/send?phone=918075324709&text=Hi%20Matrimony%2c%20I%20need%20your%20help${
                          props.singleUser !== null &&
                          props.singleUser !== undefined &&
                          props.singleUser.did !== ""
                            ? `,%20my%20id%20is%20${props.singleUser.did}`
                            : ""
                        }`}
                        target="_blank"
                        className="ui basic button d-block text-truncate"
                      >
                        <div className="d-flex item-center">
                          <i className="icon-lg d-inline-block i-whatsapp mr-1" />
                          <div className="o-hidden text-left">
                            <div className="font-15 text-dark font-weight-bold text-truncate lh-lg">
                              {urls.whatsAppNo}
                            </div>
                            <span className="d-block text-muted font-12">
                              <FormattedMessage id="whatsapp.message.only">{txt => txt}</FormattedMessage>
                            </span>
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className="item">
                      <a
                        href="http://m.me/sameepam"
                        className="ui basic button d-block text-truncate"
                        target="_blank"
                      >
                        <div className="d-flex item-center">
                          <i className="icon-lg d-inline-block i-messenger mr-1" />
                          <div className="o-hidden text-left">
                            <div className="font-15 text-dark font-weight-bold text-truncate lh-lg">
                              {urls.messanger}
                            </div>
                            <span className="d-block text-muted font-12">
                              <FormattedMessage id="messenger">{txt => txt}</FormattedMessage>
                            </span>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default connect(
  undefined,
  { openModal }
)(ActiveStatus);
