import React from "react";
import { NavLink } from "react-router-dom";
import { Dropdown, DropdownDivider } from "semantic-ui-react";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import { openMore } from "../../../../../../redux/actions/mobile/matches-more";

function MatchesTopbar({profileId,handleOpen,sort,activeSort, matchesCount}){
    return (
        <React.Fragment>
        <div className="ui borderless menu match-middle-menu">
        <NavLink to={`/${profileId}/matches`} exact className="item">
            <div className="d-flex flex-column">
                {matchesCount !== null && (<label>{matchesCount.matches.new}</label>)}
                <div>New <span className="d-none-xs">matches</span></div>
            </div>
        </NavLink>
        
        <NavLink to={`/${profileId}/matches/all`} exact className="item">
            <div className="d-flex flex-column">
                <label>{matchesCount !== null && (<label>{matchesCount.matches.total}</label>)}</label>
                All Matches 
            </div>
        </NavLink>
        <NavLink to={`/${profileId}/matches/shortlisted`} exact className="item">
        {matchesCount !== null && (<span class="d-none"><i class="i-heart"></i>{matchesCount.matches.favourite + matchesCount.reverse_matches.r_favourite}</span>)}
            
            <span class="d-none-xs">Shortlisted</span>
        </NavLink>
        <Dropdown text='More' className='item more-menu'>
        <Dropdown.Menu>
            <Dropdown.Item>
                <NavLink to={`/${profileId}/matches/all`} className="text-black">All Matches</NavLink>
            </Dropdown.Item>
            <Dropdown.Item>
                <NavLink to={`/${profileId}/matches/viewed`} className="text-black">Viewed by me</NavLink>
            </Dropdown.Item>
            <Dropdown.Item>
                <NavLink to={`/${profileId}/matches/mutual`} className="text-black">Mutual Matches</NavLink>
            </Dropdown.Item>
            <Dropdown.Item>
                <NavLink to={`/${profileId}/matches/reverse`} className="text-black">Reverse Matches</NavLink>
            </Dropdown.Item>
            <DropdownDivider/>
            <Dropdown.Item>
            <NavLink to={`/${profileId}/matches/rejected`} className="text-black">Rejected by me</NavLink>
            </Dropdown.Item>
            <Dropdown.Item>
            <NavLink to={`/${profileId}/matches/contact-viewed`} className="text-black">Contact Viewed by me</NavLink>
            </Dropdown.Item>
            <Dropdown.Item>
            <NavLink to={`/${profileId}/requests`} className="text-black">Requests sent by me</NavLink>
            </Dropdown.Item>
            <DropdownDivider/>
            <Dropdown.Item>
            <NavLink to={`/${profileId}/matches/reverse-contact-viewed`} className="text-black" >Who viewed my contact number</NavLink>
            </Dropdown.Item>
            <Dropdown.Item>
            <NavLink to={`/${profileId}/matches/reverse-shortlisted`} className="text-black" >Who Shortlisted my profile</NavLink>
            </Dropdown.Item>
            <Dropdown.Item>
            <NavLink to={`/${profileId}/matches/reverse-viewed`} className="text-black" >Who viewed my profile</NavLink>
            </Dropdown.Item>
            <Dropdown.Item>
            <NavLink to={`/${profileId}/requests-received`} className="text-black" >Requests received</NavLink>
            </Dropdown.Item>
        </Dropdown.Menu>
        </Dropdown>
        <div className="item match-mobi-popup" onClick={handleOpen}>
            <div>
            More
            <span className="ui mini label pl-05 pr-05 font-8 pos-absolute radius-2 bg-pink-light text-pink" style={ {top:'4px', left: '35px'}} >NEW</span>
            </div>
        </div>
        <div className="right menu match-sort-btn">
            <Dropdown text='Sort By' className='item'>
            <Dropdown.Menu>
                <Dropdown.Item onClick={()=> {sort('score')}} active={activeSort === 'score'} >Match Score</Dropdown.Item>
                <Dropdown.Item onClick={()=> {sort('activity')}} active={activeSort === 'activity'} >Recent Activity</Dropdown.Item>
                <Dropdown.Item onClick={()=> {sort('matched-date')}} active={activeSort === 'matched-date'} >Released Date</Dropdown.Item>
            </Dropdown.Menu>
            </Dropdown>
        </div>
      </div>
      <div className="match-mobi-filrer mb-2">
            <Dropdown icon={<i className='dropdown icon text-blue'></i>} text={<span className='text-blue'>Sort By</span>} className='match-sort-btn button btn-link'>
            <Dropdown.Menu>
                <Dropdown.Item onClick={()=> {sort('score')}} active={activeSort === 'score'} >Match Score</Dropdown.Item>
                <Dropdown.Item onClick={()=> {sort('activity')}} active={activeSort === 'activity'} >Recent Activity</Dropdown.Item>
                <Dropdown.Item onClick={()=> {sort('matched-date')}} active={activeSort === 'matched-date'} >Released Date</Dropdown.Item>
            </Dropdown.Menu>
            </Dropdown>
        </div>
      </React.Fragment>
    );
}

const mapStateToProps = state => ({

});
  
const mapDispatchToProps = dispatch => (
    bindActionCreators({
      handleOpen: openMore,
    }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(MatchesTopbar);