import React from "react";
import { connect } from "react-redux";
import { Modal } from "semantic-ui-react";

import GeneralInformationModal from "../draft-dashboard-forms/general_information_modal";
import BasicInformationModal from "../draft-dashboard-forms/basic_information_modal";
import LifeStyleAndAppearanceModal from "../draft-dashboard-forms/life_style_and_appearance_modal";
import LocationAndLanguageModal from "../draft-dashboard-forms/location_and_language_modal";
import EducationAndCareer from "../draft-dashboard-forms/education_and_career_modal";
import WealthModal from "../draft-dashboard-forms/wealth_modal";
import HoroscopModal from "../draft-dashboard-forms/horoscope_modal";
import FamilyInformationModal from "../draft-dashboard-forms/family_information_modal";
import PartnerContactDetailsModal from "../draft-dashboard-forms/partner_contact_modal";
import SocialMediaModal from "../draft-dashboard-forms/social_media_modal";

import ProfileImageCropModal from "../profile-image-crop/profile_image_crop_modal";

import { setError } from "../../../redux/actions/otp-verify/verify";
import UpgradeModal from "../match-view-modals/UpgradeModal";
import CommonUpgrade from "../common/commonUpgrade";
import Upgrade from "../chat/upgrade";
import { closeModal } from "./modalActions";
import PremimumUserModal from "../match-view-modals/PremimumUserModal";
import PremimumUsersUpgradeModal from "../match-view-modals/PremimumUsersUpgradeModal";
import PendingActivationModal from "../common/pending-activation";
import PendingCompleteModal from "../common/pending-complete";
import PhotoGuide from "../common/photo-guide";
import PreferenceAdjustModal from "../common/preference-adjust";
import FreeProfileModal from "../common/free-profile";
import HelpVideoModal from "../common/HelpVideo";
import UploadPhotoWarning from "../common/upload-photo";
import ChatModal from "../chat/index";
import ContactViewAlert from "../chat/contact-view-alert";
import MobileChat from "../chat/mobile-chat";
import PendingActionModal from "../chat/pending-actions";

const ModalManager = props => {
  const { currentModal } = props;

  let renderedModal;
  if (currentModal) {
    props.setError(null);
    const {
      modalProps: { customClass = "modal-sm" }
    } = currentModal;
    const ModalComponent = modalLookup[currentModal.modalType];
    renderedModal = <ModalComponent {...currentModal.modalProps} />;
    return (
      <Modal
        className={`ten coupled wd-modal ${customClass}`}
        open={true}
        closeOnDimmerClick={true}
        onClose={() => closeModal()}
      >
        {renderedModal}
      </Modal>
    );
  } else {
    return (
      <Modal className="ten coupled wd-modal modal-sm" open={false}>
        {renderedModal}
      </Modal>
    );
  }
};

const modalLookup = {
  GeneralInformationModal,
  BasicInformationModal,
  LifeStyleAndAppearanceModal,
  LocationAndLanguageModal,
  EducationAndCareer,
  WealthModal,
  HoroscopModal,
  FamilyInformationModal,
  PartnerContactDetailsModal,
  SocialMediaModal,
  HelpVideoModal,
  ProfileImageCropModal,
  UpgradeModal,
  PremimumUserModal,
  PremimumUsersUpgradeModal,
  CommonUpgrade,
  Upgrade,
  PendingActivationModal,
  PhotoGuide,
  PendingCompleteModal,
  FreeProfileModal,
  UploadPhotoWarning,
  PreferenceAdjustModal,
  ChatModal,
  MobileChat,
  ContactViewAlert,
  PendingActionModal
};

const mapState = state => ({
  currentModal: state.form_modal
});

export default connect(
  mapState,

  { setError, closeModal }
)(ModalManager);
