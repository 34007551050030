import { USER } from "../../constants/ActionTypes";
import { createReducer } from "../../../util/reducerUtil";
import isEmpty from "../../../util/isEmpty";

const initState = {
  isAuthenticated: localStorage.getItem("wedmToken") !== null,
  token : null,
  user: {},
  userSpinner: false,
  basicProfileDataSpinner: false,
  basicProfileData: "",
  profileId: null,
  profileCards: [],
  phoneNumberValid: false,
  startTimer: false,
  singleProfileData: null,
  updateProSpinner: false,
  croppedProfilePicUrl: null,
  profilePicSpinner: false,
  profileNotFound: false,
  unread: 0
};

/**
 * @setUser start timer for otp and voice methods
 * @param state
 * @param payload
 * @returns {{startTimer: boolean}}
 */
export const startTimer = (state, payload) => ({
  ...state,
  startTimer: payload
});

/**
 * @setUser set current user in state
 * @param state
 * @param payload
 * @returns {{isAuthenticated: boolean, user: *}}
 */
export const setCurrentUser = (state, payload) => ({
  ...state,
  isAuthenticated: !isEmpty(payload),
  user: payload
});


export const setToken = (state, payload) => ({
  ...state,
  token: payload
});

/**
 * @setBasicProfileData: Setting basic data of user
 * @param {*} state
 * @param {*} payload
 */
export const setBasicProfileData = (state, payload) => ({
  ...state,
  basicProfileData: payload
});

/**
 * @setBasicProfileData: Setting basic data of user
 * @param {*} state
 * @param {*} payload
 */
export const setSingleUserProfile = (state, payload) => ({
  ...state,
  singleProfileData: payload
});

/**
 * @setBasicProfileDataSpinner: true or false value
 * @param {*} state
 * @param {*} payload
 */
export const setBasicProfileDataSpinner = (state, payload) => ({
  ...state,
  basicProfileDataSpinner: payload
});

/**
 * @setBasicProfileDataSpinner: true or false value
 * @param {*} state
 * @param {*} payload
 */
export const updateProfileSpinner = (state, payload) => ({
  ...state,
  updateProSpinner: payload
});

/**
 * @setProfileId
 * @param {*} state
 * @param {*} payload
 */
export const setProfileId = (state, payload) => ({
  ...state,
  profileId: payload
});

/**
 * @profileCards
 * @param {*} state
 * @param {*} payload
 */
export const setProfileCards = (state, payload) => ({
  ...state,
  profileCards: payload
});

/**
 * @phoneNumberValid
 * @param {*} state
 * @param {*} payload
 */
export const phoneNumberValid = (state, payload) => ({
  ...state,
  phoneNumberValid: payload
});

/**
 * @phoneNumberValid
 * @param {*} state
 * @param {*} payload
 */
export const setCroppedProfilePicUrl = (state, payload) => ({
  ...state,
  croppedProfilePicUrl: payload
});

/**
 * @phoneNumberValid
 * @param {*} state
 * @param {*} payload
 */
export const setProfilePicSpinner = (state, payload) => ({
  ...state,
  profilePicSpinner: payload
});

/**
 * @phoneNumberValid
 * @param {*} state
 * @param {*} payload
 */
export const setProfileNotFound = (state, payload) => ({
  ...state,
  profileNotFound: payload
});

export const setUnreadCount = (state, payload) => ({
  ...state,
  unread: payload
});

export default createReducer(initState, {
  [USER.SET_CURRENT_USER]: setCurrentUser,
  [USER.SET_AUTH_TOKEN]: setToken,
  [USER.PROFILE_BASIC_DATA_SPINNER]: setBasicProfileDataSpinner,
  [USER.PROFILE_BASIC_DATA]: setBasicProfileData,
  [USER.PROFILE_ID]: setProfileId,
  [USER.PROFILE_CARDS]: setProfileCards,
  [USER.PHONE_NO_VALID]: phoneNumberValid,
  [USER.START_TIMER]: startTimer,
  [USER.SINGLE_PROFILE]: setSingleUserProfile,
  [USER.UPDATE_PROFILE_SPINNER]: updateProfileSpinner,
  [USER.CROPPED_PROFILE_PIC_URL]: setCroppedProfilePicUrl,
  [USER.PROFILE_PIC_SPINNER]: setProfilePicSpinner,
  [USER.PROFILE_NOT_FOUND]: setProfileNotFound,
  [USER.UNREAD_MESSAGES]: setUnreadCount
});
