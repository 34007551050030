import { MODAL_CLOSE, MODAL_OPEN } from "./modalTypes";
import swal from "sweetalert";
export const openModal = (modalType, modalProps) => {
  return {
    type: MODAL_OPEN,
    payload: {
      modalType,
      modalProps
    }
  };
};

export const closeModal = () => {
  return {
    type: MODAL_CLOSE
  };
};

export const customSweetAlert = () => dispatch => {
  swal({
    title: "Are you sure?",
    closeOnClickOutside: false,
    closeOnEsc: false,
    icon: "warning",
    buttons :
      {
        cancel: {
          text: "Yes, Cancel it",
          visible: true,
          value: 1,
          className: "swal-button--cancel",
          closeModal: true,
        },
        confirm: {
          text: "Continue update",
          value: 0,
          visible: true,
          className: "swal-button--cancel",
          closeModal: true
        }
      }
    }).then(close => {
    if (close) {
      dispatch(closeModal());
    }
  });
};
