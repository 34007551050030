import React, { Component } from "react"
import { connect } from "react-redux"
import { openModal } from "../../../../modals/redux-state/modalActions"
import { setUnreadCount } from "../../../../../redux/actions/user/user"
import { Link } from "react-router-dom"
import { Button ,Dropdown, Dimmer,Loader, Popup  } from "semantic-ui-react"
import { ASSETS_CDN } from "../../../../../util/GlobalContants"
import { requests , xls , doc, pdf } from "../../../../../util/chat"
import { chatAxios } from "../../../../../util/axios"
import Message from "./message"
import Reminder from "./reminder"
import {readAsDataURL} from "../../../../../util/toBase64"
import InfiniteScroll from 'react-infinite-scroll-component'
import _ from "lodash"
import swal from "sweetalert"
import TextareaAutosize from 'react-textarea-autosize'

class ChatArea extends Component {
    onlineStatusTimer = null
    clearInterval = 5000
    clearTimerId = null
    state = {
        approvedRequests : ['view-income'],
        partnerOnline : false,
        loading : true,
        typedString : '',
        hasMore: false,
        typing : false,
        conversationID: this.props.conversationID,
        conversation : this.props.conversation,
        attachments: [],
        sending : null,
        messages : [],
        reminders : {},
        ptexts: {},
        preplies: {}
  }

  componentDidMount() {
    this.props.shareMethods(this.receivedNewMessage.bind(this))
    this.setState({
        approvedRequests : (this.props.conversation.partner.photo !== null && this.props.conversation.partner.photo_privacy === 0 ) ? ['add-photo','view-photo','view-income'] : this.props.conversation.partner.photo !== null ? ['add-photo','view-income'] : this.props.conversation.partner.photo === null ? ['view-income','view-photo'] : ['view-income']
    })
    this.loadMessages(this.state.conversationID,this.state.conversation.conversation_token)
  }

  componentWillUnmount() {
    clearTimeout(this.onlineStatusTimer)
  }

  async markAllRead(convId,chatToken){
    try {
        const {data} = await chatAxios.post(`/conversations/${convId}/mark-read`,{ token : chatToken})
        if(data && data.result) {
            this.props.setUnreadCount(this.props.unread - this.state.conversation.self_state.unread)
            this.props.setReadZero(convId)
        }
    } catch (error) {
        
    }
}
  async onlineStatus(convId,chatToken) {
      try {
        const {data} = await chatAxios.get(`/conversations/${convId}/online-status`,{
            params : {token : chatToken}
        })
        this.setState({
            partnerOnline : data.data
        })

      } catch (error) {
        this.setState({
            partnerOnline : false
        })
      }
  }
  receivedNewMessage = (event,data)=> {
        try {

            if(event === 'new-message') {
                this.setState({typing: false})
                clearTimeout(this.clearTimerId)
                let messages = this.state.messages
                messages.unshift(data.message)
                this.setState({
                    sending : null,
                    attachments: [],
                    messages : messages
                },() => {
                    const objDiv = document.getElementById("MsgContainer")
                    objDiv.scrollTop = objDiv.scrollHeight
                    objDiv.scrollIntoView({ behavior: 'auto', block: 'end' });
                })
                this.markAllRead(this.state.conversationID,this.state.conversation.conversation_token)
            }
            else if(event === 'new-action') {
                let messages = this.state.messages
                const e = messages.findIndex(i => i.id === data.message)
                if(e >= 0){
                    messages[e].action = data.action
                }
                messages.filter(m => (m.linked !== undefined &&  m.linked.id === data.message)).forEach(ma => ma.linked.action = data.action)
                this.setState({messages : messages})
                if(data.action === 'accept' && data.partner === this.props.profileId) {
                    const approvedRequests = this.state.approvedRequests
                    approvedRequests.push(data.request)
                    this.setState({ approvedRequests : approvedRequests})
                }
                if(data.action !== 'later' && data.profile === this.props.profileId ) {
                    const conversation = this.state.conversation
                    conversation.partner.pending_actions = conversation.partner.pending_actions - 1
                    this.setState({ conversation : conversation})
                }
            }
            else if(event === 'typing') {
                clearTimeout(this.clearTimerId)
                this.setState({typing: true})
                this.clearTimerId = setTimeout( () => {
                    this.setState({typing: false})
                  }, this.clearInterval);
            }

            else if(event === 'read') {
                const msgs = this.state.messages
                msgs.forEach((m,i) => {
                    m.read = true
                })
                this.setState({messages : msgs})
            }

        } catch (error) {
            console.log(error)
        }
  }

  tokenUpdated = async(token) => {
        await this.props.tokenUpdated(this.state.conversationID,token)
        this.sendText()
  }
  async sendText() {
    const text = this.state.typedString
    try {
        const attachments = this.state.attachments
        if(text !== '' || attachments.length > 0) {
            this.setState({sending : text || 'Sending files', typedString : ''},() => {
                const objDiv = document.getElementById("MsgContainer")
                objDiv.scrollTop = objDiv.scrollHeight
                objDiv.scrollIntoView({ behavior: 'auto', block: 'end' });
            })
            const {data} = await chatAxios.post(`/conversations/${this.state.conversationID}/messages`,{ 
                token : this.state.conversation.conversation_token,
                type: attachments.length > 0 ? 'file': 'text',
                attachments : attachments.map(a => a.data),
                text : text
            })
            if(data && data.result) {
                if(this.state.conversation.archived.includes(this.props.profileId)){
                    //this.moveToInbox()
                }
            }
            else{
                this.setState({sending : null, typedString : text})
            }
        }
    } catch (error) {
        if(error.response.status === 401 && error.response.data && error.response.data.spam){
            this.props.openModal('ContactViewAlert',{
                token :  this.state.conversation.conversation_token,
                tokenUpdated : this.tokenUpdated,
                customClass : 'modal-xs'
            })
        }
        this.setState({sending : null, typedString : text})
    }
  }
  sendPtextPremium = async(text) => {
    const profile = this.props.profile
    const rpt = (this.state.conversation.contact_viewed && profile && profile.contact) ? `+${profile.contact.phone.no}`: 'My number'
    this.setState({typedString : text.replace('[my-number]',rpt)})
  }

  sendPtext = async(id,text) => {
    try {
        this.setState({sending : text},() => {
            const objDiv = document.getElementById("MsgContainer")
            objDiv.scrollTop = objDiv.scrollHeight
            objDiv.scrollIntoView({ behavior: 'auto', block: 'end' });
        })
        
        const {data} = await chatAxios.post(`/conversations/${this.state.conversationID}/messages`,{ 
            token : this.state.conversation.conversation_token,
            type: 'ptext',
            text : id
        })
        if(data && data.result) {
                if(this.state.conversation.archived.includes(this.props.profileId)){
                    this.moveToInbox()
                }
        }
        else{
            this.setState({sending : null})
        }
    } catch (error) {
        this.setState({sending : null})
    }
  }

  sendPreply = async(id,text,message) => {
    try {
        this.setState({sending : text},() => {
            const objDiv = document.getElementById("MsgContainer")
            objDiv.scrollTop = objDiv.scrollHeight
            objDiv.scrollIntoView({ behavior: 'auto', block: 'end' });
        })
        
        const {data} = await chatAxios.post(`/conversations/${this.state.conversationID}/messages`,{ 
            token : this.state.conversation.conversation_token,
            type: 'preply',
            message: message,
            text : id
        })
        if(data && data.result) {
                if(this.state.conversation.archived.includes(this.props.profileId)){
                    this.moveToInbox()
                }
        }
        else{
            this.setState({sending : null})
        }
    } catch (error) {
        this.setState({sending : null})
    }
  }

  async handleUploadFile(e) {
      e.preventDefault()
      const files = [...e.target.files]
      const existing = this.state.attachments
      e.target.value = null
      if((files.length + existing.length) > 5) {
          swal({
              title: 'Maximum file limit',
              text : 'You can add maximum 5 files at a time',
              icon: "warning",
              dangerMode: true
          })
      }
      
      const attachments = await Promise.all(files.slice(0, (5- existing.length)).map(f=>{return readAsDataURL(f)}))
      this.setState({
          attachments : existing.concat(attachments)
      })
  }
  removeAttachment(i) {
        try {
            let existing = this.state.attachments
            existing.splice(i, 1)
            this.setState({
                attachments : existing
            })
        } catch (error) {
            
        }
  }
  sendRequest = async(id,text) => {
    try {
        this.setState({sending : text},() => {
            const objDiv = document.getElementById("MsgContainer")
            objDiv.scrollTop = objDiv.scrollHeight
            objDiv.scrollIntoView({ behavior: 'auto', block: 'end' });
        })
        
        const {data} = await chatAxios.post(`/conversations/${this.state.conversationID}/messages`,{ 
            token : this.state.conversation.conversation_token,
            type: 'request',
            text : id
        })
        if(data && data.result) {
            if(this.state.conversation.archived.includes(this.props.profileId)){
                this.moveToInbox()
            }
        }
        else{
            this.setState({sending : null})
        }
    } catch (error) {
        this.setState({sending : null})
    }
  }
  async loadMessages(convId,chatToken){
        try {
            clearTimeout(this.onlineStatusTimer)
            this.setState({messages: [], loading : true, typedString : '',partnerOnline : false})
            this.onlineStatus(convId,chatToken)
            this.onlineStatusTimer = setInterval(() => { this.onlineStatus(convId,chatToken)},10000)
            const {data} = await chatAxios.get(`/conversations/${convId}/messages`,{params : { token : chatToken}})
            if(data && data.result) {
                const approvedRequests = this.state.approvedRequests
                const msgs = data.data
                msgs.forEach((m,i) => {
                    m.read = i >= this.state.conversation.partner_state.unread
                })
                this.setState({messages :msgs, loading : false, hasMore : data.data.length >= 20 , reminders : data.reminders  ,ptexts : data.ptexts , preplies: data.preplies,approvedRequests : approvedRequests.concat(data.requests) })
                this.markAllRead(convId,chatToken)
                const objDiv = document.getElementById("MsgContainer")
                objDiv.scrollTop = objDiv.scrollHeight
                objDiv.scrollIntoView({ behavior: 'auto', block: 'end' });
            }
            else{
                this.setState({messages: [], loading : false})
            }
        } catch (error) {
            this.setState({messages: [], loading : false})
        }
  }

  moveToInbox(){
    try {
        this.props.conversationAction('unarchive',this.state.conversationID)
        swal(`This conversation has been moved to inbox. Please contiune from inbox`)
    } catch (error) {
        
    }
  }

  scrollTo(id){
      const div = document.getElementById(`M_${id}`)
      if(div){
        div.scrollIntoView({behavior: 'smooth'})
      }else{
          console.log(id,'not found')
      }
      
  }

  handleTyping = async () => {
        try {
            await chatAxios.post(`/typing`,{  token : this.state.conversation.conversation_token})
        } catch (error) {
            console.log(error)
        }
  } 

  handleInputThrottled = _.throttle(this.handleTyping, 5000)

  loadMoreMessages = async() =>{
    try {
        const convId =  this.state.conversationID
        const chatToken = this.state.conversation.conversation_token
        this.setState({ partnerOnline : false})
        const {data} = await chatAxios.get(`/conversations/${convId}/messages`,{params : { 
                token : chatToken,
                s : this.state.messages[this.state.messages.length - 1].id
        }})
        if(data && data.result) {
            const allMsgs = this.state.messages.concat(data.data)
            allMsgs.forEach((m,i) => {
                m.read = i >= this.state.conversation.partner_state.unread
            })
            this.setState({messages : allMsgs, hasMore : data.data.length >= 20 })
        }else{
            this.setState({ hasMore : false })
        }
    } catch (error) {
        this.setState({ hasMore : false })
    }
  }
  requestAction = async(index,action,linked = false) => {

    

    if(action === 'cancel') {
        const confirm = await swal({
            title: "Cancel Request",
            text: "Are you sure do you want to cancel this request?",
            icon: "warning",
            buttons: ['No', 'Yes Cancel'],
            dangerMode: true
          });
        if(!confirm) {
            return
        }
    }
    if(action === 'add') {
        this.props.history.push(`/${this.props.profileId}/edit-profile`)
    }
    const messages = this.state.messages
    try {
        if(messages[index] !== undefined) {
            messages[index].loading = true
            this.setState({
                messages : messages
            })
            const messageId = linked ? linked.id : messages[index].id
            const {data} = await chatAxios.post(`/conversations/${this.state.conversationID}/messages/${messageId}`,{ 
                token : this.state.conversation.conversation_token,
                action : action
            })
            if(data && data.result) {
                messages[index].loading = false
                if(!linked){
                    if(action !== 'reminder'){
                        messages[index].action = action
                    }else{
                        messages[index].reminders = messages[index].reminders + 1
                    }
                }else{
                    messages[index].linked.action = action
                }

                this.setState({
                    messages : messages
                })
                if(this.state.conversation.archived.includes(this.props.profileId)){
                    //this.moveToInbox()
                }
            }
            else{
                messages[index].loading = false
                this.setState({ messages : messages})
            }
        }
    } catch (error) {
        messages[index].loading = false
        this.setState({ messages : messages})
    }
  }


  mobileDropDown(action) {
        if(action === 'request'){
            this.props.openModal('MobileChat',{
                type : action,
                list :  requests,
                action : this.sendRequest
            })
        }
        else if(action === 'ptext'){
            this.props.openModal('MobileChat',{
                    type : action,
                    list :  this.state.ptexts,
                    action : this.sendPtext
            })
        }
  }

  async conversationAction(action) {
        try {
            this.setState({loading : true})
            const {data} = await chatAxios.post(`/conversations/${this.state.conversationID}/${action}`,{ token : this.state.conversation.conversation_token})
            this.setState({loading : false})
            if(data && data.result) {
                this.props.conversationAction(action,this.state.conversationID)
            }
        } catch (error) {
            this.setState({loading : false})
        }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.conversationID !== this.state.conversationID){
        this.setState({
            conversationID : nextProps.conversationID,
            conversation : nextProps.conversation,
            approvedRequests : nextProps.conversation.partner.photo !== null ? ['add-photo'] : []
        })
        this.loadMessages(nextProps.conversationID,nextProps.conversation.conversation_token)
    }
}
  
  render() {
    const conversation = this.state.conversation
    const messages = this.state.messages
    const profileId = this.props.profileId
    const profile = this.props.profile
    return (
        <div className="chatbox-content-container">
            <div className="chatbox-header">
            <Link to={`/${profileId}/matches/${conversation.partner.id}`} className="ui avatar image"><img src={conversation.partner.photo || `${ASSETS_CDN}/img/${conversation.partner.gender}.png` }  alt={conversation.partner.name}/></Link>
            <Link to={`/${profileId}/matches/${conversation.partner.id}`} className="content">
                <div className="name">{conversation.partner.name}

                            <Popup
                            trigger={
                                <i className={`dot md d-inline-block align-middle ml-05 ${this.state.partnerOnline ? 'dot-green' : 'dot-gray'}`}></i>
                            }
                            inverted
                            content={this.state.partnerOnline ? 'Online' : 'Offline'}
                            size='mini'
                            position='top right'
                            />

                    

                    { conversation.mute.includes(profileId) && (
                        <i className="icon-md d-inline-block i-volume-mute align-middle ml-05 opacity-5"></i>
                    ) }
                    </div>
                <div className="description">
                <span className="font-13 mem-id">ID: {conversation.partner.did}</span>
                <span className="font-10 text-gray mem-act"><span>Last Activity: </span>{conversation.partner.last_accessed_ago}</span>
                </div>
            </Link>
            <div className="right-menu">
                <ul>
                        {conversation.partner.pending_actions > 0 && (
                            <li className="action-btn">
                            <Button 
                                className="fluid ui button btn-link text-blue font-weight-bold p-0 font-14"
                                onClick={() => {
                                    this.props.openModal('PendingActionModal',{
                                        token :  this.state.conversation.conversation_token,
                                        partner : conversation.partner,
                                        convId : this.state.conversationID,
                                        profileId: profileId,
                                        customClass : 'modal-xs'
                                    })
                                }}
                            >
                                <i className="i-bell-blue dot d-inline-block mr-1 align-middle"></i><span>Pending actions</span>
                            </Button>
                            </li>
                        )}
                        <li className="view-btn">
                        <Link to={`/${profileId}/matches/${conversation.partner.id}`} target="_blank" className="text-blue font-weight-bold p-0 font-14">View profile</Link>
                        </li>
                        <li>
                        <Dropdown inline 
                            text={null} 
                            icon={null} 
                            trigger={<i className="icon-lg i-more-vertical d-block"></i>}
                        >
                            <Dropdown.Menu>
                                {conversation.archived.includes(profileId) ? (
                                    <Dropdown.Item text='Unarchive' onClick={() => this.conversationAction('unarchive') } />
                                ): (
                                    <Dropdown.Item text='Archive' onClick={() => this.conversationAction('archive') } />
                                )}
                                {conversation.blocked.includes(profileId)  ? (
                                    <Dropdown.Item text='Unblock' onClick={() => this.conversationAction('unblock')} />
                                ) : (
                                    <Dropdown.Item text='Block' onClick={() => this.conversationAction('block')} />
                                )}
                                 
                                 {conversation.mute.includes(profileId) ? (
                                    <Dropdown.Item text='Unmute' onClick={() => this.conversationAction('unmute') } />
                                ): (
                                    <Dropdown.Item text='Mute' onClick={() => this.conversationAction('mute') } />
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                        </li>
                        <li className="chatbox-close">
                            <button className="ui button btn-link" onClick={() => {
                                this.props.closeChat()
                            }}><i className="i-close"></i></button>
                        </li>
                </ul>
            </div>
            </div>
            <div className="chatbox-body">
                <Dimmer active={this.state.loading} inverted><Loader inverted content='Loading Messages' /></Dimmer>
                {Object.keys(this.state.reminders).length > 0 && (
                    <Reminder
                        done={() => {
                            const keys = Object.keys(this.state.reminders)
                            const rm = this.state.reminders
                            delete rm[keys[0]]
                            this.setState({reminders : rm})
                        }}
                        data={this.state.reminders[Object.keys(this.state.reminders)[0]]}
                        token={this.state.conversation.conversation_token}
                        profileId={profileId}
                        history={this.props.history}
                        convId={this.state.conversationID}
                    />
                )}
                

                <div className="msg-container">
                <div id="MsgContainer" className="ui list" style={{
                     display: 'flex',
                     flexDirection: 'column-reverse',
                }}>
                <InfiniteScroll
                    dataLength={messages.length}
                    next={this.loadMoreMessages}
                    style={{ display: 'flex', flexDirection: 'column-reverse' }}
                    inverse={true}
                    hasMore={this.state.hasMore}
                    loader={<div className="item center">
                    <div className="font-14 text-gray d-flex item-center lh-lg">
                        <span className="ui basic loading button btn-link mr-2 p-0 d-inline-block no-shadow"></span>Loading more messages</div>
                    </div>}
                    scrollableTarget="MsgContainer"
                >
                
                { this.state.typing  && (<div className="item left">
                    <div className="loader-box">
                        <div className="loader" id="loader-4">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
                )}
                 { messages.length > 0  && messages.filter(m => m.from !== profileId).length === 0 && (
                <div class="item center">
                <div class="bg-blue-light radius-4 p-2 w-100 border-blue-light box-shadow">
                    <div class="text-blue font-14 lh-md">Would like to get immediate response from the matches? <Link to={`/${profileId}/matches/${conversation.partner.id}`} class="font-weight-bold text-blue">View Contact Number</Link> and call now.</div>
                </div>
                </div>
                )}
                { this.state.sending !== null && (<div className="item right">
                <div className="msg-card sending">
                    <div className="cmt-box">
                        <div className="description">
                            <div className="content">
                            <div className="font-15 text-black lh-lg">{this.state.sending}</div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>)}
                {messages.length > 0 && messages[0].from !== profileId && messages[0].type === 'ptext' && this.state.preplies &&  (

                    <div className="item right">
                        <div className="response-group">
                            <div className="title">Select a response</div>
                            { Object.keys(this.state.preplies).map(key => 
                                    <button className="res-bubble" onClick={() => {
                                        this.sendPreply(key,this.state.preplies[key].value, messages[0].id)
                                    }}>{this.state.preplies[key].key}</button>
                            )}
                        </div>
                    </div>

                )}

                {messages.length > 0 && (
                    messages.map((message,i) => {
                        return (<Message
                            key={message.id}
                            index={i}
                            tz={this.props.tz}
                            profileId = {profileId}
                            scrollTo={this.scrollTo}
                            read = { message.read }
                            requestAction = {this.requestAction}
                            partner={conversation.partner}
                            upgrade_link={this.props.profile.upgrade_link}
                            data = {message}
                        />)
                    })
                )}
                
               

                </InfiniteScroll>
                </div>
                </div>
            </div>
            {conversation.blocked.length === 0 && (
                    <div className="chatbox-footer">
                    {this.state.attachments.length > 0 && (
                        <div className="file-upload-box">
                            {this.state.attachments.map((a,i) => {
                                let img = a.data
                                if(xls.includes(a.type)){
                                    img = `${ASSETS_CDN}/img/xls.png`
                                }else if(doc.includes(a.type)){
                                    img = `${ASSETS_CDN}/img/doc.png`
                                }else if (pdf.includes(a.type)){
                                    img = `${ASSETS_CDN}/img/pdf.png`
                                }
                                
                                return(
                                    <div key={i} className="thumb">
                                        <Dimmer active={this.state.sending !== null } inverted><Loader content={null} /></Dimmer>
                                        {!this.state.sending && (
                                            <button
                                            className="circular ui mini icon bg-white button"
                                            onClick={e => {
                                                this.removeAttachment(i)
                                            }}
                                            >
                                            <i className="close icon text-black"></i>
                                            </button>
                                        )}
                                        <img src={img} alt={a.name}/>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                <div className="chatbox-controls">
                    <div className="action-btn-group">
                        
                    <Dropdown pointing text={null} icon={null} trigger={<i className="i-plus-square"></i>} className="icon button add-square mobi" disabled={this.state.loading} >
                            <Dropdown.Menu>
                                <Dropdown.Item text={null} >
                                <div className="file-input ui button btn-link font-weight-semibold p-0 text-dark">
                                    <label htmlFor="file-attach-mobi">Browse files</label>
                                    <input 
                                        type="file"  
                                        className="inputfile"
                                        multiple
                                        id="file-attach-mobi"
                                        disabled={this.state.loading} 
                                        onChange={e => this.handleUploadFile(e)}
                                        accept=".xlsx,.xls,image/*,.doc,.docx,.pdf"
                                     />
                                </div>
                                </Dropdown.Item>
                                <Dropdown.Item text='Requests' disabled={this.state.loading} onClick={() => this.mobileDropDown('request')} />
                                <Dropdown.Item text='Default messages' disabled={this.state.loading} onClick={() => this.mobileDropDown('ptext')} />
                            </Dropdown.Menu>
                        </Dropdown>
    
                        
                        <div className={ (this.state.loading) ? `ui button file-input attach-btn p-0 btn-link disabled`:`ui button file-input attach-btn p-0 btn-link`}>
                            <label htmlFor="file-attach-desktop" className="ui button btn-link font-weight-semibold">
                            <Popup
                            trigger={
                                <i className="i-clip"></i>
                            }
                            inverted
                            content='Send files (Maximum 5 files at a time)'
                            position='top'
                            />
                            </label>
                            <input 
                                type="file"
                                className="inputfile" 
                                disabled={this.state.loading} 
                                onChange={e => this.handleUploadFile(e)}
                                multiple
                                id="file-attach-desktop"
                                accept=".xlsx,.xls,image/*,.doc, .docx,.pdf"
                            />
                        </div>

                        

                        <Dropdown pointing text={null} icon={null} trigger={<Popup
                            trigger={
                                <i className="i-plus-square"></i>  
                            }
                            inverted
                            content='Send requests'
                            position='top'
                            />
                        } className="icon button add-square" disabled={this.state.loading} >
                            <Dropdown.Menu>
                                { Object.keys(requests).map(i => {
                                        if((!this.state.approvedRequests.includes(i))){
                                            return <Dropdown.Item text={requests[i].text} key={i} onClick={() => this.sendRequest(i,requests[i].text)}/>
                                        }
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                        
                        
                        <Dropdown pointing text={null} icon={null} trigger={
                            <Popup
                            trigger={
                                <i className="i-file"></i>
                            }
                            inverted
                            content='Send Predefined text'
                            position='top'
                            />
                        } className="icon button msg-btn" disabled={this.state.loading} >
                            <Dropdown.Menu>
                            {this.state.ptexts && Object.keys(this.state.ptexts).map(i => <Dropdown.Item text={this.state.ptexts[i].key} onClick={() => (profile && profile.package === 'free') ? this.sendPtext(i,this.state.ptexts[i].value) : this.sendPtextPremium(this.state.ptexts[i].value)} key={i} />)}
                            </Dropdown.Menu>
                            </Dropdown>
  
                    </div>
                    <div className="type-container">
                        <div className="char-limit"><span>{this.state.typedString.length || 0 }</span><span>500</span></div>
                        <div className="ui icon input">
                            <TextareaAutosize 
                                autoComplete="off"
                                maxLength="500"
                                minRows={1}
                                maxRows={5}
                                value={this.state.typedString }
                                onChange={(event) => {this.setState({typedString: event.target.value})}}
                                onKeyUp={this.handleInputThrottled}
                                placeholder="Type something..."
                                onKeyPress={(event) => {
                                    if(event.key === 'Enter'){
                                       // this.sendText()
                                    }
                                }}
                                disabled={this.state.loading} 
                            />
                            

                            <button 
                                className="ui icon button"
                                disabled={this.state.loading || (this.state.typedString === '' && this.state.attachments.length === 0)}
                                onClick={() => {
                                    this.sendText()
                                }}
                            >
                            <i className="i-send-blue"></i>
                            </button>
                        </div>
                    </div>
                </div>
                </div>
            )}
        </div>
    );
  }
}

const mapStateToProps = state => ({
  unread: state.user.unread,
  singleUser: state.user.singleProfileData,
  globalSpinner: state.globalSpinner.globalSpinner,
  globalNumber: state.commonData.defaultCountryNumber
});


export default connect(
    mapStateToProps,{
      openModal,
      setUnreadCount
    }
  )(ChatArea);
