import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import {
  getCurrentCountry,
  setDefaultCountryNumber,
  setDefaultCountry
} from "../../redux/actions/common/common-data";
import { countryNumbers, HOMEPAGE, urls } from "../../util/GlobalContants";

class HelpCenterMobilePopup extends Component {
  state = {
    defaultNumber: "+91 80753 24709"
  };
  componentDidMount() {
    this.setState({ profileId: this.props.location.pathname.split("/")[1] });
    if (localStorage.getItem("defaultCountryIp")) {
      this.props.setDefaultCountry(
        JSON.parse(localStorage.getItem("defaultCountryIp"))
      );
    } else {
      this.props.getCurrentCountry();
    }
  }

  setCountry = number => {
    this.setState({ defaultNumber: number });
    this.props.setDefaultCountryNumber(number);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.defaultCountry && nextProps.defaultCountry.data.iso) {
      countryNumbers.map((country, index) => {
        if (country.code === nextProps.defaultCountry.data.iso) {
          this.setCountry(country.number);
        } else {
          this.setCountry(countryNumbers[0].number);
        }
      });
    }
  }
  render() {
    const { helpCenterActive, closeHelpCenterPopup } = this.props;
    return (
      <div
        className={helpCenterActive ? `mobi-contact-lightbox wd-contact-active` : `mobi-contact-lightbox`}
      >
        <button
          className="ui circular icon button wd-menu-close"
          id="mobi-contact-close"
          onClick={closeHelpCenterPopup}
        >
          <i className="i-close icon" />
        </button>
        <div className="font-17 text-black font-weight-bold p-2 pb-0 mt-1">
          Help Center
        </div>
        <div className="menu">
          <div className="ui header font-15 font-weight-bold mb-1 text-black text-capitalize">
            Other countries number
          </div>
          {countryNumbers.map((country, index) => {
            return (
              <a href={`tel:${country.number}`} className="item font-13">
                <i className={`flag-sprit flag-${country.code}`} />{" "}
                {country.name}{" "}
                <span className="float-right">{country.number}</span>
              </a>
            );
          })}

          <div className="ui divider" />
          <a
           href={`https://api.whatsapp.com/send?phone=918075324709&text=Hi%Sameepam%2c%20I%20need%20your%20help,%20my%20id%20is%20TA10607170${
            this.state.profile !== null &&
            this.state.profile !== undefined &&
            this.state.profile.did !== ""
              ? `,%20my%20id%20is%20${this.state.profile.did}`
              : ""
          }`}    
            target="_blank"
            className="ui basic button d-block mr-2 ml-2 mb-2 mt-2"
          >
            <div className="d-flex item-center">
              <i className="icon-lg d-inline-block i-whatsapp mr-1" />
              <div className="o-hidden text-left">
                <div className="font-15 text-dark font-weight-bold">
                  {urls.whatsAppNo}
                </div>
                <span className="d-block text-muted font-12 mt-05">
                  Whatsapp (Message only)
                </span>
              </div>
            </div>
          </a>
          <a
            href={urls.messangerLink}
            target="_blank"
            className="ui basic button d-block mr-2 ml-2 mb-2 mt-1"
          >
            <div className="d-flex item-center">
              <i className="icon-lg d-inline-block i-messenger mr-1" />
              <div className="o-hidden text-left">
                <div className="font-15 text-dark font-weight-bold">
                  {urls.messanger}
                </div>
                <span className="d-block text-muted font-12 mt-05">
                  Messenger
                </span>
              </div>
            </div>
          </a>
          <div className="ui divider" />

          <a
            href={`${HOMEPAGE}/contact-us`}
            target="_blank"
            className="item font-13"
          >
            Contact us
          </a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  defaultCountry: state.commonData.defaultCountryData
});

export default connect(
  mapStateToProps,
  { getCurrentCountry, setDefaultCountryNumber, setDefaultCountry }
)(withRouter(HelpCenterMobilePopup));
