import React, { useEffect } from "react";
import Wrapper from "./wrapper/Wrapper";
import { connect } from "react-redux";
import { route_config } from "../../util/GlobalContants";
import { singleProfile } from "../../redux/actions/user/user";

function CommingSoon(props) {
  const { singleUser, globalSpinner, singleProfile } = props;

  useEffect(() => {
    singleProfile(`profile/${props.match.params.profileId}`);
  }, [props.match.params.profileId, singleProfile]);
  return (
    <Wrapper
      config={
        props.location.pathname === "/myaccount"
          ? route_config.myaccount
          : route_config.message
      }
      isLoading={globalSpinner}
      singleUser={{}}
    >
      <div className="content-body">
        <div className="content-wrapper">
          <div className="wd-rail">
            <div className="wd-middle-content">
              <div className="featured-container">
                <img
                  className="ui image"
                  src="/assets/img/comingsoon.png"
                  alt="comingsoon"
                />
                <div className="header">Coming Soon</div>
                <div className="leads">
                  We are adding extra features for this page. Please stay tuned
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const mapStateToProps = state => {
  return {
    singleUser: state.user.singleProfileData,
    globalSpinner: state.globalSpinner.globalSpinner
  };
};

export default connect(
  mapStateToProps,
  { singleProfile }
)(CommingSoon);
