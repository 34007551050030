import React, { Component } from "react"
import { NavLink } from "react-router-dom"
import moment from "moment"
import { Button, Checkbox } from 'semantic-ui-react'
import swal from "sweetalert"
import { gpAxios } from "../../../../../util/axios"
class Preference extends Component {
    state = {
        showMatches: false,
        activePreference: true
    }

    componentDidMount() {
        this.setState({
            showMatches : this.props.pref.published,
            activePreference : this.props.pref.state === 1
        })
    }

      
    nth(i){
        if(i === 2 )
            return 'nd'
        if(i === 3 )
            return 'rd'
        return 'th'
    }

    hideShowMatches = async() =>{
        try {
            const confirm = await swal({
                title: "Are you sure",
                text: "Are you sure do you want make this change?",
                icon: "warning",
                buttons: ['No','Yes'],
                dangerMode: true
              });
           if(!confirm){
               return
           }
           const current = this.state.showMatches
           await gpAxios.post(`profile/${this.props.profileId}/preferences/${this.props.pref.id}/publish`,{publish: !current})
           this.setState({
                showMatches : !current
           })
        } catch (error) {
            console.log(error)
        }
    }

    toggleStatus = async() =>{
        try {
            const confirm = await swal({
                title: "Are you sure",
                text: "Are you sure do you want make this change?",
                icon: "warning",
                buttons: ['No','Yes'],
                dangerMode: true
              });
           if(!confirm){
               return
           }
           const current = this.state.activePreference
           await gpAxios.post(`profile/${this.props.profileId}/preferences/${this.props.pref.id}/state`,{state: !current})
           this.setState({
            activePreference : !current
           })
        } catch (error) {
            console.log(error)
            
        }
    }

    render() {
        const {i,pref,profileId} = this.props
        return (
            <div className="ui fluid card wd-card pp-item mb-4">
                <div className={ pref.state === 0 ? `head` : `head pb-0`} >
                    <div className="left">
                    <Checkbox
                        readOnly
                        defaultChecked={pref.state === 1}
                        checked={this.state.activePreference}
                        onClick={this.toggleStatus}
                    />
                    <div className="pl-2">
                        <div className="font-20 lh-xl mb-05">
                            <NavLink to={`/${profileId}/partner-preferences/${pref.id}`} className="text-black">
                                {pref.is_default === 1 ? '1st Preference' : pref.name? pref.name:i+1 +this.nth(i+1)+ ` Preference` }
                            </NavLink>
                        </div>
                        {pref.state === 0 && (
                            <span className="ui small label bg-yellow-light text-gold lh-16 px-05 py-0 border-gold-lt radius-2">Draft</span>
                        )}
                        {pref.state === 1 && (
                            <span className="ui small label bg-green-lt text-green2 lh-16 px-05 py-0 border-green-lt radius-2">Active</span>
                        )}
                        {![0,1].includes(pref.state) && (
                            <span className="ui small label bg-pink-light text-pink lh-16 px-05 py-0 border-pink-lt radius-2">
                                Disabled
                            </span>
                        )}
                    </div>
                    </div>
                    <div className="right">
                    <NavLink to={`/${profileId}/partner-preferences/${pref.id}`} className="ui button btn-link font-14 font-weight-normal text-blue p-0 pointer" id="modal3">Edit</NavLink>
                    </div>
                </div>
                {pref.state !== 0 && (
                    <div className="pp-stats">
                    <div className="column">
                    <a href className="d-block">
                        <div className="font-17 text-black">{pref.new}</div>
                        <div className="font-11 text-muted">New Matches(Unread)</div>
                    </a>
                    </div>
                    <div className="column">
                    <a href className="d-block">
                        <div className="font-17 text-black">{pref.matches}</div>
                        <div className="font-11 text-muted">Matches Released</div>
                    </a>
                    </div>
                    <div className="column">
                    <a href className="d-block">
                        <div className="font-17 text-black">{pref.favourite}</div>
                        <div className="font-11 text-muted">Shortlisted</div>
                    </a>
                    </div>
                    <div className="column">
                    <a href className="d-block">
                        <div className="font-17 text-black">{pref.contact_viewed}</div>
                        <div className="font-11 text-muted">Contact View</div>
                    </a>
                    </div>
                    <div className="column">
                    <a href className="d-block">
                        <div className="font-17 text-black">{pref.hide}</div>
                        <div className="font-11 text-muted">Rejected</div>
                    </a>
                    </div>
                    <div className="column">
                    <a href className="d-block">
                        <div className="font-16 text-pink">{pref.filterout}</div>
                        <div className="font-11 text-muted">Filtered Out</div>
                    </a>
                    </div>
                </div>
                )}
                <div className="pp-history border-top">
                    <div className="column">
                    <div className="font-11 text-muted">Created by:</div>
                    <div className="font-14 text-dark">{pref.created_by === 0 ? 'Customer' : 'Admin'} on {moment(pref.created_at).format('DD-MMM-YYYY [at] HH:mm')}</div>
                    </div>
                    <div className="column">
                    <div className="font-11 text-muted">Last modified by:</div>
                    <div className="font-14 text-dark">{(pref.last_updated_by === 0 || pref.last_updated_by === null ) ? 'Customer' : 'Admin'} on {moment(pref.updated_at||pref.created_at).format('DD-MMM-YYYY [at] HH:mm')}</div>
                    </div>
                    {pref.latest_release !== null && (
                        <div className="column">
                        <div className="font-11 text-muted">Last released:</div>
                        <div className="font-14 text-dark">{pref.latest_release.matches} Matches on {moment(pref.latest_release.created_at).format('DD-MMM-YYYY [at] HH:mm')}</div>
                        </div>
                    )}
                    
                </div>
                {pref.state === 1 && (
                    <div className="pp-footer">
                    <div className="left">
                        <NavLink to={`/${profileId}/matches/all?pref=${pref.id}`} className="ui small button btn-blue radius-2 ripple mr-0">View Matches</NavLink>
                    </div>
                    <div className="right">
                            <Checkbox 
                                toggle 
                                fitted={false}
                                readOnly
                                defaultChecked={pref.published === 1}
                                onClick={this.hideShowMatches}
                                checked={!this.state.showMatches}
                                label='Hide Matches'
                            />
                    </div>
                    </div>
                )}
                 {pref.state === 0 && (
                        <div className="pp-footer">
                        <div className="left">
                            <Button 
                                className="btn-green radius-2"
                                onClick={this.toggleStatus}
                            >
                            Activate
                            </Button>
                        </div>
                        </div>
                 )}
                </div>)
    }
}
export default Preference