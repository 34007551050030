export const VERIFY_NUMBER = "VERIFY_NUMBER";
export const VERIFY_SPINNER = "VERIFY_SPINNER";
export const RESEND_OTP = "RESEND_OTP";
export const CHANGE_NUMBER = "CHANGE_NUMBER";
export const CHANGE_NUMBER_MODAL = "CHANGE_NUMBER_MODAL";
export const CHANGE_NUMBER_SPINNER = "CHANGE_NUMBER_SPINNER";

export const VERIFY = {
  VALIDATE_TOKEN: "VALIDATE_TOKEN",
  VALIDATE_TOKEN_SPINNER: "VALIDATE_TOKEN_SPINNER",
  VALID_NUMBER: "VALID_NUMBER",
  RESET_TIMER: "RESET_TIMER",
  CLOSE_MODAL: "CLOSE_MODAL"
};

export const USER = {
  SET_CURRENT_USER: "SET_CURRENT_USER",
  SET_AUTH_TOKEN: "SET_AUTH_TOKEN",
  PROFILE_BASIC_DATA_SPINNER: "PROFILE_BASIC_DATA_SPINNER",
  PROFILE_BASIC_DATA: "PROFILE_BASIC_DATA",
  PROFILE_ID: "PROFILE_ID",
  PROFILE_CARDS: "PROFILE_CARDS",
  PHONE_NO_VALID: "PHONE_NO_VALID",
  START_TIMER: "START_TIMER",
  SINGLE_PROFILE: "SINGLE_PROFILE",
  UPDATE_PROFILE_SPINNER: "UPDATE_PROFILE_SPINNER",
  PROFILE_PIC_SPINNER: "PROFILE_PIC_SPINNER",
  CROPPED_PROFILE_PIC_URL: "CROPPED_PROFILE_PIC_URL",
  PROFILE_NOT_FOUND: "PROFILE_NOT_FOUND",
  UNREAD_MESSAGES: "UNREAD_MESSAGES"
};

export const COMMON = {
  SET_COMMON_DATA: "SET_COMMON_DATA",
  COMMON_DATA_SPINNER: "COMMON_DATA_SPINNER",
  CURRENT_COUNTRY: "CURRENT_COUNTRY",
  DEFAULT_NUMBER: "DEFAULT_NUMBER",
  NUMBER_SPINNER: "NUMBER_SPINNER",
  CURRENCY_DATA: "CURRENCY_DATA"
};

export const BASIC_PROFILE_DATA = {
  SAVE_BASIC_PROFILE_DATA_SPINNER: "SAVE_BASIC_PROFILE_DATA_SPINNER",
  SAVE_BASIC_PROFILE_DATA: "SAVE_BASIC_PROFILE_DATA"
};

export const EDUCATION_CAREER = {
  SAVE_EDUCATION_CAREER_SPINNER: "SAVE_EDUCATION_CAREER_SPINNER",
  SAVE_EDUCATION_CAREER: "SAVE_EDUCATION_CAREER"
};

export const LIFE_STYLE = {
  SAVE_LIFE_STYLE_SPINNER: "SAVE_LIFE_STYLE_SPINNER",
  SAVE_LIFE_STYLE: "SAVE_LIFE_STYLE"
};

export const CLIENT_PROFILES = {
  GET_CLIENT_PROFILES: "GET_CLIENT_PROFILES"
};

export const ONBOARDING = {
  GENERAL_INFORMATION: {
    UPDATE: "UPDATE",
    SPINNER: "SPINNER"
  },
  BASIC_INFORMATION: {
    UPDATE: "UPDATE",
    SPINNER: "SPINNER"
  }
};

export const CHECK_LOGIN = {
  CHECK_LOGIN: "CHECK_LOGIN",
  UPDATE_STATUS: "UPDATE_STATUS"
};

export const MATCHES = {
  SPINNER: "SPINNER",
  PROFILES: "MATCHES_PROFILE",
  DETAILS: "DETAILS",
  SHORTLIST: "SHORTLIST",
  HIDE: "HIDE",
  SINGLE_MATCH_NOT_FOUND: "SINGLE_MATCH_NOT_FOUND",
  MATCH_SINGLE_TEMP_UNAVAILABLE: "MATCH_SINGLE_TEMP_UNAVAILABLE"
};

export const LANGUAGE = {
  SWITCH_LANGUAGE: "SWITCH_LANGUAGE"
};
