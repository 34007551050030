import React from "react";
import LanguageItem from "./LanguageItem";
import languageData from "./language_data";

function LanguageSwitcher({
  switchLanguage,
  mobileView = false,
  current_lang = {},
  closeResponsiveHeader
}) {
  const languageItems = () => {
    return languageData.map((language, index) => (
      <LanguageItem
        key={index}
        language={language}
        switchLang={switchLanguage}
        customClass={index !== languageData.length - 1 && "border-right"}
        current_lang={current_lang}
      />
    ));
  };

  return (
    <div className={`${!mobileView && "m-1"}`}>
      <div
        onClick={mobileView && closeResponsiveHeader }
        className={`${
          mobileView
            ? "ui fluid basic buttons mb-1"
            : "ui two buttons border radius-2"
        }`}
      >
        {languageItems()}
      </div>
    </div>
  );
}

export default LanguageSwitcher;
