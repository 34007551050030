import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import {
  getMatchDetails,
  setShortlist,
  setShortListHide,
  setHideMatchList,
  singleMatchFound
} from "../../../../../redux/actions/matches/matches";
import { getCommonData } from "../../../../../redux/actions/common/common-data";
import swal from "sweetalert";
/**
 * @globalLayoutWrapper
 */
import Wrapper from "../../../../../common/shared-components/wrapper/Wrapper";

/**
 * @globalConfigurations
 */
import {
  route_config,
  overview_keys
} from "../../../../../util/GlobalContants";

/**
 * @childrenComponents
 */
import ProfileCard from "./profile-card/profile_card";
import Overview from "./overview/overview";
import PhoneCard from "./phone_card/phone_card";
import Background from "./background/Background";
import EducationCareerSingleView from "./education-career/EducationCareerSingleView";
import LifeStyleAppearanceSingleVIew from "./life-style-appearance/LifeStyleAppearanceSingleVIew";
import HoroscopeSingleView from "./horoscope/HoroscopeSingleView";
import FamilySingleView from "./family/FamilySingleView";
import AboutSingleView from "./about/AboutSingleView";
import AdditionalSingleView from "./additional/AdditionalSingleView";
import { singleProfile } from "../../../../../redux/actions/user/user";
import {
  openModal,
  closeModal
} from "../../../../modals/redux-state/modalActions";
import HisHerPartner from "./his-partner/HisHerPartner";
import { chatAxios } from "../../../../../util/axios";

class MatchDetails extends Component {
  state = {
    common_data: [],
    match_details: null,
    spinner: false,
    s_list: false,
    hide: false
  };
  componentDidMount() {
    const {
      match: { params },
      singleProfile,

      getCommonData
    } = this.props;
    getCommonData();
    singleProfile(`profile/${params.profileId}`, false, params.partner_id);
  }

  /**
   *
   * @param {@listening for changes} nextProps
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.allCommonData !== this.props.allCommonData) {
      this.setState({ common_data: nextProps.allCommonData });
    }

    if (nextProps.matchDetails !== this.props.matchDetails) {
      this.setState({ match_details: nextProps.matchDetails });

      nextProps.setShortListHide(
        nextProps.matchDetails.favourite === 1 ? true : false
      );
      nextProps.setHideMatchList(
        nextProps.matchDetails.hide === 1 ? true : false
      );
    }

    if (nextProps.matchesSpinner !== this.props.matchesSpinner) {
      this.setState({ spinner: nextProps.matchesSpinner });
    }
  }

  componentWillUnmount() {
    const { singleMatchFound } = this.props;
    singleMatchFound(false);
  }
  async initChat(token) {
    try{
      if(this.state.match_details.conversation_id === null) {
        const confirm = await swal({
          title: "Send Interest",
          text: "Would you like to send your interest to this profile?",
          icon: "warning",
          buttons: ["Cancel", "Send"],
          dangerMode: true
        });
        if(!confirm){
          return
        }

      }

      const {data} = await chatAxios.post(`/conversations`,{ 
        token : token,
        type: 'ptext' ,
        text : 'interested'
      })
      if(data && data.result) {
        if(data.data.conversation_id){
            this.props.history.push(`/${this.props.single_user.id}/messages/#${data.data.conversation_id}`);
        }
      }
    }catch(e){

    }
  }
  render() {
    const {
      globalSpinner,
      match: { params },
      short_list,
      hide,
      single_user,
      openModal,
      closeModal,
      isSingleMatchFound,
      setShortlist,
      isTempNotAvailable
    } = this.props;
    const { match_details, common_data } = this.state;

    return (
      <Wrapper
        config={route_config.matches_single}
        isLoading={globalSpinner}
        singleUser={single_user}
        matchNotFound={isSingleMatchFound}
        matchDetails = {match_details}
        tempNotAvailable={isTempNotAvailable}
        profile_id={single_user && single_user.id}
      >
        {match_details !== null &&
        (match_details.state !== 2 ||
          match_details.client_state !== 1 ||
          match_details.blocked === 1) ? (
          <div className="content-body">
            <div className="content-wrapper">
              <div className="wd-rail">
                <div className="wd-middle-content">
                  <div className="ui relaxed divided items">
                    <div className="ui vertical segment wd-section wd-error bb-0">
                      <div className="text-center">
                        <img
                          className="ui fluid image d-block m-auto"
                          src="contents/profile-card.png"
                          alt=""
                        />
                        <h1 className>
                          This profile is temporarily not available
                        </h1>
                        <p>
                          Please check at a later time OR we can notify you once
                          it becomes available by clicking{" "}
                          <a href className="text-blue">
                            HERE
                          </a>
                          .
                        </p>
                        <a
                          href="index.php"
                          className="ui button btn-blue font-12 ripple mb-4"
                        >
                          GO TO HOME
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="content-body">
            <div className="content-wrapper">
              <div className="wd-rail">
                <div className="wd-middle-content">
                  <div className="matches-middle-col">
                    <div className="match-tiny-stats mb-1">
                      <div className="font-17 text-black font-weight-semibold">
                        Match View
                      </div>
                    </div>

                    {match_details && common_data && (
                      <Fragment>
                        <ProfileCard
                          match_details={match_details && match_details}
                          common_data={common_data}
                          globalSpinner={globalSpinner}
                          profileId={params.profileId}
                          short_list={short_list}
                          hide={hide}
                          upgradeWarning={match_details.upgrade_warning}
                          photoWarning={match_details.photo_upload_warning}
                          single_user={single_user}
                        />
                        {!short_list && (
                          <Fragment>
                            <div className="mobi-action-buttons mb-1">
                              {!hide && (
                                <button
                                  type="button"
                                  onClick={() =>
                                    setShortlist(
                                      `/profile/${single_user.id}/matches/${match_details.id}/shortlist`,
                                      { set: 1 },
                                      "shortlist"
                                    )
                                  }
                                  className="ui small button bg-white border-green box-shadow font-weight-normal text-black radius-2 ripple mr-05 p-1"
                                >
                                  Shortlist
                                </button>
                              )}

                              {hide ? (
                                <span>
                                  This profile has been hide.{" "}
                                  <button
                                    onClick={() =>
                                      setShortlist(
                                        `/profile/${single_user.id}/matches/${match_details.id}/hide`,
                                        { set: 0 },
                                        "hide"
                                      )
                                    }
                                    type="button"
                                    className="ui button btn-link text-blue p-0 font-weight-normal"
                                  >
                                    Change your mind?
                                  </button>
                                </span>
                              ) : (
                                <button
                                  type="button"
                                  onClick={() =>
                                    setShortlist(
                                      `/profile/${single_user.id}/matches/${match_details.id}/hide`,
                                      { set: 1 },
                                      "hide"
                                    )
                                  }
                                  className="ui small button bg-white border box-shadow font-weight-normal text-black radius-2 ripple mr-05 p-1"
                                >
                                  <i className="icon icon-sm i-times-yellow d-inline-block align-middle mr-05"></i>
                                  <span className="align-middle">Hide</span>
                                </button>
                              )}
                              <Button className="small bg-white border box-shadow font-weight-normal text-black radius-2 ripple p-1 float-right mr-0"
                              onClick={() =>{
                                this.initChat(match_details.chat_token)
                                // openModal("ChatModal", { 
                                //     customClass: "modal-md",
                                //     profileId : single_user.id ,
                                //     token : match_details.chat_token,
                                //     upgrade_link: single_user.upgrade_link,
                                //     subscription : single_user.package === 'free' || (single_user.package.group !== undefined && single_user.package.group === 1880)
                                //   })
                              }}
                              >
                              <i class="icon icon-sm i-message-gray d-inline-block align-middle mr-05"></i>
                                Chat 
                              </Button>
                            </div>
                          </Fragment>
                        )}

                        {short_list && (
                          <div className="mobi-action-buttons mb-1">
                            <button className="ui small button bg-white border-green box-shadow font-weight-normal text-black radius-2 ripple mr-1 p-1">
                              <i className="icon icon-sm i-check-green d-inline-block align-middle mr-05"></i>
                              <span className="align-middle">Shortlisted</span>
                            </button>
                            <button
                              type="button"
                              onClick={() =>
                                setShortlist(
                                  `/profile/${single_user.id}/matches/${match_details.id}/shortlist`,
                                  { set: 0 },
                                  "shortlist"
                                )
                              }
                              className="ui button btn-link text-blue p-0 font-weight-normal"
                            >
                              Remove Shortlist?
                            </button>
                            <Button className="small bg-white border box-shadow font-weight-normal text-black radius-2 ripple p-1 float-right mr-0"
                              onClick={() =>{
                                this.initChat(match_details.chat_token)
                                // openModal("ChatModal", { 
                                //     customClass: "modal-md",
                                //     profileId : single_user.id ,
                                //     token : match_details.chat_token,
                                //     upgrade_link: single_user.upgrade_link,
                                //     subscription : single_user.package === 'free' || (single_user.package.group !== undefined && single_user.package.group === 1880)
                                //   })
                              }}
                              >
                              <i class="icon icon-sm i-message-gray d-inline-block align-middle mr-05"></i>
                                Chat
                              </Button>
                          </div>
                        )}
                      </Fragment>
                    )}
                    {match_details && common_data && (
                      <div className="ui cards mt-0">
                        <Overview
                          match_details={match_details && match_details}
                          common_data={common_data}
                          globalSpinner={globalSpinner}
                          profileId={params.profileId}
                          short_list={short_list}
                          hide={hide}
                        />

                        <Background
                          match_details={match_details}
                          common_data={common_data}
                        />
                        <EducationCareerSingleView
                          match_details={match_details}
                          common_data={common_data}
                          profileId={params.profileId}
                        />
                        <LifeStyleAppearanceSingleVIew
                          match_details={match_details}
                          common_data={common_data}
                        />
                        <HoroscopeSingleView
                          match_details={match_details}
                          common_data={common_data}
                        />
                        <FamilySingleView
                          match_details={match_details}
                          common_data={common_data}
                        />
                        <AboutSingleView
                          match_details={match_details}
                          common_data={common_data}
                        />
                        {match_details && common_data && common_data.data && (
                          <HisHerPartner
                            single_user={single_user}
                            match_details={match_details}
                            common_data={common_data}
                          />
                        )}

                        <AdditionalSingleView
                          match_details={match_details}
                          common_data={common_data}
                        />
                        {single_user && (
                          <PhoneCard
                            single_user={single_user}
                            match_details={match_details}
                            common_data={common_data}
                            openModal={openModal}
                            closeModal={closeModal}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Wrapper>
    );
  }
}

/**
 * @param {@reading from store} state
 */
const mapStateToProps = state => ({
  matchDetails: state.matches.matchDetails,
  short_list: state.matches.short_list,
  isSingleMatchFound: state.matches.isSingleMatchFound,
  isTempNotAvailable: state.matches.isTempNotAvailable,
  hide: state.matches.hide,
  matchesSpinner: state.matches.matchesSpinner,
  allCommonData: state.commonData.allCommonData,
  globalSpinner: state.globalSpinner.globalSpinner,
  single_user: state.user.singleProfileData
});

export default connect(mapStateToProps, {
  getMatchDetails,
  getCommonData,
  setShortListHide,
  setHideMatchList,
  singleProfile,
  openModal,
  closeModal,
  singleMatchFound,
  setShortlist
})(MatchDetails);
