export const numberRange = (
  from,
  to,
  sufix = null,
  prefix = null,
  extra = null
) => {
  let range = extra ? [extra] : [];
  for (let i = from; i <= to; i++) {
    let s = sufix || "";
    let p = prefix || "";
    let t = i <= 9 ? "0" + i.toString() : i.toString();
    range.push({
      text: p + t + s,
      value: i,
      key: i
    });
  }
  return range;
};
