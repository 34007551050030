import React from 'react'
import Lottie from "react-lottie";
import searching_animation from "../../../../../../util/lottie/searching.json";

export default function Searching() {

    const searchingOptions = {
        loop: true,
        autoplay: true,
        animationData: searching_animation,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };

    return (
        <div className="ui fluid card wd-card m-0 mb-2">
          <div className="p-2 m-auto text-center">
            <Lottie options={searchingOptions} height={250} width={250} />
          </div>
          <div className="text-center mb-4 px-2">
            <div className="font-20 text-black mb-1">
              Searching for your match
            </div>
            <div className="font-16 text-gray lh-lg">
              Yes you are almost there! Keep hold your excitement for some time.
              We are looking at your preference and matches will be shown in few
              minutes or little longer sometimes.
            </div>
          </div>
        </div>
    )
}