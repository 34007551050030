var countryArray = [];
var years = [];
var minSalary = [];
var maxSalary = [];
var preferedCounties = [];

export const maritalStateForPreferenceMatch = [
  "single",
  "divorced",
  "widow",
  "widower",
  "awaiting-divorce",
];

export const maritalStatus = [{
    key: "Single",
    text: "Single",
    value: "single"
  },
  {
  key: "Divorced",
  text: "Divorced",
  value: "divorced"
 },
  {
    key: "Widow",
    text: "Widow",
    value: "widow"
  },
  {
    key: "Widower",
    text: "Widower",
    value: "widower"
  },

  {
    key: "Awaiting Divorce",
    text: "Awaiting Divorce",
    value: "awaiting-divorce"
  },

];

export const cdosham = [{
    text: "Doesn't Matter",
    value: "*",
    parent: "field pr-0 mb-1",
    key: "*"
  },

  {
    text: "Yes Chevvai",
    value: 1,
    parent: "field pr-0 mb-1",
    key: 1
  },
  {
    text: " No Dhosham",
    value: 0,
    parent: "field pr-0 mb-1",
    key: 0
  }
];

export const kids = [{
    key: 0,
    text: "No Kids",
    value: 0
  },
  {
    key: 1,
    text: "With Kids",
    value: 1
  },
  {
    key: 2,
    text: "Doesn't matter",
    value: "*"
  }
];

export const kidsStatus = [{
    key: 0,
    text: "Not Living with Partner",
    value: 0
  },
  {
    key: 1,
    text: "Living with Partner",
    value: 1
  },
  {
    key: 2,
    text: "Doesn't matter",
    value: "*"
  }
];

export const skin_tone = [{
    key: "moderate-fair",
    text: "Moderate Fair",
    value: "moderate-fair"
  },
  {
    key: "fair",
    text: "Fair",
    value: "fair"
  },
  {
    key: "medium",
    text: "Medium",
    value: "medium"
  },
  {
    key: "dark",
    text: "Dark",
    value: "dark"
  }
];
export const body_type = [{
    key: "athletic",
    text: "Athletic",
    value: "athletic"
  },
  {
    key: "slim",
    text: "Slim",
    value: "slim"
  },
  {
    key: "average",
    text: "Average",
    value: "average"
  },
  {
    key: "heavy",
    text: "Heavy",
    value: "heavy"
  }
];

export const haveChildren = [{
    key: 0,
    text: "No",
    value: 0
  },
  {
    key: 1,
    text: "Yes - Living together",
    value: 1
  },
  {
    key: 2,
    text: "Yes - Not living together",
    value: 2
  }
];

export const drinkingHabbit = [{
    text: "Not Specified ",
    value: 3,
    parent: "field pr-0 mt-xs-0",
    key: 3
  },
  {
    text: "No",
    value: 0,
    parent: "field pr-0  mt-xs-0",
    key: 0
  },
  {
    text: "Occasionally",
    value: 2,
    parent: "field pr-0 mt-xs-0",
    key: 2
  },
  {
    text: "Yes",
    value: 1,
    parent: "field pr-0 mt-xs-0",
    key: 1
  }
];

export const smokingPref = [{
    text: "Doesn't Matter",
    parent: "field",
    value: "*",
    key: 1
  },
  {
    text: "Never smokes",
    parent: "field pr-0",
    value: "0",
    key: 2
  }
];

export const drikingPref = [{
    text: "Doesn't Matter",
    parent: "field",
    value: "*",
    key: 1
  },
  {
    text: "Never drinks",
    parent: "field pr-0",
    value: "0",
    key: 0
  }
];


export const filteroutOptions = [{
  text: "Anyone",
  parent: "column p-xs-0 mb-xs-1",
  value: "0",
  key: 1
},
{
  text: "Only members who meet my criteria.",
  parent: "column p-xs-0",
  value: "1",
  key: 0
}
];

let minutes = [];
for (var min = 0; min <= 59; min++) {
  minutes.push({
    text: `${min} Mins`,
    value: min,
    key: min
  });
}

export const birthTime = {
  hours: [{
      text: "00 Hours",
      value: 0,
      key: 0
    },
    {
      text: "01 Hours",
      value: 1,
      key: 1
    },
    {
      text: "02 Hours",
      value: 2,
      key: 2
    },
    {
      text: "03 Hours",
      value: 3,
      key: 3
    },
    {
      text: "04 Hours",
      value: 4,
      key: 4
    },
    {
      text: "05 Hours",
      value: 5,
      key: 5
    },
    {
      text: "06 Hours",
      value: 6,
      key: 6
    },
    {
      text: "07 Hours",
      value: 7,
      key: 7
    },
    {
      text: "08 Hours",
      value: 8,
      key: 8
    },
    {
      text: "09 Hours",
      value: 9,
      key: 9
    },
    {
      text: "10 Hours",
      value: 10,
      key: 10
    },
    {
      text: "11 Hours",
      value: 11,
      key: 11
    },
    {
      text: "12 Hours",
      value: 12,
      key: 12
    }
  ],
  minutes: minutes,
  dayOrNight: [{
      text: "AM",
      value: "am",
      key: "am"
    },
    {
      text: "PM",
      value: "pm",
      key: "pm"
    }
  ]
};

export const siblings = [{
    key: 0,
    text: "0",
    value: 0
  },
  {
    key: 1,
    text: "1",
    value: 1
  },
  {
    key: 2,
    text: "2",
    value: 2
  },
  {
    key: 3,
    text: "3",
    value: 3
  },
  {
    key: 4,
    text: "4",
    value: 4
  },
  {
    key: 5,
    text: "5",
    value: 5
  }
];

export const paathams = [{
    text: "Not Specified",
    value: 0,
    key: 0
  },
  {
    text: "1m Paatham",
    value: 1,
    key: 1
  },
  {
    text: "2m Paatham",
    value: 2,
    key: 2
  },
  {
    text: "3m Paatham",
    value: 3,
    key: 3
  },
  {
    text: "4m Paatham",
    value: 4,
    key: 4
  }
];

export const chevvaiDoshamLakna = [{
    text: "Nill",
    value: 1,
    key: 1
  },
  {
    text: "In 2 - 4 - 12",
    value: 2,
    key: 2
  },
  {
    text: "In 1 - 7 - 8",
    value: 3,
    key: 3
  }
];

export const chhevaiDoshamHoroscope = [{
    text: "Yes",
    value: 1,
    parent: "field"
  },
  {
    text: "No",
    value: 0,
    parent: "field pr-0"
  }
];

export const chevvaiDosham = [{
    text: "Yes",
    value: 1,
    key: 1,
    parent: "field pr-0"
  },
  {
    text: "No",
    value: 0,
    key: 0,
    parent: "field pr-0"
  },
  {
    text: "Not Specified",
    value: 2,
    key: 2,
    parent: "field pr-0"
  }
];

export const hisHeight = [{
    key: 122,
    text: "4ft 0in - 122cm",
    value: 122
  },
  {
    key: 125,
    text: "4ft 1in - 125cm",
    value: 125
  },
  {
    key: 127,
    text: "4ft 2in - 127cm",
    value: 127
  },
  {
    key: 130,
    text: "4ft 3in - 130cm",
    value: 130
  },
  {
    key: 132,
    text: "4ft 4in - 132cm",
    value: 132
  },
  {
    key: 135,
    text: "4ft 5in - 135cm",
    value: 135
  },
  {
    key: 137,
    text: "4ft 6in - 137cm",
    value: 137
  },
  {
    key: 140,
    text: "4ft 7in - 140cm",
    value: 140
  },
  {
    key: 142,
    text: "4ft 8in - 142cm",
    value: 142
  },
  {
    key: 145,
    text: "4ft 9in - 145cm",
    value: 145
  },
  {
    key: 147,
    text: "4ft 10in - 147cm",
    value: 147
  },
  {
    key: 150,
    text: "4ft 11in - 150cm",
    value: 150
  },
  {
    key: 152,
    text: "5ft 0in - 152cm",
    value: 152
  },
  {
    key: 155,
    text: "5ft 1in - 155cm",
    value: 155
  },
  {
    key: 157,
    text: "5ft 2in - 157cm",
    value: 157
  },
  {
    key: 160,
    text: "5ft 3in - 160cm",
    value: 160
  },
  {
    key: 163,
    text: "5ft 4in - 163cm",
    value: 163
  },
  {
    key: 165,
    text: "5ft 5in - 165cm",
    value: 165
  },
  {
    key: 168,
    text: "5ft 6in - 168cm",
    value: 168
  },
  {
    key: 170,
    text: "5ft 7in - 170cm",
    value: 170
  },
  {
    key: 173,
    text: "5ft 8in - 173cm",
    value: 173
  },
  {
    key: 175,
    text: "5ft 9in - 175cm",
    value: 175
  },
  {
    key: 178,
    text: "5ft 10in - 178cm",
    value: 178
  },
  {
    key: 180,
    text: "5ft 11in - 180cm",
    value: 180
  },
  {
    key: 183,
    text: "6ft 0in - 183cm",
    value: 183
  },
  {
    key: 185,
    text: "6ft 1in - 185cm",
    value: 185
  },
  {
    key: 188,
    text: "6ft 2in - 188cm",
    value: 188
  },
  {
    key: 191,
    text: "6ft 3in - 191cm",
    value: 191
  },
  {
    key: 193,
    text: "6ft 4in - 193cm",
    value: 193
  },
  {
    key: 196,
    text: "6ft 5in - 196cm",
    value: 196
  },
  {
    key: 198,
    text: "6ft 6in - 198cm",
    value: 198
  },
  {
    key: 201,
    text: "6ft 7in - 201cm",
    value: 201
  },
  {
    key: 203,
    text: "6ft 8in - 203cm",
    value: 203
  },
  {
    key: 206,
    text: "6ft 9in - 206cm",
    value: 206
  },
  {
    key: 208,
    text: "6ft 10in - 208cm",
    value: 208
  },
  {
    key: 211,
    text: "6ft 11in - 211cm",
    value: 211
  },
  {
    key: 213,
    text: "7ft 0in - 213cm",
    value: 213
  }
];

export const horoscopes = [{
    text: "Important",
    value: 1,
    parent: "field pr-0"
  },
  {
    text: "Not Important",
    value: 0,
    parent: "field pr-0"
  },
  {
    text: "Not Specified",
    value: 3,
    parent: "field pr-0"
  }
];

export const horoscopesPref = [{
    text: "Not Applicable",
    value: "0",
    parent: "field pr-0 mb-1"
  },
  {
    text: "Important",
    value: "1",
    parent: "field pr-0 mb-1"
  },
  {
    text: "Not Important",
    value: "2",
    parent: "field pr-0 mb-1"
  }
];

export const disability = [{
    key: "Normal",
    text: "Normal",
    value: 0
  },
  {
    key: "Physically challenged",
    text: "Physically challenged",
    value: 1
  }
];

export const arrayWithAny = (a, txt = "Any", parent = null) => {
  let newArr = JSON.parse(JSON.stringify(a));
  const any = {
    text: txt,
    value: "*",
    parent: parent
  };
  newArr.unshift(any);
  return newArr;
};

/**
 * @years
 */
const year = new Date();
for (var i = year.getFullYear(); i > 1900; i--) {
  years.push({
    key: i,
    text: i,
    value: i
  });
}

/**
 * age
 */
let age = [];
for (var a = 18; a <= 60; a++) {
  age.push({
    key: a,
    text: a,
    value: a
  });
}

/**
 * @salary
 */

for (var m = 0; m <= 100000; m += 5000) {
  minSalary.push({
    key: m,
    text: m,
    value: m
  });
}

for (var s = 5000; s <= 100000; s += 5000) {
  maxSalary.push({
    key: s,
    text: s,
    value: s
  });
}

preferedCounties = [
  "au",
  "at",
  "be",
  "ca",
  "dk",
  "fr",
  "de",
  "in",
  "it",
  "kw",
  "my",
  "nl",
  "nz",
  "no",
  "om",
  "qa",
  "sg",
  "lk",
  "se",
  "ch",
  "ae",
  "gb",
  "us"
];

export const star = [{
    id: 1343,
    text: "Aswini/அசுவினி",
    key: 1343
  },
  {
    id: 1344,
    text: "Bharani/பரணி",
    key: 1344
  },
  {
    id: 1345,
    text: "Karthigai/கிருத்திகை",
    key: 1345
  },
  {
    id: 1346,
    text: "Rohini/ரோகிணி",
    key: 1346
  },
  {
    id: 1347,
    text: "Mirugasheersham/மிருகசிரீஷம்",
    key: 1347
  },
  {
    id: 1348,
    text: "Thiruvaathira/திருவாதிரை",
    key: 1348
  },
  {
    id: 1349,
    text: "Punarpoosam/புனர்பூசம்",
    key: 1349
  },
  {
    id: 1350,
    text: "Poosam/பூசம்",
    key: 1350
  },
  {
    id: 1351,
    text: "Aayilyam/ஆயில்யம்",
    key: 1351
  },
  {
    id: 1352,
    text: "Makam/மகம்",
    key: 1352
  },
  {
    id: 1353,
    text: "Pooram/பூரம்",
    key: 1353
  },
  {
    id: 1354,
    text: "Uthiram/உத்திரம்",
    key: 1354
  },
  {
    id: 1355,
    text: "Hastham/ஹஸ்தம்",
    key: 1355
  },
  {
    id: 1356,
    text: "Chithirai/சித்திரை",
    key: 1356
  },
  {
    id: 1357,
    text: "Swaathi/சுவாதி",
    key: 1357
  },
  {
    id: 1358,
    text: "Visaakam/விசாகம்",
    key: 1358
  },
  {
    id: 1359,
    text: "Anusham/அனுஷம்",
    key: 1359
  },
  {
    id: 1360,
    text: "Kettai/கேட்டை",
    key: 1360
  },
  {
    id: 1361,
    text: "Moolam/மூலம்",
    key: 1361
  },
  {
    id: 1362,
    text: "Pooraadam/பூராடம்",
    key: 1362
  },
  {
    id: 1364,
    text: "Uthiraadam/உத்திராடம்",
    key: 1364
  },
  {
    id: 1365,
    text: "Thiruvonam/திருவோணம்",
    key: 1365
  },
  {
    id: 1366,
    text: "Avittam/அவிட்டம்",
    key: 1366
  },
  {
    id: 1367,
    text: "Chathayam/சதயம்",
    key: 1367
  },
  {
    id: 1368,
    text: "Poorattathi/பூரட்டாதி",
    key: 1368
  },
  {
    id: 1369,
    text: "Uthirattathi/உத்திரட்டாதி",
    key: 1369
  },
  {
    id: 1370,
    text: "Revathi/ரேவதி",
    key: 1370
  },
  {
    id: 1564,
    text: "Not Specified",
    key: 1564
  }
];

export const raasi = [{
    id: 1371,
    text: "Mesha/மேஷம்/Aries",
    key: 1371
  },
  {
    id: 1372,
    text: "Rishaba/ரிஷபம்/Taurus",
    key: 1372
  },
  {
    id: 1373,
    text: "Mithuna/மிதுனம்/Gemini",
    key: 1373
  },
  {
    id: 1374,
    text: "Kataka/கடகம்/Cancer",
    key: 1374
  },
  {
    id: 1375,
    text: "Simha/சிம்மம்/Leo",
    key: 1375
  },
  {
    id: 1376,
    text: "Kanya/கன்னி/Virgo",
    key: 1376
  },
  {
    id: 1377,
    text: "Thula/துலாம்/Libra",
    key: 1377
  },
  {
    id: 1378,
    text: "Vrischika/விருச்சிகம்/Scorpio",
    key: 1378
  },
  {
    id: 1379,
    text: "Dhanush/தனுசு/Sagittarius",
    key: 1379
  },
  {
    id: 1380,
    text: "Makara/மகரம்/Capricorn",
    key: 1380
  },
  {
    id: 1381,
    text: "Kumbha/கும்பம்/Aquarius",
    key: 1381
  },
  {
    id: 1382,
    text: "Meena/மீனம்/Pisces",
    key: 1382
  },
  {
    id: 1565,
    text: "Not Specified",
    key: 1565
  }
];

export {
  countryArray,
  years,
  minSalary,
  maxSalary,
  preferedCounties,
  age
};