import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Checkbox, Button } from "semantic-ui-react";
import swal from "sweetalert";

import { closeModal, customSweetAlert } from "../redux-state/modalActions";
import {
  singleProfile,
  updateDraftDashboardProfile
} from "../../../redux/actions/user/user";
import { getCommonData } from "../../../redux/actions/common/common-data";

function SocialMediaModal(props) {
  const [facebook, set_facebook] = useState({ url: null, request_only: false });
  const [linkedin, set_linkedin] = useState({ url: null, request_only: false });

  const updateSocial = e => {
    e.preventDefault();
    const formData = {
      facebook: facebook,
      linkedin: linkedin
    };
    props.updateDraftDashboardProfile(
      `profile/${props.profileId}`,
      "social",
      formData
    );
  };

  useEffect(() => {
    if (props.singleUser === null) {
      props.singleProfile(`profile/${props.profileId}`);
    }
    if (props.allCommonData.length < 1) {
      props.getCommonData();
    }

    // setting default state
    if (props.singleUser) {
      set_facebook(
        facebook.url !== null && facebook.request_only !== null
          ? facebook
          : props.singleUser.facebook !== null
          ? props.singleUser.facebook
          : {}
      );
      set_linkedin(
        linkedin.url !== null && linkedin.request_only !== null
          ? linkedin
          : props.singleUser.linkedin !== null
          ? props.singleUser.linkedin
          : {}
      );
    }
  }, [facebook, linkedin, props]);

  return (
    <React.Fragment>
      <div className="ui header">
        <h3>Social Media</h3>
      </div>
      <div className="content">
        <form method="POST" onSubmit={updateSocial}>
          <div className="ui form">
            <div className="inline field fields flex-start mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Facebook URL</span>
              </label>
              <div className="w-100">
                <input
                  className="wd-input w-100"
                  name="facebook"
                  type="text"
                  placeholder="https://facebook.com/name"
                  onChange={e => {
                    set_facebook({
                      ...facebook,
                      url: e.target.value,
                      request_only: false
                    });
                  }}
                  value={facebook && facebook.url}
                />
                <div className="ui checkbox pink mt-1">
                  <Checkbox
                    label="On request only"
                    onChange={(e, data) => {
                      set_facebook({
                        ...facebook,
                        request_only: data.checked
                      });
                    }}
                    defaultChecked={facebook && facebook.request_only}
                    checked={facebook.request_only}
                  />
                </div>
              </div>
            </div>
            <div className="inline field fields flex-start mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Linkedin URL</span>
              </label>
              <div className="w-100">
                <input
                  className="wd-input w-100"
                  name="name"
                  type="text"
                  placeholder="https://linkedin.com/in/linkedinyourname."
                  onChange={e =>
                    set_linkedin({ url: e.target.value, request_only: false })
                  }
                  value={linkedin && linkedin.url}
                />
                <div className="ui checkbox pink mt-1">
                  <Checkbox
                    label="On request only"
                    onChange={(e, data) => {
                      set_linkedin({ ...linkedin, request_only: data.checked });
                    }}
                    defaultChecked={linkedin.request_only}
                    checked={linkedin.request_only}
                  />
                </div>
              </div>
            </div>
            <div className="inline fields">
              <label className="w-50 lh-md hidden-xs" />
              <div className="w-100">
                <div className="two wide fields m-0 w-100 btn-group">
                  <div className="field mb-0">
                    <Button
                      type="submit"
                      className="fluid ui button btn-pink ripple"
                      loading={props.updateProSpinner}
                    >
                      Save
                    </Button>
                  </div>
                  <div className="field pr-0 mb-0">
                    <button
                      type="button"
                      onClick={() => props.customSweetAlert()}
                      className="fluid ui button btn-white ripple"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    allCommonData: state.commonData.allCommonData,
    errors: state.error.formsError,
    singleUser: state.user.singleProfileData,
    updateProSpinner: state.user.updateProSpinner
  };
};

export default connect(
  mapStateToProps,
  {
    closeModal,
    singleProfile,
    getCommonData,
    updateDraftDashboardProfile,
    customSweetAlert
  }
)(SocialMediaModal);
