import React, { Component } from "react";
import { connect } from "react-redux";
import { Dropdown, Button, TextArea } from "semantic-ui-react";
import { NavLink } from "react-router-dom";
import moment from "moment";
/**
 * @resuableSteps
 */
import Steps from "../../../../../common/shared-components/StepsFollow";
import StepsPercentage from "../../../../../common/shared-components/StepsPercentage";

/**
 * @reusableError
 */
import { Error } from "../../../../../common/shared-components/Error";

/**
 * @importActions
 */
import { getBasicProfileDataServer } from "../../../../../redux/actions/user/user";
import { getCommonData } from "../../../../../redux/actions/common/common-data";
import { saveStepThree } from "../../../../../redux/actions/step-basic-profile-data/basic-profile";
/**
 * @importComponents
 */
import NumberOfMatches from "../basic-information/childrens/number-of-matches";
import RadioBox from "../../../../../common/shared-components/Radio";
import { filterItems } from "../../../../../util/filter_common_data";
import { drinkingHabbit, chevvaiDosham, birthTime } from "../../../../../common/forms-data/forms-data";
import Wrapper from "../../../../../common/shared-components/wrapper/Wrapper";
import { route_config } from "../../../../../util/GlobalContants";
import { horoscopes } from "../../../../../common/forms-data/forms-data";
import { ASSETS_CDN } from "../../../../../util/GlobalContants";
import { numberRange } from "../../../../../util/numberRange";
import { genderPrefix } from "../../../../../util/getGender"; 

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";



class StepThree extends Component {
  state = {
    databind : false,
    smoking_habit: null,
    drinking_habit: null,
    hobbies: [],
    diet: null,
    horoscope : null,
    chevvai_dosham: null,
    birth_time : null,
    birth_country : null,
    birth_location : null,
    birth_location_lat : null,
    birth_location_lng : null,
    native_district: null,
    about : "",
    address : "",
    hours : null,
    minutes : null,
    ampm : null,
    star : null
  };

  /**
   * @lifeCycleHook
   */
  
  componentDidMount() {
    if (localStorage.getItem("wedmToken")) {
      this.props.getBasicProfileDataServer(
        this.props.match.params.profileId,
        this.props.history
      );
    }

    /**
     * @getCommonDataOnMount
     */
    this.props.getCommonData();
  }

  /**
   * @updatingState
   */
  UNSAFE_componentWillReceiveProps(nextProps) {

    const stateObj = this.state;
    if(!stateObj.databind){
      if(nextProps.data.basicProfileData && nextProps.data.basicProfileData.data){
        console.log(nextProps.data.basicProfileData.data)
        this.setState({
          databind : true
        })
      }
      for(var item in stateObj){
        if(item !== 'databind'){
          this.setState({
            [item] : (nextProps.data.basicProfileData && nextProps.data.basicProfileData.data && nextProps.data.basicProfileData.data[item]!== null) ? nextProps.data.basicProfileData.data[item] : stateObj[item] 
          })
        }
      }

      this.setState({
        address :  nextProps.data.basicProfileData && nextProps.data.basicProfileData.data && nextProps.data.basicProfileData.data['birth_location'] ? nextProps.data.basicProfileData.data['birth_location'] : stateObj['birth_location']
      })

      this.setState({
        about :  nextProps.data.basicProfileData && nextProps.data.basicProfileData.data && nextProps.data.basicProfileData.data['about'] && nextProps.data.basicProfileData.data['about']['about'] ? nextProps.data.basicProfileData.data['about']['about'] : stateObj['about']
      })

      if(nextProps.data.basicProfileData && nextProps.data.basicProfileData.data && nextProps.data.basicProfileData.data['birth_time']){
        if(moment(nextProps.data.basicProfileData.data['birth_time'],["HH:mm:ss"]).isValid()){
          const bt = moment(nextProps.data.basicProfileData.data['birth_time'],["HH:mm:ss"])
          this.setState({ 
            hours : parseInt(bt.format('h')),
            minutes : parseInt(bt.format('m')),
            ampm : bt.format('a'),
          })
        }
      }
    }
  }
  
  setTime = (part,value) => {
    this.setState({ 
      [part] : value
     },() => {
      this.setState({ 
        birth_time : moment(this.state.hours + ":" + this.state.minutes + " " + this.state.ampm,["h:m a"]).isValid() ? moment(this.state.hours + ":" + this.state.minutes + " " + this.state.ampm,["h:m a"]).format("HH:mm:ss"): null,
       })
     })
  }

  handleBlur= (e) => {

  }
  handleChange = address => {
    this.setState({ address })
  }

  handleSelect = address => {
    this.setState({ 
      address : address,
      birth_location : address
     })
    geocodeByAddress(address)
    .then(results => getLatLng(results[0]))
    .then(latLng => {
      this.setState({
        birth_location_lat : latLng.lat,
        birth_location_lng : latLng.lng,
       })
    })
    .catch(error => {
      this.setState({
        birth_location : null,
        address : null
      })
    })
  }

  handleNextButton = e => {
    e.preventDefault();
    this.props.saveStepThree(
      this.props.match.params.profileId,
      this.props.history,
      this.state
    );
  };


  render() {
    const { data } = this.props.data.basicProfileData;
    const { allCommonData } = this.props;
    return (
      <Wrapper
        config={route_config.education_career}
        isLoading={this.props.spinner}
        singleUser={this.props.singleUser}
        whyRegister={true}
      >
        <div className="pusher">
          <div className="full height"  >
            {/* Steps */}
            <Steps active={2} />
            {/* / Steps End */}
            <div className="ui container">
              <div className="page-form">
                <div class="text-center mb-4">
                    <div class="font-16 font-weight-bold text-green3 text-center mb-5">
                      Great! you’ve completed 70%
                    </div>
                    <div class="wd-form-title">
                      Just a few more details to show you better matches
                    </div>
                  </div>

                <form method="post" onSubmit={this.handleNextButton}>
                  <div className="ui fluid card mb-5">
                    {this.props.globalSpinner && (
                      <div className="ui active transition visible inverted dimmer">
                        <div className="content">
                          <div className="ui large text loader">Loading</div>
                        </div>
                      </div>
                    )}
                    {this.props.spinner && (
                      <div className="ui active transition visible inverted dimmer">
                        <div className="content">
                          <div className="ui large text loader">Loading</div>
                        </div>
                      </div>
                    )}
                    <div className="content bt-0">
                      <div className="wd-form-divider pt-0">
                        <div className="ui header">
                          <span>Lifestyle Information</span>
                        </div>
                      </div>
                      <div className="ui form">
                      {data && data.gender !== "female" && (
                        <React.Fragment>
                          <div className="inline fields mb-4 flex-start">
                          <label className="w-50 lh-md">
                            <span className="mr-05">
                             Smoking Habit?
                            </span>
                          </label>
                          <div className="w-100">
                            <div className="ui radio checkbox pink wd-radio-box w-100">
                              <RadioBox
                                name="smoking_habit"
                                radioBoxObject={drinkingHabbit}
                                className="ui radio checkbox pink wd-radio-box mb-1 w-100"
                                selectedRadioValue={radioValue =>
                                  this.setState({ smoking_habit: radioValue })
                                }
                                setDefaultValue={this.state.smoking_habit}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="inline fields mb-4 flex-start">
                          <label className="w-50 lh-md">
                            <span className="mr-05">
                            Drinking Habit?
                            </span>
                          </label>
                          <div className="w-100">
                            <div className="ui radio checkbox pink wd-radio-box w-100">
                              <RadioBox
                                name="drinking_habit"
                                radioBoxObject={drinkingHabbit}
                                className="ui radio checkbox pink wd-radio-box mb-1 w-100"
                                selectedRadioValue={radioValue =>
                                  this.setState({
                                    drinking_habit: radioValue
                                  })
                                }
                                setDefaultValue={this.state.drinking_habit}
                              />
                            </div>
                          </div>
                        </div>
                        </React.Fragment>
                      )}
                        <div className="inline fields mb-4">
                          <label className="w-50 lh-md">
                            <span className="mr-05">Hobbies & Interests</span>
                          </label>
                          <div className="w-100">
                            <Dropdown
                              search
                              className={`wd-input ${Error(
                                this.props.errors,
                                "hobbies"
                              )}`}
                              placeholder="Hobbies & Interests"
                              fluid
                              selection
                              multiple
                              
                              selectOnBlur={false}
                              options={filterItems(
                                "hobby",
                                allCommonData && allCommonData.data
                              )}
                              onChange={(e, { value }) =>
                                this.setState({ hobbies: value })
                              }
                              value={this.state.hobbies}
                            />
                          </div>
                        </div>
                        {(data && data.religion === 1124) ||
                          (data && data.religion === 1121) ? (
                            <div className="inline fields mb-4 flex-start">
                              <label className="w-50 lh-md">
                                <span className="mr-05">Importance of Horoscope</span>
                              </label>
                              <div className="w-100">
                                <RadioBox
                                  name="horoscope"
                                  radioBoxObject={horoscopes}
                                  className="ui radio checkbox pink checked wd-radio-box mb-1 w-100"
                                  selectedRadioValue={radioValue =>
                                    this.setState({ horoscope: radioValue })
                                  }
                                  setDefaultValue={this.state.horoscope}
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                           {[0,1].includes(this.state.horoscope) &&  (
                              <React.Fragment>
                                <div className="inline field fields mb-4">
                                  <label className="w-50 lh-md">
                                    <span className="mr-05">Star</span>
                                  </label>
                                  <div className="w-100">
                                    <Dropdown
                                        search
                                        className={`wd-input ${Error(
                                            this.props.errors,
                                            "star"
                                        )}`}
                                        placeholder="Star"
                                        fluid
                                        selection
                                        clearable
                                        selectOnBlur={false}
                                        options={filterItems(
                                            "star",
                                            allCommonData && allCommonData.data
                                        )}
                                        onChange={(e, { value }) =>
                                            this.setState({ star: value })
                                        }
                                        value={this.state.star}
                                    />
                                  </div>
                                </div>
                                <div className="inline field fields flex-start mb-4">
                                <label className="w-50 lh-md">
                                <span className="mr-05">Have Chevvai Dosham?</span>
                                </label>
                                <div className="w-100">
                                <RadioBox
                                      name="chevvai_dosham"
                                      radioBoxObject={chevvaiDosham}
                                      className="ui radio checkbox pink checked wd-radio-box mb-1 w-100"
                                      selectedRadioValue={radioValue =>
                                        this.setState({ chevvai_dosham: radioValue })
                                      }
                                      setDefaultValue={this.state.chevvai_dosham}
                                    />
                                </div>
                                </div>
                                 <div className="inline field fields mb-4">
                                    <label className="w-50 lh-md">
                                      <span className="mr-05">Country of Birth</span>
                                    </label>
                                    <div className="w-100">
                                      <Dropdown
                                        search
                                        className={`wd-input ${Error( this.props.errors && this.props.errors ,"birth_country")}`}
                                        placeholder="Select Country of Birth"
                                        fluid
                                        selection
                                        clearable
                                        selectOnBlur={false}
                                        options={filterItems(
                                          "nationality",
                                          allCommonData && allCommonData.data
                                        ).map(nation => {
                                          return {
                                            key: nation.id,
                                            text: nation.name,
                                            value: nation.id
                                          };
                                        })}
                                        onChange={(e, { value }) => {
                                          this.setState({ birth_country: (value && value !== "") ? value : null })
                                        }}
                                        value={this.state.birth_country}
                                      />
                                    </div>
                                  </div>
                                </React.Fragment>
                           )}
                    {this.state.birth_country && this.state.birth_country !=="" && [0,1].includes(this.state.horoscope) &&  (
                        <React.Fragment>
                        <div className="inline field fields mb-4">
                        <label className="w-50 lh-md">
                          <span className="mr-04">Birth Location</span>
                        </label>
                        <div className="w-100">
                            <PlacesAutocomplete
                                value={this.state.address}
                                onChange={this.handleChange}
                                onSelect={this.handleSelect}
                                debounce={300}
                                searchOptions={{
                                  types: ["(cities)"],
                                  componentRestrictions: {
                                    country: this.state.birth_country
                                  }
                                }}
                              >
                                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                                  <div className="pos-relative">
                                    <div className="ui fluid search mb-0">
                                      <div className="ui right icon input w-100">
                                        <input
                                          {...getInputProps({
                                            onBlur: this.handleBlur,
                                            placeholder: "Search Places ...",
                                            className: "prompt w-100  wd-input"
                                          })}
                                        />
                                      </div>
                                    </div>
                                    {suggestions.length > 0 && (
                                      <div className="autocomplete-dropdown-container">
                                        {suggestions.map(suggestion => {
                                          const className = suggestion.active
                                            ? "suggestion-item suggestion-item-active"
                                            : "suggestion-item";
                                          return (
                                            <div
                                              {...getSuggestionItemProps(suggestion, {
                                                className
                                              })}
                                            >
                                              <span>{suggestion.description}</span>
                                            </div>
                                          );
                                        })}
                                        <div className="autocomplete-dropdown-footer">
                                          <img
                                            alt="google Logo"
                                            src={`${ASSETS_CDN}/img/powered_by_google_default.png`}
                                            className="autocomplete-dropdown-footer-image"
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </PlacesAutocomplete>
                        </div>
                      </div>
                      </React.Fragment>
                      )}
                       {[0,1].includes(this.state.horoscope) &&  (
                         <React.Fragment>
                        <div className="inline field fields flex-start mb-4">
                          <label className="w-50 lh-md">
                            <span className="mr-05">Time of Birth</span>
                          </label>
                          <div className="w-100">
                            <div className="three wide fields m-0 time-group">
                              <div className="field">
                                <Dropdown
                                  search
                                  className={`wd-input ${Error(
                                    this.props.errors && this.props.errors,
                                    "birth_time"
                                  )}`}
                                  placeholder="Hours"
                                  fluid
                                  selection
                                  selectOnBlur={false}
                                  options={numberRange(1, 12, " Hours")}
                                  onChange={(e, { value }) => this.setTime('hours',value)}
                                  value={this.state.hours}
                                />
                              </div>
                              <div className="field">
                                <Dropdown
                                  search
                                  className={`wd-input ${Error(
                                    this.props.errors && this.props.errors,
                                    "birth_time"
                                  )}`}
                                  placeholder="Minutes"
                                  fluid
                                  selection
                                  selectOnBlur={false}
                                  options={numberRange(0, 59, " Mins")}
                                  onChange={(e, { value }) => this.setTime('minutes',value)}
                                  value={this.state.minutes}
                                />
                              </div>
                              <div className="field mb-0 pr-0">
                                <Dropdown
                                  search
                                  className={`wd-input ${Error(
                                    this.props.errors && this.props.errors,
                                    "birth_time"
                                  )}`}
                                  fluid
                                  placeholder="AM"
                                  selection
                                  selectOnBlur={false}
                                  options={birthTime.dayOrNight}
                                  onChange={(e, { value }) => this.setTime('ampm',value)}
                                  value={this.state.ampm}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        </React.Fragment>
                       )}
{/*
                        <div className="inline field fields mb-4">
                          <label className="w-50 lh-md">
                            <span className="mr-05">Native District in Kerala</span>
                          </label>
                          <div className="w-100">
                          <Dropdown
                              search
                              className={`wd-input ${Error(
                                this.props.errors,
                                "native_district"
                              )}`}
                              placeholder="Native District in Kerala"
                              fluid
                              selection
                              clearable
                              selectOnBlur={false}
                              options={filterItems(
                                "district",
                                allCommonData && allCommonData.data
                              )}
                              onChange={(e, { value }) =>
                                this.setState({ native_district: value })
                              }
                              value={this.state.native_district}
                            />
                          </div>
                        </div>
*/}
                        {(data && data.profile_for === 1194) ? (
                          <div className="inline fields mb-4 flex-start">
                          <label className="w-50 lh-md">
                            <span className="mr-05">About You and Your Family</span>
                            (optional)
                          </label>
                          <div className="w-100">
                            <TextArea
                              className="wd-input"
                              type="text"
                              name="name"
                              row="4"
                              placeholder="About You and Your Family"
                              value={this.state.about}
                              onChange={e =>
                                this.setState({
                                  about: e.target.value
                                })
                              }
                              autoComplete="nope"
                            />
                          </div>
                        </div>
                        ) : (
                          <div className="inline fields mb-4 flex-start">
                          <label className="w-50 lh-md">
                            <span className="mr-05">About {data && data.name} and family</span>
                            (optional)
                          </label>
                          <div className="w-100">
                            <TextArea
                              className="wd-input"
                              type="text"
                              name="name"
                              row="4"
                              placeholder="About yourself"
                              value={this.state.about}
                              onChange={e =>
                                this.setState({
                                  about: e.target.value
                                })
                              }
                              autoComplete="nope"
                            />
                          </div>
                        </div>

                        ) }
                        <div className="inline fields">
                            <label className="w-50 lh-md" />
                            <div className="w-100">
                              <div className="two wide fields m-0 w-100 btn-group">
                                <div className="field mb-0">
                                <Button className="fluid ui button btn-green ripple">
                                    Continue
                                </Button>
                                </div>
                                <div className="field pr-0">
                                    <NavLink
                                      to={`/${this.props.match.params.profileId}/step-2`}
                                      className="fluid ui inverted white button ripple"
                                    >
                                      Go back
                                    </NavLink>
                                  </div>
                                </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </form>
                {/* / Inline Card End */}
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

/**
 *
 * @param {*} state
 */
const mapStateToProps = state => {
  return {
    data: state.user,
    basicProfile: state.user.basicProfileData,
    commonData: state.commonData,
    spinner: state.commonData.commonDataSpinner,
    globalSpinner: state.globalSpinner.globalSpinner,
    errors: state.error.formsError,
    allCommonData: state.commonData.allCommonData
  };
};

export default connect(mapStateToProps, {
  getBasicProfileDataServer,
  getCommonData,
  saveStepThree
})(StepThree);
