import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Search } from "semantic-ui-react";
import { gpAxios } from "../../util/axios";

const resultRenderer = ({ title }) => <div className="item">{title}</div>;

const SearchInput = props => {
  const [initialState, setInitialState] = useState({
    isLoading: false,
    results: [],
    value: ""
  });

  const handleResultSelect = (e, { result }) => {
    props.set_native_location(result.id);
  };

  useEffect(() => {

    if (props.location_id !== null && props.location_id !== "") {
      search_location(props.location_id, true);
    }
  }, [props.location_id]);

  const handleSearchChange = (e, { value }) => {
    if (value === "") {
      return null;
    }
    setInitialState({ isLoading: true, value: value });
    search_location(value, false);
  };

  const search_location = (value, type) => {

    gpAxios.get(`/location?q=${value}`).then(res => {
      let response = res.data;
      let finalResult = response.data.map(data => {
        let result = {
          title: data.address,
          id: data.id
        };
        return result;
      });

      if (type) {
        setInitialState({
          value: finalResult.length > 0 && finalResult[0].title
        });
      }
      setInitialState({
        isLoading: false,
        results: finalResult
      });
    });
  };

  return (
    <Search
      className="wd-input borderNone"
      placeholder="Search Native Place"
      minCharacters={3}
      loading={initialState.isLoading}
      onResultSelect={handleResultSelect}
      onSearchChange={_.debounce(handleSearchChange, 500, {
        leading: true
      })}
      resultRenderer={resultRenderer}
      results={initialState.results}
      value={initialState.value}
      {...props}
      selectFirstResult={false}
      fluid={true}
      showNoResults={false}
    />
  );
};

export default SearchInput;
