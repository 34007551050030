import React from "react";
import { Placeholder } from "semantic-ui-react";
export default function CardPlaceholder() {
  return (
    <div
      style={{ width: "100%" }}
      className="ui compact selection dropdown border radius-4 pos-relative custom-card"
    >
      <Placeholder>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
      </Placeholder>
    </div>
  );
}
