import { gpAxios } from "../../../util/axios";

import { globalSpinner } from "../../../common/commonActions/commonSpinner";
import { setErrors } from "../../../common/commonActions/errorAction";
import { getBasicProfileDataServer } from "../user/user";

/**
 * @almostThere: saving data to server
 * @param {*} profileId
 */
export const almostThere = (
  profileId,
  data,
  history,
  prefrenceId
) => dispatch => {
  dispatch(globalSpinner(true));
  const profile_id = profileId;
  let url = "";
  if (prefrenceId !== null) {
    url = `/profile/${profileId}/preference/${prefrenceId}`;
  } else {
    url = `/profile/${profileId}/preference`;
  }
  gpAxios
    .post(url, data)
    .then(response => {
      dispatch(globalSpinner(false));
      dispatch(getBasicProfileDataServer(profile_id));
      dispatch(setErrors(null));
      history.push(`/`);
    })
    .catch(error => {
      dispatch(globalSpinner(false));
      if (
        error.response &&
        error.response.data !== undefined &&
        error.response.data.error
      ) {
        dispatch(setErrors(error.response.data.error));
      }
    });
};
