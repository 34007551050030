import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link, NavLink } from "react-router-dom"
import moment from "moment-timezone"
import {
  singleProfile,
  defaultSingleProfile
} from "../../../../../redux/actions/user/user"

import { updateLayout } from "../../../../../redux/layout_controll/layout_action"
import {
  closeModal
} from "../../../../modals/redux-state/modalActions"

import { getCommonData } from "../../../../../redux/actions/common/common-data"
import {
  route_config,
  ASSETS_CDN
} from "../../../../../util/GlobalContants"

import Wrapper from "../../../../../common/shared-components/wrapper/Wrapper"
import {injectIntl} from "react-intl"
import { Dimmer,Loader , Button } from "semantic-ui-react"
import ChatArea from "./chat-area"
import { chatAxios } from "../../../../../util/axios"
import { playSound } from "../../../../../util/sound"
import { parseText } from "../../../../../util/chat"
class Messages extends Component {
  
  state = {
    singleUser : null,
    profileId: parseInt(this.props.match.params.profileId),
    selectedConversationId : null,
    selectedConversation : null,
    lastConversation : null,
    selectedTab : 'active',
    loading: true,
    mute : localStorage.getItem("chatMute") === 'true' ? true : false,
    pendingRequests: [],
    conversations : null,
    loadedConversations : 0,
    pending : false
  }

  componentDidMount() {
    this.props.closeModal()
    this.props.defaultSingleProfile(`profile/${this.state.profileId}`)
    this.props.getCommonData()
  }

  componentWillUnmount() {
    this.props.closeModal()
  }
  muteUnmute(mute) {
      localStorage.setItem("chatMute",mute)
      this.setState({
        mute : mute
      })
  }
  setReadZero(id){
      try {
        const c = this.state.conversations
        if(c[id] !== undefined){
          c[id].self_state.unread = 0
          this.setState({ conversations : c})
        }
      } catch (error) {}
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    })
  }
  acceptMethods(receivedNewMessage) {
    this.receivedNewMessage = receivedNewMessage
  }
  closeChat = () => {
    this.setState({
      selectedConversationId : null,
      selectedConversation : null
    })
  }
  conversationAction = (action,id) => {
      try {
        const conversations = this.state.conversations
        if(['archive','unarchive'].includes(action)){
          if(conversations[id] !== undefined){
            delete conversations[id]
          }
          this.setState({
            selectedConversationId : null,
            selectedConversation : null,
            conversations: conversations
          })
        }
        else if(action === 'block') {
          if(conversations[id] !== undefined){
            conversations[id].blocked.push(this.state.profileId)
            this.setState({ conversations: conversations})
          }
        }
        else if(action === 'unblock') {
          if(conversations[id] !== undefined){
            const  i  = conversations[id].blocked.indexOf(this.state.profileId)
            if(i > -1) {
              conversations[id].blocked.splice(i,1)
              this.setState({ conversations: conversations})
            }
          }
        }
        else if(action === 'mute') {
          if(conversations[id] !== undefined){
            conversations[id].mute.push(this.state.profileId)
            this.setState({ conversations: conversations})
          }
        }
        else if(action === 'unmute') {
          if(conversations[id] !== undefined){
            const  i  = conversations[id].mute.indexOf(this.state.profileId)
            if(i > -1) {
              conversations[id].mute.splice(i,1)
              this.setState({ conversations: conversations})
            }
          }
        }
      } catch (error) {

      }
  }
  listenNewMessage = (action,data)=> {
      try {
        const c = this.state.conversations
        if(action === 'new-message'){
          if(c[data.conversation] !== undefined){
            c[data.conversation].last_message.text = (data.last_message !== undefined && data.last_message.text !== undefined && data.last_message.text !== null )?  parseText(data.last_message,this.state.profileId,c[data.conversation].partner.name) : c[data.conversation].last_message.text
            if(data.message.from !== this.state.profileId){
              if(!this.state.mute) {
                playSound('incoming')
              }
              if(data.message.type === 'request'){
                c[data.conversation].partner.pending_actions = c[data.conversation].partner.pending_actions + 1
                const singleUser = this.state.singleUser
                singleUser.pending_requests = singleUser.pending_requests + 1
                this.setState({ singleUser : singleUser})
              }
              c[data.conversation].self_state.unread = c[data.conversation].self_state.unread + 1
            }
            this.setState({ conversations : c})
          }else{

          }
        }
        else if(action === 'new-conversation'){
          this.getConversation(data.conversation,data.conversation_token)
        }
        else if(action === 'block'){
          if(c[data.conversation] !== undefined){
            c[data.conversation].blocked.push(data.profile)
            this.setState({ conversations : c})
          }
        }
        else if(action === 'unblock'){
          if(c[data.conversation] !== undefined){
            const  i  = c[data.conversation].blocked.indexOf(data.profile)
            if(i > -1) {
              c[data.conversation].blocked.splice(i,1)
              this.setState({ conversations: c})
            }
          }
        }
        else if(action === 'new-action'){
          if(data.action !== 'later' && data.profile === this.state.profileId ) {
            const singleUser = this.state.singleUser
            singleUser.pending_requests = singleUser.pending_requests - 1
            this.setState({ singleUser : singleUser})
          }
        }
        else if(action === 'read'){
          if(c[data.conversation] !== undefined){
            c[data.conversation].partner_state.unread = 0
            this.setState({ conversations : c})
          }
        }
        

        if(data.conversation === this.state.selectedConversationId){
          this.receivedNewMessage(action,data)
        }
      } catch (error) {
        
      }
  }
  switchConversation = (id) => {
    const c = this.state.conversations[id]
    this.setState({
      selectedConversationId : id,
      selectedConversation : c
    })
    window.location.hash = `#${id}`
  }

  async getConversation(conversation,conversation_token,notify = true,autoselect = false,first = true){
        try {
          const {data} = await chatAxios.get(`/conversations/${conversation}`,{params : { token : conversation_token}})
          if(data && data.result) {
              if(notify) playSound('incoming')
              let conversations = this.state.conversations || {}
              conversations = first ? Object.assign({ [conversation] : data.data}, conversations) : Object.assign(conversations, { [conversation] : data.data})
              this.setState({conversations : conversations},() => {
                if(autoselect){
                  this.switchConversation(conversation)
                }
              })
          }
        } catch (error) {
          window.history.replaceState(null, null, ' ')
        }
  }

  async loadMoreConversation() {
    try {
      const chatToken = this.props.singleUser.chat_token
      this.setState({ loading: true})
      const {data} = await chatAxios.get('/conversations',{params : { 
          token : chatToken, 
          state: this.state.selectedTab,
          s : this.state.lastConversation,
          pending : this.state.pending
      }})
      if(data && data.result) {
        const conversations = this.state.conversations || {}
        this.setState({
            conversations : {...conversations,...data.data}, 
            loading : false,
            loadedConversations : Object.keys(data.data).length,
            lastConversation : Object.keys(data.data)[Object.keys(data.data).length-1]
          })
      }
      else{
        this.setState({loading : false, loadedConversations : 0})
      }
    } catch (error) {
      console.log(error)
      this.setState({loading : false, loadedConversations : 0})
    }
  }
  async loadConversation(state = `active` ) {
    try {
      const chatToken = this.props.singleUser.chat_token
      this.setState({
        selectedConversationId : null,
        selectedConversation : null,
        loading: true,
        selectedTab : state,
        conversations : {}
      })
      const {data} = await chatAxios.get('/conversations',{params : { token : chatToken, state: state , pending : this.state.pending}})
      if(data && data.result) {
        this.setState({
            conversations :data.data, loading : false, 
            loadedConversations : Object.keys(data.data).length,
            lastConversation : Object.keys(data.data)[Object.keys(data.data).length-1]
          })
      }
      else{
        this.setState({loading : false, loadedConversations: 0})
      }
      
    } catch (error) {
      this.setState({loading : false, loadedConversations: 0})
    }
  }
  async UNSAFE_componentWillReceiveProps(nextProps) {
    if(this.props.singleUser && !this.state.conversations){
          this.setState({singleUser : this.props.singleUser})
          await this.loadConversation()
          const hash  = window.location.hash.split('#')[1]
          if(hash !== undefined){
            if(this.state.conversations[hash] !== undefined){
              this.setState({
                selectedConversationId : hash,
                selectedConversation : this.state.conversations[hash]
              })
            }else{
              this.getConversation(hash,this.props.singleUser.chat_token,false,true,false)
            }
          }
    }
  }
  
  render() {
    const tz = moment.tz.zonesForCountry(this.props.user.country)[0];
    return (
      <Wrapper
        config={route_config.draft_dashboard}
        isLoading={this.props.globalSpinner}
        singleUser={this.props.singleUser}
        listenNewMessage = {this.listenNewMessage}
        chatWrapper = {true}
      >
        <div className={ this.state.selectedConversationId !== null ?  `content-body open` :`content-body`}>
          <div className="content-wrapper">
              <div className="wd-rail">
                  <div className="wd-chat-wrapper">
                    <div className="chat-heading">
                        <Link>Chat</Link>
                        {this.state.mute  ? ( <Button className="icon" onClick={()=> this.muteUnmute(false)} ><i className="i-volume-mute"></i></Button>
                        ):(  <Button className="icon" onClick={()=> this.muteUnmute(true)} ><i className="i-volume"></i></Button> )}
                        
                    </div>
                      <div className="chat-body-wrapper bg-white box-shadow radius-2">
                        <div className="chatbox-container">
                        <div className="chatbox-leftbar">
                            <div className="leftbar-container">
                                <div className="leftbar-header">
                                    <div className="ui fluid three item menu chat-tab">
                                        <button 
                                          className={ this.state.selectedTab === 'active' ? `active item` : `item`}
                                          onClick={() => {
                                            window.history.replaceState(null, null, ' ')
                                            this.loadConversation('active')
                                          }}
                                          disabled={this.state.selectedTab === 'active'}
                                        ><i className="i-inbox"></i>Inbox
                                        </button>
                                        <Link to={`/${this.state.profileId}/requests`} className="item"><i className="i-sent"></i>Request</Link>
                                        <button 
                                          className={ this.state.selectedTab === 'archived' ? `active item` : `item`}
                                          disabled={this.state.selectedTab === 'archived'}
                                          onClick={() => {
                                            window.history.replaceState(null, null, ' ')
                                            this.loadConversation('archived')
                                          }}
                                        >
                                        Closed</button>
                                    </div>
                                </div>
                                <div className="chat-list">
                                    <Dimmer active={this.state.loading} inverted><Loader inverted content='Loading' /></Dimmer>
                                    <div className="ui list m-0">
                                        {this.state.singleUser && this.state.singleUser.pending_requests > 0 && !this.state.pending && (
                                          <div className="item pointer"
                                          onClick={() => {
                                            this.setState({pending : true},() => {
                                              this.loadConversation()
                                            })
                                            }}
                                          >
                                          <div className="content">
                                              <div className="header">Pending request</div>
                                              <div className="tiny-msg text-gray">Waiting for your response</div>
                                          </div>
                                          <div className="extra">
                                        <div className="ui label badge-pill badge-blue m-0 font-weight-normal">{this.state.singleUser.pending_requests}</div>
                                          </div>
                                        </div>
                                        )}

                                      { this.state.pending && (
                                        <div className="item pointer" 
                                          onClick={() => {
                                          this.setState({pending : false},() => {
                                            this.loadConversation()
                                          })
                                          }}
                                        >
                                            <div className="content">
                                                <i className="i-corner-left icon-sm float-left align-middle mr-1 mt-05"></i>
                                                <div className="o-hidden">
                                                    <div className="header">Show all conversations</div>
                                                    <div className="tiny-msg text-gray">Filter: Pending request</div>
                                                </div>
                                            </div>
                                      </div>)}


                                        {this.state.conversations !== null && Object.keys(this.state.conversations).length > 0 && Object.keys(this.state.conversations).map((convId) => {
                                            const conv = this.state.conversations[convId]
                                            return   convId === this.state.selectedConversationId ? (<div key={convId} className="item opened">
                                                    <span className="ui avatar image">
                                                        <img src={conv.partner.photo || `${ASSETS_CDN}/img/${conv.partner.gender}.png` }  alt={conv.partner.name} />
                                                    </span>
                                                    <div className="content">
                                                        <div className="header">{conv.partner.name} { conv.mute.includes(this.state.profileId) && (
                                                            <i className="icon-md d-inline-block i-volume-mute align-middle ml-05 opacity-5"></i>
                                                        ) }</div>
                                                        {conv.last_message && conv.last_message.text && (
                                                          <div className="tiny-msg text-gray">{parseText(conv.last_message,this.state.profileId,conv.partner.name)}</div>
                                                        )}
                                                    </div>
                                                    <div className="extra"><i className="i-caret-right"></i></div>
                                                </div>
                                            ) : (
                                              <div key={convId} className="item pointer" onClick={() => this.switchConversation(convId)}>
                                                    <span className="ui avatar image">
                                                    <img src={conv.partner.photo || `${ASSETS_CDN}/img/${conv.partner.gender}.png` } alt={conv.partner.name}/>
                                                    </span>
                                                    <div className="content">
                                                        {(conv.self_state && conv.self_state.unread > 0 ) ? (
                                                          <div className="header">{conv.partner.name}
                                                          <span className="ui label badge-pill badge-blue m-0 font-weight-normal ml-1">{conv.self_state.unread}</span></div>
                                                        ):(
                                                          <div className="header text-gray font-weight-normal">{conv.partner.name}</div>
                                                        )}
                                                        
                                                        {conv.last_message && conv.last_message.text && (
                                                          <div className="tiny-msg text-gray">{parseText(conv.last_message,this.state.profileId,conv.partner.name)}</div>
                                                        )}
                                                    </div>
                                                    <div className="extra"><span>{moment(conv.updated_at).tz(tz).format('HH:mm a')}</span></div>
                                                </div>
                                            )
                                        })}
                                         {!this.state.loading && this.state.conversations !== null && Object.keys(this.state.conversations).length === 0 ? (
                                           <div className="chat-empty">
                                           <i className="icon-xl i-box-empty d-block m-auto mb-1 mt-4"></i>
                                           <div className="font-16 text-black font-weight-bold mb-1">Empty conversations</div>
                                           <div className="font-13 text-gray lh-md m-auto text-center mb-4">
                                           Currently, there is no communication in this folder <br />Initiate a new conversation with matches instantly
                                           </div>
                                           <Link to={`/${this.state.profileId}/matches`} className="ui button btn-blue radius-2 ripple mr-0">View Matches</Link>
                                          </div>) : (
                                             <div className="chat-empty">
                                             <i className="icon-xl i-box-empty d-block m-auto mb-1 mt-4"></i>
                                             <div className="font-13 text-gray lh-md m-auto text-center mb-4">
                                             Initiate more conversations and find the life partner quicker
                                             </div>
                                             <Link to={`/${this.state.profileId}/matches`} className="ui button btn-blue radius-2 ripple mr-0">View Matches</Link>
                                            </div>
                                          )
                                          }
                                    </div>
                                </div>
                                {this.state.loadedConversations >= 10 && (
                                  <div className="chat-more">
                                    <button
                                      disabled={this.state.loading}
                                      className="fluid ui button btn-link text-blue font-weight-bold p-0 font-14"
                                      onClick={() => {
                                        this.loadMoreConversation()
                                      }}
                                    >Load more
                                  </button>
                                  </div>
                                )}
                                
                            </div>
                        </div>
                        <div className={this.state.selectedConversationId === null ? `chatbox-content d-hide` : `chatbox-content`}>
                          {this.state.selectedConversationId !== null && (
                            <ChatArea
                              tz={tz}
                              profile={this.props.singleUser}
                              shareMethods={this.acceptMethods.bind(this)}
                              setReadZero={(id) => {
                                this.setReadZero(id)
                              }}
                              tokenUpdated={async(conversation,token) => {
                                  const conversations = this.state.conversations
                                  conversations[conversation].conversation_token = token
                                  await this.setStateAsync({conversations:conversations})
                              }}
                              history={this.props.history}
                              mute={this.state.mute}
                              conversationAction={this.conversationAction}
                              conversation={this.state.selectedConversation}
                              conversationID={this.state.selectedConversationId}
                              profileId={this.state.profileId}
                              closeChat={this.closeChat}
                            />
                          )}

                        {this.state.selectedConversationId === null && !this.state.loading && this.state.conversations !== null && Object.keys(this.state.conversations).length > 0 && (
                            <div className="chatbox-content-container">
                              <div className="chat-push">
                                  <img className="ui image mx-auto mb-4" src={`${ASSETS_CDN}/img/arrow-up.svg`} alt="No chat" />
                                  <div className="title">Select any chat to continue</div>
                                  <p>You can start new chat from Matches page</p>
                                  <a className="ui button btn-blue radius-2 ripple mr-0" href={`/${this.state.profileId}/matches`}>View Matches</a>
                              </div>
                          </div>
                          )}
                          
                        </div>
                        </div>
                  </div>
                  </div>
                </div>
            </div>
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  profileCards: state.user.profileCards,
  user :  state.user.user.data,
  singleUser: state.user.singleProfileData,
  profileNotFound: state.user.profileNotFound,
  globalSpinner: state.globalSpinner.globalSpinner,
  globalNumber: state.commonData.defaultCountryNumber
});

export default injectIntl(
    connect(mapStateToProps, {
    singleProfile,
    updateLayout,
    getCommonData,
    closeModal,
    defaultSingleProfile
})(withRouter(Messages)));
