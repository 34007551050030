import React from "react";
import Img from "react-image";
import { Placeholder } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { in_active_days } from "../../../../../util/GlobalContants";

export default function DeletionPending({ profile }) {
  return (
    <div className="ui fluid card wd-card m-0 mb-2 ">
      <div className="content">
        <div className="left floated ui tiny image thumb-lg radius-4 o-hidden mr-1 mb-0">
          <a href>
            <Img
              className="ui fluid image"
              src={
                profile && profile.photo
                  ? profile.photo
                  : profile.gender == "male"
                  ? "https://storage.googleapis.com/matrimony-assets/dashboard/img/d-boy.png"
                  : "https://storage.googleapis.com/matrimony-assets/dashboard/img/d-girl.png"
              }
              loader={
                <Placeholder style={{ height: 150, width: 150 }}>
                  <Placeholder.Image />
                </Placeholder>
              }
            />
          </a>
        </div>
        <div className="o-hidden">
          <div className="header font-16 mb-05">
            <a href="#" className="text-black font-weight-bold">
              {profile.name}
            </a>
          </div>
          <div className="meta font-12 text-gray mb-05">
            ID: {profile.did}
            <span class="activity">
              Last Activity: {profile.last_accessed_diff}
            </span>
          </div>
        </div>
      </div>
      <div className="extra content">
        <div className="m-auto text-center font-12">
          <a href="#" className="text-red text-uppercase">
            <i className="icon-xs i-timer-red d-inline-block align-middle mr-05" />
            {profile.dstate}
          </a>
        </div>
      </div>
    </div>
  );
}
