import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as toasterReducer } from "react-redux-toastr";
import Verify from "./otp-verify/verify";
import Error from "../../common/commonReducers/errorReducer";
import user from "./user/user";
import commonData from "./common/common";
import globalSpinner from "../../common/commonReducers/spinnerReducer";
import checkLogin from "./check-login/check-login";
import matches from "./matches/matches";
import language from "./language/language";
import layout from "../layout_controll/layout_reducer";
import form_modal from "../../components/modals/redux-state/modalReducer";
import partner_contact from "../../components/modals/draft-dashboard-forms/parter_contact_sub_modals/redux/reducer";
import matchesMore from "./mobile/matches-more";
// Onboarding
import onboarding_general_information from "./onboarding/general_information";

export default history =>
  combineReducers({
    router: connectRouter(history),
    verify: Verify,
    toastr: toasterReducer,
    error: Error,
    user,
    commonData,
    globalSpinner,
    onboarding_general_information,
    checkLogin,
    matches,
    language,
    layout,
    form_modal,
    partner_contact,
    matchesMore
  });
