import React, { useState, useEffect } from "react";
import { Modal, Checkbox, Button } from "semantic-ui-react";
import IntlTelInput from "react-intl-tel-input";
import { connect } from "react-redux";
import "react-intl-tel-input/dist/main.css";

import PhoneNumberVerification from "./PhoneNumberVerification";
import { preferedCounties } from "../../../../common/forms-data/forms-data";
import { update_mobile_number } from "./redux/action";

function ChangeMobileNumberModal(props) {
  const [open, set_open] = useState(false);
  const [open_modal, set_open_modal] = useState(false);
  const [phone, set_phone] = useState(null);
  const [whatsapp, set_whatsapp] = useState(false);
  const [viber, set_viber] = useState(false);

  useEffect(() => {

    if (props.verification_modal) {
      set_open_modal(true);
    }
  }, [props]);

  const getChangedNumber = (status, value, data) => {
    if (value.indexOf("+")) {
      set_phone(`+${data.dialCode + value}`);
    } else {
      set_phone(value);
    }
  };

  const focusMe = me => me.current.focus();

  const updateNumber = e => {
    e.preventDefault();
    const data = {
      phone: phone,
      whatsapp: whatsapp,
      viber: viber
    };
    props.update_mobile_number(profileId, data, props.numberType);
  };

  const { profileId } = props;

  return (
    <Modal
      open={open}
      onOpen={() => set_open(true)}
      onClose={() => set_open(false)}
      size="small"
      trigger={<a href>{props.btnTitle}</a>}
      className="modal-sm"
    >
      <React.Fragment>
        <div className="ui header">
          <h3>{props.btnMainTitle}</h3>
        </div>
        <div className="content">
          <div className="ui form">
            <div className="field mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Mobile number</span>
              </label>
              <div className="w-100">
                <div className="intl-tel-input allow-dropdown separate-dial-code">
                  <div className="flag-container"></div>

                  <IntlTelInput
                      autoFocus
                    onPhoneNumberChange={getChangedNumber}
                    preferredCountries={preferedCounties}
                    inputClassName="inputClass wd-input"
                    separateDialCode
                    autoPlaceholder
                    defaultCountry={localStorage.getItem("dmc") || "auto"}
                    telInputProps={{
                      onKeyPress: function(event) {
                        if (event.key === "Enter") {
                          updateNumber(event);
                        }
                      }
                    }}
                  />
                  <p style={{ marginTop: "5px", color: "red" }}>
                    {props.error !== null && props.error}
                  </p>
                </div>
                <div className="mt-1">
                  <div className="ui checkbox pink mr-1">
                    <Checkbox
                      label="WhatsApp"
                      onChange={(e, data) => {
                        set_whatsapp(data.checked);
                      }}
                      // defaultChecked={facebook && facebook.request_only}
                      //   checked={facebook.request_only}
                    />
                  </div>
                  <div className="ui checkbox pink mr-1">
                    <Checkbox
                      label="Viber"
                      onChange={(e, data) => {
                        set_viber(data.checked);
                      }}
                      // defaultChecked={facebook && facebook.request_only}
                      //   checked={facebook.request_only}
                    />
                  </div>
                </div>
              </div>
            </div>
            {open_modal && (
              <PhoneNumberVerification
                profile_id={profileId}
                current_state={open_modal}
                numberType={props.numberType}
              />
            )}
            <Button
              className="fluid ui button btn-pink btn-shadow btn-submit ripple m-0 mb-3"
              id="modal16"
              type="button"
              onClick={updateNumber}
              loading={props.spinner}
            >
              {props.btnTitle}
            </Button>
            <Button
              className="fluid ui button btn-white ripple btn-submit mb-3"
              id="modal16"
              type="button"
              onClick={() => set_open(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      </React.Fragment>
    </Modal>
  );
}

const mapStateToProps = state => {
  return {
    spinner: state.partner_contact.spinner,
    verification_modal: state.partner_contact.open_verification_modal,
    error: state.error.formsError,
    cmodal: state.verify.closeModal
  };
};

export default connect(
  mapStateToProps,
  { update_mobile_number }
)(ChangeMobileNumberModal);
