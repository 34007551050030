import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Radio } from "semantic-ui-react";
import { NavLink } from "react-router-dom";

/**
 * @importActions
 */
import { getBasicProfileDataServer } from "../../../../../redux/actions/user/user";
import { getCommonData } from "../../../../../redux/actions/common/common-data";
import { almostThere } from "../../../../../redux/actions/almost-there/almost-there";
import { checkIfProfileIdNotEmpty } from "../../../../../common/shared-components/CheckProfileId";
import Wrapper from "../../../../../common/shared-components/wrapper/Wrapper";
import { route_config } from "../../../../../util/GlobalContants";
import {
  openModal,
  closeModal
} from "../../../../modals/redux-state/modalActions";

import {
  SavePhotoPrivacy
} from "../../../../../redux/actions/step-basic-profile-data/basic-profile";


class FinalStep extends Component {
  /**
   * @initalState
   */
  state = {
    databind : false,
    photo_privacy: 0,
    photo_error : false
  };

  componentDidMount() {
    checkIfProfileIdNotEmpty(
      this.props.history,
      this.props.match.params.profileId
    );
    if (localStorage.getItem("wedmToken")) {
      this.props.getBasicProfileDataServer(
        this.props.match.params.profileId,
        this.props.history
      );
    }
    this.props.getCommonData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const stateObj = this.state;
    if(!stateObj.databind){
      if(nextProps.data.basicProfileData && nextProps.data.basicProfileData.data){
        this.setState({
          databind : true
        })
      }
      for(var item in stateObj){
        if(item !== 'databind'){
          this.setState({
            [item] : stateObj[item] ? stateObj[item] : nextProps.data.basicProfileData && nextProps.data.basicProfileData.data && nextProps.data.basicProfileData.data[item]
          })
        }
      }      
    }
  }
  setPrivacy = (e, { value }) => this.setState({ 
    photo_privacy :  value
  }) 


  async handleUploadPhoto(e){
    e.preventDefault();
    this.props.openModal("ProfileImageCropModal", {
      files: e.target.files,
      profileId: this.props.match.params.profileId
    })
  }

  // saveStepTwo
  handleFormSubmission = e => {
    e.preventDefault();
    if(!this.props.croppedProfilePicUrl && !this.props.data.basicProfileData.data.photo) {
      this.setState({
        photo_error : true
      })
      return;
    }else{
      this.setState({
        photo_error : false
      })
    }
    this.props.SavePhotoPrivacy(
      this.props.match.params.profileId,
      this.props.history,
      this.state,
    )
  }

  // saveStepTwo
  handleSkipSubmission = e => {
    e.preventDefault();
    this.setState({
      photo_error : false
    })
    this.props.SavePhotoPrivacy(
      this.props.match.params.profileId,
      this.props.history,
      this.state,
    )
  }

  render() {
    return (
      <Wrapper
        config={route_config.success}
        isLoading={this.props.spinner}
        singleUser={this.props.singleUser}
        whyRegister={true}
      >
          <div className="pusher">
            <div className="full height"  >
              <div className="ui container">
                <div className="page-form">
                    { this.props.data.basicProfileData && this.props.data.basicProfileData.data && (
                          <React.Fragment>
                          <div className="ui fluid card mb-3x">
                          {this.props.globalSpinner && (
                                <div className="ui active dimmer inverted radius-4">
                                <div className="ui small text active inverted loader">Loading</div>
                                </div>
                              )}
                            <div className="content bt-0 ">
                              <div className="text-center mb-3">
                                <div className="font-18 text-black mb-05">Set Profile Picture</div>
                                <div className="font-14 text-gray">Mostly users shortlist if the photos available. So consider upload photo and set privacy as per your requirement.
                                  <button className="ui button btn-link text-blue font-weight-normal p-0 mr-0 ml-05 guideline-xs"
                                  onClick={() =>{
                                    this.props.openModal("PhotoGuide", { customClass: "modal-md"})
                                  }
                                }
                                  >Photo guidlines</button>
                                </div>
                              </div>
                              { this.state.photo_error && (<div className="text-center text-red font-13 my-auto mb-1">*Please upload your photo to continue</div>)}
                              <div className="album-image-big mb-4">
                                {this.props.croppedProfilePicUrl !== null ? (
                                  <React.Fragment>
                                     {this.props.profilePicSpinner && (
                                       <div className="ui active dimmer transition visible radius-4">
                                        <div className="ui small text active inverted loader">Uploading</div>
                                      </div>
                                     )}
                                    <img src={this.props.croppedProfilePicUrl} alt="" />
                                    <div className="update-dp d-block">
                                        <label htmlFor="ProfilePhoto"> <i className="icon-xs i-camera d-inline-block align-middle mr-05"></i> Change Photo
                                            <input
                                            type="file"
                                            accept="image/*"
                                            onChange={e => this.handleUploadPhoto(e)}
                                            id="ProfilePhoto" 
                                            size={60}
                                            />
                                        </label>
                                    </div>
                                  </React.Fragment>
                                ) : (this.props.data.basicProfileData.data.photo) ? (
                                  <React.Fragment> 
                                  <img src={this.props.data.basicProfileData.data.photo} alt=""/> 
                                  <div class="update-dp d-block">
                                  <label htmlFor="ProfilePhoto"><i className="icon-xs i-camera d-inline-block align-middle mr-05" />Change Photo
                                    <input 
                                      type="file"
                                      accept="image/*"
                                      onChange={e => this.handleUploadPhoto(e)}
                                      id="ProfilePhoto" 
                                      size={60} />
                                  </label>
                                  </div>
                                </React.Fragment>
                                ):(
                                  <div className="add-img">
                                  <label htmlFor="ProfilePhoto"><i className="icon-40 i-add-red d-block my-auto mb-1" />Upload Photo
                                    <input 
                                      type="file"
                                      accept="image/*"
                                      onChange={e => this.handleUploadPhoto(e)}
                                      id="ProfilePhoto" 
                                      size={60} />
                                  </label>
                                  </div>
                                )}
                                </div>
                                
                                <div className="privacy-options">
                                  <div className="album-content">
                                      <div className="row w-100">
                                      <div className="item">
                                    <Radio
                                        label="Display to all matches"
                                        className="pink"
                                        name='radioGroup'
                                        value={0}
                                        checked={this.state.photo_privacy === 0}
                                        onChange={this.setPrivacy}
                                      />
                                      <div class="description">
                                        <span className="bg-yellow-light border-gold text-black radius-2 p-xs rec-label">Recommended</span>
                                      </div>
                                    </div>
                                    <div className="item">
                                    <Radio
                                        label="Display to all matches who paid"
                                        className="pink"
                                        name='radioGroup'
                                        value={2}
                                        checked={this.state.photo_privacy === 2}
                                        onChange={this.setPrivacy}
                                      />
                                      <div class="description">
                                          <span className="mr-1 d-inline-block pointer"></span>
                                      </div>
                                    </div>
                                    <div className="item">
                                    <Radio
                                        label="Display only if i accept"
                                        className="pink"
                                        name='radioGroup'
                                        value={1}
                                        checked={this.state.photo_privacy === 1}
                                        onChange={this.setPrivacy}
                                      />
                                      <div class="description">
                                          <span className="mr-1 d-inline-block pointer"></span>
                                      </div>
                                    </div>
                                      </div>
                                    </div>
                                  </div>
                            </div>
                            <div className="px-2 py-3 border-top d-flex item-center">
                              <button className="ui button btn-link text-blue font-weight-normal p-0 mr-0 guideline-lg"
                                onClick={() =>{
                                  this.props.openModal("PhotoGuide", { customClass: "modal-md"})
                                }
                              }
                              >Photo guidlines</button>
                              <div className="ml-auto">
                                <Button className="ui button btn-link text-dark p-0 font-14 mr-0" onClick={this.handleSkipSubmission} >Upload later</Button>
                                <Button className="ui button btn-green ml-2 mr-0" onClick={this.handleFormSubmission} >Continue</Button>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                    </div>
              </div>
            </div>
          </div>
      </Wrapper>
    );
  }
}

/**
 *
 * @param {*} state
 */
const mapStateToProps = state => {
  return {
    data: state.user,
    commonData: state.commonData,
    spinner: state.commonData.commonDataSpinner,
    globalSpinner: state.globalSpinner.globalSpinner,
    errors: state.error.formsError,
    allCommonData: state.commonData.allCommonData,
    profilePicSpinner: state.user.profilePicSpinner,
    croppedProfilePicUrl: state.user.croppedProfilePicUrl
  };
};

export default connect(
  mapStateToProps,
  { getBasicProfileDataServer, getCommonData, almostThere ,openModal,closeModal, SavePhotoPrivacy}
)(FinalStep);
