import React from "react";

export default function AboutSingleView(props) {
  const { match_details } = props;
  return (
   match_details.about.hasOwnProperty('about') && match_details.about.about !== "" && match_details.about.about !== null && (
      <div className="ui fluid card wd-card view-card-group mt-0 mb-1">
        <div className="header">
          <div className="title text-black font-17 font-weight-semibold">
            <span>
              <i className="i-user-blue" />
            </span>
            About {match_details.name}

          </div>
        </div>

        <div className="content">
          <div className="row">
            <i className={`icon-md i-user-fill-${match_details.gender === "male" ? `male` : `female`} d-inline-block float-left mr-2`} />
            <div className="o-hidden">
              <p>{match_details.about.about}</p>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
