import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";

import PlacesAutocomplete from "react-places-autocomplete";
import { ASSETS_CDN } from "../../../../../../util/GlobalContants";


/**
 * @ResusableErrorComponent
 */
import { Error } from "../../../../../../common/shared-components/Error";
import { filterItems } from "../../../../../../util/filter_common_data";
import { genderPrefix } from "../../../../../../util/getGender"; 

class LanguagesLocationsSteps extends Component {


  getStateCitySearchData = value => {
    this.props.setParentState('living_location',(value && value !== "") ? value : null)
  }

  handleChange = address => {
    if (address === "" || address === null) {
      this.props.setParentState('living_location', null)
    } else {
      this.props.setParentState('living_location',(address && address !== "") ? address : null)
    }
  }

  handleSelect = address => {
    this.props.setParentState('living_location',(address && address !== "") ? address : null)
  }

  render() {
    const {
      data: { data },
      state,
      allCommonData,
      errors
    } = this.props;

    return (
      <React.Fragment>
        <div className="wd-form-divider pt-0">
          <div className="ui header">
            <span>Locations &amp; Languages</span>
          </div>
        </div>
        <div className="inline fields mb-4">
          <label className="w-50 lh-md">
            <span className="mr-05">Birth Country</span>
          </label>
          <div className="w-100">
            <Dropdown
              search
              className={`wd-input ${Error(errors, "birth_country")}`}
              placeholder="Select Birth Country"
              fluid
              selection
              clearable
              selectOnBlur={false}
              options={filterItems(
                "nationality",
                allCommonData && allCommonData.data
              ).map(nation => {
                return {
                  key: nation.id,
                  text: nation.name,
                  value: nation.id
                };
              })}
              onChange={(e, { value }) => {
                this.props.setParentState('birth_country',(value && value !== "") ? value : null)
              }}
              value={state.birth_country}
            />
          </div>
        </div>
        <div className="inline fields mb-4">
          <label className="w-50 lh-md">
            <span className="mr-05">Living Country</span>
          </label>
          <div className="w-100">
            <Dropdown
              search
              className={`wd-input ${Error(errors, "living_country")}`}
              placeholder="Select Living Country"
              fluid
              selection
              clearable
              selectOnBlur={false}
              options={filterItems(
                "nationality",
                allCommonData && allCommonData.data
              ).map(nation => {
                return {
                  key: nation.id,
                  text: nation.name,
                  value: nation.id
                };
              })}
              onChange={(e, { value }) => {
                if(!value || value !== ""){
                  this.props.setParentState('living_location', null)
                }
                this.props.setParentState('living_country',(value && value !== "") ? value : null)
              }}
              value={state.living_country}
            />
          </div>
        </div>
        {state.living_country !== "" && state.living_country !== null && (
          <React.Fragment>
            <div className="inline fields mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Living City / State</span>
              </label>
              <div className="w-100">
                <PlacesAutocomplete
                  value={state.living_location}
                  onChange={this.handleChange}
                  onSelect={this.handleSelect}
                  debounce={300}
                  searchOptions={{
                    types: ["(cities)"],
                    componentRestrictions: {
                      country: state.living_country
                    }
                  }}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                    <div className="pos-relative">
                      <div className="ui fluid search mb-0">
                        <div className="ui right icon input w-100">
                          <input
                            {...getInputProps({
                              placeholder: "Search Places ...",
                              className: "prompt w-100  wd-input"
                            })}
                          />
                        </div>
                      </div>
                      {suggestions.length > 0 && (
                        <div className="autocomplete-dropdown-container">
                          {suggestions.map(suggestion => {
                            const className = suggestion.active
                              ? "suggestion-item suggestion-item-active"
                              : "suggestion-item";
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                          <div className="autocomplete-dropdown-footer">
                            <img
                              alt="google Logo"
                              src={`${ASSETS_CDN}/img/powered_by_google_default.png`}
                              className="autocomplete-dropdown-footer-image"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
            </div>
          </React.Fragment>
        )}
       {
        state.living_country !== "" && 
        state.living_country !== null && 
        state.birth_country !== "" && 
        state.birth_country !== null && 
        (state.living_country !== state.birth_country) &&  (
        <React.Fragment>
          <div className="inline fields mb-4">
          <label className="w-50 lh-md">
       <span className="mr-05">Nationality</span>
          </label>
          <div className="w-100">
            <Dropdown
              search
              className={`wd-input ${Error(errors, "nationality")}`}
              placeholder="Select Nationality"
              fluid
              selection
              clearable
              selectOnBlur={false}
              options={filterItems(
                "nationality",
                allCommonData && allCommonData.data
              ).map(nation => {
                return {
                  key: nation.id,
                  text: nation.name,
                  value: nation.id
                };
              })}
              onChange={(e, { value }) => {
                this.props.setParentState('nationality',(value && value !== "") ? value : null)
              }}
              value={state.nationality}
            />
          </div>
        </div>
        {
        state.nationality !== "" && 
        state.nationality !== null && 
        (state.living_country !== state.nationality) &&  (
        <React.Fragment>
        <div className="inline fields mb-4">
          <label className="w-50 lh-md">
            <span className="mr-05">Visa Status of Living Country</span>
          </label>
          <div className="w-100">
            <Dropdown
              search
              className={`wd-input ${Error(errors, "residence_status")}`}
              placeholder="Select Visa Status of Living country"
              fluid
              clearable
              selection
              selectOnBlur={false}
              options={filterItems(
                "residence-status",
                allCommonData && allCommonData.data
              )}
              onChange={(e, { value }) => {
                this.props.setParentState('residence_status',(value && value !== "") ? value : null)
              }}
              value={state.residence_status}
            />
          </div>
        </div>
        </React.Fragment>
          )}
        </React.Fragment>
        )}

        <div className="inline fields mb-4 flex-start">
          <label className="w-50 lh-md">
            <span className="mr-05">Primary Speaking Language</span>
          </label>
          <div className="w-100">
            <Dropdown
              search
              className={`wd-input ${Error(errors, "primary_lang")}`}
              placeholder="Primary Speaking Language"
              fluid
              selection
              clearable
              selectOnBlur={false}
              options={filterItems(
                "mother-tongue",
                allCommonData && allCommonData.data
              )}
              onChange={(e, { value }) =>
                this.props.setParentState('primary_lang',value)
              }
              value={state.primary_lang}
            />
          </div>
        </div>
        <div className="inline field fields mb-4">
          <label className="w-50 lh-md">
            <span className="mr-05">Native District in Kerala</span>
          </label>
          <div className="w-100">
            <Dropdown
                search
                className={`wd-input ${Error(
                    this.props.errors,
                    "native_district"
                )}`}
                placeholder="Native District in Kerala"
                fluid
                selection
                clearable
                selectOnBlur={false}
                options={filterItems(
                    "district",
                    allCommonData && allCommonData.data
                )}
                onChange={(e, { value }) =>
                    this.props.setParentState('native_district',(value && value !== "") ? value : null)
                }
                value={state.native_district}
            />
          </div>
        </div>
        <div className="inline fields mb-4">
          <label className="w-50 lh-md">
            <span className="mr-05">Other Speaking Languages</span>
          </label>
          <div className="w-100">
            <Dropdown
              search
              className={`wd-input ${Error(errors, "other_langs")}`}
              placeholder="Other Speaking Languages"
              fluid
              multiple
              selection
              selectOnBlur={false}
              options={filterItems(
                "mother-tongue",
                allCommonData && allCommonData.data
              )}
              onChange={(e, { value }) =>
                this.props.setParentState('other_langs',value)
              }
              value={state.other_langs}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default LanguagesLocationsSteps;
