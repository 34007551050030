import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dropdown, Button } from "semantic-ui-react";
import PlacesAutocomplete from "react-places-autocomplete";

import { closeModal, customSweetAlert } from "../redux-state/modalActions";
import {
  singleProfile,
  updateDraftDashboardProfile
} from "../../../redux/actions/user/user";
import { getCommonData } from "../../../redux/actions/common/common-data";
import { filterItems } from "../../../util/filter_common_data";
import { Error } from "../../../common/shared-components/Error";

import { setUserData } from "../../../util/setUserData";
import { ASSETS_CDN } from "../../../util/GlobalContants";

function LocationAndLanguageModal(props) {

  const [binded, setBinded] = useState(false);
  const [birth_country, set_birth_country] = useState(null);
  const [living_country, set_living_country] = useState(null);
  const [living_location, set_living_location] = useState(null);
  const [native_district, set_native_district] = useState(null);
  const [nationality, set_nationality] = useState(null);
  const [residence_status, set_residence_status] = useState(null);
  const [primary_lang, set_primary_lang] = useState(null);
  const [other_langs, set_other_langs] = useState([]);

  const updateLocationAndLanguage = e => {
    e.preventDefault();
    const formData = {

      birth_country: birth_country === "" ? null : birth_country,
      living_country: living_country === "" ? null : living_country,
      living_location: living_location === "" ? null : living_location,
      native_district: native_district === "" ? null : native_district,
      nationality: nationality === "" ? null : nationality,
      residence_status: residence_status === "" ? null : residence_status,
      primary_lang: primary_lang === "" ? null : primary_lang,
      other_langs:  other_langs
    };
    props.updateDraftDashboardProfile(
      `profile/${props.profileId}`,
      "step-location-language",
      formData
    );
  };

  const handleChange = address => {
    set_living_location(null);
    set_living_location(address);
  };

  const handleSelect = address => {
    set_living_location(address);
  };
  useEffect(() => {
    if (props.singleUser === null) {
      props.singleProfile(`profile/${props.profileId}`);
    }
    if (props.allCommonData.length < 1) {
      props.getCommonData();
    }

    // setting default state
    if (props.singleUser && !binded) {
      setBinded(true)
      set_birth_country(
        setUserData(props.singleUser, birth_country, "birth_country")
      );
      set_living_country(
        setUserData(props.singleUser, living_country, "living_country")
      );
      set_living_location(
        living_location !== null
          ? living_location
          : props.singleUser.living_location
      );

      set_native_district(
        setUserData(props.singleUser, native_district, "native_district")
      );
      set_nationality(
        setUserData(props.singleUser, nationality, "nationality")
      );
      set_residence_status(
        setUserData(props.singleUser, residence_status, "residence_status")
      );
      set_primary_lang(setUserData(props.singleUser, primary_lang, "primary_lang"));
      set_other_langs(setUserData(props.singleUser, other_langs, "other_langs"));
    }
  }, [
    birth_country,
    living_country,
    living_location,
    nationality,
    native_district,
    props,
    residence_status,
    primary_lang,
    other_langs,
    binded
  ]);

  return (
    <React.Fragment>
      <div className="ui header">
        <h3>Location &amp; Language</h3>
      </div>
      <div className="content">
        <form method="POST" onSubmit={updateLocationAndLanguage}>
          <div className="ui form">
            <div className="inline field fields mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Birth Country</span>
                
              </label>
              <div className="w-100">
                <Dropdown
                  search
                  className={`wd-input ${Error(props.errors, "birth_country")}`}
                  placeholder="Select Birth Country"
                  fluid
                  selection
                  clearable
                  selectOnBlur={false}
                  options={filterItems(
                    "nationality",
                    props.allCommonData && props.allCommonData.data
                  ).map(nation => {
                    return {
                      key: nation.id,
                      text: nation.name,
                      value: nation.id
                    };
                  })}
                  onChange={(e, { value }) => set_birth_country(value)}
                  value={birth_country}
                />
              </div>
            </div>
            <div className="inline field fields mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Living Country</span>
                
              </label>
              <div className="w-100">
                <Dropdown
                  search
                  className={`wd-input ${Error(
                    props.errors,
                    "living_country"
                  )}`}
                  placeholder="Select Living Country"
                  fluid
                  selection
                  clearable
                  selectOnBlur={false}
                  options={filterItems(
                    "nationality",
                    props.allCommonData && props.allCommonData.data
                  ).map(nation => {
                    return {
                      key: nation.id,
                      text: nation.name,
                      value: nation.id
                    };
                  })}
                  onChange={(e, { value }) => set_living_country(value)}
                  value={living_country}
                />
              </div>
            </div>

            {living_country !== null && (
              <div className="inline field fields mb-4">
                <label className="w-50 lh-md">
                  <span className="mr-04">Living City / State</span>
                  
                </label>
                <div className="w-100">
                  <PlacesAutocomplete
                    value={living_location}
                    onChange={handleChange}
                    onSelect={handleSelect}
                    debounce={300}
                    searchOptions={{
                      types: ["(cities)"],
                      componentRestrictions: {
                        country: living_country
                      }
                    }}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps
                    }) => (
                        <div className="pos-relative">
                          <div className="ui fluid search mb-0">
                            <div className="ui right icon input w-100">
                              <input
                                {...getInputProps({
                                  placeholder: "Search Places ...",
                                  className: "prompt w-100   wd-input m-0"
                                })}
                              />
                              <i className="search icon" />
                            </div>
                          </div>
                          {suggestions.length > 0 && (
                            <div className="autocomplete-dropdown-container">
                              {suggestions.map(suggestion => {
                                const className = suggestion.active
                                  ? "suggestion-item suggestion-item-active"
                                  : "suggestion-item";
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                              <div className="autocomplete-dropdown-footer">
                                <img
                                  alt=""
                                  src={`${ASSETS_CDN}/img/powered_by_google_default.png`}
                                  className="autocomplete-dropdown-footer-image"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                  </PlacesAutocomplete>
                </div>
              </div>
            )}

            <div className="inline field fields mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Nationality</span>
                
              </label>
              <div className="w-100">
                <Dropdown
                  search
                  className={`wd-input ${Error(props.errors, "nationality")}`}
                  placeholder="Select Nationality"
                  fluid
                  selection
                  selectOnBlur={false}
                  options={filterItems(
                    "nationality",
                    props.allCommonData && props.allCommonData.data
                  )}
                  onChange={(e, { value }) => set_nationality(value)}
                  value={nationality}
                />
              </div>
            </div>
            <div className="inline field fields mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Visa Status of Living Country</span>
                
              </label>
              <div className="w-100">
                <Dropdown
                  search
                  className={`wd-input ${Error(
                    props.errors,
                    "residence_status"
                  )}`}
                  placeholder="Visa Status of Living Country"
                  fluid
                  clearable
                  selection
                  selectOnBlur={false}
                  options={filterItems(
                    "residence-status",
                    props.allCommonData && props.allCommonData.data
                  )}
                  onChange={(e, { value }) => set_residence_status(value)}
                  value={residence_status}
                />
              </div>
            </div>
            <div className="inline field fields mb-4">
                          <label className="w-50 lh-md">
                            <span className="mr-05">Native District in Kerala</span>
                          </label>
                          <div className="w-100">
                          <Dropdown
                              search
                              className={`wd-input ${Error(
                                props.errors,
                                "native_district"
                              )}`}
                              placeholder="Native District in Kerala"
                              fluid
                              selection
                              clearable
                              selectOnBlur={false}
                              options={filterItems(
                                "district",
                                props.allCommonData && props.allCommonData.data
                              )}
                              onChange={(e, { value }) =>
                              set_native_district(value)
                              }
                              value={native_district}
                            />
                          </div>
                        </div>
            <div className="inline fields mb-4 flex-start">
              <label className="w-50 lh-md">
                <span className="mr-05">Primary Speaking Language</span>
                
              </label>
              <div className="w-100">
                <Dropdown
                  search
                  className={`wd-input ${Error(props.errors, "primary_lang")}`}
                  placeholder="Primary Speaking Language"
                  fluid
                  selection
                  clearable
                  selectOnBlur={false}
                  options={filterItems(
                    "mother-tongue",
                    props.allCommonData && props.allCommonData.data
                  )}
                  onChange={(e, { value }) =>
                    set_primary_lang(value)
                  }
                  value={primary_lang}
                />
              </div>
            </div>
            <div className="inline fields mb-4">
              <label className="w-50 lh-md">
              <span className="mr-05">Other Speaking Languages</span>
                
              </label>
              <div className="w-100">
                <Dropdown
                  search
                  className="wd-input"
                  placeholder="Other Speaking Languages"
                  fluid
                  multiple
                  selection
  
                  selectOnBlur={false}
                  options={filterItems(
                    "mother-tongue",
                    props.allCommonData && props.allCommonData.data
                  )}
                  onChange={(e, { value }) =>
                    set_other_langs(value)
                  }
                  value={other_langs}
                />
              </div>
            </div>
            <div className="inline fields">
              <label className="w-50 lh-md hidden-xs" />
              <div className="w-100">
                <div className="two wide fields m-0 w-100 btn-group">
                  <div className="field mb-0">
                    <Button
                      loading={props.updateProSpinner}
                      className="fluid ui button btn-pink ripple"
                      type="submit"
                    >
                      Save
                    </Button>
                  </div>
                  <div className="field pr-0 mb-0">
                    <Button
                      onClick={() => props.customSweetAlert()}
                      className="fluid ui button btn-white ripple"
                      type="button"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    allCommonData: state.commonData.allCommonData,
    errors: state.error.formsError,
    singleUser: state.user.singleProfileData,
    updateProSpinner: state.user.updateProSpinner
  };
};

export default connect(
  mapStateToProps,

  {
    closeModal,
    singleProfile,
    getCommonData,
    updateDraftDashboardProfile,
    customSweetAlert
  }
)(LocationAndLanguageModal);
