import { debounce } from "lodash";
import React from "react";
import { gpAxios } from "../../util/axios";

class CareerSearch extends React.Component {

  state = {
    loading: false,
    value: null,
    options: [],
    placeholder: "Search"
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside= (event) =>{
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        options: []
      })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps){
        this.setState({
            value : nextProps.value,
            placeholder:  nextProps.placeholder
        })
      }
  }

  _handleSearch  = debounce(async(value) => {
    try {
        if(value.length < 2){
            this.setState({ loading : false, options: []})
            return;
        }
        this.setState({ loading : true})
        const result = await gpAxios.get(`/common/occupation?q=${value.trim()}`);
        const obj = result.data.data
        this.setState({ loading : false, options: obj || []})
      } catch (error) {
        this.setState({ loading : false})
      }
  },200)

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  }
  _onSelected = selected => {
        this.setState({
            options: [],
            value: selected
        })
        this.props.onChange(selected)
  };

  render() {
    return (
      <React.Fragment>
          <div ref={this.setWrapperRef} className="pos-relative">
            <div className="ui fluid search mb-0">
                <div className="ui right icon input w-100">
                    <input 
                        type="text" 
                        autoComplete="off"  
                        placeholder={this.props.placeholder} 
                        className="prompt w-100 wd-input m-0" 
                        value={this.state.value || ''}
                        onChange={({ target: { value } }) => {
                            this.props.onChange(value)
                            this.setState({value:value})
                            this._handleSearch(value)
                        }}
                        />
                        { this.state.loading ? (<i className="spinner fa-spin icon"></i>) : (<i className="search icon"></i>)}
                </div>
            </div>
            { this.state.options.length > 0 && (
                <div className="autocomplete-dropdown-container">
                 { this.state.options.map(item => {
                    return (
                      <div 
                      className="suggestion-item"
                      key={item.value}
                      onClick={e => {
                            this._onSelected(item.text)
                      }}>
                      {item.text}
                      </div>
                    );
                  })}
                </div>
                )
            }
        </div>
      </React.Fragment>
    );
  }
}

export default CareerSearch;
