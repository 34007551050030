import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import { Button, Dimmer, Loader } from "semantic-ui-react";


import { getNameFromArrayObject } from "../../../../../../util/getNameFromArrayObject";
import { filterItems } from "../../../../../../util/filter_common_data";
import { setShortlist } from "../../../../../../redux/actions/matches/matches";
import { openModal } from "../../../../../modals/redux-state/modalActions";
import MatchDetailPhoto from "../../../../../../common/shared-components/photo/detail_photo";
import { gpAxios, chatAxios } from "../../../../../../util/axios";
import swal from "sweetalert";
import { Link  } from "react-router-dom";

function ProfileCard(props) {
  /**
   * @description: destructuring state
   */
  const {
    match_details,
    common_data,
    setShortlist,
    profileId,
    short_list,
    hide,
    single_user,
    openModal,
    upgradeWarning,
    photoWarning
  } = props;

  /**
   * @description: states
   */
  const [spinner, set_spinner] = useState(false);
  const [chat_spinner, set_chat_spinner] = useState(false);

  /**
   * @description: loading contact information
   */

  const initChat = async (token) => {
    try{
      console.log(token);
      return false;
      if(match_details.conversation_id === null) {
        const confirm = await swal({
          title: "Send Interest",
          text: "Would you like to send your interest to this profile?",
          icon: "warning",
          buttons: ["Cancel", "Send"],
          dangerMode: true
        });
        if(!confirm){
          return
        }

      }

      set_chat_spinner(true);
      const {data} = await chatAxios.post(`/conversations`,{ 
        token : token,
        type: 'ptext' ,
        text : 'interested'
      })
      set_chat_spinner(false);
      if(data && data.result) {
        if(data.data.conversation_id){
          window.location.href = `/${profileId}/messages/#${data.data.conversation_id}`
        }
      }
    }catch(e){
        set_chat_spinner(false);
    }
  }
  const viewContact = async () => {

    if (single_user.state !== 2) {
      openModal("PendingActivationModal", { customClass: "modal-md", profileId : profileId})
      return;
    }

    set_spinner(true);

    if (localStorage.getItem("contactAlert") == null && single_user.package !== "free") {
      localStorage.setItem("contactAlert", true);
      const confirm = await swal({
        title: "Contact detail",
        text: "Once viewed, one contact number count will be reduced from your monthly view eligibility limit. Please view the number only if you think it's a potential match.",
        icon: "warning",
        buttons: ["Cancel", "View contact"],
        dangerMode: true
      });
      if (confirm) {
        try {
          const contact_data = await gpAxios.get(
            `profile/${profileId}/matches/${match_details.id}/contact`
          );
          set_spinner(false);

          common_data.data &&
            openModal("PremimumUserModal", {
              className: "tiny",
              contact_details: contact_data.data,
              common_data: common_data && common_data,
              matched_name: match_details.name
            });
        } catch (error) {

          set_spinner(false);
          if (single_user.package !== "free") {
            openModal("PremimumUsersUpgradeModal", {
              customClass: "tiny upgrade-modal",
              matched_name: match_details.name,
              phone_number:
                match_details.hasOwnProperty("contact") &&
                match_details.contact !== null &&
                match_details.contact.phone,
              email:
                match_details.hasOwnProperty("contact") &&
                match_details.contact !== null &&
                match_details.contact.hasOwnProperty("email") &&
                match_details.contact.email,
              reset_date:
                single_user.package !== null &&
                single_user.package.hasOwnProperty("reset_at") &&
                single_user.package.reset_at !== null &&
                single_user.package.reset_at,
              upgrade_link: single_user && single_user.upgrade_link
            });
          } else {
            openModal("UpgradeModal", {
              customClass: "tiny upgrade-modal",
              matched_name: match_details.name,
              phone_number:
                match_details.hasOwnProperty("contact") &&
                match_details.contact !== null &&
                match_details.contact.phone,
              email:
                match_details.hasOwnProperty("contact") &&
                match_details.contact !== null &&
                match_details.contact.hasOwnProperty("email") &&
                match_details.contact.email,
              upgrade_link: single_user && single_user.upgrade_link
            });
          }
        }
      } else {
        set_spinner(false);
      }
    } else {
      try {
        const contact_data = await gpAxios.get(
          `profile/${profileId}/matches/${match_details.id}/contact`
        );

        set_spinner(false);
        common_data.data &&
          openModal("PremimumUserModal", {
            className: "tiny",
            contact_details: contact_data.data,
            common_data: common_data && common_data,
            matched_name: match_details.name
          });
      } catch (error) {

        set_spinner(false);
        if (single_user.package !== "free") {
          openModal("PremimumUsersUpgradeModal", {
            customClass: "tiny upgrade-modal",
            matched_name: match_details.name,
            phone_number:
              match_details.hasOwnProperty("contact") &&
              match_details.contact !== null &&
              match_details.contact.phone,
            email:
              match_details.hasOwnProperty("contact") &&
              match_details.contact !== null &&
              match_details.contact.hasOwnProperty("email") &&
              match_details.contact.email,
            reset_date:
              single_user.package !== null &&
              single_user.package.hasOwnProperty("reset_at") &&
              single_user.package.reset_at !== null &&
              single_user.package.reset_at,
            upgrade_link: single_user && single_user.upgrade_link
          });
        } else {
          openModal("UpgradeModal", {
            customClass: "tiny upgrade-modal",
            matched_name: match_details.name,
            phone_number:
              match_details.hasOwnProperty("contact") &&
              match_details.contact !== null &&
              match_details.contact.phone,
            email:
              match_details.hasOwnProperty("contact") &&
              match_details.contact !== null &&
              match_details.contact.hasOwnProperty("email") &&
              match_details.contact.email,
            upgrade_link: single_user && single_user.upgrade_link
          });
        }
      }
    }
  };
  return (
    <Fragment>
      <div className="match-view-header mb-05">
      {upgradeWarning && (
          <a href={single_user && single_user.upgrade_link} target="_blank" rel="noopener noreferrer" className="reverse-upgrade">
              <div className="content">To view this match, upgrade your profile to premium</div>
          </a>
      )}
      

      {photoWarning && (
          <Link to={`/${profileId}/edit-profile`} className="reverse-upgrade">
              <div className="content">Please upload your photo to see the full profile.</div>
          </Link>
      )}
      
                                        
      {match_details.package_group !== null && (
          <span className="mobi-match-label premium">
           Premium
          </span>
        )}

        <div className="view-header-left">
          <MatchDetailPhoto 
            single_user={single_user} 
            match_details={match_details} 
          />
        </div>
        <div className="match-view-content">
          <div className="match-view-description-wrapper h-100">
            <div className="description-header">
              <div className="match-name">
                <span className="mr-05">{match_details.name}</span>
                {match_details.package_group !== null && (
                  <span className="ui label match-label premium">
                    Premium
                  </span>
                )}
              </div>
              <div className="description">
                <span className="match-id">{match_details.did}</span>
                <span className="createdby">
                  Created by{" "}
                  {
                    getNameFromArrayObject(
                      match_details.created_by,
                      filterItems("relationship", common_data.data)
                    )[0]
                  }{" "}
                  {match_details.created_at_diff}
                </span>
              </div>
            </div>
            <div className="ui grid extra-content">
              <div className="eight wide column text-truncate">
                <div className="font-12 text-black text-truncate">
                  <i className={`icon-md i-user-fill-${match_details.gender} d-inline-block align-middle mr-1`} />
                    {match_details.about_txt}
                </div>
              </div>
              <div className="eight wide column text-truncate">
                <div className="font-12 text-black text-truncate">
                  <i className="icon icon-sm i-map-pin d-inline-block align-middle mr-1" />
                  {match_details.location_txt}
                </div>
              </div>
              <div className="eight wide column text-truncate">
                <div className="font-12 text-black text-truncate">
                  <i className="icon icon-sm i-language-bubble d-inline-block align-middle mr-1" />
                  {match_details.community_txt}
                </div>
              </div>
            </div>
            <div className="description-footer">
              <div className="match-activity mt-05 mb-05">
                <span className="text-gray font-12">
                  Last activity : {match_details.last_accessed_diff}
                </span>
              </div>
            </div>
            <div className="d-flex item-center bg-yellow-light p-1 action-block">
              {!short_list && (
                <Fragment>
                  {!hide && (
                    <button
                      type="button"
                      onClick={() =>{
                        if(photoWarning) {
                          openModal("UploadPhotoWarning", { customClass: "modal-md", profileId : profileId})
                        }
                        else if(upgradeWarning) {
                          openModal("UpgradeModal", { customClass: "modal-md", profileId : profileId})
                        }
                        else if(single_user.state===2){
                          setShortlist(
                            `/profile/${profileId}/matches/${match_details.id}/shortlist`,
                            { set: 1 },
                            "shortlist"
                          )
                        }else{
                          openModal("PendingActivationModal", { customClass: "modal-md", profileId : profileId})
                        }
                      }
                      }
                      className="ui small button bg-white border-green box-shadow font-weight-normal text-black radius-2 ripple mr-05 p-1"
                    >
                      Shortlist
                    </button>
                  )}

                  {hide ? (
                    <span>
                      This profile has been hide.{" "}
                      <button
                        onClick={() =>{
                          if(photoWarning) {
                            openModal("UploadPhotoWarning", { customClass: "modal-md", profileId : profileId})
                          }
                          else if(upgradeWarning) {
                            openModal("UpgradeModal", { customClass: "modal-md", profileId : profileId})
                          }
                          else if(single_user.state===2){
                            setShortlist(
                              `/profile/${profileId}/matches/${match_details.id}/hide`,
                              { set: 0 },
                              "hide"
                            )
                          }else{
                            openModal("PendingActivationModal", { customClass: "modal-md", profileId : profileId})
                          }
                        }
                        }
                        type="button"
                        className="ui button btn-link text-blue p-0 font-weight-normal"
                      >
                        Change your mind?
                      </button>
                    </span>
                  ) : (
                      <button
                        type="button"
                        onClick={() =>{
                          if(photoWarning) {
                            openModal("UploadPhotoWarning", { customClass: "modal-md", profileId : profileId})
                          }
                          else if(upgradeWarning) {
                            openModal("UpgradeModal", { customClass: "modal-md", profileId : profileId})
                          }
                          else if(single_user.state===2){
                            setShortlist(
                              `/profile/${profileId}/matches/${match_details.id}/hide`,
                              { set: 1 },
                              "hide"
                            )
                          }else{
                            openModal("PendingActivationModal", { customClass: "modal-md", profileId : profileId})
                          }
                        }
                      }
                        className="ui small button bg-white border box-shadow font-weight-normal text-black radius-2 ripple mr-05 p-1"
                      >
                        <i className="icon icon-sm i-times-yellow d-inline-block align-middle mr-05" />
                        <span className="align-middle">Hide</span>
                      </button>
                    )}
                </Fragment>
              )}

              {short_list && (
                <Fragment>
                  <button
                    type="button"
                    className="ui small button bg-white border-green box-shadow font-weight-normal text-black radius-2 ripple mr-1 p-1"
                  >
                    <i className="icon icon-
                    sm i-check-green d-inline-block align-middle mr-05" />
                    <span className="align-middle">Shortlisted</span>
                  </button>
                  <button
                    type="button"
                    onClick={() =>{
                      if(photoWarning) {
                        openModal("UploadPhotoWarning", { customClass: "modal-md", profileId : profileId})
                      }
                      else if(upgradeWarning) {
                        openModal("UpgradeModal", { customClass: "modal-md", profileId : profileId})
                      }
                      else if(single_user.state===2){
                        setShortlist(
                          `/profile/${profileId}/matches/${match_details.id}/shortlist`,
                          { set: 0 },
                          "shortlist"
                        )
                      }else{
                        openModal("PendingActivationModal", { customClass: "modal-md", profileId : profileId})
                      }
                    }
                    }
                    className="ui button btn-link text-blue p-0 font-weight-normal"
                  >
                    Remove Shortlist?
                  </button>
                </Fragment>
              )}

              <div className="d-flex ml-auto">
                <Dimmer active={chat_spinner} inverted><Loader inverted content={null} /></Dimmer>
       {/*         <Button className="small bg-white border box-shadow font-weight-normal text-black radius-2 ripple mr-05 p-1 hidden-md"
                onClick={() =>{
                  initChat(match_details.chat_token)
                  // openModal("ChatModal", { 
                  //     customClass: "modal-md",
                  //     profileId : profileId ,
                  //     token : match_details.chat_token,
                  //     upgrade_link: single_user.upgrade_link,
                  //     subscription : single_user.package === 'free' || (single_user.package.group !== undefined && single_user.package.group === 1880)
                  //   })
                }}
                >
                <i class="icon icon-sm i-message-gray d-inline-block align-middle mr-05"></i>
                  Chat
                </Button>*/}
                <Button
                  loading={spinner}
                  className="ui small button btn-green border box-shadow font-weight-normal text-white radius-2 ripple mr-05 p-1 hidden-xs"
                  id="modal9"
                  type="button"
                  onClick={() =>{
                    if(photoWarning) {
                      openModal("UploadPhotoWarning", { customClass: "modal-md", profileId : profileId})
                    }
                    else if(upgradeWarning) {
                      openModal("UpgradeModal", { customClass: "modal-md", profileId : profileId})
                    }
                    else if(single_user.state===2){
                      viewContact()
                    }else{
                      openModal("PendingActivationModal", { customClass: "modal-md", profileId : profileId})
                    }
                  }
                }
                >
                  View Contact
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default connect(
  undefined,

  { setShortlist, openModal }
)(ProfileCard);
