const hisHergender = propsData => {
  return propsData && propsData.gender === "male" ? "His" : "Her";
};

const heOrShe = propsData => {
  return propsData && propsData.gender === "male" ? "He" : "She";
};

const profileName = propsData => {
  return propsData && propsData.name;
};

const genderPrefix = (gender,type) => {
  try{
    const data = {
      your : {
        male : 'His',
        female : 'Her'
      },
      you : {
        male : 'He',
        female : 'she'
      }
    }
  return data[type][gender];
  }catch(e) {
    return "";
  }
};

export { hisHergender, heOrShe, profileName, genderPrefix };
