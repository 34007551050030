import React, { Fragment , useEffect, useState } from "react"
import { connect } from "react-redux"
import { closeModal , openModal } from "../redux-state/modalActions"
import { Dimmer,Loader , Form, Radio } from "semantic-ui-react"
import { chatAxios } from "../../../util/axios"
import swal from "sweetalert"
import { Link } from "react-router-dom"

function ChatModal(props) {
  const [loading, setLoading] = useState(false)
  const [ message , setMessage] = useState("")
  const [ pt , setPt] = useState(null)
  const [ ptexts , setPtexts] = useState(null)
  const { token , subscription , closeModal, profileId, openModal , tokenUpdated, upgrade_link } = props

  useEffect(() => {
    async function fetchData() {
        try {
            setPtexts({})
            const {data} = await chatAxios.get(`/ptexts`,{params : { token : token}})
            if(data && data.result) {
                setPtexts(data.data)
            }
        } catch (error) {
        }
      }
      if(ptexts === null){
        fetchData()
      }
  })

  const sendMessage = async (e) => {
        e.preventDefault()
        try {
            setLoading(true)
            const {data} = await chatAxios.post(`/conversations`,{ 
                token : token,
                type: subscription ? 'ptext' : 'text' ,
                text : subscription ?  pt : message
            })
            if(data && data.result) {
                const wrapper = document.createElement('div')
                wrapper.innerHTML = `<a href="/${profileId}/messages" > Go to Chat</a>`
                swal({
                    title: 'Your message has been sent',
                    icon: "success",
                    content: wrapper
                  })
                closeModal()
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            if(error.response.status === 401 && error.response.data && error.response.data.spam){
                openModal('ContactViewAlert',{
                    token :  token,
                    profileId: profileId,
                    text: message,
                    tokenUpdated : (token) => {
                        tokenUpdated(token)
                    },
                    customClass : 'modal-xs'
                })
            }
        }
  }

  return (
    <Fragment>
      <Dimmer active={loading} inverted><Loader inverted content='Loading' /></Dimmer>
      <div className="modal-header border-bottom">
                    <div class="ui text menu">
                        <div className="item active">Write message</div>
                        <a href={`/${profileId}/messages`} className="item">Inbox</a>
                    </div>
                </div>
      <i className="close modal-close" onClick={() => closeModal()} >×</i>
      <div className="content modal-body">
      <span className="smiley"><i className="icon-lg i-chat-fill-blue mt-1"></i></span>
      <div className="ui form">
            
            <div className="field mb-2">
                <textarea 
                    className="wd-input textarea-light font-15" 
                    type="text"
                    maxLength="500"
                    readOnly={subscription}
                    onClick={async ()=> {
                        if(subscription){
                            const wrapper = document.createElement('div')
                            wrapper.innerHTML = `<p>Looking to send more customized chat by your own words? Check out our packages and avail the exclusive benefits 
                            </p>`
                            const r = await swal({
                                title: `Upgrade now!`,
                                buttons: {
                                    confirm: {
                                        text: "View Packages",
                                        value: true,
                                        visible: true,
                                        closeModal: true
                                      },
                                    cancel: {
                                      text: "Cancel",
                                      value: false,
                                      visible: true,
                                      closeModal: true,
                                    }
                                  },
                                content: wrapper
                              })
                              if(r) {
                                window.open(upgrade_link,'_blank');
                              }
                        }
                    }}
                    placeholder="Write message"
                    onChange={e => setMessage(e.target.value)}
                    value={message}
                />
                <div className="msg-box mt-2">
                {ptexts !== null && Object.keys(ptexts).map(t => {
                    return (<Radio
                        label={ptexts[t].key}
                        key={t}
                        name="ptext"
                        className="check-radio mb-1"
                        value={t}
                        checked={pt === t}
                        onChange={(e, { value }) => {
                            setPt(t)
                            setMessage(ptexts[t].value)
                        }}
                    />)
                })}
                </div>
            </div>
            <div className="field text-center mb-2">
                <button 
                    className="ui small button btn-blue border box-shadow font-weight-normal radius-2 ripple mr-0"
                    disabled={message === ''}
                    onClick={sendMessage}
                >Send message</button>
            </div>
        </div>
      </div>
    </Fragment>
  );
}

export default connect(
  undefined,
  { closeModal, openModal}
)(ChatModal);
