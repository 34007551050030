import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { gpAxios } from "../../../../../util/axios";
import {
  singleProfile,
  defaultSingleProfile
} from "../../../../../redux/actions/user/user";
import { toastr } from 'react-redux-toastr';
import { updateLayout } from "../../../../../redux/layout_controll/layout_action";
import { getCommonData } from "../../../../../redux/actions/common/common-data";
import {
  route_config
} from "../../../../../util/GlobalContants";

import Wrapper from "../../../../../common/shared-components/wrapper/Wrapper";
import {FormattedMessage,injectIntl} from "react-intl";
import { Button, Dropdown, Checkbox, Accordion } from "semantic-ui-react";
import RadioBox from "../../../../../common/shared-components/Radio";
import swal from "sweetalert"

import {
    arrayWithAny,
    cdosham,
    hisHeight,
    smokingPref,
    drikingPref,
    maritalStatus,
    age,
    skin_tone,
    body_type
  } from "../../../../../common/forms-data/forms-data";
  
import { checkAny } from "../../../../../util/check_any";
import { filterItems } from "../../../../../util/filter_common_data";

class EditPreference extends Component {
  state = {
    profileId: this.props.match.params.profileId,
    preferenceId : this.props.match.params.preferenceId,
    databind : false,
    saving : false,
    preference: null,
    errors: {},
    accordion: {
    },
    form : {
        marital_status : [],
        community: [],
        religion: [],
        caste: [],
        speaking_language:[],
        age: {
          min : null,
          max : null
        },
        height: {
          min : null,
          max : null
        },
        living_country: [],
        district: [],
        nationality: [],
        disability: null,
        diet: [],
        smoking_habit: null,
        drinking_habit: null,
        preferred_stars: [],
        avoid_stars: [],
        dosham: null,
        education_level: [],
        education_field: [],
        employment_type: [],
        created_by: [],
        children: null,
        children_state: null,
        skin_tone: [],
        body_type: [],
        description : null,
        strict : []
    }
  };

  componentDidMount() {
    this.props.defaultSingleProfile(`profile/${this.state.profileId}`);
    this.props.getCommonData();
  }

  componentWillUnmount() {
    // this.props.closeModal();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    
    if(!this.state.databind){
      if(nextProps.singleUser && nextProps.singleUser.partner_preferences  && nextProps.singleUser.partner_preferences.length){
        this.setState({
          databind : true
        })
        const data = nextProps.singleUser.partner_preferences.find(p => p.id == this.state.preferenceId)
        if(data) {
          const preference  = data.preference
          const stateObj = this.state.form;
          for(var item in stateObj){
              stateObj[item] = preference[item] !== null  ? preference[item] : stateObj[item] ;
          }
          stateObj.description = data.description;
          stateObj.strict = data.strict_match;
          this.setState({
              preference: preference,
              form : stateObj
          })
        }else{
          this.setState({
            preference : null
        })
        }
      }
    }
  }

  updateFormState = (field,value) => {
    const stateObj = this.state.form;
    stateObj[field] = value
    this.setState({
        form : stateObj
    })
  }
  updateStrictState = (data) => {
    const stateObj = this.state.form;
    const strict = stateObj.strict;
    if(data.checked){
        strict.push(data.value)
    }else{
        const i = strict.indexOf(data.value);
        if (i >= 0) {
            strict.splice(i, 1);
        }
    }
    stateObj.strict = strict
    this.setState({
        form : stateObj
    })
  }
  handleAccordion = (type) => {
    const stateObj = this.state.accordion;
    stateObj[type] = !stateObj[type] 
    this.setState({
      accordion : stateObj
    })
  }

  handleFormSubmission = async(publish, saveAs ) =>{
    try {
      if(publish || saveAs) {
        const confirm = await swal({
          title: "Are you sure",
          text: publish ? `Are you sure do you want to activate this?` : `Are you sure do you want to Save as new?`,
          icon: "warning",
          buttons: ['No','Yes'],
          dangerMode: true
        });
        if(!confirm){
            return
        }
      }
        this.setState({saving : true, errors: {}})
        const params = this.state.form
        params.publish = publish
        params.save_as_new = saveAs
  
        await gpAxios.post(`/profile/${this.state.profileId}/preferences/${this.state.preferenceId}`,params)
        this.setState({saving : false})
        toastr.success('Success!', 'Details are saved');
      } catch (error) {
        this.setState({saving : false})
        if(error.response && error.response.data && error.response.data.error){
          this.setState({errors : error.response.data.error})
        }
        toastr.error('Failed!', 'Details not saved');
      }
  };

  hasAnyError = (keys) => {
        let errors = []
        keys.forEach(k => {
            if(Object.keys(this.state.errors).includes(k)){
              errors.push(this.state.errors[k][0])
            }
        })
        return errors.length ? errors : false 
  }

  displayError = (errors) => {
    return errors && errors.length ? (<div class="text-red font-12 mt-05">{errors.join(', ')}</div>) : "";
  }

  render() {
    return (
      <Wrapper
        config={route_config.draft_dashboard}
        isLoading={this.props.globalSpinner}
        singleUser={this.props.singleUser}
      >
        <div className="content-body">
            <div className="content-wrapper">
            {!this.props.globalSpinner && (
                <div className="wd-rail">
                <div className="wd-middle-content">
                    <h1 className="page-title">Edit Partner Preference</h1>
                    <div className="font-12 text-gray mt--3 mb-2">
                        <b>Note:</b> Currently, you will receive matches based on the following preferences: Gender, Age, Community, Religion, Marital Status, Caste, Eating Habits, Physical Status and Kids Status . You can use "Strict Filters" to control the types of matches you receive.
                    </div>
                    <div className="ui relaxed divided items mt-0 preference-filter">
                    <div className="ui relaxed divided items mt-0 preference-filter">                                    
                                    <div className="ui fluid card wd-card mb-4">
                                        <div className="bg-blue-light p-3">
                                        <div className="font-15 font-weight-bold text-black">{ this.props.singleUser && this.props.singleUser.name}'s Partner Preference</div>
                                            <div className="font-13 text-gray">Tell us about your life partner</div>
                                        </div>
                                        <form method="post" onSubmit={(e) => {
                                          e.preventDefault()
                                        }} className="ui form custom-form">
                                        {this.state.saving &&(  
                                          <div class="ui active dimmer inverted radius-4">
                                            <div class="ui small text active inverted loader">Please wait..</div>
                                          </div>)
                                        }
                                            <div className="content p-3 b-none">
                                                <div className="d-inline-block w-100 mb-2">
                                                    <span className="float-right font-12">Manage Strict</span>
                                                </div>
                                                <div className="inline field fields flex-start mb-4">
                                                <label className="w-50 lh-md">
                                                <span className="mr-05"><FormattedMessage id="preference.description"/></span>
                                                </label>
                                                <div className="w-100">
                                                <div className="d-flex w-100">
                                                    <textarea
                                                    className="wd-input"
                                                    type="text"
                                                    placeholder="Describe about your dream partner...."
                                                    defaultValue={""}
                                                    onChange={e => {
                                                        this.updateFormState("description",e.target.value)
                                                    }}
                                                    value={this.state.form.description}
                                                    />
                                                    <div className="strict-box" />
                                                </div>
                                                </div>
                                            </div>
                                              
                                            <div 
                                            className={this.hasAnyError(['age.min','age.max']) ? "inline field fields mb-4 error" : "inline field fields mb-4"}
                                            >
                                            <label className="w-50 lh-md">
                                              <span className="mr-05"><FormattedMessage id="preference.age"/></span>
                                            </label>
                                            <div className="w-100">
                                              <div className="d-flex w-100">
                                                <div className="mr-33">
                                                  <div className="d-flex item-center age-group">
                                                    <span className="font-15 mr-05">From</span>
                                                    <span className="w-xxs mr-05">
                                                      <Dropdown
                                                        search
                                                        className={`wd-input`}
                                                        placeholder="Age"
                                                        fluid
                                                        selection
                                                        disabled={this.state.preference &&  this.state.preference.age.min !== null}
                                                        selectOnBlur={false}
                                                        options={age}
                                                        onChange={(e, { value }) => {
                                                          this.updateFormState("age",{
                                                            min : value,
                                                            max : this.state.form.age.max
                                                          })
                                                        }}
                                                        value={this.state.form.age.min}
                                                      />
                                                    </span>
                                                    <span className="font-15 mr-05">to</span>
                                                    <span className="w-xxs mr-05">
                                                      <Dropdown
                                                        search
                                                        className={`wd-input`}
                                                        placeholder="Age"
                                                        fluid
                                                        selection
                                                        selectOnBlur={false}
                                                        options={age}
                                                        disabled={this.state.preference &&  this.state.preference.age.max !== null}
                                                        onChange={(e, { value }) => {
                                                          this.updateFormState("age",{
                                                            min : this.state.form.age.min,
                                                            max : value
                                                          })
                                                        }}
                                                        value={this.state.form.age.max}
                                                      />
                                                    </span>
                                                    <span className="font-15">years</span>
                                                  </div>
                                                </div>
                                                <div className="strict-box">
                                                <div className="ui checkbox pink ">
                                                <Checkbox
                                                        value="age"
                                                        name="strict"
                                                        disabled
                                                        checked={true}
                                                        />
                                                      </div>
                                                </div>
                                              </div>
                                              {this.displayError(this.hasAnyError(['age','age.min','age.max']))}
                                            </div>
                                          </div>

                                            <div className="inline field fields mb-4 flex-start">
                                                <label className="w-50 lh-md">
                                                <span className="mr-05"><FormattedMessage id="preference.marital_status"/></span>
                                                </label>
                                                <div className="w-100">
                                                <div className="d-flex w-100">
                                                    <Dropdown
                                                    search
                                                    className={`wd-input`}
                                                    placeholder="Marital Status"
                                                    fluid
                                                    selection
                                                    selectOnBlur={false}
                                                    multiple
                                                    disabled={this.state.preference &&  this.state.preference.marital_status !== null && this.state.preference.marital_status.length}
                                                    options={arrayWithAny(maritalStatus)}
                                                    onChange={(e, { value }) => {
                                                        this.updateFormState("marital_status",checkAny(value))
                                                    }}
                                                    value={this.state.form.marital_status}
                                                    />
                                                    <div className="strict-box">
                                                    <div className="ui checkbox pink ">
                                                        <Checkbox
                                                        value="marital_status"
                                                        name="strict"
                                                        disabled
                                                        checked
                                                        />
                                                    </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>

                                            <div className="inline field fields mb-4">
                                              <label className="w-50 lh-md">
                                                <span className="mr-05"><FormattedMessage id="preference.community"/></span>
                                              </label>
                                              <div className="w-100">
                                                <div className="d-flex w-100">
                                                  <Dropdown
                                                    search
                                                    className={`wd-input`}
                                                    placeholder="Select Community"
                                                    fluid
                                                    selection
                                                    selectOnBlur={false}
                                                    multiple
                                                    disabled={this.state.preference &&  this.state.preference.community !== null &&  this.state.preference.community.length}
                                                    options={arrayWithAny(
                                                      filterItems(
                                                        "community",
                                                        this.props.commonData && this.props.commonData.data
                                                      )
                                                    )}
                                                    onChange={(e, { value }) => {
                                                      this.updateFormState("community",checkAny(value))
                                                    }}
                                                    value={this.state.form.community}
                                                  />
                                                   <div className="strict-box">
                                                    <div className="ui checkbox pink ">
                                                        <Checkbox
                                                        value="community"
                                                        name="strict"
                                                        disabled
                                                        checked
                                                        />
                                                    </div>
                                                    </div>
                                                </div>
                                              </div>
                                            </div>


                                            <div className="inline field fields mb-4">
                                                  <label className="w-50 lh-md">
                                                    <span className="mr-05"><FormattedMessage id="preference.speaking_language"/></span>
                                                  </label>
                                                  <div className="w-100">
                                                    <div className="d-flex w-100">
                                                      <Dropdown
                                                        search
                                                        className={`wd-input`}
                                                        placeholder="Select Speaking Languages"
                                                        fluid
                                                        selection
                                                        selectOnBlur={false}
                                                        multiple
                                                        options={arrayWithAny(
                                                          filterItems(
                                                            "mother-tongue",
                                                            this.props.commonData && this.props.commonData.data
                                                          )
                                                        )}
                                                        onChange={(e, { value }) => {
                                                          this.updateFormState("speaking_language",checkAny(value))
                                                        }}
                                                        value={this.state.form.speaking_language}
                                                      />
                                                     <div className="strict-box">
                                                    <div className="ui checkbox pink ">
                                                        <Checkbox
                                                        value="speaking_language"
                                                        name="strict"
                                                        onChange={(e, data) => {
                                                          this.updateStrictState(data)
                                                      }}
                                                      checked={this.state.form.strict.includes("speaking_language")}
                                                        />
                                                    </div>
                                                    </div>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="inline field fields mb-4">
                                                <label className="w-50 lh-md">
                                                  <span className="mr-05"><FormattedMessage id="preference.religion"/></span>
                                                </label>
                                                <div className="w-100">
                                                  <div className="d-flex w-100">
                                                    <Dropdown
                                                      search
                                                      className={`wd-input`}
                                                      placeholder="Select Religion"
                                                      fluid
                                                      selection
                                                      selectOnBlur={false}
                                                      multiple
                                                      disabled={this.state.preference &&  this.state.preference.religion !== null && this.state.preference.religion.length}
                                                      options={arrayWithAny(
                                                        filterItems(
                                                          "religion",
                                                          this.props.commonData && this.props.commonData.data
                                                        )
                                                      )}
                                                      onChange={(e, { value }) => {
                                                        this.updateFormState("religion",checkAny(value))
                                                      }}
                                                      value={this.state.form.religion}
                                                    />
                                                      <div className="strict-box">
                                                    <div className="ui checkbox pink ">
                                                        <Checkbox
                                                        value="religion"
                                                        name="strict"
                                                        disabled
                                                        checked
                                                        />
                                                    </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="inline field fields mb-4">
                                                <label className="w-50 lh-md">
                                                  <span className="mr-05"><FormattedMessage id="preference.caste"/></span>
                                                </label>
                                                <div className="w-100">
                                                  <div className="d-flex w-100">
                                                    <Dropdown
                                                      search
                                                      className={`wd-input`}
                                                      placeholder="Select Caste"
                                                      fluid
                                                      selection
                                                      selectOnBlur={false}
                                                      multiple
                                                      
                                                      options={arrayWithAny(
                                                        filterItems(
                                                          "caste",
                                                          this.props.commonData && this.props.commonData.data,
                                                          {type:1}
                                                        )
                                                      )}
                                                      onChange={(e, { value }) => {
                                                        this.updateFormState("caste",checkAny(value))
                                                      }}
                                                      value={this.state.form.caste}
                                                    />
                                                      <div className="strict-box">
                                                    <div className="ui checkbox pink ">
                                                        <Checkbox
                                                        value="caste"
                                                        name="strict"
                                                        disabled
                                                        checked={true}
                                                        />
                                                    </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="inline field fields mb-4">
                                                    <label className="w-50 lh-md">
                                                      <span className="mr-05"><FormattedMessage id="preference.living_country"/></span>
                                                    </label>
                                                    <div className="w-100">
                                                      <div className="d-flex w-100">
                                                        <Dropdown
                                                          search
                                                          className={`wd-input`}
                                                          placeholder="Select Living Country"
                                                          fluid
                                                          selection
                                                          selectOnBlur={false}
                                                          multiple
                                                          options={arrayWithAny(
                                                            filterItems(
                                                              "nationality",
                                                              this.props.commonData && this.props.commonData.data
                                                            )
                                                          )}
                                                          onChange={(e, { value }) => {
                                                            this.updateFormState("living_country",checkAny(value))
                                                          }}
                                                          value={this.state.form.living_country}
                                                        />
                                                        <div className="strict-box">
                                                    <div className="ui checkbox pink ">
                                                        <Checkbox
                                                        value="living_country"
                                                        name="strict"
                                                        onChange={(e, data) => {
                                                          this.updateStrictState(data)
                                                      }}
                                                      checked={this.state.form.strict.includes("living_country")}
                                                        />
                                                    </div>
                                                    </div>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div className="inline field fields flex-start mb-4">
                                                    <label className="w-50 lh-md">
                                                      <span className="mr-05"><FormattedMessage id="preference.education_level"/></span>
                                                    </label>
                                                    <div className="w-100">
                                                      <div className="d-flex w-100">
                                                        <Dropdown
                                                          search
                                                          className={`wd-input`}
                                                          placeholder="Select Education Level"
                                                          fluid
                                                          selection
                                                          multiple
                                                          selectOnBlur={false}
                                                          options={arrayWithAny(
                                                            filterItems(
                                                              "education-level",
                                                              this.props.commonData && this.props.commonData.data,
                                                              {type:1}
                                                            )
                                                          )}
                                                          onChange={(e, { value }) => {
                                                            this.updateFormState("education_level",checkAny(value))
                                                          }}
                                                          value={this.state.form.education_level}
                                                        />
                                                        <div className="strict-box">
                                                    <div className="ui checkbox pink ">
                                                        <Checkbox
                                                        value="education_level"
                                                        name="strict"
                                                        onChange={(e, data) => {
                                                            this.updateStrictState(data)
                                                        }}
                                                        checked={this.state.form.strict.includes("education_level")}
                                                        />
                                                    </div>
                                                    </div>
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <Accordion className="ui styled fluid accordion wd-preference-accordion mb-1">
                                                        <Accordion.Title onClick={()=>{
                                                            this.handleAccordion('lifestyle')
                                                        }} 
                                                        active={this.state.accordion.lifestyle}
                                                        >
                                                        Lifestyle Preference<i class="angle down icon"></i>
                                                      </Accordion.Title>
                                                    <Accordion.Content active={this.state.accordion.lifestyle}>
                                                        <div className="inline field fields mb-4">
                                                            <label className="w-50 lh-md">
                                                              <span className="mr-05"><FormattedMessage id="preference.diet"/></span>
                                                            </label>
                                                            <div className="w-100">
                                                              <div className="d-flex w-100">
                                                                <Dropdown
                                                                  search
                                                                  className={`wd-input`}
                                                                  placeholder="Eating habit"
                                                                  fluid
                                                                  selection
                                                                  selectOnBlur={false}
                                                                  multiple
                                                                  options={arrayWithAny(
                                                                    filterItems(
                                                                      "diet",
                                                                      this.props.commonData && this.props.commonData.data,
                                                                      {type:1}
                                                                    )
                                                                  )}
                                                                  onChange={(e, { value }) => {
                                                                    this.updateFormState("diet",checkAny(value))
                                                                  }}
                                                                  value={this.state.form.diet}
                                                                />
                                                                <div className="strict-box">
                                                            <div className="ui checkbox pink ">
                                                                <Checkbox
                                                                value="diet"
                                                                name="strict"
                                                                disabled
                                                                checked={true}
                                                                />
                                                            </div>
                                                            </div>
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="inline field fields flex-start mb-4">
                                                              <label className="w-50 lh-md">
                                                                <span className="mr-05"><FormattedMessage id="preference.drinking_habit"/></span>
                                                              </label>
                                                              <div className="w-100">
                                                                <div className="d-flex w-100">
                                                                  <div className="mr-33">
                                                                    <div className="two wide fields m-0 w-full-xs">
                                                                        <RadioBox
                                                                          name="drinking_habit"
                                                                          radioBoxObject={drikingPref}
                                                                          className="ui radio checkbox pink checked wd-radio-box w-100"
                                                                          selectedRadioValue={radioValue => {
                                                                            this.updateFormState("drinking_habit",radioValue)
                                                                          }
                                                                          }
                                                                          setDefaultValue={this.state.form.drinking_habit}
                                                                        />
                                                                    </div>
                                                                  </div>
                                                                  <div className="strict-box">
                                                                  <div className="ui checkbox pink ">
                                                                      <Checkbox
                                                                      value="drinking_habit"
                                                                      name="strict"
                                                                      onChange={(e, data) => {
                                                                          this.updateStrictState(data)
                                                                      }}
                                                                      checked={this.state.form.strict.includes("drinking_habit")}
                                                                      />
                                                                  </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>

                                                            <div className="inline field fields flex-start mb-4">
                                                              <label className="w-50 lh-md">
                                                                <span className="mr-05"><FormattedMessage id="preference.smoking_habit"/></span>
                                                              </label>
                                                              <div className="w-100">
                                                                <div className="d-flex w-100">
                                                                  <div className="mr-33">
                                                                    <div className="two wide fields m-0 w-full-xs">
                                                                        <RadioBox
                                                                          name="smoking_habit"
                                                                          radioBoxObject={smokingPref}
                                                                          className="ui radio checkbox pink checked wd-radio-box w-100"
                                                                          selectedRadioValue={radioValue => {
                                                                            this.updateFormState("smoking_habit",radioValue)
                                                                          }
                                                                          }
                                                                          setDefaultValue={this.state.form.smoking_habit}
                                                                        />
                                                                    </div>
                                                                  </div>
                                                                  <div className="strict-box">
                                                                  <div className="ui checkbox pink ">
                                                                      <Checkbox
                                                                      value="smoking_habit"
                                                                      name="strict"
                                                                      onChange={(e, data) => {
                                                                          this.updateStrictState(data)
                                                                      }}
                                                                      checked={this.state.form.strict.includes("smoking_habit")}
                                                                      />
                                                                  </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            </Accordion.Content>
                                                      
                                                      <Accordion.Title onClick={()=>{
                                                            this.handleAccordion('location')
                                                        }} 
                                                        active={this.state.accordion.location}
                                                        >
                                                          Location Preference<i class="angle down icon"></i>
                                                      </Accordion.Title>
                                                    <Accordion.Content active={this.state.accordion.location}>

                                                        <div className="inline field fields mb-4">
                                                                <label className="w-50 lh-md">
                                                                  <span className="mr-05"><FormattedMessage id="preference.nationality"/></span>
                                                                </label>
                                                                <div className="w-100">
                                                                  <div className="d-flex w-100">
                                                                    <Dropdown
                                                                      search
                                                                      className={`wd-input`}
                                                                      placeholder="Select Nationality"
                                                                      fluid
                                                                      selection
                                                                      multiple
                                                                      selectOnBlur={false}
                                                                      options={arrayWithAny(
                                                                        filterItems(
                                                                          "nationality",
                                                                          this.props.commonData && this.props.commonData.data
                                                                        )
                                                                      )}
                                                                      onChange={(e, { value }) => {
                                                                        this.updateFormState("nationality",checkAny(value))
                                                                      }}
                                                                      value={this.state.form.nationality}
                                                                    />
                                                                    <div className="strict-box">
                                                                  <div className="ui checkbox pink ">
                                                                      <Checkbox
                                                                      value="nationality"
                                                                      name="strict"
                                                                      onChange={(e, data) => {
                                                                          this.updateStrictState(data)
                                                                      }}
                                                                      checked={this.state.form.strict.includes("nationality")}
                                                                      />
                                                                  </div>
                                                                  </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div className="inline field fields mb-4">
                                                                <label className="w-50 lh-md">
                                                                  <span className="mr-05"><FormattedMessage id="preference.district"/></span>
                                                                </label>
                                                                <div className="w-100">
                                                                  <div className="d-flex w-100">
                                                                    <Dropdown
                                                                      search
                                                                      className={`wd-input`}
                                                                      placeholder="Select Native District"
                                                                      fluid
                                                                      selection
                                                                      selectOnBlur={false}
                                                                      multiple
                                                                      options={arrayWithAny(
                                                                        filterItems(
                                                                          "district",
                                                                          this.props.commonData && this.props.commonData.data,
                                                                          {type:1}
                                                                        )
                                                                      )}
                                                                      onChange={(e, { value }) => {
                                                                        this.updateFormState("district",checkAny(value))
                                                                      }}
                                                                      value={this.state.form.district}
                                                                    />
                                                                    <div className="strict-box">
                                                                  <div className="ui checkbox pink ">
                                                                      <Checkbox
                                                                      value="district"
                                                                      name="strict"
                                                                      onChange={(e, data) => {
                                                                          this.updateStrictState(data)
                                                                      }}
                                                                      checked={this.state.form.strict.includes("district")}
                                                                      />
                                                                  </div>
                                                                  </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                        </Accordion.Content>

                                                        <Accordion.Title onClick={()=>{
                                                            this.handleAccordion('professional')
                                                        }} 
                                                        active={this.state.accordion.professional}
                                                        >
                                                          Professional Preference<i class="angle down icon"></i>
                                                      </Accordion.Title>
                                                    <Accordion.Content active={this.state.accordion.professional}>        
                                                        <div className="inline field fields mb-4">
                                                                <label className="w-50 lh-md">
                                                                  <span className="mr-05"><FormattedMessage id="preference.education_field"/></span>
                                                                </label>
                                                                <div className="w-100">
                                                                  <div className="d-flex w-100">
                                                                    <Dropdown
                                                                      search
                                                                      className={`wd-input`}
                                                                      placeholder="Select Education Field"
                                                                      fluid
                                                                      selection
                                                                      multiple
                                                                      selectOnBlur={false}
                                                                      options={arrayWithAny(
                                                                        filterItems(
                                                                          "education-field",
                                                                          this.props.commonData && this.props.commonData.data,
                                                                          {type:1}
                                                                        )
                                                                      )}
                                                                      onChange={(e, { value }) => {
                                                                        this.updateFormState("education_field",checkAny(value))
                                                                      }}
                                                                      value={this.state.form.education_field}
                                                                    />

                                                                  <div className="strict-box">
                                                                  <div className="ui checkbox pink ">
                                                                      <Checkbox
                                                                      value="education_field"
                                                                      name="strict"
                                                                      onChange={(e, data) => {
                                                                          this.updateStrictState(data)
                                                                      }}
                                                                      checked={this.state.form.strict.includes("education_field")}
                                                                      />
                                                                  </div>
                                                                  </div>
                                                                    
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div className="inline field fields mb-4">
                                                                <label className="w-50 lh-md">
                                                                  <span className="mr-05"><FormattedMessage id="preference.employment_type"/></span>
                                                                </label>
                                                                <div className="w-100">
                                                                  <div className="d-flex w-100">
                                                                    <Dropdown
                                                                      search
                                                                      className={`wd-input`}
                                                                      placeholder="Working Status"
                                                                      fluid
                                                                      multiple
                                                                      selection
                                                                      selectOnBlur={false}
                                                                      options={arrayWithAny(
                                                                        filterItems(
                                                                          "employment-type",
                                                                          this.props.commonData && this.props.commonData.data,
                                                                          {type:1}
                                                                        )
                                                                      )}
                                                                      onChange={(e, { value }) => {
                                                                        this.updateFormState("employment_type",checkAny(value))
                                                                      }}
                                                                      value={this.state.form.employment_type}
                                                                    />
                                                                     <div className="strict-box">
                                                                  <div className="ui checkbox pink ">
                                                                      <Checkbox
                                                                      value="employment_type"
                                                                      name="strict"
                                                                      onChange={(e, data) => {
                                                                          this.updateStrictState(data)
                                                                      }}
                                                                      checked={this.state.form.strict.includes("employment_type")}
                                                                      />
                                                                  </div>
                                                                  </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              </Accordion.Content>

                                                              <Accordion.Title onClick={()=>{
                                                            this.handleAccordion('other')
                                                        }} 
                                                        active={this.state.accordion.other}
                                                        >
                                                          Other Preference<i class="angle down icon"></i>
                                                      </Accordion.Title>
                                                    <Accordion.Content active={this.state.accordion.other}>
                                                        <div className="inline field fields mb-4">
                                                          <label className="w-50 lh-md">
                                                            <span className="mr-05">Profile Created by</span>
                                                          </label>
                                                          <div className="w-100">
                                                            <div className="d-flex w-100">
                                                              <Dropdown
                                                                search
                                                                className={`wd-input`}
                                                                placeholder="Created By"
                                                                fluid
                                                                multiple
                                                                selection
                                                                selectOnBlur={false}
                                                                options={arrayWithAny(
                                                                  filterItems(
                                                                    "relationship",
                                                                    this.props.commonData && this.props.commonData.data
                                                                  )
                                                                )}
                                                                onChange={(e, { value }) => {
                                                                  this.updateFormState("created_by",checkAny(value))
                                                                }}
                                                                value={this.state.form.created_by}
                                                              />

                                                                <div className="strict-box">
                                                                  <div className="ui checkbox pink ">
                                                                      <Checkbox
                                                                      value="created_by"
                                                                      name="strict"
                                                                      onChange={(e, data) => {
                                                                          this.updateStrictState(data)
                                                                      }}
                                                                      checked={this.state.form.strict.includes("created_by")}
                                                                      />
                                                                  </div>
                                                                  </div>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div 
                                                        className={this.hasAnyError(['height.min','height.max','height']) ? "inline field fields mb-4 error" : "inline field fields mb-4"}
                                                        >
                                                          <label className="w-50 lh-md">
                                                            <span className="mr-05">Height</span>
                                                          </label>
                                                          <div className="w-100">
                                                            <div className="d-flex w-100">
                                                              <div className="mr-33">
                                                                <div className="d-flex item-center height-group">
                                                                  <Dropdown
                                                                    search
                                                                    className={`ui fluid selection dropdown wd-input mr-05`}
                                                                    placeholder="Select Height"
                                                                    fluid
                                                                    selection
                                                                    selectOnBlur={false}
                                                                    options={hisHeight}
                                                                    onChange={(e, { value }) => {
                                                                      const height = {
                                                                        min : value,
                                                                        max : this.state.form.height.max
                                                                      }
                                                                      this.updateFormState("height",height)
                                                                    }}
                                                                    value={this.state.form.height.min}
                                                                  />
                                                                  <span className="font-15 mr-05">to</span>
                                                                  <Dropdown
                                                                    search
                                                                    className={`ui fluid selection dropdown wd-input mr-05`}
                                                                    placeholder="Select Height"
                                                                    fluid
                                                                    selection
                                                                    selectOnBlur={false}
                                                                    options={hisHeight}
                                                                    onChange={(e, { value }) => {
                                                                      const height = {
                                                                        min : this.state.form.height.min,
                                                                        max : value
                                                                      }
                                                                      this.updateFormState("height",height)
                                                                    }}
                                                                    value={this.state.form.height.max}
                                                                  />
                                                                </div>
                                                              </div>
                                                              
                                                              <div className="strict-box">
                                                                  <div className="ui checkbox pink ">
                                                                      <Checkbox
                                                                      value="height"
                                                                      name="strict"
                                                                      onChange={(e, data) => {
                                                                          this.updateStrictState(data)
                                                                      }}
                                                                      checked={this.state.form.strict.includes("height")}
                                                                      />
                                                                  </div>
                                                                  </div>

                                                            </div>
                                                            {this.displayError(this.hasAnyError(['height','height.min','height.max']))}
                                                          </div>
                                                        </div>

                                                        <div className="inline field fields flex-start mb-4">
                                                          <label className="w-50 lh-md">
                                                            <span className="mr-05">Dosham</span>
                                                          </label>
                                                          <div className="w-100">
                                                            <div className="d-flex w-100">
                                                              <div className="mr-33">
                                                              <RadioBox
                                                                      name="chhevai_dosham"
                                                                      radioBoxObject={cdosham}
                                                                      className="ui radio checkbox pink  wd-radio-box w-100"
                                                                      selectedRadioValue={value => {
                                                                        this.updateFormState("dosham",value)
                                                                      }}
                                                                      setDefaultValue={this.state.form.dosham}
                                                                    />
                                                              
                                                              </div>
                                                              
                                                                    
                                                              <div className="strict-box">
                                                                  <div className="ui checkbox pink ">
                                                                      <Checkbox
                                                                      value="dosham"
                                                                      name="strict"
                                                                      onChange={(e, data) => {
                                                                          this.updateStrictState(data)
                                                                      }}
                                                                      checked={this.state.form.strict.includes("dosham")}
                                                                      />
                                                                  </div>
                                                                  </div>

                                                            </div>
                                                          </div>
                                                        </div>
                                                        

                                                        <div className="inline field fields flex-start mb-4">
                                                          <label className="w-50 lh-md">
                                                            <span className="mr-05">Physical Status</span>
                                                          </label>
                                                          <div className="w-100">
                                                            <div className="d-flex w-100">
                                                              <div className="mr-33">
                                                                <RadioBox
                                                                  name="pc"
                                                                  radioBoxObject={[
                                                                    {
                                                                      text: "Doesn't Matter",
                                                                      value: "*",
                                                                      parent: "field pr-0"
                                                                    },
                                                                    { text: "Normal", value: 0, parent: "field pr-0" },
                                                                    {
                                                                      text: "Physically Challenged",
                                                                      value: 1,
                                                                      parent: "field pr-0"
                                                                    }
                                                                  ]}
                                                                  className="ui radio checkbox pink checked wd-radio-box mb-1 w-100"
                                                                  selectedRadioValue={value =>{
                                                                    this.updateFormState("disability",value)
                                                                   }}
                                                                  setDefaultValue={this.state.form.disability}
                                                                />
                                                              </div>
                                                              
                                                                    
                                                              <div className="strict-box">
                                                                  <div className="ui checkbox pink ">
                                                                      <Checkbox
                                                                      value="disability"
                                                                      name="strict"
                                                                      disabled
                                                                      checked={true}
                                                                      />
                                                                  </div>
                                                                  </div>

                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="inline field fields mb-4 flex-start">
                                                          <label className="w-50 lh-md">
                                                                  <span className="mr-05">Kids</span>
                                                          </label>
                                                          <div className="w-100">
                                                            <div className="d-flex w-100">
                                                              <div className="mr-33">
                                                                <RadioBox
                                                                  name="kids"
                                                                  radioBoxObject={[
                                                                    {
                                                                      text: "Doesn't Matter",
                                                                      value: "*",
                                                                      parent: "field pr-0"
                                                                    },
                                                                    { text: "No Kids", value: 0, parent: "field pr-0" },
                                                                    { text: "With Kids", value: 1, parent: "field pr-0" }
                                                                    
                                                                  ]}
                                                                  className="ui radio checkbox pink checked wd-radio-box mb-1 w-100"
                                                                  selectedRadioValue={value =>{
                                                                    this.updateFormState("children",value)
                                                                  }}
                                                                  setDefaultValue={this.state.form.children}
                                                                />
                                                              </div>
                                                              
                                                              <div className="strict-box">
                                                                  <div className="ui checkbox pink ">
                                                                      <Checkbox
                                                                      value="children"
                                                                      name="strict"
                                                                      disabled
                                                                      checked={true}
                                                                      />
                                                                  </div>
                                                                  </div>

                                                            </div>
                                                          </div>
                                                        </div>
                                                        {this.state.form.children !== null && (this.state.form.children === 1 || this.state.form.children === '*') ? (
                                                            <div className="inline field fields flex-start mb-4">
                                                            <label className="w-50 lh-md">
                                                              <span className="mr-05">Kids Living Status</span>
                                                            </label>
                                                            <div className="w-100">
                                                              <div className="d-flex w-100">
                                                                <div className="mr-33">
                                                                  <RadioBox
                                                                    name="kidsc"
                                                                    radioBoxObject={[
                                                                      {
                                                                        text: "Doesn't Matter",
                                                                        value: "*",
                                                                        parent: "field pr-0"
                                                                      },
                                                                      {
                                                                        text: "Not Living with Partner",
                                                                        value: 0,
                                                                        parent: "field pr-0"
                                                                      },
                                                                      {
                                                                        text: "Living with Partner",
                                                                        value: 1,
                                                                        parent: "field pr-0"
                                                                      }
                                                                      
                                                                    ]}
                                                                    className="ui radio checkbox pink checked wd-radio-box mb-1 w-100"
                                                                    selectedRadioValue={value => {
                                                                      this.updateFormState("children_state",value)
                                                                    }}
                                                                    setDefaultValue={this.state.form.children_state}
                                                                  />
                                                                </div>
                                                                
                                                                <div className="strict-box">
                                                                  <div className="ui checkbox pink ">
                                                                      <Checkbox
                                                                      value="children_state"
                                                                      name="strict"
                                                                      onChange={(e, data) => {
                                                                          this.updateStrictState(data)
                                                                      }}
                                                                      checked={this.state.form.strict.includes("children_state")}
                                                                      />
                                                                  </div>
                                                                  </div>
                                                                

                                                              </div>
                                                            </div>
                                                          </div>
                                                        ):""}
                                                        
                                                        <div className="inline field fields mb-4 flex-start">
                                                          <label className="w-50 lh-md">
                                                            <span className="mr-05">Skin Tone</span>
                                                          </label>
                                                          <div className="w-100">
                                                            <div className="d-flex w-100">
                                                              <Dropdown
                                                                search
                                                                className={`wd-input`}
                                                                placeholder="Skin Tone"
                                                                fluid
                                                                multiple
                                                                selection
                                                                selectOnBlur={false}
                                                                options={arrayWithAny(skin_tone, "Doesn't Matter")}
                                                                onChange={(e, { value }) => {
                                                                  this.updateFormState("skin_tone",checkAny(value))
                                                                }}
                                                                value={this.state.form.skin_tone}
                                                              />
                                                              <div className="strict-box">
                                                                  <div className="ui checkbox pink ">
                                                                      <Checkbox
                                                                      value="skin_tone"
                                                                      name="strict"
                                                                      onChange={(e, data) => {
                                                                          this.updateStrictState(data)
                                                                      }}
                                                                      checked={this.state.form.strict.includes("skin_tone")}
                                                                      />
                                                                  </div>
                                                                  </div>



                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="inline field fields mb-4 flex-start">
                                                          <label className="w-50 lh-md">
                                                            <span className="mr-05">Body Type</span>
                                                          </label>
                                                          <div className="w-100">
                                                            <div className="d-flex w-100">
                                                              <Dropdown
                                                                search
                                                                className={`wd-input`}
                                                                placeholder="Body Type"
                                                                fluid
                                                                multiple
                                                                selection
                                                                selectOnBlur={false}
                                                                options={arrayWithAny(body_type, "Doesn't Matter")}
                                                                onChange={(e, { value }) => {
                                                                  this.updateFormState("body_type",checkAny(value))
                                                                }}
                                                                value={this.state.form.body_type}
                                                              />
                                                              <div className="strict-box">
                                                                  <div className="ui checkbox pink ">
                                                                      <Checkbox
                                                                      value="body_type"
                                                                      name="strict"
                                                                      onChange={(e, data) => {
                                                                          this.updateStrictState(data)
                                                                      }}
                                                                      checked={this.state.form.strict.includes("body_type")}
                                                                      />
                                                                  </div>
                                                                  </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        </Accordion.Content>
                                                  </Accordion>
                                            <div className="inline field fields">
                                                <label className="w-50 lh-md" />
                                                <div className="w-100">
                                                  {this.state.preference && (
                                                    <div className="d-flex w-100">
                                                    <Button type="submit" className="right floated btn-blue font-12 ripple mr-1" 
                                                    disabled={this.state.preference.state !== 0}
                                                    onClick={() => {
                                                      this.handleFormSubmission(false,false)
                                                    }} >SAVE</Button>
                                                    <Button type="submit" className="right floated btn-green font-12 ripple mr-1" 
                                                    disabled={this.state.preference.state !== 0}
                                                    onClick={() => {
                                                      this.handleFormSubmission(true,false)
                                                    }}
                                                    >Save & Activate</Button>
                                                    <Button type="submit" className="right floated btn-pink font-12 ripple"
                                                    onClick={() => {
                                                      this.handleFormSubmission(false,true)
                                                    }}
                                                    >Save as New</Button>
                                                    <div className="strict-box" />  
                                                    </div>
                                                  )}
                                                
                                                </div>
                                            </div>

                                            </div>
                                        </form>
                                    </div>
                    </div>
                    </div>
                </div>
            </div>
            )}
        </div>
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  singleUser: state.user.singleProfileData,
  commonData: state.commonData.allCommonData,
  profileNotFound: state.user.profileNotFound,
  globalSpinner: state.globalSpinner.globalSpinner,
  globalNumber: state.commonData.defaultCountryNumber
});

export default injectIntl(
    connect(mapStateToProps, {
    singleProfile,
    updateLayout,
    getCommonData,
    defaultSingleProfile
})(withRouter(EditPreference)));
