import { BASIC_PROFILE_DATA } from "../../constants/ActionTypes";
import { gpAxios } from "../../../util/axios";

import { globalSpinner } from "../../../common/commonActions/commonSpinner";
import { setErrors } from "../../../common/commonActions/errorAction";
import { getBasicProfileDataServer } from "../user/user";

/**
 * @setting basic profile data response
 * @param {*} data
 */
export const updateBasicProfileStep1 = response => ({
  type: BASIC_PROFILE_DATA.SAVE_BASIC_PROFILE_DATA,
  payload: response
});

/**
 * @sendBasicProfileStepOneData: saving data to server
 * @param {*} profileId
 */
export const civilResidence = (profileId, data, history) => dispatch => {
  dispatch(globalSpinner(true));
  gpAxios
    .post(`/profile/${profileId}/civil-residence`, data)
    .then(response => {
      dispatch(globalSpinner(false));
      dispatch(updateBasicProfileStep1(response.data));
      dispatch(setErrors(null));
      history.push(`/${profileId}/education-career`);
    })
    .catch(error => {
      dispatch(globalSpinner(false));
      // console.log(error.response.data);
      if (
        error.response &&
        error.response.data !== undefined &&
        error.response.data.error
      ) {
        dispatch(setErrors(error.response.data.error));
      }
    });
};

/**
 * @updateDOBandCommunity: Updating DOB and Community
 * @param {*} profileId, data
 */
export const updateDOBandCommunity = (profileId, history, data) => dispatch => {
  dispatch(globalSpinner(true));
  const profile_id = profileId;
  gpAxios
    .post(`/profile/${profile_id}/basic`, data)
    .then(response => {
      dispatch(globalSpinner(false));
      dispatch(getBasicProfileDataServer(profile_id));
      dispatch(setErrors(null));
      history.push(`/${profileId}/step-2`);
    })
    .catch(error => {
      dispatch(globalSpinner(false));
      if (
        error.response &&
        error.response.data !== undefined &&
        error.response.data.error
      ) {
        dispatch(setErrors(error.response.data.error));
      }
    });
};



export const saveStepTwo = (profileId, history, data) => dispatch => {
  dispatch(globalSpinner(true));
  const profile_id = profileId;
  gpAxios
    .post(`/profile/${profile_id}/step-2`, data)
    .then(response => {
      dispatch(globalSpinner(false));
      // dispatch(getBasicProfileDataServer(profile_id));
      dispatch(setErrors(null));
      history.push(`/${profileId}/step-3`);
    })
    .catch(error => {
      dispatch(globalSpinner(false));
      if (
        error.response &&
        error.response.data !== undefined &&
        error.response.data.error
      ) {
        dispatch(setErrors(error.response.data.error));
      }
    });
};

export const saveStepThree = (profileId, history, data) => dispatch => {
  dispatch(globalSpinner(true));
  const profile_id = profileId;
  gpAxios
    .post(`/profile/${profile_id}/step-3`, data)
    .then(response => {
      dispatch(globalSpinner(false));
      // dispatch(getBasicProfileDataServer(profile_id));
      dispatch(setErrors(null));
      history.push(`/${profileId}/photo`);
    })
    .catch(error => {
      dispatch(globalSpinner(false));
      if (
        error.response &&
        error.response.data !== undefined &&
        error.response.data.error
      ) {
        dispatch(setErrors(error.response.data.error));
      }
    });
};

export const SavePhotoPrivacy = (profileId, history, data) => dispatch => {
  const profile_id = profileId;
  dispatch(globalSpinner(true));
  gpAxios
    .post(`/profile/${profile_id}/profile-photo-privacy`, data)
    .then(response => {
      dispatch(globalSpinner(false));
      dispatch(setErrors(null));
      history.push(`/?profileId=${profileId}&result-success`);
    })
    .catch(error => {
      dispatch(globalSpinner(false));
      if (
        error.response &&
        error.response.data !== undefined &&
        error.response.data.error
      ) {
        dispatch(setErrors(error.response.data.error));
      }
    });
};