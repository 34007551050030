import { ONBOARDING } from "../../constants/ActionTypes";
import { createReducer } from "../../../util/reducerUtil";

const initState = {
  spinner: false
};

/**
 * @setVerified
 * @param state
 * @param payload
 * @returns {{spinner: boolean}}
 */
export const spinner = (state, payload) => ({
  ...state,
  spinner: payload
});

export default createReducer(initState, {
  [ONBOARDING.GENERAL_INFORMATION.SPINNER]: spinner
});
