import React from "react";
import { Checkbox } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { Button } from "semantic-ui-react";

import { connect } from "react-redux";
import IntlTelInput from "react-intl-tel-input";
import { openModal } from "../modals/redux-state/modalActions";

import { checkMobileNumber } from "../../redux/actions/user/user";

import {
  RESEND_OTP_SECONDS,
  HOMEPAGE,
  route_config
} from "../../util/GlobalContants";
import { gpAxios } from "../../util/axios";
import { preferedCounties } from "../../common/forms-data/forms-data";
import { setErrors } from "../../common/commonActions/errorAction";
import Wrapper from "../../common/shared-components/wrapper/Wrapper";
import { queryString } from "../../util/qs";
import HowToLogin from "./HowToLogin";

class SignIn extends React.Component {
  state = {
    mobile: "",
    password: "",
    showNext: false,
    errorClass: false,
    showOtp: false,
    resendOtpAndCall: false,
    timer: RESEND_OTP_SECONDS,
    checked: false,
    sendOtpButton: true,
    rememberMe: false,
    sampleNumber : localStorage.getItem("dmc") ? window.intlTelInputUtils.getExampleNumber(localStorage.getItem("dmc"),false,1) : '+91 80753 24709'
  };

  toggle = data => {
    this.setState({ checked: data.checked }, () => {
      localStorage.setItem("rememberMe", data.checked);
    });
  };

  componentDidMount() {
    localStorage.removeItem("timer");
    const qs = queryString(this.props.location.search);

    if(qs && qs.mobile){
      localStorage.setItem('mobile',`+${qs.mobile}`)
      this.props.checkMobileNumber(
        { mobile: `+${qs.mobile}` },
        this.props.history,
        true
      );
    }
    if (this.props.isAuthenticated) {
      this.props.history.push("/dashboard");
    }

    this.props.setErrors(null);
    localStorage.setItem("rememberMe", false);
  }

  getChangedNumber = (status, value, data) => {
    if (value.indexOf("+")) {
      this.setState({ mobile: `+${data.dialCode + value}` });
    } else {
      this.setState({ mobile: value });
    }
    localStorage.setItem("dmc", data.iso2);
  };

  onBlurDailCode = (status, value, data) => {
    if (value.indexOf("+")) {
      this.setState({ mobile: `+${data.dialCode + value}` });
    } else {
      this.setState({ mobile: value });
    }
    localStorage.setItem("dmc", data.iso2);
  };

  shwoPasswordFunc = () => {
    this.props.checkMobileNumber(
      { mobile: this.state.mobile },
      this.props.history,
      true
    );
  };
  flagSelected = (status, value, data) => {
      if(value && value.iso2 &&  window.intlTelInputUtils){
        this.setState({
          sampleNumber : window.intlTelInputUtils.getExampleNumber(value.iso2,false,1)
        })
      }
  }

  render() {
    const { globalSpinner } = this.props.globalSpinner;
    const { defaultNumber } = this.props;
    const defaultCountry = 'in'; 
    return (
      <Wrapper
        config={route_config.sign_in}
        isLoading={this.props.globalSpinner}
        singleUser={this.props.singleUser}
        whyRegister={true}
      >
          <div className="pusher">
            <div className="full height"  >
              <div className="ui container">
                <div className="page-form login-form">
                <HowToLogin/>
                  {!this.state.showNext && (
                    <div className="ui fluid card mb-5">
                      {globalSpinner && (
                        <div className="ui active transition visible inverted dimmer">
                          <div className="content">
                            <div className="ui large text loader">Loading</div>
                          </div>
                        </div>
                      )}

                      <div className="card-head">
                        <h3 className="ui header">Login</h3>
                      </div>
                      <div className="content bt-0 pt-0">
                        <div className="ui form">
                          <div className="field">
                            <label>Enter your registered phone number</label>
                            <div>
                              <IntlTelInput
                                style={{ width: "100%" }}
                                onPhoneNumberChange={this.getChangedNumber}
                                onPhoneNumberBlur={this.onBlurDailCode}
                                onSelectFlag={this.flagSelected}
                                preferredCountries={preferedCounties}
                                inputClassName={`wd-input intPhone h-50 ${this.state
                                  .errorClass && "error"}`}
                                defaultCountry={
                                  localStorage.getItem("dmc") || defaultCountry
                                }
                                geoIpLookup={function(success, failure) {
                                  gpAxios
                                    .get("/common/ip")
                                    .then(function(response) {
                                      console.log(response.data.data.iso);
                                      success(response.data.data.iso);
                                    })
                                    .catch(function(er) {
                                      success(defaultCountry);
                                    });
                                }}
                                separateDialCode
                                autoPlaceholder
                                telInputProps={{
                                  onKeyPress: function(event) {
                                    if (event.key === "Enter") {
                                      this.shwoPasswordFunc();
                                    }
                                  }.bind(this)
                                }}
                                defaultValue={
                                  (localStorage.getItem("mobile") &&
                                    localStorage.getItem("mobile")) ||
                                  ""
                                }
                              />
                            </div>
                              <div class="mt-05 text-muted">Number format example: {this.state.sampleNumber}</div>
                            <p style={{ marginTop: "5px", color: "red" }}>
                              {this.props.error !== null && this.props.error}
                            </p>
                          </div>

                          <div className="field mb-4 mt-4">
                          <span className="text-blue font-weight-normal float-right pointer d-none-xs m-0"
                          onClick={(e) =>{
                            this.props.openModal("HelpVideoModal", { customClass: "modal-md", video : "https://youtu.be/Oy9YjMc9R14" })
                          }}
                          >How to login?
                          </span>
                          <span className="text-blue font-weight-normal float-right pointer d-block-xs m-0"
                          onClick={(e) =>{
                            this.props.openModal("HelpVideoModal", { customClass: "modal-md", video : "https://youtu.be/Oy9YjMc9R14"})
                          }}
                          >How to login?
                          </span> 
                            <div className="ui checkbox pink">
                              <Checkbox
                                label="Remember me"
                                onChange={(e, data) => {
                                  this.toggle(data);
                                  this.nextBtn.focus();
                                }}
                                defaultChecked={
                                  JSON.parse(
                                    localStorage.getItem("rememberMe")
                                  ) == true || this.state.checked
                                }
                              />
                            </div>
                          </div>

                          {JSON.parse(localStorage.getItem("max_attempt")) ===
                            true && (
                            <div className="ui stackable two column divided grid container mt-4 mb-4 two-col-group">
                              <div className="row content-center">
                                <div className="column w-100 text-center">
                                  <i className="icon-xl i-phone-colored d-inline-block align-middle mr-1"></i>
                                  <div className="d-inline-block align-middle text-left">
                                    <div className="d-block">
                                      Please contact us for support
                                    </div>
                                    <a
                                      href={`tel:${defaultNumber &&
                                        defaultNumber}`}
                                      className="text-blue"
                                    >
                                      {defaultNumber && defaultNumber}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          <Button
                            ref={ip => (this.nextBtn = ip)}
                            type="button"
                            className="fluid ui button btn-green btn-submit text-uppercase btn-shadow ripple mb-4"
                            onClick={this.shwoPasswordFunc}
                          >
                            Next
                          </Button>

                          <div className="font-16 text-gray text-center mb-5">
                            No account yet?{" "}
                            <a
                              href={`${HOMEPAGE}/register`}
                              className="text-pink"
                            >
                              Sign up for free
                            </a>{" "}
                            here
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  
                </div>
              </div>
            </div>
          </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  globalSpinner: state.globalSpinner,
  error: state.error.formsError,
  isAuthenticated: state.user.isAuthenticated,
  phoneNumberValid: state.user.phoneNumberValid,
  defaultNumber: state.commonData.defaultCountryNumber,
  dcc: state.commonData.defaultCountryData
});

export default connect(
  mapStateToProps,
  {
    checkMobileNumber,
    setErrors,
    openModal
  }
)(withRouter(SignIn));
