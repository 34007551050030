import React from "react";

import { Button } from "semantic-ui-react";

import { connect } from "react-redux";

import {
  authentication,
  checkMobileNumber,
  sendOtp,
  sendVoiceOtp
} from "../../redux/actions/user/user";

import { RESEND_OTP_SECONDS, route_config } from "../../util/GlobalContants";
import {
  setErrors,
  maxReachLimit,
  maxReachLimitUpdate
} from "../../common/commonActions/errorAction";
import {
  updateStatus,
  checkLoginData
} from "../../redux/actions/check-login/check-login";

import Wrapper from "../../common/shared-components/wrapper/Wrapper";
import HowToLogin from "./HowToLogin";

let timer = null;

class Password extends React.Component {
  state = {
    mobile: "",
    password: "",
    showNext: true,
    errorClass: false,
    showOtp: false,
    resendOtpAndCall: false,
    timer: localStorage.getItem("timer")
      ? localStorage.getItem("timer")
      : RESEND_OTP_SECONDS,
    checked: false,
    sendOtpButton: true,
    getCall: false,
    timerMode: false,
    loginText: false
  };

  toggle = () => this.setState(prevState => ({ checked: !prevState.checked }));

  componentWillMount() {
    if (localStorage.getItem("check_login") === null) {
      window.location.href = "/";
    }
  }

  componentDidMount() {
    clearInterval(timer);

    if (this.props.isAuthenticated) {
      this.props.history.push("/dashboard");
    }

    if (localStorage.getItem("mobile") === null) {
      this.props.history.push("/");
    }

    if (
      !JSON.parse(localStorage.getItem("check_login")) &&
      localStorage.getItem("mobile") !== null
    ) {
      this.props.checkMobileNumber(
        { mobile: localStorage.getItem("mobile") },
        this.props.history,
        false
      );
    } else {
      this.props.checkLoginData(
        JSON.parse(localStorage.getItem("check_login"))
      );
    }

    this.setState({
      mobile: localStorage.getItem("mobile")
    });

    this.props.maxReachLimitUpdate(null);

    if (
      JSON.parse(localStorage.getItem("timer")) !== null &&
      JSON.parse(localStorage.getItem("timer")) > 0
    ) {
      localStorage.setItem("timer", JSON.parse(localStorage.getItem("timer")));
      this.setState({ timer: JSON.parse(localStorage.getItem("timer")) });
      this.resetTimer();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.max_limit === "max_atempt") {
      localStorage.setItem("max_attempt", true);
      localStorage.setItem("timer", 0);
      this.setState({ loginText: true });
    }
    if (nextProps.phoneNumberValid === true) {
      this.setState({ showNext: true });
    }

    if (JSON.parse(localStorage.getItem("max_attempt")) !== true) {
      if (nextProps.ifPassword && !nextProps.ifPassword.data.pass) {
        this.setState({ showOtp: true, timerMode: true });
        if (
          JSON.parse(localStorage.getItem("timer")) === null &&
          JSON.parse(localStorage.getItem("timer")) < 1
        ) {
          localStorage.setItem("timer", RESEND_OTP_SECONDS);
          this.resetTimer();
        }
      }
    }

    if (nextProps.ifPassword && nextProps.ifPassword.data.pass) {
      this.setState({ timerMode: false });
    }
  }

  getChangedNumber = (status, value, data) => {
    this.setState({ mobile: `+${data.dialCode + value}` });
    localStorage.setItem("dmc", data.iso2);
  };

  onBlurDailCode = (status, value, data) => {
    this.setState({ mobile: `+${data.dialCode + value}` });
    localStorage.setItem("dmc", data.iso2);
  };

  handleLogin = e => {
    e.preventDefault();
    this.state.showOtp || JSON.parse(localStorage.getItem("sendOtp")) === true
      ? this.props.authentication(
          {
            mobile: this.state.mobile,
            password: this.state.password,
            type: "otp"
          },
          this.props.history
        )
      : this.props.authentication(
          {
            mobile: this.state.mobile,
            password: this.state.password
          },
          this.props.history
        );
  };

  shwoPasswordFunc = () => {
    this.props.checkMobileNumber({ mobile: this.state.mobile });
  };

  showOtpFunc = () => {
    localStorage.setItem("sendOtp", true);
    this.props.updateStatus(false);
    this.props.sendOtp({ mobile: this.state.mobile });
    this.props.setErrors(null);
    this.setState({ password: "" });
  };

  componentWillUnmount() {
    clearInterval(timer);
    timer = null;
  }

  resetTimer = () => {
    this.setState({
      timer:
        JSON.parse(localStorage.getItem("timer")) > 0
          ? JSON.parse(localStorage.getItem("timer"))
          : RESEND_OTP_SECONDS
    });
    timer = setInterval(() => {
      if (this.state.timer === 0) {
        clearInterval(timer);
        timer = null;
      } else {
        this.setState({ timer: this.state.timer - 1 }, () => {
          localStorage.setItem("timer", this.state.timer);
          if (this.state.timer === 0) {
            clearInterval(timer);
            timer = null;
          }
        });
      }
    }, 1000);
  };

  changeMobileNumber = () => {
    this.setState({
      showNext: false,
      timer: RESEND_OTP_SECONDS,
      sendOtpButton: true,
      showOtp: false
    });
    this.props.maxReachLimitUpdate(null);
  };

  sendOtp = () => {
    this.props.sendOtp({
      mobile: this.state.mobile || localStorage.getItem("mobile")
    });
    this.setState({ getCall: false });
    this.resetTimer();
  };

  sendVoiceOtp = () => {
    this.props.sendVoiceOtp({ mobile: this.state.mobile });
    this.setState({
      getCall: true
    });
    this.resetTimer();
  };

  render() {
    const { globalSpinner } = this.props.globalSpinner;
    const { timer } = this.state;
    const { defaultNumber } = this.props;
    const { ifPassword } = this.props;
    let showTimer = "";

    if (
      this.state.timerMode ||
      JSON.parse(localStorage.getItem("sendOtp")) === true
    ) {
      showTimer = JSON.parse(localStorage.getItem("max_attempt")) !== true &&
        this.state.timer > 0 && (
          <div className="font-14 text-dark text-center mt-4 mb-4">
            {`Please wait ${timer} seconds to try alternative options`}
          </div>
        );
    } else {
      showTimer = "";
    }

    let otpField = (
      <React.Fragment>
        <div className="field">
          <label>Enter your temporary password</label>
          <div className="ui big input">
            <input
              autoFocus={true}
              className="custom-input wd-input"
              type="text"
              name="pass"
              placeholder="Sign in with temporary password"
              value={this.state.password}
              onChange={e =>
                this.setState({
                  password: e.target.value
                })
              }
              onKeyPress={event => {
                if (event.key === "Enter") {
                  this.handleLogin(event);
                }
              }}
            />
          </div>
          <div className="field-error mt-05">
            {this.props.error !== null && this.props.error}
          </div>
        </div>
      </React.Fragment>
    );

    return (
      <Wrapper
        config={route_config.sign_in}
        isLoading={this.props.globalSpinner}
        singleUser={this.props.singleUser}
        whyRegister={true}
      >
          <div className="pusher">
            <div className="full height"  >
              <div className="ui container">
                <div className="page-form login-form">
                  {this.state.showNext && (
                    <div className="ui fluid card mb-5">
                      {globalSpinner && (
                        <div className="ui active transition visible inverted dimmer">
                          <div className="content">
                            <div className="ui large text loader">Loading</div>
                          </div>
                        </div>
                      )}
                      <div className="card-head">
                        <h3 className="ui header mb-05">Login</h3>
                        {((JSON.parse(localStorage.getItem("max_attempt")) !==
                          true &&
                          JSON.parse(localStorage.getItem("sendOtp")) ===
                            true) ||
                          this.state.showOtp) && (
                          <React.Fragment>
                            {this.state.loginText !== true && (
                              <div className="mb-1">
                                {this.state.getCall
                                  ? "A verification phone call will occur in a few moments, please stand by."
                                  : `A temporary password has been sent to your phone number for verification. Please enter to proceed.`}
                              </div>
                            )}
                          </React.Fragment>
                        )}
                      </div>
                      <div className="content bt-0 pt-0">
                        <form
                          className="ui form"
                          method="POST"
                          onSubmit={this.handleLogin}
                        >
                          <div className="p-2 border mb-3">
                            <span className="font-14 text-black d-inline-block align-middle lh-md ">
                              {this.state.mobile}
                            </span>
                            <button
                              className="mini ui button font-14 btn-link text-blue p-0 align-middle ml-05 float-right font-weight-normal lh-md"
                              onClick={() => {
                                localStorage.removeItem("timer");

                                localStorage.setItem("sendOtp", false);
                                localStorage.removeItem("max_attempt");
                                localStorage.removeItem("check_login");
                                this.props.setErrors(null);
                                this.props.history.push("/");
                              }}
                            >
                              Change number
                            </button>
                          </div>

                          {ifPassword !== null &&
                            ifPassword.data.pass === true &&
                            (JSON.parse(localStorage.getItem("sendOtp")) ===
                              false && (
                              <div className="field">
                                <label>Password</label>
                                <div className="ui big input">
                                  <input
                                    autoFocus={true}
                                    className="custom-input wd-input"
                                    type="password"
                                    name="pass"
                                    placeholder="Enter your password"
                                    value={this.state.password}
                                    onChange={e =>
                                      this.setState({
                                        password: e.target.value
                                      })
                                    }
                                    onKeyPress={event => {
                                      if (event.key === "Enter") {
                                        this.handleLogin(event);
                                      }
                                    }}
                                  />
                                </div>
                                <p style={{ color: "red", marginTop: "5px" }}>
                                  {this.props.error !== null &&
                                    this.props.error}
                                </p>
                              </div>
                            ))}

                          {(JSON.parse(localStorage.getItem("sendOtp")) ===
                            true ||
                            JSON.parse(localStorage.getItem("check_login")).data
                              .pass === false) &&
                            otpField}

                          {!JSON.parse(localStorage.getItem("sendOtp")) &&
                            JSON.parse(localStorage.getItem("check_login")).data
                              .pass === true && (
                              <div className="field mb-4">
                                {/* {this.state.sendOtpButton && ( */}
                                <div className="d-block">
                                  Forgot Password?{" "}
                                  <button
                                    type="button"
                                    className="ui button btn-link p-0 font-14 text-blue"
                                    onClick={this.showOtpFunc}
                                  >
                                    Send temporary password
                                  </button>
                                </div>
                                {/* // )} */}
                              </div>
                            )}

                          {showTimer}

                          {JSON.parse(localStorage.getItem("max_attempt")) !==
                            true &&
                            parseInt(this.state.timer) === 0 && (
                              <React.Fragment>
                                <div className="ui stackable two column divided grid container mt-4 mb-4 two-col-group">
                                  <div class="row font-14 text-dark content-center text-center p-0 group-title">
                                    Didn't receive password? Try following methods
                                  </div>
                                  <div className="row">
                                    <div className="column">
                                      <i className="icon-xl i-phone-colored d-inline-block left floated mr-1" />
                                      <div className="o-hidden">
                                        <div clas="span">Password by Phone call</div>
                                        <div className="font-13 text-dark">
                                          Try this method?{" "}
                                          <button
                                            type="button"
                                            className="mini ui button font-13 btn-link text-blue ripple p-0 font-weight-normal"
                                            onClick={this.sendVoiceOtp}
                                          >
                                            Get call
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="column">
                                      <i className="icon-xl i-sms-colored d-inline-block left floated mr-1" />
                                      <div className="o-hidden">
                                        <div clas="d-inline-block">
                                          Password by SMS
                                        </div>
                                        <div className="font-13 text-dark">
                                          Haven't received code?{" "}
                                          <button
                                            type="button"
                                            className="mini ui button font-13 btn-link text-blue ripple p-0 font-weight-normal"
                                            onClick={this.sendOtp}
                                          >
                                            Resend password
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            )}

                          {JSON.parse(localStorage.getItem("max_attempt")) ===
                            true && (
                            <div className="ui stackable two column divided grid container mt-4 mb-4 two-col-group">
                              <div class="row content-center">
                                <div class="column w-100 text-center">
                                  <i class="icon-xl i-phone-colored d-inline-block align-middle mr-1"></i>
                                  <div class="d-inline-block align-middle text-left">
                                    <div class="d-block">
                                      Please contact us for support
                                    </div>
                                    <a
                                      href={`tel:${defaultNumber &&
                                        defaultNumber}`}
                                      className="text-blue"
                                    >
                                      {defaultNumber && defaultNumber}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          <Button className="fluid ui button btn-green btn-submit text-uppercase btn-shadow ripple mb-4">
                            Login
                          </Button>
                          <div class="font-4 text-gray text-center">
                            Problem? Call us{" "}
                            <a
                              href={`tel:${this.props.defaultNumber &&
                                this.props.defaultNumber}`}
                              class="text-black"
                            >
                              {this.props.defaultNumber &&
                                this.props.defaultNumber}
                            </a>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                  <HowToLogin/>
                </div>
              </div>
            </div>
          </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  globalSpinner: state.globalSpinner,
  error: state.error.formsError,
  max_limit: state.error.maxReachLimit,
  isAuthenticated: state.user.isAuthenticated,
  startTimer: state.user.startTimer,
  phoneNumberValid: state.user.phoneNumberValid,
  defaultNumber: state.commonData.defaultCountryNumber,
  dcc: state.commonData.defaultCountryData,
  ifPassword: state.checkLogin.check_login_data
});

export default connect(
  mapStateToProps,
  {
    authentication,
    checkMobileNumber,
    sendOtp,
    sendVoiceOtp,
    setErrors,
    updateStatus,
    maxReachLimit,
    maxReachLimitUpdate,
    checkLoginData
  }
)(Password);
