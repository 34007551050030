import React from "react";

const NumberOfMatches = ({ spinner, data: { data } }) => {
  return (
    <div className="ui fluid card mb-4">
      {spinner ? (
        <div className="ui segment" style={{ height: "70px" }}>
          <div className="ui active loader"></div>
        </div>
      ) : (
        <div className="ui two column stackable grid container m-0 wd-profile-card">
          <div className="column">
            <div className="d-flex">
              <img
                className="ui middle aligned image thumb thumb-md radius-4"
                src={`/assets/img/${
                  data && data.gender === "male" ? "male.png" : "female.png"
                }`}
                alt="myprofilepic"
              />
              <div className="pl-2 text-truncate">
                <div className="font-16 text-dark font-weight-bold text-truncate">
                  {data && data.name}
                </div>
                <div className="font-12 text-gray">{data && data.did}</div>
              </div>
            </div>
          </div>
          {/* <div className="column">
            <div className="font-15 text-pink p-1 bg-pink-lt text-center mt-01">
              <i className="icon-md i-hearts-pink icon d-inline-block align-middle mr-1" />
              You have {data && data.matches} preferred matches
            </div>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default NumberOfMatches;
