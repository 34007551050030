export const propsData = (state, props, fieldName) => {
  if (state && state.length > 0) {
    return state;
  } else {
    if (props !== null) {
      const {
        data: { partner_preferences }
      } = props;
      if (partner_preferences.length > 0) {
        if (partner_preferences[0].preference.hasOwnProperty(fieldName)) {
          return partner_preferences[0].preference[fieldName];
        }
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  if (state && state !== null && state !== "") {
    return state;
  } else {
    if (props !== null) {
      const {
        data: { partner_preferences }
      } = props;
      if (partner_preferences.length > 0) {
        if (partner_preferences[0].preference.hasOwnProperty(fieldName)) {
          return partner_preferences[0].preference[fieldName];
        }
      } else {
        return [];
      }
    } else {
      return [];
    }
  }
};
