import React, { Component } from 'react';
import {ASSETS_CDN} from "../../../util/GlobalContants";

class WhyRegisterWithMatrimony extends Component {
  render() {
    return (
      <div className="ui vertical segment wd-privacy bb-0">
        <div className="ui container">
          <div className="page-form mt-0">
            <div className="font-16 font-weight-bold text-black text-center mb-4">
              Why register with Matrimony?
            </div>
            <div className="ui three column doubling stackable grid container">
              <div className="column">
                <img
                    className=""
                    src={`${ASSETS_CDN}/img/footer/100.png`}
                    alt="sameepam-logo"
                />

                <div className="content">
                  <div className="font-13 font-weight-bold text-black mb-1">
                    100% Privacy
                  </div>
                  <div className="font-13 text-gray">
                    We maintain our Malayalees culture and privacy at highest level
                  </div>
                </div>
              </div>
              <div className="column">
                <img
                    className=""
                    src={`${ASSETS_CDN}/img/footer/secure.png`}
                    alt="sameepam-logo"
                />
                <div className="content">
                  <div className="font-13 font-weight-bold text-black mb-1">
                    Fully Secure
                  </div>
                  <div className="font-13 text-gray">
                    Our intelligence system will find perfect life partner from our large database
                  </div>
                </div>
              </div>
              <div className="column">
                <img
                    className=""
                    src={`${ASSETS_CDN}/img/footer/worldwide.png`}
                    alt="sameepam-logo"
                />
                <div className="content">
                  <div className="font-13 font-weight-bold text-black mb-1">
                    Worldwide Coverage
                  </div>
                  <div className="font-13 text-gray">No matter where you are, your life partner is only one step away</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WhyRegisterWithMatrimony;
