import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import { gpAxios } from "../../../../../util/axios";
import {
  singleProfile,
  defaultSingleProfile
} from "../../../../../redux/actions/user/user";
import { toastr } from 'react-redux-toastr';
import { updateLayout } from "../../../../../redux/layout_controll/layout_action";
import { getCommonData } from "../../../../../redux/actions/common/common-data";
import {
  route_config
} from "../../../../../util/GlobalContants";

import Wrapper from "../../../../../common/shared-components/wrapper/Wrapper";
import {FormattedMessage,injectIntl} from "react-intl";
import { Button, Dropdown, Checkbox } from "semantic-ui-react";
import RadioBox from "../../../../../common/shared-components/Radio";
import SubSideBar from "../components/subsidebar";
import SubFooter from "../components/subfooter";


import {
    arrayWithAny,
    filteroutOptions,
    maritalStatus,
    age,
  } from "../../../../../common/forms-data/forms-data";
  
import { checkAny } from "../../../../../util/check_any";
import { filterItems } from "../../../../../util/filter_common_data";

class FilterOut extends Component {
  state = {
    profileId: this.props.match.params.profileId,
    databind : false,
    saving : false,
    filterout: null,
    errors: {},
    form : {
        age: {
          min : null,
          max : null
        },
        marital_status : [],
        religion: [],
        caste: [],
        community: [],
        living_country: [],
        education_level: [],
        employment_type: [],
        state: 1
    }
  };

  componentDidMount() {
    this.props.defaultSingleProfile(`profile/${this.state.profileId}`);
    this.props.getCommonData();
  }

  componentWillUnmount() {
    // this.props.closeModal();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    
    if(!this.state.databind){
      if(nextProps.singleUser && nextProps.singleUser.filterout){
        this.setState({
          databind : true
        })
        const data = nextProps.singleUser.filterout
        if(data) {
          const stateObj = this.state.form;
          for(var item in stateObj){
              stateObj[item] = data[item] !== null  ? data[item] : stateObj[item] ;
          }
          stateObj.state = nextProps.singleUser.filterout_state || 0;
          this.setState({
              filterout: data,
              form : stateObj
          })
        }else{
          this.setState({
            filterout : null
        })
        }
      }
    }
  }

  updateFormState = (field,value) => {
    const stateObj = this.state.form;
    stateObj[field] = value
    this.setState({
        form : stateObj
    })
  }

  handleFormSubmission = async(e) =>{
    e.preventDefault();
    try {
        this.setState({saving : true, errors: {}})
        await gpAxios.post(`/profile/${this.state.profileId}/filterout`,this.state.form)
        this.setState({saving : false})
        toastr.success('Success!', 'Details are saved');
      } catch (error) {
        this.setState({saving : false})
        if(error.response && error.response.data && error.response.data.error){
          this.setState({errors : error.response.data.error})
        }
        toastr.error('Failed!', 'Details not saved');
      }
  };

  hasAnyError = (keys) => {
        let errors = []
        keys.forEach(k => {
            if(Object.keys(this.state.errors).includes(k)){
              errors.push(this.state.errors[k][0])
            }
        })
        return errors.length ? errors : false 
  }

  displayError = (errors) => {
    return errors && errors.length ? (<div class="text-red font-12 mt-05">{errors.join(', ')}</div>) : "";
  }

  render() {
    const profileId = this.state.profileId
    return (
      <Wrapper
        config={route_config.draft_dashboard}
        isLoading={this.props.globalSpinner}
        singleUser={this.props.singleUser}
      >
        <div className="content-body">
            <div className="content-wrapper">
            {!this.props.globalSpinner && (
                <div className="wd-rail">
                <div className="wd-container-box">
                    <SubSideBar
                      profileId={profileId}
                    />
                    <div className="box-content">
                    <div className="ui small breadcrumb setting-breadcrumb">
                      <NavLink to={`/`} className="section text-blue">Settings</NavLink>
                      <i className="right chevron icon divider" />
                      <div className="active section font-weight-normal text-gray">Filterout</div>
                    </div>
                    <h1 className="page-title">Filterout</h1>
                    <div className="content-container mb-4">
                                  <div className="header b-none">Who can contact me?</div> 
                                    <div className="content pt-0">
                                      <div className="ui middle aligned divided list edit-list">
                                              <div className="item">
                                                <div className="mb-2">
                                                  <div className="font-16 text-black lh-lg mb-05">Contact filters can help you control who contacts you.</div>
                                                  <p className="text-gray font-14 lh-md">
                                                    Requests from members who do not meet the following criteria will be filtered out For example, if your age range is set as 24 - 27 years in the filters, communication from a member whose age is 30 years will go into your
                                                    filtered folder.
                                                  </p>
                                                  <div className="font-16 text-black lh-lg mb-05">Who can contact me:</div>
                                                  {this.state.saving &&(  
                                                    <div class="ui active dimmer inverted radius-4">
                                                      <div class="ui small text active inverted loader">Please wait..</div>
                                                    </div>)
                                                  }
                                                  <div className="ui two column stackable grid mt-0">
                                                          <RadioBox
                                                              name="state"
                                                              radioBoxObject={filteroutOptions}
                                                              className="ui radio checkbox pink checked wd-radio-box w-100"
                                                              selectedRadioValue={radioValue => {
                                                                this.updateFormState("state",radioValue)
                                                              }
                                                              }
                                                              setDefaultValue={this.state.form.state}
                                                          />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            {this.state.form.state == 1 ? (
                                                  <form method="post" onSubmit={this.handleFormSubmission} className="ui form px-1 pb-2">
                                                   <div 
                                                      className={this.hasAnyError(['age.min','age.max']) ? "inline field fields mb-4 error" : "inline field fields mb-4"}
                                                      >
                                                      <label className="w-50 lh-md">
                                                        <span className="mr-05"><FormattedMessage id="preference.age"/></span>
                                                      </label>
                                                      <div className="w-100">
                                                        <div className="d-flex w-100">
                                                          <div className="mr-33">
                                                            <div className="d-flex item-center age-group">
                                                              <span className="font-15 mr-05">From</span>
                                                              <span className="w-xxs mr-05">
                                                                <Dropdown
                                                                  search
                                                                  className={`wd-input`}
                                                                  placeholder="Age"
                                                                  fluid
                                                                  selection
                                                                  disabled={this.state.preference &&  this.state.preference.age.min !== null}
                                                                  selectOnBlur={false}
                                                                  options={age}
                                                                  onChange={(e, { value }) => {
                                                                    this.updateFormState("age",{
                                                                      min : value,
                                                                      max : this.state.form.age.max
                                                                    })
                                                                  }}
                                                                  value={this.state.form.age.min}
                                                                />
                                                              </span>
                                                              <span className="font-15 mr-05">to</span>
                                                              <span className="w-xxs mr-05">
                                                                <Dropdown
                                                                  search
                                                                  className={`wd-input`}
                                                                  placeholder="Age"
                                                                  fluid
                                                                  selection
                                                                  selectOnBlur={false}
                                                                  options={age}
                                                                  disabled={this.state.preference &&  this.state.preference.age.max !== null}
                                                                  onChange={(e, { value }) => {
                                                                    this.updateFormState("age",{
                                                                      min : this.state.form.age.min,
                                                                      max : value
                                                                    })
                                                                  }}
                                                                  value={this.state.form.age.max}
                                                                />
                                                              </span>
                                                              <span className="font-15">years</span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        {this.displayError(this.hasAnyError(['age','age.min','age.max']))}
                                                      </div>
                                                    </div>
          
                                                      <div className="inline field fields mb-4 flex-start">
                                                          <label className="w-50 lh-md">
                                                          <span className="mr-05"><FormattedMessage id="preference.marital_status"/></span>
                                                          </label>
                                                          <div className="w-100">
                                                          <div className="d-flex w-100">
                                                              <Dropdown
                                                              search
                                                              className={`wd-input`}
                                                              placeholder="Marital Status"
                                                              fluid
                                                              selection
                                                              selectOnBlur={false}
                                                              multiple
                                                              disabled={this.state.preference &&  this.state.preference.marital_status !== null && this.state.preference.marital_status.length}
                                                              options={arrayWithAny(maritalStatus)}
                                                              onChange={(e, { value }) => {
                                                                  this.updateFormState("marital_status",checkAny(value))
                                                              }}
                                                              value={this.state.form.marital_status}
                                                              />
                                                          </div>
                                                          </div>
                                                      </div>
          
                                                      <div className="inline field fields mb-4">
                                                        <label className="w-50 lh-md">
                                                          <span className="mr-05"><FormattedMessage id="preference.community"/></span>
                                                        </label>
                                                        <div className="w-100">
                                                          <div className="d-flex w-100">
                                                            <Dropdown
                                                              search
                                                              className={`wd-input`}
                                                              placeholder="Select Community"
                                                              fluid
                                                              selection
                                                              selectOnBlur={false}
                                                              multiple
                                                              disabled={this.state.preference &&  this.state.preference.community !== null &&  this.state.preference.community.length}
                                                              options={arrayWithAny(
                                                                filterItems(
                                                                  "community",
                                                                  this.props.commonData && this.props.commonData.data
                                                                )
                                                              )}
                                                              onChange={(e, { value }) => {
                                                                this.updateFormState("community",checkAny(value))
                                                              }}
                                                              value={this.state.form.community}
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>  
                                                          <div className="inline field fields mb-4">
                                                          <label className="w-50 lh-md">
                                                            <span className="mr-05"><FormattedMessage id="preference.religion"/></span>
                                                          </label>
                                                          <div className="w-100">
                                                            <div className="d-flex w-100">
                                                              <Dropdown
                                                                search
                                                                className={`wd-input`}
                                                                placeholder="Select Religion"
                                                                fluid
                                                                selection
                                                                selectOnBlur={false}
                                                                multiple
                                                                disabled={this.state.preference &&  this.state.preference.religion !== null && this.state.preference.religion.length}
                                                                options={arrayWithAny(
                                                                  filterItems(
                                                                    "religion",
                                                                    this.props.commonData && this.props.commonData.data
                                                                  )
                                                                )}
                                                                onChange={(e, { value }) => {
                                                                  this.updateFormState("religion",checkAny(value))
                                                                }}
                                                                value={this.state.form.religion}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
          
                                                        <div className="inline field fields mb-4">
                                                          <label className="w-50 lh-md">
                                                            <span className="mr-05"><FormattedMessage id="preference.caste"/></span>
                                                          </label>
                                                          <div className="w-100">
                                                            <div className="d-flex w-100">
                                                              <Dropdown
                                                                search
                                                                className={`wd-input`}
                                                                placeholder="Select Caste"
                                                                fluid
                                                                selection
                                                                selectOnBlur={false}
                                                                multiple
                                                                
                                                                options={arrayWithAny(
                                                                  filterItems(
                                                                    "caste",
                                                                    this.props.commonData && this.props.commonData.data,
                                                                    {type:1}
                                                                  )
                                                                )}
                                                                onChange={(e, { value }) => {
                                                                  this.updateFormState("caste",checkAny(value))
                                                                }}
                                                                value={this.state.form.caste}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
          
                                                        <div className="inline field fields mb-4">
                                                              <label className="w-50 lh-md">
                                                                <span className="mr-05"><FormattedMessage id="preference.living_country"/></span>
                                                              </label>
                                                              <div className="w-100">
                                                                <div className="d-flex w-100">
                                                                  <Dropdown
                                                                    search
                                                                    className={`wd-input`}
                                                                    placeholder="Select Living Country"
                                                                    fluid
                                                                    selection
                                                                    selectOnBlur={false}
                                                                    multiple
                                                                    options={arrayWithAny(
                                                                      filterItems(
                                                                        "nationality",
                                                                        this.props.commonData && this.props.commonData.data
                                                                      )
                                                                    )}
                                                                    onChange={(e, { value }) => {
                                                                      this.updateFormState("living_country",checkAny(value))
                                                                    }}
                                                                    value={this.state.form.living_country}
                                                                  />
                                                                </div>
                                                              </div>
                                                            </div>
          
                                                            <div className="inline field fields flex-start mb-4">
                                                              <label className="w-50 lh-md">
                                                                <span className="mr-05"><FormattedMessage id="preference.education_level"/></span>
                                                              </label>
                                                              <div className="w-100">
                                                                <div className="d-flex w-100">
                                                                  <Dropdown
                                                                    search
                                                                    className={`wd-input`}
                                                                    placeholder="Select Education Level"
                                                                    fluid
                                                                    selection
                                                                    multiple
                                                                    selectOnBlur={false}
                                                                    options={arrayWithAny(
                                                                      filterItems(
                                                                        "education-level",
                                                                        this.props.commonData && this.props.commonData.data,
                                                                        {type:1}
                                                                      )
                                                                    )}
                                                                    onChange={(e, { value }) => {
                                                                      this.updateFormState("education_level",checkAny(value))
                                                                    }}
                                                                    value={this.state.form.education_level}
                                                                  />
                                                                  
                                                                </div>
                                                              </div>
                                                            </div>
                                                                    
                                                            <div className="inline field fields mb-4">
                                                                <label className="w-50 lh-md">
                                                                  <span className="mr-05"><FormattedMessage id="preference.employment_type"/></span>
                                                                </label>
                                                                <div className="w-100">
                                                                  <div className="d-flex w-100">
                                                                    <Dropdown
                                                                      search
                                                                      className={`wd-input`}
                                                                      placeholder="Working Status"
                                                                      fluid
                                                                      multiple
                                                                      selection
                                                                      selectOnBlur={false}
                                                                      options={arrayWithAny(
                                                                        filterItems(
                                                                          "employment-type",
                                                                          this.props.commonData && this.props.commonData.data,
                                                                          {type:1}
                                                                        )
                                                                      )}
                                                                      onChange={(e, { value }) => {
                                                                        this.updateFormState("employment_type",checkAny(value))
                                                                      }}
                                                                      value={this.state.form.employment_type}
                                                                    />
                                                                  </div>
                                                                </div>
                                                              </div>
                                                      <div className="inline fields mb-4">
                                                          <label className="w-50 lh-md mb-05" />
                                                          <div className="w-100">
                                                          <div className="two wide fields m-0 w-100 btn-group">
                                                              <div className="field mb-0">
                                                              <Button type="submit" fluid className="btn-pink ripple">SAVE</Button>
                                                              </div>
                                                          </div>
                                                          </div>
                                                      </div>
                                                  </form>
                                            ): (
                                              <div className="ui form px-1 pb-2">
                                                 <div className="inline fields mb-4">
                                                          <label className="w-50 lh-md mb-05" />
                                                          <div className="w-100">
                                                          <div className="two wide fields m-0 w-100 btn-group">
                                                              <div className="field mb-0">
                                                              <Button 
                                                                fluid 
                                                                className="btn-pink ripple"
                                                                onClick={this.handleFormSubmission}
                                                              >SAVE</Button>
                                                              </div>
                                                          </div>
                                                          </div>
                                                    </div>
                                                  </div>
                                            )}
                                             
                                    </div>
                                </div>
                                <div className="mb-5">
                                  <SubFooter
                                    profileId={profileId}
                                  />
                                </div>
                    <div>
                </div>
            </div>
            </div>
            </div>
            )}
        </div>
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  singleUser: state.user.singleProfileData,
  commonData: state.commonData.allCommonData,
  profileNotFound: state.user.profileNotFound,
  globalSpinner: state.globalSpinner.globalSpinner,
  globalNumber: state.commonData.defaultCountryNumber
});

export default injectIntl(
    connect(mapStateToProps, {
    singleProfile,
    updateLayout,
    getCommonData,
    defaultSingleProfile
})(withRouter(FilterOut)));
