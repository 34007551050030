export const matchesRoutes  = {
    'new': {
        title : `New Matches`,
        subtitle : `New and unviewed matches based on your [preference]`,
        emtpy: `Currently no new matches based on your preferences. Relax your [preference] or look out your other matches categories and take action.`,
        sort: 'matched-date'
    },
    'all': {
        title : `All Matches`,
        subtitle : `Total matches released based on your [preference]`,
        emtpy: `Currently no matches based on your partner preference. Edit your [preference] here and keep checking regularly.`,
        sort: 'score'
    },
    'viewed': {
        title : `Viewed by me`,
        subtitle : `These are the matches recently viewed by you`,
        emtpy: `You have not yet viewed any profile. Please check [all-matches] and take further action`,
        sort: 'activity'
    },
    'mutual': {
        title : `Mutual Matches`,
        subtitle : `These members [preference] matches with your profile and your basic [preference] matches with their profile`,
        emtpy: `Your profile has not matched with any of our other members. Please [edit-profile] and your [preference] to explore more matches `,
        sort: 'score'
    },
    'reverse': {
        title : `Reverse Matches`,
        subtitle : ` These members [preference] matches with your profile and might like you`,
        emtpy: `Your profile has not matched with any of our other members. Please [edit-profile] and fill out your missed details and update as much as recent informations`,
        sort: 'score'
    },
    'shortlisted': {
        title : `Shortlisted by me`,
        subtitle : `These are the profiles you have shortlisted`,
        emtpy: `You have not shortlisted any members. Please check [all-matches] and take further action`,
        sort: 'score'
    },
    'rejected':{
        title : `Rejected by me`,
        subtitle : `These are the profiles you have ignored`,
        emtpy: `You have not ignored any members.`,
        sort: 'score'
    },
    'contact-viewed': {
        title : `Contact viewed by me`,
        subtitle : `These are the profile's contact number you have viewed`,
        emtpy: `You have not viewed any contact number of your matches. Please check [all-matches] and take further action`,
        sort: 'score'
    },
    'reverse-contact-viewed': {
        title : `Who viewed my contact number`,
        subtitle : ` These are the members who have viewed your contact number`,
        emtpy: `No one has viewed your phone number yet. Please [edit-profile] and fill out your missed details and update as much as recent informations`,
        sort: 'score'
    },
    'reverse-shortlisted': {
        title : `Who shortlisted my profile`,
        subtitle : `These are the members who shortlisted your profile`,
        emtpy: `Your profile is yet to be shortlisted. Please [edit-profile] and fill out your missed details and update as much as your recent informations`,
        sort: 'score'
    },
    'reverse-viewed': {
        title : `Who viewed my profile`,
        subtitle : `These are the members who viewed your profile`,
        emtpy: `Your profile is yet to be viewed by others. Please [edit-profile] and fill out your missed details and update as much as your recent informations`,
        sort: 'score'
    }
}
