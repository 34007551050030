import React, { Fragment, useState } from "react"
import { Dimmer,Loader  } from "semantic-ui-react"
import { chatAxios } from "../../../../../util/axios"
import {requests} from "../../../../../util/chat"

function Reminder(props) {
  const [busy, setBusy] = useState(false)
  const { token ,convId, profileId, data, done, history } = props

  const requestAction = async (action) => {
        try {
          if(action === 'add') {
            history.push(`/${profileId}/edit-profile`)
            return
          }
          setBusy(true)
          await chatAxios.post(`/conversations/${convId}/messages/${data.id}`,{ 
              token : token,
              action : action
          })
          setBusy(false)
          done()
    } catch (error) {
      setBusy(false)
    }
  }
  const req = requests[data.text]
  const prevMsgActions = data.from === profileId ? req.self_actions : req.partner_actions
  const prop = 'later'

  const msgActions  = Object.keys(prevMsgActions).reduce((object, key) => {
    if (key !== prop) {
      object[key] = prevMsgActions[key]
    }
    return object
  }, {})

  return (
    <Fragment>
      <div className="chat-notification text-white pos-relative">
        <i className="i-clock-white"></i>
        <div className="notification-content"><p>Reminder for {req.text} request</p></div>
        <div className="notification-actions">
              { Object.keys(msgActions).map(i => {
                  return <button
                          key={i}
                          className='ui btn-link button text-white font-weight-normal p-0'
                          onClick={(e) => {
                              e.preventDefault()
                              requestAction(i)
                          }}
                      >{msgActions[i].label}
                  </button>
              })}
              {busy && (
          <Dimmer active inverted><Loader size='tiny' inverted content={null} /></Dimmer>
        )}
          </div>

        
        </div>
    </Fragment>
  );
}

export default Reminder;
