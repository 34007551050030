import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dropdown, Button, Modal } from "semantic-ui-react";

import { closeModal, customSweetAlert } from "../redux-state/modalActions";
import {
  singleProfile,
  updateDraftDashboardProfile
} from "../../../redux/actions/user/user";
import { getCommonData } from "../../../redux/actions/common/common-data";
import { filterItems } from "../../../util/filter_common_data";
import { Error } from "../../../common/shared-components/Error";
import ChangeMobileNumberModal from "./parter_contact_sub_modals/ChangeMobileNumberModal";

function PartnerContactModal(props) {
  const [name, set_name] = useState(null);
  const [relationship, set_relationship] = useState(null);
  const [call_time, set_call_time] = useState(null);
  const [instructions, set_instructions] = useState(null);

  const updateContact = e => {
    e.preventDefault();
    const formData = {
      name: name,
      relationship: relationship,
      call_time: call_time,
      instructions: instructions
    };
    props.updateDraftDashboardProfile(
      `profile/${props.profileId}`,
      "contacts",
      formData
    );
  };

  useEffect(() => {
    if (props.singleUser === null) {
      props.singleProfile(`profile/${props.profileId}`);
    }
    if (props.allCommonData.length < 1) {
      props.getCommonData();
    }

    // setting default state
    if (props.singleUser) {
      set_name(
        name !== null
          ? name
          : props.singleUser.contact !== null
          ? props.singleUser.contact.name
          : null
      );
      set_relationship(
        relationship !== null
          ? relationship
          : props.singleUser.contact !== null
          ? props.singleUser.contact.relationship
          : null
      );
      set_call_time(
        call_time !== null
          ? call_time
          : props.singleUser.contact !== null
          ? props.singleUser.contact.call_time
          : null
      );
      set_instructions(
        instructions !== null
          ? instructions
          : props.singleUser.contact !== null
          ? props.singleUser.contact.instructions
          : null
      );
    }
  }, [call_time, instructions, name, props, relationship]);

  return (
    <React.Fragment>
      <div className="ui header">
        <h3 className="m-0">Contact Details For Public</h3>
      </div>
      <div className="content">
        <form method="POST" onSubmit={updateContact}>
          <div className="ui form">
            <div className="inline field fields mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Contact Name for Public</span>
              </label>
              <div className="w-100">
                <input
                  className={`wd-input w-100 ${Error(props.errors, "name")}`}
                  name="name"
                  type="text"
                  placeholder="Enter name"
                  onChange={e => set_name(e.target.value)}
                  value={name}
                />
              </div>
            </div>
            <div className="inline field fields mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Relationship with Profile</span>
              </label>
              <div className="w-100">
                <Dropdown
                  search
                  className={`wd-input ${Error(props.errors, "relationship")}`}
                  placeholder="Relationship"
                  fluid
                  selection
                  clearable
                  selectOnBlur={false}
                  options={filterItems(
                    "relationship",
                    props.allCommonData && props.allCommonData.data
                  )}
                  onChange={(e, { value }) => set_relationship(value)}
                  value={relationship}
                />
              </div>
            </div>
            <div className="inline field fields mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Main number</span>
              </label>
              <div className="w-100">
                <div className="font-14 text-black">
                  +
                  {props.singleUser.contact !== null &&
                    props.singleUser.contact.hasOwnProperty("phone") &&
                    props.singleUser.contact.phone.no}{" "}
                  <button
                    className="ui button btn-link text-blue font-weight-normal p-0 ml-05"
                    id="modal15"
                    type="button"
                  >
                    <ChangeMobileNumberModal
                      profileId={props.profileId}
                      btnTitle={`${
                        props.singleUser.contact !== null &&
                        props.singleUser.contact.hasOwnProperty("phone") &&
                        props.singleUser.contact.phone.verified === true
                          ? "Change main number"
                          : "Add main number"
                      }`}
                      btnMainTitle={`${
                        props.singleUser.contact !== null &&
                        props.singleUser.contact.hasOwnProperty("phone") &&
                        props.singleUser.contact.phone.verified === true
                          ? "Change main number"
                          : "Add main number"
                      }`}
                      numberType="change"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className="inline field fields mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Additional number</span>
              </label>
              <div className="w-100">
                {props.singleUser.contact !== null &&
                  props.singleUser.contact.hasOwnProperty("additional_phone") &&
                  props.singleUser.contact.additional_phone !== null &&
                  props.singleUser.contact.additional_phone.verified && 
                  '+'+props.singleUser.contact.additional_phone.no}{" "}
                <button
                    className="ui button btn-link text-blue font-weight-normal p-0 ml-05"
                    id="modal15"
                    type="button"
                >
                <ChangeMobileNumberModal
                  profileId={props.profileId}
                  btnTitle={`${
                    props.singleUser.contact !== null &&
                    props.singleUser.contact.hasOwnProperty(
                      "additional_phone"
                    ) &&
                    props.singleUser.contact.additional_phone !== null &&
                    props.singleUser.contact.additional_phone.verified === true
                      ? "Change additional number"
                      : "Add additional number"
                  }`}
                  btnMainTitle={`${
                    props.singleUser.contact !== null &&
                    props.singleUser.contact.hasOwnProperty(
                      "additional_phone"
                    ) &&
                    props.singleUser.contact.additional_phone !== null &&
                    props.singleUser.contact.additional_phone.verified === true
                      ? "Change additional number"
                      : "Add additional number"
                  }`}
                  numberType="add"
                />
                </button>
              </div>
            </div>
            <div className="inline field fields mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Prefered Time to Call</span>
              </label>
              <div className="w-100">
                <Dropdown
                  search
                  className={`wd-input ${Error(props.errors, "call_time")}`}
                  placeholder="Prefered Time to Call"
                  fluid
                  selection
                  clearable
                  selectOnBlur={false}
                  options={filterItems(
                    "call-time",
                    props.allCommonData && props.allCommonData.data
                  )}
                  onChange={(e, { value }) => set_call_time(value)}
                  value={call_time}
                />
              </div>
            </div>
            <div className="inline field fields mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Communication Instrucutions</span>
              </label>
              <div className="w-100">
                <input
                  className={`wd-input w-100 ${Error(
                    props.errors,
                    "instructions"
                  )}`}
                  onChange={e => set_instructions(e.target.value)}
                  value={instructions}
                  type="text"
                  placeholder="E.g: Calls only, Message only, Whatsapp calls only"
                />
              </div>
            </div>
            <div className="inline fields">
              <label className="w-50 lh-md hidden-xs" />
              <div className="w-100">
                <div className="two wide fields m-0 w-100 btn-group">
                  <div className="field mb-0">
                    <Button
                      loading={props.updateProSpinner}
                      className="fluid ui button btn-pink ripple"
                      type="submit"
                    >
                      Save
                    </Button>
                  </div>
                  <div className="field pr-0 mb-0">
                    <button
                      onClick={() => props.customSweetAlert()}
                      className="fluid ui button btn-white ripple"
                      type="button"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    allCommonData: state.commonData.allCommonData,
    errors: state.error.formsError,
    singleUser: state.user.singleProfileData,
    updateProSpinner: state.user.updateProSpinner
  };
};

export default connect(
  mapStateToProps,
  {
    closeModal,
    singleProfile,
    getCommonData,
    updateDraftDashboardProfile,
    customSweetAlert
  }
)(PartnerContactModal);
