import React from "react";
import { Dropdown ,TextArea} from "semantic-ui-react";
import RadioBox from "../../../../../../common/shared-components/Radio";
import {
  disability,
  kids,
  kidsStatus,
  skin_tone,
  body_type,
  arrayWithAny
} from "../../../../../../common/forms-data/forms-data";
import { Error } from "../../../../../../common/shared-components/Error";
import { filterItems } from "../../../../../../util/filter_common_data";
import { checkAny } from "../../../../../../util/check_any";

export default function OtherDetails({
  commonD,
  errors,
  current_state,
  setDiet,
  setSmokting,
  setChildrenState,
  setChildren,
  setDisability,
  setSkin,
  setBody,
  setDescription,
  setKidsState,
  allCommonData,
  setDrinking
}) {
  return (
    <React.Fragment>

<div className="inline fields mb-4">
        <label className="w-50 lh-md">
          <span className="mr-05">Eating Habit</span>
        </label>
        <div className="w-100">
          <Dropdown
            search
            className={`wd-input ${Error(errors, "hobby")}`}
            placeholder="Select Diet"
            fluid
            selection
            selectOnBlur={false}
            multiple
            options={arrayWithAny(
              filterItems("diet", allCommonData && allCommonData.data , { type:1})
            )}
            onChange={(e, { value }) => setDiet(checkAny(value))}
            value={current_state.diet}
          />
        </div>
      </div>
      <div className="inline fields mb-4 flex-start">
        <label className="w-50 lh-md">
          <span className="mr-05">Drinking Habit</span>
        </label>
        <div className="w-100">
          <div className="two wide fields m-0">
            <div className="field mb-0 pr-0">
              <RadioBox
                name="drinking_habit"
                radioBoxObject={[
                  {
                    text: "Doesn't Matter",
                    value: 1,
                    parent: "field"
                  }
                ]}
                className="wd-radio-box w-100 pink"
                selectedRadioValue={radioValue => setDrinking(radioValue)}
                setDefaultValue={current_state.drinking_habit}
              />
            </div>
            <div className="field mb-0 pr-0">
              <div className="ui radio checkbox pink wd-radio-box w-100">
                <RadioBox
                  name="drinking_habit"
                  radioBoxObject={[
                    {
                      text: "Never Drinks",
                      value: 0,
                      parent: "field mb-0 pr-0"
                    }
                  ]}
                  className="wd-radio-box w-100 pink"
                  selectedRadioValue={radioValue => setDrinking(radioValue)}
                  setDefaultValue={current_state.drinking_habit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="inline fields mb-4 flex-start">
        <label className="w-50 lh-md">
          <span className="mr-05">Smoking Habit</span>
        </label>
        <div className="w-100">
          <div className="two wide fields m-0">
            <div className="field mb-0 pr-0">
              <RadioBox
                name="smoking_habit"
                radioBoxObject={[
                  {
                    text: "Doesn't Matter",
                    value: 1,
                    parent: "field"
                  }
                ]}
                className="wd-radio-box w-100 pink"
                selectedRadioValue={radioValue => setSmokting(radioValue)}
                setDefaultValue={current_state.smoking_habit}
              />
            </div>
            <div className="field mb-0 pr-0">
              <div className="ui radio checkbox pink wd-radio-box w-100">
                <RadioBox
                  name="smoking_habit"
                  radioBoxObject={[
                    {
                      text: "Never Smokes",
                      value: 0,
                      parent: "field mb-0 pr-0"
                    }
                  ]}
                  className="wd-radio-box w-100 pink"
                  selectedRadioValue={radioValue => setSmokting(radioValue)}
                  setDefaultValue={current_state.smoking_habit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="wd-form-divider">
                            <div className="ui header">
                              <span>Other Details</span>
                            </div>
                            <div className="font-12 text-gray pr-1 pl-1">
                              Let's set up your account, while we find Matches
                              for you!.
                            </div>
                          </div>

       <div className="inline fields mb-4">
        <label className="w-50 lh-md">
          <span className="mr-05">Physical Status</span>
        </label>
        <div className="w-100">
          <Dropdown
            search
            className={`wd-input ${Error(errors, "disability")}`}
            placeholder="Select Disability"
            fluid
            selection
            selectOnBlur={false}
            options={arrayWithAny(disability, "Doesn't Matter")}
            onChange={(e, { value }) => setDisability(value)}
            value={current_state.disability}
          />
        </div>
      </div>

      <div className="inline fields mb-4 ">
        <label className="w-50 lh-md">
          <span className="mr-05">Kids</span>
        </label>
        <div className="w-100">
          <Dropdown
            search
            className={`wd-input ${Error(errors, "children")}`}
            placeholder="Select Kids"
            fluid
            selection
            selectOnBlur={false}
            options={kids}
            onChange={(e, { value }) => {
              setChildrenState(value);
              if (value === 0) {
                setKidsState(null);
              }
            }}
            value={current_state.children}
          />
        </div>
      </div>

      {(current_state.children === "*" || current_state.children === 1) && (
        <div className="inline fields mb-4">
          <label className="w-50 lh-md">
            <span className="mr-05">Kids Living Status</span>
          </label>
          <div className="w-100">
            <Dropdown
              search
              className={`wd-input ${Error(errors, "children_state")}`}
              placeholder="Select Living Status"
              fluid
              selection
              selectOnBlur={false}
              options={kidsStatus}
              onChange={(e, { value }) => {
                setKidsState(value);
              }}
              value={current_state.children_state}
            />
          </div>
        </div>
      )}

      <div className="inline fields mb-4">
        <label className="w-50 lh-md">
          <span className="mr-05">Skin Tone</span>
        </label>
        <div className="w-100">
          <Dropdown
            search
            className={`wd-input ${Error(errors, "skin_tone")}`}
            placeholder="Select Skin Tone"
            fluid
            clearable
            selection
            selectOnBlur={false}
            multiple
            options={arrayWithAny(skin_tone, "Doesn't Matter")}
            onChange={(e, { value }) => setSkin(checkAny(value))}
            value={current_state.skin_tone}
          />
        </div>
      </div>
      <div className="inline fields mb-4">
        <label className="w-50 lh-md">
          <span className="mr-05">Body Type</span>
        </label>

        <div className="w-100">
          <Dropdown
            search
            className={`wd-input ${Error(errors, "body_type")}`}
            placeholder="Select Body Type"
            fluid
            selection
            selectOnBlur={false}
            clearable
            multiple
            options={arrayWithAny(body_type, "Doesn't Matter")}
            onChange={(e, { value }) => setBody(checkAny(value))}
            value={current_state.body_type}
          />
        </div>
      </div>

      <div className="inline fields mb-4 flex-start">
        <label className="w-50 lh-md">
          <span className="mr-05">What I am looking for</span>
        </label>
        <div className="w-100">
        <TextArea 
            placeholder="Write about how a partner you are looking for" 
            className="wd-input" 
            rows="7"
            value={current_state.description}
            onBlur={e =>
              setDescription(e.target.value)
            }
        />
        </div>
      </div>

    </React.Fragment>
  );
}
