import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import MainHeader from "../../../containers/template-parts/main-header";
import Sidebar from "../../../containers/template-parts/sidebar";
import Header from "../../../containers/template-parts/header";
import Footer from "../../../containers/template-parts/footer";
import WhyRegisterWithMatrimony from "../../../components/main/shared-components/why-register-with-matrimony";
import {
  excludedStatusRoutes,
  excludedStatus
} from "../../../util/GlobalContants";
import { Link } from "react-router-dom";
import { rootStatusCards } from "../../../components/main/private-pages/dashboard/dashboard-error-status/rootStatusCards";
import { singleProfile , setUnreadCount} from "../../../redux/actions/user/user";
import ProfileNotFound from "../../../components/main/private-pages/dashboard/dashboard-error-status/profile-not-found";
import Lottie from "react-lottie";
import player1 from "../../../util/lottie/ready_state_1";
import { withRouter } from "react-router-dom";
import ProfileCard from "../../../components/main/private-pages/dashboard/single-match/profile-card/profile_card";
import Pusher from 'pusher-js'
import { setChatAuthToken } from "../../../util/axios";

class Wrapper extends Component {
  state = {
    loader: true
  }
  
  pusher = null
  presence = null 

  UNSAFE_componentWillReceiveProps(nextProps, prevState) {
    const {
      config: { global_loader }
    } = this.props;
    if (global_loader) {
      if (nextProps.isLoading !== prevState.isLoading) {
        this.setState({
          loader: nextProps.isLoading
        });
      }
    }
    
  }

  componentDidMount() {
    const {
      config: { global_loader }
    } = this.props;
    this.setState({ loader: global_loader });

    const authTkn = localStorage.getItem("wedmToken")
    if (authTkn) {
      const authToken = JSON.parse(authTkn)
      setChatAuthToken(authToken.token.access_token)
    }
    const { match: { params }, singleProfile , setUnreadCount } = this.props;
    
    
    if(params.profileId){
      this.pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
        cluster: 'eu',
        authEndpoint: `${process.env.REACT_APP_PUSHER_AUH}/${params.profileId}/private-auth`,
        auth: {
          headers: {
            'Authorization': `Bearer ${this.props.accessToken}`
          }
        },
        forceTLS: true
      })
      this.presence = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
        cluster: 'eu',
        authEndpoint: `${process.env.REACT_APP_PUSHER_AUH}/${params.profileId}/presence-auth`,
        auth: {
          headers: {
            'Authorization': `Bearer ${this.props.accessToken}`
          }
        },
        forceTLS: true
      })

      const channel = this.pusher.subscribe('private-profile-'+params.profileId)
      this.presence.subscribe('presence-profile-'+params.profileId)
      channel.bind('matches-found', (data) => {
          singleProfile(`profile/${params.profileId}`,false,null,false)
      })

      channel.bind('new-message', (data) => {
        //console.log('new-message',data)

          if(data.message.from !== parseInt(params.profileId)) {
            setUnreadCount(this.props.unread + 1)
          }
          if(this.props.listenNewMessage) {
            this.props.listenNewMessage('new-message',data)
          }
      })

      channel.bind('new-action', (data) => {
         //console.log('new-action',data)
          if(this.props.listenNewMessage) {
            this.props.listenNewMessage('new-action',data)
          }
      })

      channel.bind('block', (data) => {
        //console.log('typing',data)
        if(this.props.listenNewMessage) {
          this.props.listenNewMessage('block',data)
        }
      })
      channel.bind('unblock', (data) => {
        //console.log('typing',data)
        if(this.props.listenNewMessage) {
          this.props.listenNewMessage('unblock',data)
        }
      })
      channel.bind('typing', (data) => {
        //console.log('typing',data)
        if(this.props.listenNewMessage) {
          this.props.listenNewMessage('typing',data)
        }
      })

      channel.bind('read', (data) => {
        //console.log('typing',data)
        if(this.props.listenNewMessage) {
          this.props.listenNewMessage('read',data)
        }
      })

      channel.bind('new-conversation', (data) => {
        //console.log('new-conversation',data)
        if(this.props.listenNewMessage) {
          if(data.partner === parseInt(params.profileId)) {
            setUnreadCount(this.props.unread + 1)
          }
          this.props.listenNewMessage('new-conversation',data)
        }
      })

    }
  }

  componentWillUnmount(){
    const { match: { params } } = this.props;
    if(params.profileId ){
      if(this.pusher !== null )
      this.pusher.unsubscribe('private-profile-'+params.profileId)
      if(this.presence !== null )
      this.presence.unsubscribe('presence-profile-'+params.profileId)
    }
  }
  
  render() {

    const defaultOptions1 = {
      loop: true,
      autoplay: true,
      animationData: player1,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };

    const {
      config: { header, left_sidebar },
      children,
      profileNotFound,
      singleUser,
      whyRegister = false,
      matchNotFound = false,
      matchDetails = null,
      tempNotAvailable = false,
      profile_id = null,
      external= null,
      allCommonData = {},
      chatWrapper = false
    } = this.props;

    const { loader } = this.state;
    if (!header) {
      return (
        <Fragment>
          <Header />
          <div className="ui pushable segment mt-60">
            {loader && (
              <div
                className="ui active transition visible inverted dimmer"
                style={{
                  opacity: 1,
                  backgroundColor: "#fff",
                  overflow: "hidden",
                  height: "100%"
                }}
              >
                <div className="content">
                  <div className="ui large text loader">Loading</div>
                </div>
              </div>
            )}
            {children}
          </div>
          {whyRegister && <WhyRegisterWithMatrimony />}
          <Footer />
        </Fragment>
      );
    }

    if (profileNotFound)
      return (
        <Fragment>
          <Header />
          <ProfileNotFound />;
        </Fragment>
      );

    if (
      this.props.singleUser &&
      excludedStatus.includes(this.props.singleUser.state)
    ) {
      return (
        <Fragment>
          <Header />
          <div className="ui pushable segment">
            {rootStatusCards(this.props.singleUser && this.props.singleUser)}
          </div>
          <Footer />
        </Fragment>
      );
    }

    if (matchNotFound) {
      return (
        (matchDetails !== null ? (
          <div className="wd-body pb-xs">
          <div className="app-wrapper">
            {!profileNotFound &&
              (singleUser && excludedStatusRoutes.includes(singleUser.state)
                ? ""
                : left_sidebar && <Sidebar />)}

            <div className="app-content">
              <MainHeader />

              <div className="content-body">
                <div className="content-wrapper">
                  <div className="wd-rail">
                    <div className="wd-middle-content">
                    <div className="matches-middle-col">
                    <div className="ui two column stackable grid match-tiny-stats item-center">
                          <div className="column">
                              <div className="font-17 text-black font-weight-semibold">Match View
                              </div>
                          </div>
                          {singleUser && singleUser.package === 'free' && (
                              <div className="column">
                              <div className="font-13 texr-gray"><span className="mr-1">Contact her directly through phone, Email &amp; chat</span><a href={singleUser.upgrade_link} className="text-gradient font-weight-bold pointer">Upgrade now <i className="i-carret-right-pink d-inline-block align-middle ml-1" /></a></div>
                              </div>
                          )}
                          
                      </div>
                      <ProfileCard
                          match_details={matchDetails}
                          common_data={allCommonData}
                          globalSpinner={this.state.loader}
                          profileId={profile_id}
                          short_list={false}
                          hide={false}
                          upgradeWarning={matchDetails.upgrade_warning}
                          photoWarning={matchDetails.photo_upload_warning}
                          single_user={singleUser}
                        />
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        ) : (
          <div className="wd-body pb-xs">
          <div className="app-wrapper">
            {!profileNotFound &&
              (singleUser && excludedStatusRoutes.includes(singleUser.state)
                ? ""
                : left_sidebar && <Sidebar />)}

            <div className="app-content">
              <MainHeader />

              <div className="content-body">
                <div className="content-wrapper">
                  <div className="wd-rail">
                    <div className="wd-middle-content">
                      <div className="ui relaxed divided items">
                        <div className="ui vertical segment wd-section wd-error bb-0">
                          <div className="ui container wd-container text-center">
                            <img
                              className="ui image pro-error-img d-block m-auto"
                              src="//storage.googleapis.com/matrimony-assets/dashboard/img/profile-error.png"
                              alt=""
                            />
                            <h1 className>Match profile not found</h1>
                            <p>
                              The profile you were be looking for could not be
                              found.
                            </p>

                            <Link
                              to={`/`}
                              className="ui button btn-blue font-12 ripple mb-4"
                            >
                              GO TO HOME
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        ))
        
      );
    }

    if (tempNotAvailable) {
      return (
        <div className="wd-body pb-xs">
          <div className="app-wrapper">
            {!profileNotFound &&
              (singleUser && excludedStatusRoutes.includes(singleUser.state)
                ? ""
                : left_sidebar && <Sidebar />)}

            <div className="app-content">
              <MainHeader />

              <div className="content-body">
                <div className="content-wrapper">
                  <div className="wd-rail">
                    <div className="wd-middle-content">
                      <div className="ui relaxed divided items">
                        <div className="ui vertical segment wd-section wd-error bb-0">
                          <div className="text-center">
                            <img
                              className="ui fluid image d-block m-auto"
                              src="/assets/contents/profile-card.png"
                              alt=""
                            />
                            <h1 className>
                              This profile is temporarily not available
                            </h1>
                            {/* <p>
                              Please check at a later time OR we can notify you once
                              it becomes available by clicking{" "}
                              <a href className="text-blue">
                                HERE
                              </a>
                              .
                            </p> */}
                            <Link
                              to={`/${profile_id}`}
                              className="ui button btn-blue font-12 ripple mb-4"
                            >
                              GO TO HOME
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <Fragment>
        {loader && (
          <div
            className="ui active transition visible inverted dimmer"
            style={{
              opacity: 1,
              backgroundColor: "#fff",
              overflow: "hidden",
              height: "100%"
            }}
          >
            <div className="content">
              <div className="ui large text loader">Loading</div>
            </div>
          </div>
        )}
        {singleUser && (
          <React.Fragment>
             {/* {  [1,2].includes(singleUser.state) && singleUser.match_state === 0 && (
               <div className="setting-overlay">
               <div className="overlay-box">
                 <h1>Thankyou for signing up</h1>
                 <div className="lead">We're setting up your account as fast as we can. If you're not redirected please refresh this page</div>
                 <Lottie options={defaultOptions1} height={300} width={300} />
                 <div className="font-14 text-muted">Searching for matches</div>
               </div>
             </div>
             )} */}
            <div className={ ([1,2].includes(singleUser.state)) ? 'app-wrapper' : chatWrapper ? 'app-wrapper chat-app-wrapper' : 'app-wrapper'}>
              {!profileNotFound &&
                (singleUser && excludedStatusRoutes.includes(singleUser.state)
                  ? ""
                  : left_sidebar && <Sidebar />)}

              <div className="app-content">
                {singleUser &&
                  !excludedStatus.includes(singleUser.state) &&
                  !profileNotFound &&
                  header && <MainHeader />}
                {singleUser && !profileNotFound && children}
              </div>
              {external !== null && external}
            </div>
          </React.Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    profileNotFound: state.user.profileNotFound,
    allCommonData: state.commonData.allCommonData,
    accessToken : state.user.token,
    unread : state.user.unread
  }
}
export default connect(
  mapStateToProps,
  { singleProfile, setUnreadCount }
)(withRouter(Wrapper))
