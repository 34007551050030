import React, { Component } from "react";
import { Dropdown,Loader } from "semantic-ui-react";


import { Error } from "../../../../../../common/shared-components/Error";
import { filterItems } from "../../../../../../util/filter_common_data";
import { genderPrefix } from "../../../../../../util/getGender"; 
import { gpAxios } from "../../../../../../util/axios";
import CareerSearch from "../../../../../../common/shared-components/CareerSearch";
export default class EducationsCareerSteps extends Component {

  state= {
    occupations : [],
    binded : false,
  }

  handleAddOccupation = async(e, { value }) => {
    
    try {
      const result = await gpAxios.post(`/common/occupation`, {
        name: value
      });
      const obj = result.data.data
      this.setState((prevState) => ({
        occupations: [obj, ...prevState.occupations],
      }))
      this.props.setParentState('occupation',obj.value)
    } catch (error) {
      
    }

    
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(!this.state.binded){
      if(nextProps.allCommonData && nextProps.allCommonData.data){
        this.setState({
          binded : true,
          occupations:  filterItems( "occupation",nextProps.allCommonData.data)
        })
      }
    }
  }

  render(){
    const {
      data: { data },
      state,
      allCommonData,
      errors
    } = this.props;

    return (
      <React.Fragment>
        <div className="wd-form-divider pt-0">
                            <div className="ui header">
                              <span>
                                Educations &amp; Career
                              </span>
                            </div>
                          </div>
        <div className="inline fields mb-4">
          <label className="w-50 lh-md">
            <span className="mr-05">Educational Level</span>
          </label>
          <div className="w-100">
            <Dropdown
              search
              className={`wd-input ${Error(
                errors,
                "education_level"
              )}`}
              placeholder="Select Education Level"
              fluid
              selection
              clearable
              selectOnBlur={false}
              options={filterItems(
                "education-level",
                allCommonData && allCommonData.data
              )}
              onChange={(e, { value }) => {
                this.props.setParentState('education_level',(value && value !== "") ? value : null)
                this.props.setParentState('education_field',null)
              }}
              value={state.education_level}
            />
          </div>
        </div>
        <div className="inline fields mb-4">
          <label className="w-50 lh-md">
            <span className="mr-05">Educational Field </span>
          </label>
          <div className="w-100">
            <Dropdown
              search
              className={`wd-input ${Error(
                errors,
                "education_field"
              )}`}
              placeholder="Select Education Field"
              fluid
              selection
              multiple
              selectOnBlur={false}
              options={filterItems(
                "education-field",
                allCommonData && allCommonData.data,
              { community: data && data.community, education_level: state.education_level }
                )}
              onChange={(e, { value }) =>
                this.props.setParentState('education_field',(value && value !== "") ? value : null)
              }
              value={state.education_field}
            />
          </div>
        </div>
        <div className="inline fields mb-4 flex-start">
          <label className="w-50 lh-md">
            <span className="mr-05">
            Working Status
            </span>
          </label>
          <div className="w-100">
            <Dropdown
              search
              className={`wd-input ${Error(
                errors,
                "employment_type"
              )}`}
              placeholder="Select Employment Type"
              fluid
              selection
              clearable
              selectOnBlur={false}
              options={filterItems(
                "employment-type",
                allCommonData && allCommonData.data
              )}
              onChange={(e, { value }) => {
                this.props.setParentState('employment_type',(value && value !== "") ? value : null)
              }
              }
              value={state.employment_type}
            />
          </div>
        </div>
        {![null,1561,1562,1774,""].includes(state.employment_type) && (
          <React.Fragment>
          <div className="inline fields mb-4 flex-start">
          <label className="w-50 lh-md">
            <span className="mr-05">
            Occupation 
            </span>
          </label>
          <div className="w-100">
            <CareerSearch
              placeholder="E.g: Teacher"
              onChange={(value) => {
                this.props.setParentState('occupation',(value && value !== "") ? value : null)
              }}
              value={state.occupation}
            />
          </div>
        </div>
        </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
