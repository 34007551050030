import { PARTNER_CONTACT } from "./constant";
import { createReducer } from "../../../../../util/reducerUtil";

const initState = {
  spinner: false,
  open_verification_modal: false,
  new_phone_number: null
};

/**
 *
 * @param {*} state
 * @param {*} payload
 */
export const setSpinner = (state, payload) => ({
  ...state,
  spinner: payload
});

/**
 *
 * @param {*} state
 * @param {*} payload
 */
export const setVerificationModal = (state, payload) => ({
  ...state,
  open_verification_modal: payload
});

export const set_new_phone_number = (state, payload) => ({
  ...state,
  new_phone_number: payload
});

export default createReducer(initState, {
  [PARTNER_CONTACT.SPINNER]: setSpinner,
  [PARTNER_CONTACT.OPEN_VERIFICATION_MODAL]: setVerificationModal,
  [PARTNER_CONTACT.PHONE_NUMBER]: set_new_phone_number
});
