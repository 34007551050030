import * as constant from "../../constants/ActionTypes";
import { VERIFY } from "../../constants/ActionTypes";
import { createReducer } from "../../../util/reducerUtil";
import isEmpty from "../../../util/isEmpty";

const initState = {
  isVerified: false,
  spinner: false,
  closeModal: false,
  error: "",
  resendOtp: "",
  phoneNumberModal: false,
  changePhonSpinner: false,
  validateTokenData: "",
  validateTokenSpinner: false,
  validNumber: null,
  resetTimerOnChangeNumber: false
};

/**
 * @setVerified
 * @param state
 * @param payload
 * @returns {{isVerified: boolean, user: *}}
 */
export const validNumber = (state, payload) => {
  console.log(payload);
  return {
    ...state,
    validNumber: payload
  };
};

/**
 * @setVerified
 * @param state
 * @param payload
 * @returns {{isVerified: boolean, user: *}}
 */
export const verified = (state, payload) => ({
  ...state,
  isVerified: !isEmpty(payload),
  user: payload
});

/**
 *
 * @param {*} state
 * @param {*} payload
 */
export const spinner = (state, payload) => ({ ...state, spinner: payload });

/**
 *
 * @param {*} state
 * @param {*} payload
 */
export const resendOTP = (state, payload) => ({ ...state, resendOtp: payload });

export const changePhoneModal = (state, payload) => ({
  ...state,
  phoneNumberModal: payload
});

/**
 *
 * @param {*} state
 * @param {*} payload
 */
export const changePhoneNumberSpinner = (state, payload) => ({
  ...state,
  changePhonSpinner: payload
});

/**
 *
 * @param {*} state
 * @param {*} payload
 */
export const validateTokenSpinner = (state, payload) => ({
  ...state,
  validateTokenSpinner: payload
});

/**
 *
 * @param {*} state
 * @param {*} payload
 */
export const validateTokenData = (state, payload) => ({
  ...state,
  validateTokenData: payload
});

/**
 *
 * @param {*} state
 * @param {*} payload
 */
export const resetTimer = (state, payload) => ({
  ...state,
  resetTimerOnChangeNumber: payload
});

/**
 *
 * @param {*} state
 * @param {*} payload
 */
export const setCloseModal = (state, payload) => ({
  ...state,
  closeModal: payload
});

export default createReducer(initState, {
  [constant.VERIFY_NUMBER]: verified,
  [constant.VERIFY_SPINNER]: spinner,
  [constant.RESEND_OTP]: resendOTP,
  [constant.CHANGE_NUMBER_MODAL]: changePhoneModal,
  [constant.CHANGE_NUMBER_SPINNER]: changePhoneNumberSpinner,
  [VERIFY.VALIDATE_TOKEN_SPINNER]: validateTokenSpinner,
  [VERIFY.VALIDATE_TOKEN]: validateTokenData,
  [VERIFY.VALID_NUMBER]: validNumber,
  [VERIFY.RESET_TIMER]: resetTimer,
  [VERIFY.CLOSE_MODAL]: setCloseModal
});
