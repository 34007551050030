import React, { Component } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { ASSETS_CDN, HOMEPAGE, urls } from "../../util/GlobalContants";

import { logoutUser } from "../../redux/actions/user/user";

import MainResponsiveHeader from "./main-responsive";
import HelpCenter from "../../common/shared-components/HelpCenter";
import MyAccount from "../../common/shared-components/MyAccount";
import HeaderLeftMenu from "./header-left-menu";
import ProfileListMenu from "./profile-list-menu";
import HelpCenterMobilePopup from "./HelpCenterMobilePopup";
import MobileStickyFooter from "./mobile_sticky_footer";

class MainHeader extends Component {
  state = {
    profileId: null,
    matchesCount: {},
    profile: "",
    responsiveHeader: false,
    menuActive: false,
    profileListMenuOpen: false,
    helpCenterActive: false
  };

  componentDidMount() {
    this.setState({
      profileId: parseInt(this.props.location.pathname.split("/")[1])
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.profileCards) {
      const matches = nextProps.profileCards.filter(
        profile =>
          parseInt(profile.id) ===
          parseInt(this.props.location.pathname.split("/")[1])
      )[0];
      const sProfile = nextProps.profileCards.filter(
        profile => parseInt(profile.id) === parseInt(this.state.profileId)
      )[0];
      this.setState({ matchesCount: matches, profile: sProfile });
    }
  }

  toggleHeader = () => {
    this.setState({ responsiveHeader: !this.state.responsiveHeader });
  };

  toggleProfileList = () => {
    this.setState({ profileListMenuOpen: !this.state.profileListMenuOpen });
  };
  closeProfileList = () => {
    this.setState({ profileListMenuOpen: false });
  };
  toggleMenu = () => {
    this.setState({ menuActive: !this.state.menuActive });
  };

  closeResponsiveHeader = () => {
    this.setState({ responsiveHeader: false });
  };

  closetoggleMenu = () => {
    this.setState({ menuActive: false });
  };

  toggleHelpCenterPopup = () => {
    this.setState({ helpCenterActive: !this.state.helpCenterActive });
  };

  closeHelpCenterPopup = () => {
    this.setState({ helpCenterActive: false });
  };

  render() {
    const { profileCards, single_user } = this.props;
    return (
      <React.Fragment>
        <MainResponsiveHeader
          active={this.state.responsiveHeader}
          closeResponsiveHeader={this.closeResponsiveHeader}
          logoutUser={this.props.logoutUser}
          history={this.props.history}
          user={this.props.user}
        />
        <ProfileListMenu
          user={this.props.user}
          profileId={this.state.profileId}
          profileListMenuOpen={this.state.profileListMenuOpen}
          profileCards={profileCards}
          closeProfileList={this.closeProfileList}
        />
        <HeaderLeftMenu
          menuActive={this.state.menuActive}
          closetoggleMenu={this.closetoggleMenu}
        />
        <HelpCenterMobilePopup
          helpCenterActive={this.state.helpCenterActive}
          closeHelpCenterPopup={this.closeHelpCenterPopup}
          profileId={this.state.profileId}
        />
        <div className="ui fixed borderless menu m-auto sticky-header profile-header">
          <div className="ui container grid">
            <div className="computer only row p-0">
              <NavLink exact to={`/${this.state.profileId}`} className="item hidden-xs">
                <FormattedMessage id="header.dashboard.home">{txt => txt }</FormattedMessage>
              </NavLink>
              <NavLink to={`/${this.state.profileId}/matches`} className="item hidden-xs">
                <FormattedMessage id="header.dashboard.matches">{txt => txt }</FormattedMessage>{" "}
              </NavLink>

              {/*<NavLink to={`/${this.state.profileId}/messages`} className="item hidden-xs">
              <i className="icon-sm i-message"></i>
              {this.props.unread > 0 && (
                <div className="floating ui circular label badge-blue custom-floating">{this.props.unread}</div>
                )}
              </NavLink>*/}

              <div className="right menu">
                <HelpCenter profileId={this.state.profileId} />

                <span className="vr-divider" />

                <MyAccount profileId={this.state.profileId} />
              </div>
            </div>
            <div className="tablet mobile only row p-0">
              <div className="column">
                <div className="ui menu b-none no-shadow">
                  <Link to={`/${this.state.profileId}`} className="wd-brand desktop">
                      <img
                        src={`${ASSETS_CDN}/img/brand2.png`}
                        alt="sameepam-logo"
                      />
                  </Link>
                  <Link to={`/${this.state.profileId}`} className="wd-brand mobi">
                      <img
                        src={`${ASSETS_CDN}/img/logo-small.png`}
                        alt="sameepam-logo"
                      />
                  </Link>
                  <Link to={`/${this.state.profileId}`} className="wd-brand white">
                      <img
                        src={`${ASSETS_CDN}/img/brand-white.png`}
                        alt="sameepam-logo"
                      />
                  </Link>

                  <NavLink exact to={`/${this.state.profileId}`} className="item hidden-xs">
                    <FormattedMessage id="header.dashboard.home">{txt => txt }</FormattedMessage>
                  </NavLink>
                  <NavLink to={`/${this.state.profileId}/matches`} className="item hidden-xs">
                    <FormattedMessage id="header.dashboard.matches">{txt => txt }</FormattedMessage>{" "}
                  </NavLink>
                  
                  <div className="my-account-mobi mr-1 radius-3" onClick={this.toggleProfileList} >
                      <div className="card">
                          <div className="content p-05">
                              <span className="ui image">
                                  <img alt="profile_photo" src={
                                    single_user && single_user.photo
                                      ? single_user.photo
                                      : single_user && single_user.gender &&
                                        `https://storage.googleapis.com/matrimony-assets/dashboard/img/${single_user.gender}.png`
                                  } />
                              </span>
                              <div className="o-hidden">
                                  <div className="header pr-1">{single_user.name}</div>
                                  <div className="meta">{single_user.did}</div>
                              </div>
                              <i className="dropdown icon mr-0 mt-05 top-3 pos-relative text-white"></i>
                          </div>
                      </div>
                  </div>

                  <div className="right menu item-center">
                    <HelpCenter profileId={this.state.profileId} />
                    <MyAccount profileId={this.state.profileId} />
                    
                    <div className="ui basic buttons wd-menu-button"
                    onClick={this.toggleHeader}>
                    <button className="ui icon button">
                      <span className="m-line" />
                      <span className="m-line" />
                      <span className="m-line" />
                    </button>
                  </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <MobileStickyFooter
          profile_id={this.state.profileId}
          single_user={single_user}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  profileCards: state.user.profileCards,
  single_user: state.user.singleProfileData,
  unread : state.user.unread,
});

export default injectIntl(
  connect(
    mapStateToProps,
    { logoutUser }
  )(withRouter(MainHeader))
);
